import { Box, ErrorExclamationView, ICommand, MaskedOptions, RelayCommand, theme, themeShapeOptions, themeSizeOptions } from "Application";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import { IMaskInput } from "react-imask";
import React, { useEffect, useRef, useState } from "react";
import Datetime from "react-datetime";
import clsx from "clsx";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { FieldLabel, InputWrapper } from "../CommonStyles";
import { CalendarSVG } from "Assets/Icons/CalendarSVG";
import { ClockIcon } from "Assets/Icons/ClockSVG";
import { ClearText } from "../../General/ClearText";
/**
 * Edit Date interface.
 */
interface IEditDateBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: () => Date | undefined;
    /**
     * Text content to display as the label of the date control.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * Styling of the control.
     */
    style?: React.CSSProperties;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;
    /**
     * Any JSX Element
     */
    prefix?: JSX.Element;
    /**
     * Any JSX Element
     */
    suffix?: JSX.Element;
    /**
     * The control type - password or text.
     */
    maxDate?: Date;
    cy?: string;
    initialDate?: Date;
    includeTime?: boolean;
    timePicker?: boolean;
    dateFormat?: string;
    isWeekDisable?: boolean;
    currentDate?: Date | string;
    isRequired?: boolean;
}
export const EditDate: React.FC<IEditDateBaseProps> = observer((props) => {
    // #region DatePicker Code Behind
    const [internalString, setInternalString] = React.useState<string | null>(null);
    const [internalDate, setInternalDate] = React.useState<moment.Moment | string | undefined | null>();
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    const [openCalendar, setOpenCalendar] = useState(false);
    const datetimeRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (datetimeRef.current && !datetimeRef.current.contains(event.target as Node)) {
                setOpenCalendar(false);
            }
        };
        window.addEventListener("mousedown", handleOutsideClick);
        window.addEventListener("keydown", (event) => {
            if (event.keyCode === 9) {
                setOpenCalendar(false);
            }
        });
        return () => {
            window.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    useEffect(() => {
        reaction(
            () => moment(props.value()).toDate(),
            (value) => {
                setInternalDate(moment(value));
                if (!isNullOrUndefined(props.value())) {
                    setInternalString(moment(props.value())!.format(props.dateFormat || theme.dates.default));
                }
            },
        );
        setInternalDate(moment(props.value()));
        setInternalString(moment(props.value())!.format(props.dateFormat || theme.dates.default));
    }, []);
    const onSelectDate = (date: moment.Moment | string): void => {
        props.command.execute(isNullOrUndefined(date) ? null : moment(date).toDate());
        // setOpenCalendar(false);
    };
    // #endregion DatePicker Code Behind
    // #region Code Behind
    const getClassNames = () => {
        return clsx({ ["drop-to-the-right"]: true, [props.className!]: !isEmptyOrWhitespace(props.className) });
    };
    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };
    const getValue = (): moment.Moment | string | undefined | null => {
        return internalDate;
    };
    const canDisplayName = (): boolean => {
        return !isEmptyOrWhitespace(props.displayName);
    };
    const updateValue = (newValue: any) => {
        setInternalString(newValue as string);
    };
    const convertTimeToDate = (value: any) => {
        const initialMoment = moment(value, "HH:mm:ss");
        const initialDate = new Date();
        initialDate.setHours(initialMoment.hours());
        initialDate.setMinutes(initialMoment.minutes());
        return initialDate.toString();
    };
    const updateDate = (eventOrValue: any) => {
        let newValue;
        if (eventOrValue.target) {
            newValue = eventOrValue.target.value;
            const convertedNewValue = convertTimeToDate(newValue);
            props.command.execute(convertedNewValue);
        } else {
            newValue = eventOrValue;
        }
        setInternalString(newValue as string);
        // setOpenCalendar(false);
        newValue = newValue.replace(/ /g, "");
        const checkDate = moment(newValue, "DD/MM/YYYY");
        if (newValue.length === 10) {
            if (checkDate.isValid()) {
                setInternalDate(checkDate);
                props.command.execute(checkDate);
                // setOpenCalendar(false);
            } else {
                /*console.log("Date is not valid");
               setInternalString("");
               setInternalDate(null);
               props.command.execute(null);*/
            }
        }
    };

    const isFieldRequired = (): boolean => {
        return props.isRequired === true;
    };

    const validateDate = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const checkDate = moment(internalString, "DD/MM/YYYY");
        if (checkDate.isValid()) {
            setInternalDate(checkDate);
            props.command.execute(checkDate);
            // setOpenCalendar(false);
        } else {
            /*console.log("Date is not valid");
            setInternalString("");
            setInternalDate(null);
            props.command.execute(null);*/
        }
    };
    const canDisplayClear = (): boolean => {
        if (!isNullOrUndefined(props.value())) {
            return !isEmptyOrWhitespace(internalString);
        }
        return false;
    };
    const clearDateCommand = new RelayCommand(() => {
        setInternalString(null);
        setInternalDate(null);
        props.command.execute(null);
    });
    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");
        return props.cy || result;
    };
    // #endregion Code Behind
    const renderInput = (dateProps: any, openCalendar: any, closeCalendar: any) => {
        return (
            <IMaskInput
                {...(MaskedOptions.Date(props.dateFormat) as any)}
                onAccept={(value, mask) => {
                    updateDate(mask.value);
                }}
                onFocus={() => setOpenCalendar(true)}
                className={getClassNames()}
                onInput={updateDate}
                //onBlur={validateDate}
                // onChange={updateDate}
                placeholder={props.placeholder}
                value={internalString}
                data-cy={getDataCY()}
            />
        );
    };
    /**
     * Disable week days
     * @param current
     * @returns
     */
    const weekDys = (current: any) => {
        if (props.currentDate) {
            const currentDate = moment(props.currentDate, "DD-MM-YYYY"); // Parse the current date string
            const currentWeekStart = currentDate.clone().startOf("week");
            const currentWeekEnd = currentDate.clone().endOf("week");
            return (
                current.isSameOrAfter(currentWeekStart) &&
                current.isSameOrBefore(currentWeekEnd) &&
                current.day() !== 0 && // 0 is Sunday
                current.day() !== 6
            ); // 6 is Saturday
        } else if (props.isWeekDisable) {
            return current.day() !== 0 && current.day() !== 6;
        } else {
            return current;
        }
    };
    return (
        <InputWrapper>
            <Box flexBox>
                <FieldLabel className="placeholder">
                    {props.displayName}
                    {isFieldRequired() && <span style={{ color: theme.palette.common.warning }}>*</span>}
                </FieldLabel>
                <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />
            </Box>
            <Box className={"inputbox"}>
                {!props.suffix && (
                    <Box ml={1} className="calender-icon" onClick={() => setOpenCalendar(true)} style={{ cursor: "pointer" }}>
                        <CalendarSVG />
                    </Box>
                )}
                <Box flexBox style={{ width: "100%", gap: 0 }}>
                    <div ref={datetimeRef}>
                        <Datetime
                            open={openCalendar}
                            renderInput={renderInput}
                            className={getClassNames()}
                            onChange={onSelectDate}
                            value={getValue() as any}
                            closeOnSelect={true}
                            dateFormat={props.timePicker ? false : theme.dates.default}
                            timeFormat={props.includeTime}
                            isValidDate={weekDys}
                            // closeOnClickOutside={true}
                        />
                    </div>
                    {/* <Box minWidth="25px" center>
                        {canDisplayClear() && <ClearText command={clearDateCommand} />}
                    </Box> */}
                </Box>
                {props.suffix && (
                    <Box className="calender-icon" display={"flex"} alignItems={"center"} mr={"15px"} onClick={() => setOpenCalendar(true)} style={{ cursor: "pointer" }}>
                        {props.suffix ? props.suffix : <CalendarSVG />}
                    </Box>
                )}
            </Box>
        </InputWrapper>
    );
});
