import styled from "@emotion/styled";
import React from "react";

interface IPageRootProps {
    padding?: string;
}

export const PageRoot = styled.div<IPageRootProps>`
    // This class is the immediate parent of a scrollable section.
    // It has no height and min height of 0 for the scrollbar to be visible.
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    padding: ${(props) => props.padding || "0px 40px 0px"};
    @media only screen and (max-width: 1100px) {
        padding: ${(props) => props.padding || "0px 15px 0px"};
    }
    .filter-container {
        display: flex;
        margin-bottom: 2rem;

        .edit-filter-keyword,
        .editselect-filter-role {
            margin-right: 1rem;
            width: 20rem;
        }

        button {
            font-size: 0.75rem;
            margin-left: auto;
            padding: 0.25rem 2rem;
        }
    }
    .scrollable-section {
        // A scrollable section. Hereafter a height is not required. Plain flex
        // will do.
        /* overflow-y: auto; */
    }
    .error-scrollable-section {
        // A scrollable section. Hereafter a height is not required. Plain flex
        // will do.
        overflow-y: auto;

        // All flexbox below is needed to center the error.
        display: flex;
        flex: 1;

        > div {
            display: flex;
            flex: 1;
            flex-direction: column;
        }
    }
`;

interface IPageContainerProps {
    padding?: string;
    style?: React.CSSProperties;
}

export const PageContainer = styled.div<IPageContainerProps>`
    padding: ${(props) => props.padding || "30px 0px 0px"};

    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0;
`;

interface IPageHeaderProps {
    padding?: string;
}

export const PageHeader = styled.div<IPageHeaderProps>`
    height: 100px;
    display: flex;
    align-items: center;
    padding: ${(props) => props.padding || "0px"};
    padding-top: 10px;
`;

interface IPageSubHeaderProps {
    padding?: string;
    style?: React.CSSProperties;
}

export const PageSubHeader = styled.div<IPageSubHeaderProps>`
    display: flex;
    justify-content: space-between;
    padding: ${(props) => props.padding || "0px"};
    @media only screen and (max-width: 991px) {
        flex-wrap: wrap;
        gap: 15px;
    }
`;

export const PageFilterLabelGroup = styled.div`
    display: flex;
    align-items: center;
    & > :first-of-type {
        margin-right: 30px;
    }
    @media only screen and (max-width: 600px) {
        display: grid;
        gap: 12px;
        margin-right: 0;
        width: 100%;
        .editselect {
            width: 100% !important;
            & > div {
                width: 100% !important;
            }
        }
    }
`;
