import styled from "@emotion/styled";
import { RelayCommand, theme } from "Application";
import { Box, Button, Label } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import React from "react";

const Container = styled.div`
    padding: 25px 20px;
`;

const DataScroll = styled(Box)`
    &::-webkit-scrollbar {
        width: 1px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px 30px;
    justify-content: center;
    Button {
        width: 100%;
        margin: 0 auto;
        height: 40px;
        border-radius: 5px;
        max-width: 150px;
        background-color: #fcd21d;
        color: #000;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 400px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

export interface IProps {
    onCancel: () => void;
    data?: any;
}

const TableInnerData = styled(Box)`
    & > div:nth-child(even) {
        background: #f9f9f9;
    }
`;

let sessionLeftDayTime = [
    {
        day: "Monday",
        key: "Mon",
        time: [{ name: "am" }, { name: "pm" }],
    },
    {
        day: "Tuesday",
        key: "Tues",
        time: [{ name: "am" }, { name: "pm" }],
    },
    {
        day: "Wednesday",
        key: "Weds",
        time: [{ name: "am" }, { name: "pm" }],
    },
    {
        day: "Thursday",
        key: "Thur",
        time: [{ name: "am" }, { name: "pm" }],
    },
    {
        day: "Friday",
        key: "Fri",
        time: [{ name: "am" }, { name: "pm" }],
    },
];

const SeesionLeftModal: React.FC<IProps> = (props: IProps) => {
    const weeklyRotaModalCloseCommand = new RelayCommand(() => {
        props.onCancel();
    });

    return (
        <>
            <StyledDialog open={true} style={{ zIndex: 1 }}>
                <Header>
                    Sessions left to assign <CloseIcon onClick={() => weeklyRotaModalCloseCommand.execute()} />
                </Header>
                <Container>
                    <DataScroll maxHeight="44vh" overflowY="auto">
                        {sessionLeftDayTime.map((item) => {
                            const hasValidStartTime = props.data.model.leftStudentSession.some((std: any) => {
                                const startTimeKey = `startTime${item.key}`;
                                const startTime = std[startTimeKey];
                                return startTime !== "00:00:00";
                            });

                            // Check if any student has non-zero end time for the current day
                            const hasValidEndTime = props.data.model.leftStudentSession.some((std: any) => {
                                const endTimeKey = `endTime${item.key}`;
                                const endTime = std[endTimeKey];
                                return endTime !== "00:00:00";
                            });

                            if (hasValidStartTime || hasValidEndTime) {
                                return (
                                    <Box flex={"1 0 0"} key={item.day}>
                                        <Box background={"#F3F3F3"} textAlign={"center"} padding={"12px"} mb={"2px"}>
                                            <Label
                                                style={{
                                                    font: "normal normal bold 12px/17px Open Sans",
                                                    color: "#000000",
                                                    fontFamily: "OpenSans-CondBold",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {item.day}
                                            </Label>
                                        </Box>
                                        <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} alignItems={"flex-start"} style={{ gap: "2px" }}>
                                            {item.time.map((day) => (
                                                <Box display={"grid"} style={{ gap: "2px" }} key={day.name}>
                                                    <Box background={"#F3F3F3"} textAlign={"center"} padding={"12px"}>
                                                        <Label
                                                            style={{
                                                                font: "normal normal bold 12px/17px Open Sans",
                                                                color: "#000000",
                                                                fontFamily: "OpenSans-CondBold",
                                                                textTransform: "uppercase",
                                                            }}
                                                        >
                                                            {day.name}
                                                        </Label>
                                                    </Box>
                                                    <TableInnerData>
                                                        {props.data.model.leftStudentSession.map((std: any) => {
                                                            const startTimeKey = `startTime${item.key}`;
                                                            const endTimeKey = `endTime${item.key}`;
                                                            const startTime = std[startTimeKey];
                                                            const endTime = std[endTimeKey];
                                                            if (
                                                                startTime !== "00:00:00" || // Include entries with "00:00:00" time
                                                                endTime !== "00:00:00" // Include entries with "00:00:00" time
                                                            ) {
                                                                const startTimeHour = parseInt(startTime?.split(":")[0]);
                                                                const endTimeHour = parseInt(endTime?.split(":")[0]);

                                                                if (
                                                                    (day.name === "am" && startTimeHour >= 9 && startTimeHour <= 12 && endTimeHour >= 9 && endTimeHour <= 12) ||
                                                                    (day.name === "pm" && (startTimeHour < 9 || endTimeHour < 9 || endTimeHour > 12))
                                                                ) {
                                                                    return (
                                                                        <Box
                                                                            bg={"#fff"}
                                                                            p={"12px 10px"}
                                                                            style={{
                                                                                textDecoration: "underline",
                                                                                font: "normal normal normal 12px/16px Open Sans",
                                                                                letterSpacing: "-0.12px",
                                                                                color: "#000000",
                                                                            }}
                                                                            key={std.studentId}
                                                                        >
                                                                            {std.studentName}
                                                                        </Box>
                                                                    );
                                                                }
                                                            }
                                                            return null;
                                                        })}
                                                    </TableInnerData>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                );
                            }
                        })}
                    </DataScroll>
                    <Footer>
                        <ButtonGroup>
                            <Button command={weeklyRotaModalCloseCommand} displayName={"CANCEL"} paletteColor="secondary" />
                        </ButtonGroup>
                    </Footer>
                </Container>
            </StyledDialog>
        </>
    );
};

export default SeesionLeftModal;
