import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Label, ThemedLoader, setPageTitle } from "Application";
import { ResourceDocumentsViewModel } from "./ResourceDocumentsViewModel";
import { PageFilterLabelGroup, PageHeader, PageRoot } from "Styles/PageStyles";
import { ApplicationError } from "Components/Primitives/Application";
import { ResourceDocumentsTable } from "./Components/ResourceDocumentsTable";
import ThemedPromptModal from "Styles/ThemedPrimitives/Style1/ThemedPromptModal";
import { ThemedFormModal } from "Styles/ThemedPrimitives/Style1/ThemedFormModal";
import { ResourceDocumentFormView } from "../ResourceDocumentForm/ResourceDocumentFormView";
import { DownloadIcon } from "Assets/Icons/DownloadIcon";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import styled from "@emotion/styled";
import { ResourceDocumentTableItemViewModel } from "../ResourceDocumentTableItem/ResourceDocumentTableItemViewModel";
import { ClearButton } from "Components/General/ClearButton";
import { MinusSVG } from "Assets/Icons/MinusSVG";
import { CoreStoreInstance } from "@shoothill/core";

const PageMainHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    & > div {
        label {
            margin-right: 10px !important;
        }
        & > div {
            margin-right: 0 !important;
        }
    }
    @media only screen and (max-width: 600px) {
        & > button {
            min-width: 100% !important;
        }
    }
`;

const CloseBtn = styled(Box)`
    cursor: pointer;
    button {
        height: 20px;
        width: 20px;
        min-height: 20px;
    }
`;

const PageWrapper = styled(Box)`
    padding-top: 30px;
    margin-bottom: 8rem;
`;

export const ResourceDocumentsView: React.FC = observer(() => {
    //region boilerplate
    const [viewModel] = useState(() => new ResourceDocumentsViewModel());

    useEffect(() => {
        setPageTitle("Resources");
    }, []);
    //endregion boilerplate

    //region components
    const renderDeleteMentorModel = () => {
        return (
            <>
                <ThemedPromptModal
                    title={"Are you sure you want to delete this resource?"}
                    isOpen={viewModel.canShowDeleteResourceDocumentModal}
                    confirmCommand={viewModel.submitResourceDocumentDeletionCommand}
                    cancelCommand={viewModel.cancelResourceDocumentDeletionCommand}
                    hideIcon
                />
            </>
        );
    };

    const fileNameConvert = (text: string) => {
        let modifiedString = text.substring(0, text.lastIndexOf(".")).replace(/[_-]/g, " ");
        modifiedString = modifiedString.charAt(0).toUpperCase() + modifiedString.slice(1).toLowerCase();
        return modifiedString;
    };

    const renderFileName = (vm: ResourceDocumentTableItemViewModel) => {
        return (
            <Box flexBox alignItems={"center"} gap={"10px"} style={{ cursor: "pointer" }}>
                <ClearButton command={vm.downloadResourceDocumentCommand} displayName={<DownloadIcon />} />
                <Box>
                    <Label>{vm.fileName}</Label>
                    <Label style={{ font: "normal normal normal 10px/16px Open Sans", letterSpacing: "-0.1px", color: "#D4D2D7" }}>{vm.formattedFileSize}</Label>
                    <Label
                        style={{ font: "normal normal normal 10px/16px Open Sans", letterSpacing: "-0.1px", color: "#D4D2D7" }}
                    >{`${vm.formattedCreatedDate} (${vm.createdByFullName})`}</Label>
                </Box>
            </Box>
        );
    };

    const renderMobileView = () => {
        return viewModel.filteredResourceViewModels.map((item: ResourceDocumentTableItemViewModel) => {
            return (
                <Box display={"grid"} style={{ gap: "15px" }}>
                    <Box bg={"#F3F3F3"} p={"12px 10px"} position={"relative"} fontFamily={"OpenSans-CondBold"} fontSize={"14px"} color={"#000"}>
                        {fileNameConvert(item.fileName)}
                        <CloseBtn position={"absolute"} right={0} bottom={0} height={"20px"} width={"20px"}>
                            <ClearButton command={item.deleteResourceDocumentCommand} displayName={<MinusSVG />} />
                        </CloseBtn>
                    </Box>
                    <Box border={"1px solid #DCDCDC"} p={"8px 10px"}>
                        <Label>{renderFileName(item)}</Label>
                    </Box>
                </Box>
            );
        });
    };

    const renderUploadResourceDocumentModal = () => {
        if (viewModel.canShowUploadNewResourceDocumentModal) {
            return (
                <ThemedFormModal
                    isOpen={viewModel.canShowUploadNewResourceDocumentModal}
                    onCloseCommand={viewModel.resourceDocumentFormViewModel!.closeResourceDocumentModalCommand}
                    closeOnClickOutside={false}
                    title="Upload new resource"
                >
                    <ResourceDocumentFormView viewModel={viewModel.resourceDocumentFormViewModel!} isLoading={viewModel.isLoading} />
                </ThemedFormModal>
            );
        } else {
            return <></>;
        }
    };
    //endregion components

    return (
        <PageRoot style={{ marginBottom: "10rem" }}>
            <PageHeader>
                <Label fontStyle="h1" color="black">
                    Resources
                </Label>
            </PageHeader>
            <PageWrapper>
                {/* region filter and upload new resource */}
                <PageMainHeader>
                    <PageFilterLabelGroup>
                        <Label fontStyle="h2">Resource category:</Label>
                        <ThemedEditSelect
                            noHeader
                            displayName="All categories"
                            options={viewModel.resourceCategoriesOptions}
                            command={viewModel.updateFilterResourceCategoryIdCommand}
                            style={{ width: "150px" }}
                            value={() => viewModel.getValue("filterResourceCategoryId")}
                        />
                    </PageFilterLabelGroup>
                    <Button paletteColor="primary" command={viewModel.openUploadNewResourceDocumentModalCommand} displayName={"Upload new resource"} style={{ minWidth: 310 }} />
                </PageMainHeader>
                <Box mt="32px">
                    {/* endregion filter and upload new resource */}
                    {(() => {
                        switch (true) {
                            case viewModel.apiClient.IsBusy:
                                return <ThemedLoader isOpen delayBeforeOpen={1000} containerStyles={{ height: "calc(100vh - 160px)", width: "calc(100vw - 80px)" }} />;

                            case viewModel.apiClient.IsSubmitted && viewModel.apiClient.HaveValidationMessage:
                                return (
                                    <div className="error-scrollable-section">
                                        <div>
                                            <ApplicationError additionalMessage={viewModel.apiClient.ValidationMessage} resetCommand={viewModel.resetApiClientErrorCommand} />
                                        </div>
                                    </div>
                                );

                            default:
                                return (
                                    <Box>
                                        {CoreStoreInstance._isDesktop ? (
                                            <ResourceDocumentsTable data={viewModel.filteredResourceViewModels} />
                                        ) : (
                                            <Box display={"grid"} style={{ gap: "15px" }}>
                                                {renderMobileView()}
                                            </Box>
                                        )}
                                    </Box>
                                );
                        }
                    })()}
                </Box>
            </PageWrapper>
            {/* region modals */}
            {renderDeleteMentorModel()}
            {renderUploadResourceDocumentModal()}
            {/* endregion modals */}
        </PageRoot>
    );
});
