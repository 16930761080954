import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { CompanyLogo } from "Assets/CompanyLogo";
import { Box, Show, theme } from "Application";
import clsx from "clsx";
import AnimateHeight from "react-animate-height";
import SignInView from "./SignIn/SignInView";
import RegisterView from "./SignUp/RegisterView";
import { AccountStore } from "Stores/Domain";
import { container } from "tsyringe";
import { GlobalHistory } from "index";
import { LoginHomeViewModel } from "./LoginHomeViewModel";
import { ForgotPasswordView } from "./SignIn/ForgotPassword/ForgotPasswordView";
import { NavLink, Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";
import ConfirmEmailView from "./SignUp/ConfirmEmail/ConfirmEmailView";
import { AppUrls } from "../../AppUrls";
import artboard from "Assets/Artboard.png";
import { ResetPasswordView } from "./SignIn/ForgotPassword/ResetPasswordView";

export const Wrapper = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    content {
        padding: pxToRem(20);
        margin: auto;
    }
    leftContent {
        margin: auto;
        max-width: pxToRem(325);
    }
    @media only screen and (max-width: 1024px) {
        .logoImg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: #fcd21d;
            width: 120px;
            height: 120px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                padding: 10px;
            }
        }
    }
    maxHeight {
        height: 100vh;
    }
    .tab {
        border: 1px solid #dcdcdc !important;
        height: 30px;
        gap: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        .selected {
            background-color: ${theme.palette.secondary.main};
            color: ${theme.palette.secondary.text};
        }
        .notSelected {
            background-color: white;
            color: black;
        }
    }
`;

/**
 * Login page view.
 */

const LoginHomeView: React.FunctionComponent = observer(() => {
    // #region Code Behind
    const accountStore = container.resolve(AccountStore);
    const isMentor = accountStore.isInRole("MENTOR");
    const [viewModel] = useState(() => new LoginHomeViewModel());
    useEffect(() => {
        if (accountStore.IsLoggedIn) {
            if (isMentor) GlobalHistory.push(AppUrls.Client.Admin.Mentor.Root);
            else GlobalHistory.push(AppUrls.Client.Students.Current);
        }
        if (GlobalHistory.location.pathname == AppUrls.Client.Account.ForgotPassword || GlobalHistory.location.pathname == AppUrls.Client.Account.RegisterSuccess) {
            viewModel.signUpButtonsVisible = false;
        }
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
    };

    // #endregion Code Behind

    const renderForm = () => (
        <Wrapper onSubmit={doSubmit}>
            <Box grid className={"maxHeight"} columnGap={30} rowGap={20} mc={"1fr"} tc={"1fr"} dc={"1fr"}>
                <Box grid rowGap={"20px"} dc={"1fr"} className={"leftContent"} alignContent={"flex-start"}>
                    <Box className={"logoImg"} textAlign={"center"}>
                        <img src={artboard} alt="" />
                    </Box>
                    <AnimateHeight id="login-panel" duration={500} height={"auto"}>
                        <Routes>
                            <Route path="login" element={<SignInView loginHomeViewModel={viewModel} />} />
                            <Route path="register/*" element={<RegisterView loginHomeViewModel={viewModel} />} />
                            <Route path="newuser/:token/*" element={<RegisterView loginHomeViewModel={viewModel} />} />
                            <Route path="forgotpassword/*" element={<ForgotPasswordView loginHomeViewModel={viewModel} />} />
                        </Routes>
                    </AnimateHeight>
                </Box>
            </Box>
        </Wrapper>
    );

    return <React.Fragment>{renderForm()}</React.Fragment>;
});
export default LoginHomeView;
