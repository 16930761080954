import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import { ICommand } from "../../Application/Commands";
import styled from "@emotion/styled";
import { Label } from "Components";
import { theme } from "Application";

export interface Props {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * An optional class name for use with the button when it is selected.
     */
    selectedClassName?: string;
    /**
     * An optional React css properties object for use with the button.
     */
    style?: React.CSSProperties;
    /**
     * An optional  React css properties object for use with the button when it is selected.
     */
    selectedStyles?: React.CSSProperties;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * Text content to display as the label of the date control.
     */
    displayName?: string;
    /**
     * States if the option is in a selected state.
     */
    isSelected: boolean;

    show?: boolean;
}

const Link = styled.div`
    transition: background-color 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
        background-color: ${theme.palette.primary.lighter};
        & > * {
            cursor: pointer;
        }
    }
`;

export const HeaderLink: React.FC<Props> = observer((props) => {
    // #region Code Behind

    const getStyles = (): React.CSSProperties => {
        const styles = props.style;
        const selectedStyles = props.isSelected ? props.selectedStyles : undefined;
        return {
            ...styles,
            ...selectedStyles,
        } as React.CSSProperties;
    };

    const getClassNames = (): string => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
            [props.selectedClassName!]: props.isSelected && !isEmptyOrWhitespace(props.selectedClassName),
        });
    };

    const isVisible = (): boolean => {
        const retVal = isNullOrUndefined(props.command.canExecute) ? true : props.command.canExecute();
        return props.show ?? true;
    };

    const onClick = (): void => {
        const { command } = props;
        if (command.canExecute()) {
            command.execute();
        }
    };

    // #endregion Code Behind

    return isVisible() ? (
        <Link className={getClassNames()} style={getStyles()} onClick={onClick}>
            <Label fontStyle="headerOptionLabel">{props.displayName}</Label>
        </Link>
    ) : null;
});
