import { action, computed, makeObservable, observable } from "mobx";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { WeeklyRotaModel, WeeklyRotaModelValidator } from "./WeeklyRotaModel";
import { POSTRotaEndPoint } from "./EndPoint/POSTRotaEndPoint";
import { WeeklyRotaDetailsViewModel } from "./WeeklyRotaDetail/WeeklyRotaDetailViewModel";
import { GETRotaByDateEndPoint } from "./EndPoint/GETRotaByDateEndPoint";
import moment from "moment";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";

export class WeeklyRotaViewModel extends ViewModelBase<WeeklyRotaModel> {
    lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();
    public isWeeklyRotaModalOpen: boolean = false;
    public copyWeeklyData: boolean = false;
    public successfullyGetWeeklyRota: boolean = false;
    public weeklyRotaDetailViewModel = new WeeklyRotaDetailsViewModel();

    constructor() {
        super(new WeeklyRotaModel());
        this.setValidator(new WeeklyRotaModelValidator());
        makeObservable(this, {
            isWeeklyRotaModalOpen: observable,
            copyWeeklyData: observable,
            successfullyGetWeeklyRota: observable,
            weeklyRotaDetailViewModel: observable,

            //action

            setCopyWeeklyData: action,
            setIsWeeklyRotaModalOpen: action,
            setSuccessfullyGetWeeklyRota: action,
            //computed
            copyWeeklyDataRadioButtonValue: computed,
        });
        // this.loadRotaByDate();
        this.model.startDate = moment(new Date()).startOf("isoWeek").toDate();
    }

    //region properties
    public get formattedDateRange() {
        return moment(this.getValue("startDate")).format("YYYY-MM-DD");
    }

    public get copyWeeklyDataRadioButtonValue() {
        return this.copyWeeklyData ? "yes" : "no";
    }

    public get yesNoOptions() {
        return [
            {
                key: "yes",
                text: "Yes",
            },
            {
                key: "no",
                text: "No",
            },
        ] as KeyValuePair[];
    }

    //endregion properties

    //region actions
    public setCopyWeeklyData = (value: boolean) => {
        this.copyWeeklyData = value;
        this.model.copyFromPreviousWeek = value;
    };
    public setIsWeeklyRotaModalOpen = (value: boolean) => {
        this.isWeeklyRotaModalOpen = value;
    };

    public setSuccessfullyGetWeeklyRota = (value: boolean) => {
        this.successfullyGetWeeklyRota = value;
    };

    //endregion actions

    // #region Api Commands

    public resetApiClientErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Api Commands

    //region commands

    public toggleWeeklyRotaModalOpenStateCommand = new RelayCommand(() => {
        this.setIsWeeklyRotaModalOpen(!this.isWeeklyRotaModalOpen);
    });

    public updateNextDateCommand = new RelayCommand(() => {
        this.weeklyRotaDetailViewModel.model.startDate = moment(this.model.startDate).add(1, "week").startOf("isoWeek").toISOString();
        this.updateField("startDate", moment(this.model.startDate).add(1, "week").startOf("isoWeek").toISOString());
        this.loadRotaByDate();
    });

    public updatePreviousDateCommand = new RelayCommand(() => {
        this.weeklyRotaDetailViewModel.model.startDate = moment(this.model.startDate).add(-1, "week").startOf("isoWeek").toISOString();
        this.updateField("startDate", moment(this.model.startDate).add(-1, "week").startOf("isoWeek").toISOString());
        this.loadRotaByDate();
    });

    public addWeeklyRotaCommandAsync = new RelayCommand(() => {
        this.postRotaAsync().then(() => {
            if (this.apiClient.IsRequestSuccessful) {
                this.setIsWeeklyRotaModalOpen(false);
                this.setSuccessfullyGetWeeklyRota(true);
                // this.loadRotaByDate();
            }
        });
    });

    public copyWeeklyRotaCommand = new RelayCommand((value: "yes" | "no") => {
        this.setCopyWeeklyData(value === "yes");
    });

    private async updateField(fieldName: keyof FieldType<WeeklyRotaModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateRotaDateRangeCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("startDate", value);
        this.model.selectedWeekStartDate = value;
    });

    public updateAddRotaDateRangeCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("startDate", moment(value).format("YYYY-MM-DD"));
    });

    public async loadRotaByDate(): Promise<void> {
        let _ = this.apiClient.sendAsync(new GETRotaByDateEndPoint(moment(this.model.startDate).format("YYYY-MM-DD"), this.weeklyRotaDetailViewModel));
        if (this.apiClient.IsRequestSuccessful) {
        }
    }
    private postRotaAsync = async () => {
        await this.apiClient.sendAsync(new POSTRotaEndPoint(this, this.weeklyRotaDetailViewModel), this.model);
    };

    //endregion server calls
}
