import * as React from "react";
import clsx from "clsx";
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import { Box, ErrorExclamationView, ICommand, theme } from "Application";
import Select, { FormatOptionLabelMeta } from "react-select";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { FieldLabel } from "../CommonStyles";
import { observer } from "mobx-react-lite";
import CreatableSelect from "react-select/creatable";
// #region View Props

const SelectContainer = styled.div`
    .editselect__placeholder {
        font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize} !important;
    }
    .editselect__control {
        border: 1px solid #abb7c233;
        border-radius: 0 !important;
    }
    .editselect__control--is-focused {
        border: 1px solid ${theme.palette.common.focus} !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }
    .editselect__value-container {
        padding: 3px 8px;
        white-space: nowrap !important;
    }
    .virtualized-select-validation {
        flex-grow: 1;
    }

    .editselect__input {
        font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize} !important;
        font-family: ${theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily} !important;
    }

    .editselect__single-value {
        font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize} !important;
        font-family: ${theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily} !important;
    }

    .editselect__indicator-separator {
        display: none;
    }

    .editselect__menu {
        border-radius: 0px !important;
    }

    .editselect__menu-list {
        border-radius: 0px !important;
    }

    .editselect__option {
        height: 40px !important;
        font-size: ${theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize} !important;
        font-family: ${theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily} !important;

        &--is-focused {
            background-color: ${theme.palette.primary.lighter};
        }

        &--is-selected,
        &--is-active {
            background-color: ${theme.palette.primary.light};
            color: ${theme.palette.primary.text};
        }
    }
`;

export interface IEditSelectProps {
    canClear?: boolean;
    command: ICommand;
    className?: string;
    displayName?: string;
    optionRenderer?: (item: any) => void;
    options: KeyValuePair[];
    placeholder?: string;
    //summary:style applied to the select container
    style?: any;
    value: () => string;
    isMulti?: boolean;
    validationMessage?: () => string;
    noHeader?: boolean;
    ref?: React.Ref<any>;
    cy?: string;
    formatOptionLabel?: ((data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => React.ReactNode) | undefined;
    isRequired?: boolean;
    isCreatable?: boolean;
}

// #endregion View Props

export const EditSelect: React.FC<IEditSelectProps> = observer((props) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);

    // #region Code Behind
    useEffect(() => {
        //EN: Have to do this because react-select stores the entire key value pair in the value property
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key === props.value());

            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            }
        } else {
            if (props.value()) {
                const values = props.value() as any as string[];
                let arr = [];
                for (const item of values) {
                    const initValue = props.options.find((option: KeyValuePair) => option.key === item);
                    if (initValue) {
                        arr.push({ value: initValue.key, label: initValue.text });
                    }
                }
                setSelectedOption(arr);
            }
        }
    }, []);

    useEffect(() => {
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key === props.value()) as KeyValuePair;
            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            } else {
                setSelectedOption(null);
            }
        } else {
            const values = props.value() as any as string[];
            let arr = [];
            for (const item of values) {
                const initValue = props.options.find((option: KeyValuePair) => option.key === item);
                if (initValue) {
                    arr.push({ value: initValue.key, label: initValue.text });
                }
            }
            setSelectedOption(arr);
        }
    }, [props.value(), props.options]);

    const canDisplayName = (): boolean => {
        return !isEmptyOrWhitespace(props.displayName);
    };

    const getCanClear = (): boolean => {
        return isNullOrUndefined(props.canClear) ? false : props.canClear!;
    };

    const getClassName = (): string => {
        return clsx({
            editselect: true,
            [`editselect-${props.className}`]: !isEmptyOrWhitespace(props.className),
            "editselect-emptylabel": props.displayName === "EMPTY_LABEL",
        });
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "Select or type" : props.placeholder!;
    };

    const onChange = (option: any): void => {
        setSelectedOption(option);
        if (!props.isMulti) {
            props.command.execute({ key: option.value, text: option.label } as KeyValuePair);
        } else {
            const selected = option.map((a: any) => {
                return { key: a.value, text: a.label } as KeyValuePair;
            });
            props.command.execute(selected);
        }
    };
    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute;
    };
    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };
    const isValidationMessageNotEmpty = (): boolean => {
        return true;
        //?return !isEmptyOrWhitespace(props.validationMessage!());
    };

    const getOptions = (): any => {
        return props.options.map((item: KeyValuePair) => {
            return {
                label: item.text,
                value: item.key,
            };
        });
    };
    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");
        return props.cy || result;
    };

    const isFieldRequired = (): boolean => {
        return props.isRequired === true;
    };
    const handleCreateOption = (inputValue: any) => {
        const newOptionObject = {
            key: inputValue,
            text: inputValue,
        };
        setSelectedOption(newOptionObject);
        setSelectedOption({ label: newOptionObject.text, key: newOptionObject.key });
        props.command.execute(newOptionObject.text);
    };
    // #endregion Code Behind
    return (
        <SelectContainer className={getClassName()}>
            {!props.noHeader && (
                <Box flexBox>
                    <FieldLabel className="placeholder" style={{ color: haveError ? "red" : "" }}>
                        {props.displayName}
                        {isFieldRequired() && <span style={{ color: theme.palette.common.warning }}>*</span>}
                    </FieldLabel>
                    <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />
                </Box>
            )}
            <Box flexBox style={{ ...props.style }}>
                {props.isCreatable ? (
                    <>
                        <CreatableSelect
                            ref={props.ref}
                            isClearable={getCanClear()}
                            className="virtualized-select-validation"
                            classNamePrefix={"editselect"}
                            isDisabled={isDisabled()}
                            isMulti={props.isMulti}
                            options={getOptions()}
                            placeholder={getPlaceholder()}
                            value={selectedOption}
                            formatOptionLabel={props.formatOptionLabel}
                            onChange={onChange}
                            onCreateOption={handleCreateOption}
                        />
                    </>
                ) : (
                    <Select
                        ref={props.ref}
                        isClearable={getCanClear()}
                        className="virtualized-select-validation"
                        classNamePrefix={"editselect"}
                        isDisabled={isDisabled()}
                        onChange={onChange}
                        isMulti={props.isMulti}
                        options={getOptions()}
                        placeholder={getPlaceholder()}
                        value={selectedOption}
                        formatOptionLabel={props.formatOptionLabel}
                        data-cy={getDataCY()}
                    />
                )}
                {/* <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} /> */}

                {/* {isValidationMessageNotEmpty() && <p>{props.validationMessage!()}</p>}*/}
            </Box>
        </SelectContainer>
    );
    // #endregion Public Interface
});
EditSelect.defaultProps = {
    displayName: "",
};
