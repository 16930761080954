//dependencies
import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";

//Components
import { Box, Button, Label } from "Components";
import ScheduleChart from "./ScheduleChart/ScheduleChart";
import { WeeklyRotaDetailsViewModel } from "./WeeklyRotaDetailViewModel";
import MentorAbsenceView from "./MentorAbsence/MentorAbsenceView";
import SeesionLeftModal from "../Modal/SeesionLeftModal";

export const TableBox = styled(Box)`
    @media only screen and (max-width: 1024px) {
        max-width: calc(100vw - 40px);
    }
    & > div {
        position: relative;
        &:before {
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 99px;
            bottom: 12px;
            display: block;
            background: #e1e1e1;
        }
        & > div {
            position: relative;
        }
    }
`;

export const SwitchBox = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 28px;
    }
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fcd21d;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 3px;
        background-color: #000000;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input:checked + .slider {
        background-color: #fcd21d;
    }
    input:focus + .slider {
        box-shadow: none;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
    /* Rounded sliders */
    .slider.round {
        border-radius: 50px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
`;

const SwitchTitleBox = styled(Box)`
    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
    }
`;

export interface IProps {
    successfullyGetWeeklyRota?: boolean;
    viewModel: WeeklyRotaDetailsViewModel;
}

export const WeeklyRotaDetailView: React.FC<IProps> = observer((props: IProps) => {
    const viewModel = props.viewModel;
    return (
        <>
            <SwitchTitleBox display={"flex"} style={{ gap: "15px 30px" }} alignItems={"center"} justifyContent={"space-between"}>
                {viewModel.rotaEditable && (
                    <Button command={viewModel.resetRotaCommand} displayName={"Reset rota"} paletteColor="primary" style={{ height: "40px", minWidth: "130px" }} />
                )}
                <Button
                    command={viewModel.showMentorAbsenceModalopenCommand}
                    displayName={"ADD MENTOR ABSENCE"}
                    paletteColor="primary"
                    style={{ height: "40px", minWidth: "220px" }}
                />
                <SwitchBox>
                    <Label style={{ font: "normal normal bold 18px/24px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0px", color: "#000000" }}>Live</Label>
                    <label className="switch">
                        <input type="checkbox" onClick={() => viewModel.showEditableSchdeule.execute()} />
                        <span className="slider round"></span>
                    </label>
                    <Label style={{ font: "normal normal bold 18px/24px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0px", color: "#000000" }}>Editable</Label>
                </SwitchBox>
            </SwitchTitleBox>
            <TableBox width={"100%"} borderTop="1px dashed #DCDCDC" pt={"30px"} maxWidth={"calc(100vw - 60px)"} overflowX={"auto"}>
                <Box>
                    <ScheduleChart viewModel={viewModel} mentorStudentAdded={viewModel.mentorStudentAdded} successfullyGetWeeklyRota={props.successfullyGetWeeklyRota} />
                </Box>
                {viewModel.mentorAbsenceModalopen ? <MentorAbsenceView viewModel={viewModel} onCancel={() => viewModel.showMentorAbsenceModalopenCommand.execute()} /> : ""}
                {viewModel.sessionLeftModalOpen ? <SeesionLeftModal data={viewModel} onCancel={() => viewModel.showSeesionLeftModalopenCommand.execute()} /> : ""}
            </TableBox>
        </>
    );
});
