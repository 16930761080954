import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

export class LogoutModel extends ModelBase<LogoutModel> {
    constructor() {
        super();

        makeObservable(this, {});
    }
}
