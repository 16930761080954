/* eslint-disable @typescript-eslint/no-use-before-define */
import styled from "@emotion/styled";
import { Button, Label } from "Components";
import React, { useState } from "react";
import { LogoutViewModel } from "./LogoutViewModel";

const Container = styled.div``;

const Content = styled.div`
    padding-bottom: 20px;
`;
const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 0px 10px;
    Button {
        width: 100%;
        margin-bottom: 15px;
        height: 40px;
        border-radius: 5px;
    }
`;

export interface IProps {
    onCancel: () => void;
}

const LogoutView: React.FC<IProps> = (props: IProps) => {
    const [viewModel] = useState(new LogoutViewModel(props.onCancel));
    return (
        <>
            <Container>
                <Footer>
                    <ButtonGroup>
                        <Button command={viewModel.logoutCommand} displayName={"PROCEED"} paletteColor="primary" />
                        <Button command={viewModel.cancelLogoutCommand} displayName={"CANCEL"} paletteColor="secondary" />
                    </ButtonGroup>
                </Footer>
            </Container>
        </>
    );
};

export default LogoutView;
