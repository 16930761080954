import React from "react";
import styled from "@emotion/styled";
import { Button, IButtonProps } from "Application";

const StyledButton = styled(Button)`
    background-color: transparent;
    outline: none;
    padding: 0px;
    width: min-content;

    &:focus,
    &:hover {
        background-color: transparent;
    }
`;
export const TransparentButton: React.FC<IButtonProps> = (props) => {
    return (
        <>
            <StyledButton {...props} />
        </>
    );
};
