import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
export class AddStudentModel extends ModelBase<AddStudentModel> {
    public id: string | null = null;
    public name: string = "";
    public selectedStudentId: string = "";
    public pickUpAddressId: string = "";
    public dropOffAddressId: string = "";
    public rotaId: Guid = null;
    public mentorId: string = "";
    public studentSessionId: Guid = "";
    public startDate?: Date | string;
    public students = observable<any>([]);
    constructor() {
        super();
        makeObservable(this, {
            students: observable,
            selectedStudentId: observable,
            pickUpAddressId: observable,
            dropOffAddressId: observable,
            rotaId: observable,
            mentorId: observable,
            studentSessionId: observable,
        });
    }

    public reset = () => {
        this.selectedStudentId = "";
        this.pickUpAddressId = "";
        this.dropOffAddressId = "";
        // this.id = "";
    };
}

export class AddStudentModelModelValidator extends Validator<AddStudentModel> {
    constructor() {
        super();
        this.ruleFor("selectedStudentId").notNull().withMessage("Please select a student").notEmpty().withMessage("Please select a student");
        this.ruleFor("pickUpAddressId").notNull().withMessage("Please select the pickup address").notEmpty().withMessage("Please select the pickup address");
        this.ruleFor("dropOffAddressId").notNull().withMessage("Please select the drop-off address").notEmpty().withMessage("Please select the drop-off address");
        // this.ruleFor("selectedStudentId").must({ predicate: (item, model) => item.length > 0, message: (value, model) => "Please select a student" });
    }
}
