import IMask from "imask";
import moment from "moment";

const integerOnlyRegEx = /^[0-9]*$/;
const floatsRegEx = /^[0-9].*$/;
//https://imask.js.org/
export class MaskedOptions {
    public static Currency = (prefix: string = "£", suffix: string = "", thousandsSeparator: string = ","): IMask.AnyMaskedOptions => {
        return {
            //EN: Can't use string interplolation here because { is used as a placeholder for the mask
            mask: prefix + "num" + suffix,
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    thousandsSeparator: ",",
                    radix: ".",
                    padFractionalZeros: true,
                },
            },
        };
    };

    public static MaxLength = (maxLength: number) => {
        return {
            mask: function (value: string) {
                const pattern = new RegExp(`^.{0,${Number(maxLength)}}$`);
                return pattern.test(value);
            },
        };
    };
    public static Decimals = () => {
        return {
            mask: "num",
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    thousandsSeparator: ",",
                    inputMode: "decimal",
                    radix: ".",
                    padFractionalZeros: true,
                },
            },
        };
    };
    public static InternationalPhoneNumber = () => {
        return { mask: "+00 (0000)000000", placeholderChar: "0", lazy: false };
    };
    public static AlphaNumeric = () => {
        return {
            mask: function (value: string) {
                const pattern = new RegExp(/^[a-z0-9]+$/i);
                return pattern.test(value);
            },
        };
    };
    public static Email = () => {
        return {
            mask: function (value: string) {
                if (/^[a-z0-9_\.-]+$/.test(value)) return true;
                if (/^[a-z0-9_\.-]+@$/.test(value)) return true;
                if (/^[a-z0-9_\.-]+@[a-z0-9-]+$/.test(value)) return true;
                if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.$/.test(value)) return true;
                if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}$/.test(value)) return true;
                if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}\.$/.test(value)) return true;
                if (/^[a-z0-9_\.-]+@[a-z0-9-]+\.[a-z]{1,4}\.[a-z]{1,4}$/.test(value)) return true;
                return false;
            },
        };
    };
    public static NumbersOnly = () => {
        return {
            mask: Number,
            scale: 0,
            thousandsSeparator: ",",
            format: function (value: string) {
                return parseInt(value);
            },
        };
    };

    public static Date = (format: string = "DD/MM/YYYY", minDate: number = 1900, maxDate: number = 2090): IMask.AnyMaskedOptions => {
        return {
            mask: Date,
            pattern: format,
            lazy: false,
            min: new Date(minDate, 0, 1),
            max: new Date(maxDate, 0, 1),
            placeholderChar: " ",
            overwrite: true,
            format: function (date) {
                return moment(date).format(format);
            },
            parse: function (str): any {
                return moment(str, format);
            },
            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    from: minDate,
                    to: maxDate,
                    overwrite: true,
                },
                MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    padFractionalZeros: true,
                    normalizeZeros: true,
                    overwrite: true,
                },
                DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                    overwrite: true,
                },
                HH: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 23,
                    overwrite: true,
                },
                mm: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 59,
                    overwrite: true,
                },
            },
        };
    };
}
