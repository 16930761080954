import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { MentorAbsenceViewModel } from "../MentorAbsenceViewModel";

class Response {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
}

export class GETMentorsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: MentorAbsenceViewModel;

    constructor(viewModel: MentorAbsenceViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Mentor.GetAllMentors);
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        if (responses.length > 0) {
            this.viewModel.model.mentors.replace(responses);
        }
    }
}
