import { FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";
import { APIClient } from "Application";
import { ICommand, RelayCommand } from "Application/Commands";
import { MentorDetailModel, MentorDetailModelValidator } from "./MentorDetailModel";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";
import { GETMentorsByIdEndpoint } from "./EndPoints/GETMentorsByIdEndpoint";
import { POSTMentorEndPoint } from "./EndPoints/POSTMentorEndPoint";

export class MentorDetailViewModel extends ViewModelBase<MentorDetailModel> {
    public apiClient = new APIClient();
    public lookupStore = container.resolve(LookupStore);
    public showAvalibility: boolean = false;

    constructor(id: string | undefined) {
        super(new MentorDetailModel(null));
        this.setValidator(new MentorDetailModelValidator());
        makeObservable(this, { showAvalibility: observable });
        if (id) this.loadMentorAsync(id);
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return isEmptyOrWhitespace(this.model.id) ? "Create" : "Update";
    }

    public get studentStatusOptions() {
        return this.lookupStore.studentStatusKeyValuePairs;
    }

    public updateMentorNameCommand: ICommand = new RelayCommand((value: string) => {
        const mentorName = this.capitalizeFirstLetter(value);
        this.updateField("firstName", mentorName);
    });

    public updateEmailNameCommand: ICommand = new RelayCommand((value: string) => {
        const emailName = this.lowercaseFirstLetter(value);
        this.updateField("email", emailName);
    });

    public updatePhoneNameCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("phone", value);
    });

    public updateIsAdminNameCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("phone", value);
    });

    public capitalizeFirstLetter = (str: string) => {
        return str
            .split(" ")
            .map((word) => {
                if (word.length > 0) {
                    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                }
                return word;
            })
            .join(" ");
    };

    public lowercaseFirstLetter = (str: string) => {
        return str.charAt(0).toLowerCase() + str.slice(1).toLowerCase();
    };

    public updateAccountPasswordCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("password", value);
    });

    public updateConfirmPasswordCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("confirmPassword", value);
    });

    public navigateToMentorListCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Mentors.Root);
    });

    updateMentorAvalibilitydCommand = (e: any, selectedDay: string) => {
        this.updateField("mentorAvailability", { ...this.model.mentorAvailability, [selectedDay]: e.target.checked });
    };

    public showAvalibilityCommand = new RelayCommand(() => {
        if (this.showAvalibility) this.showAvalibility = false;
        else this.showAvalibility = true;
    });

    public saveMentorCommand: ICommand = new RelayCommand(() => {
        if (this.canSubmitForm) {
            this.apiClient.sendAsync(new POSTMentorEndPoint(this), this.model);
        }
    });

    public get canSubmitForm(): boolean {
        const isFormValid = this.isModelValid();
        return isFormValid;
    }

    private async updateField(fieldName: keyof FieldType<MentorDetailModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public async loadMentorAsync(mentorId: string): Promise<void> {
        await this.apiClient.sendAsync(new GETMentorsByIdEndpoint(mentorId, this));
    }

    // #region Api Commands

    public resetApiClientErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Api Commands
}
