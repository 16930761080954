import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";

export class DELETEMentorByIdEndpoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: any;

    constructor(viewModel: any, mentorId: string) {
        super();
        this.verb(Http.Delete);
        this.path(AppUrls.Server.Mentor.DeleteMentorById.replace(":id", mentorId));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(responses: Response): Promise<any> {
        runInAction(() => {
            return Promise.resolve(responses);
        });
    }
}
