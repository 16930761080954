import { ViewModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { HeaderLinkViewModel } from "./HeaderLinkViewModel";
import { RelayCommand } from "Application";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";

export class HeaderViewModel extends ViewModelBase<any> {
    accountStore = container.resolve(AccountStore);

    public isAdmin = this.accountStore.isInRole("ADMIN");
    public isMentor = this.accountStore.isInRole("MENTOR");
    public studentsViewModel = new HeaderLinkViewModel("students", "students");
    public mentorsViewModel = new HeaderLinkViewModel("mentors", "mentors");
    public weeklyRotaViewModel = new HeaderLinkViewModel("weekly rota", "weeklyrota");
    public attendanceViewModel = new HeaderLinkViewModel("attendance", "attendance");
    public resourcesViewModel = new HeaderLinkViewModel("resources", "resources");
    public loginViewModel = new HeaderLinkViewModel("Login", "login");
    public logoutViewModel = new HeaderLinkViewModel("Log out", "logout");

    //for mentor
    public mentorResourcesViewModel = new HeaderLinkViewModel("resources", "/mentor/resources");
    public mentorWeeklyRotaViewModel = new HeaderLinkViewModel("weekly rota", "/mentor/weeklyrota");

    private activeHeaderLinkViewModel: HeaderLinkViewModel = this.studentsViewModel;
    public userIsLoggingOut: boolean = false;

    constructor() {
        super({});
        this.intializerHeader();
        makeObservable(this, {
            //observable
            userIsLoggingOut: observable,
            //actions
            setUserIsLoggingOut: action,
        });
    }

    public intializerHeader() {
        if (this.isAdmin) {
            this.setActiveLink(this.studentsViewModel);
            this.studentsViewModel.navigateToLink(AppUrls.Client.Students.Current);
        } else {
            this.setActiveLink(this.mentorWeeklyRotaViewModel);
            this.mentorWeeklyRotaViewModel.navigateToLink(AppUrls.Client.Admin.Mentor.WeeklyRota);
        }
    }

    //region actions
    public setUserIsLoggingOut = (value: boolean) => {
        this.userIsLoggingOut = value;
    };
    //endregion actions

    //region commands

    public logOutUserCommand = new RelayCommand(() => {
        this.setUserIsLoggingOut(false);
    });

    public clearActiveCommand = new RelayCommand(() => {
        this.activeHeaderLinkViewModel.clearActiveOption();
    });

    public navigateToLoginCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.loginViewModel);
            this.loginViewModel.navigateToLink(AppUrls.Client.Account.Login);
        },
        // () => !this.isLoggedIn,
    );

    public navigateToStudentsCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.studentsViewModel);
            this.studentsViewModel.navigateToLink(AppUrls.Client.Students.Root);
        },
        // () => this.isLoggedIn,
    );

    public navigateToMentorsCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.mentorsViewModel);
            this.mentorsViewModel.navigateToLink(AppUrls.Client.Mentors.Root);
        },
        // () => this.isLoggedIn,
    );

    public navigateToWeeklyRotaCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.weeklyRotaViewModel);
            this.weeklyRotaViewModel.navigateToLink(AppUrls.Client.WeeklyRota.Home);
        },
        // () => this.isLoggedIn,
    );

    public navigateToMentorWeeklyRotaCommand = new RelayCommand(() => {
        this.setActiveLink(this.mentorWeeklyRotaViewModel);
        this.mentorWeeklyRotaViewModel.navigateToLink(AppUrls.Client.Admin.Mentor.WeeklyRota);
    });

    public navigateToAttendanceCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.attendanceViewModel);
            this.attendanceViewModel.navigateToLink(AppUrls.Client.Attendance.Home);
        },
        // () => this.isLoggedIn,
    );

    public navigateToResourceCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.resourcesViewModel);
            this.resourcesViewModel.navigateToLink(AppUrls.Client.Resources.Home);
        },
        // () => this.isLoggedIn,
    );

    public navigateToMenotrResourceCommand = new RelayCommand(
        () => {
            this.setActiveLink(this.mentorResourcesViewModel);
            this.mentorResourcesViewModel.navigateToLink(AppUrls.Client.Resources.Home);
        },
        // () => this.isLoggedIn,
    );

    public logUserOutCommand = new RelayCommand(
        () => {
            this.setUserIsLoggingOut(true);
        },
        // () => this.isLoggedIn,
    );

    //endregion commands

    // #region Supporting

    private setActiveLink = (viewModel: HeaderLinkViewModel) => {
        this.clearActiveCommand.execute();
        this.activeHeaderLinkViewModel = viewModel;
    };

    // #endregion Supporting

    // #region display permissions
    public get showStudents() {
        return this.isLoggedIn;
    }
    public get showMentors() {
        return this.isLoggedIn;
    }
    public get showWeeklyRota() {
        return this.isLoggedIn;
    }
    public get showAttendance() {
        return this.isLoggedIn;
    }
    public get showResources() {
        return this.isLoggedIn;
    }
    public get showLogout() {
        return this.isLoggedIn;
    }

    // #endregion display permissions
}
