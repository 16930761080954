import { MentorDetailViewModel } from "./MentorDetailViewModel";
import { ApplicationBusy, ApplicationError, Box, Button, CheckBox, EditDate, Input, Label, RadioButton, ThemedLoader } from "Components";
import { NullCommand, setPageTitle } from "Application";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { BottomBtn, FormContainer, NavigationContainer, PageContainer } from "./StyleComponents";
import { BackSVG } from "Assets/Back";
import { useParams } from "react-router";
import styled from "@emotion/styled";
import { InfoBar } from "Components/InfoBar";
import { uniqueId } from "lodash-es";

export interface ICurrentStudentsFormViewProps {
    className?: string;
}
const TableData = styled.div`
    background: #f3f3f3;
    text-align: center;
    padding: 12px;
    .form-control {
        grid-template-columns: 1fr;
        justify-content: center;
        input {
            color: #bd9e17;
            background: transparent;
            border: none;
            margin: 0 auto;
            &:before {
                box-shadow: inset 1em 1em #bd9e17;
            }
        }
    }
    .check-label {
        display: none !important;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
    padding: 40px 30px 25px;
`;

const CustomCheckBox = styled.div`
    height: 30px;
    width: 30px;
    margin: 0 auto;
    position: relative;
    input {
        height: 30px;
        width: 30px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
        cursor: poiner;
    }
    label {
        border: 1px solid #dcdcdc;
        height: 30px;
        width: 30px;
        background: #fff;
        position: relative;
        display: block;
        margin: 0 auto;
        &:after {
            content: "";
            height: 7px;
            width: 14px;
            border-left: 4px solid #bd9e17;
            border-bottom: 4px solid #bd9e17;
            display: block;
            transform: rotate(-45deg);
            top: 6px;
            position: absolute;
            left: 5px;
            opacity: 0;
        }
    }
    input:checked + label:after {
        opacity: 100%;
    }
`;
export const FourCol = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    @media only screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;
export const SwitchBox = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 28px;
    }
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fcd21d;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 4px;
        bottom: 3px;
        background-color: #000000;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input:checked + .slider {
        background-color: #fcd21d;
    }
    input:focus + .slider {
        box-shadow: none;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
    /* Rounded sliders */
    .slider.round {
        border-radius: 50px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
`;

export const MentorDetailView: React.FC<ICurrentStudentsFormViewProps> = observer((props) => {
    const { id } = useParams();
    const [viewModel] = useState(() => new MentorDetailViewModel(id));

    useEffect(() => {
        setPageTitle("Mentor");
    }, []);

    let mentorAvalibilityData = [
        {
            day: "Monday",
            time: [
                { name: "am", value: "monAM", isAvalible: viewModel.model.mentorAvailability.monAM },
                { name: "pm", value: "monPM", isAvalible: viewModel.model.mentorAvailability.monPM },
            ],
        },
        {
            day: "Tuesday",
            time: [
                { name: "am", value: "tuesAM", isAvalible: viewModel.model.mentorAvailability.tuesAM },
                { name: "pm", value: "tuesPM", isAvalible: viewModel.model.mentorAvailability.tuesPM },
            ],
        },
        {
            day: "Wednesay",
            time: [
                { name: "am", value: "wedsAM", isAvalible: viewModel.model.mentorAvailability.wedsAM },
                { name: "pm", value: "wedsPM", isAvalible: viewModel.model.mentorAvailability.wedsPM },
            ],
        },
        {
            day: "Thursday",
            time: [
                { name: "am", value: "thurAM", isAvalible: viewModel.model.mentorAvailability.thurAM },
                { name: "pm", value: "thurPM", isAvalible: viewModel.model.mentorAvailability.thurPM },
            ],
        },
        {
            day: "Friday",
            time: [
                { name: "am", value: "friAM", isAvalible: viewModel.model.mentorAvailability.friAM },
                { name: "pm", value: "friPM", isAvalible: viewModel.model.mentorAvailability.friPM },
            ],
        },
    ];

    const renderWorkspace = () => {
        return (
            <FormContainer>
                <FourCol pb={"25px"}>
                    <Input
                        displayName="Name"
                        value={() => viewModel.getValue("firstName")}
                        command={viewModel.updateMentorNameCommand}
                        validationMessage={() => viewModel.getError("firstName")}
                        autoFill={false}
                    />
                    <Input
                        displayName={"Email address"}
                        value={() => viewModel.getValue("email")}
                        command={viewModel.updateEmailNameCommand}
                        validationMessage={() => viewModel.getError("email")}
                        autoFill={false}
                    />
                    <Input
                        displayName={"Phone number"}
                        value={() => viewModel.getValue("phone")}
                        command={viewModel.updatePhoneNameCommand}
                        validationMessage={() => viewModel.getError("phone")}
                        autoFill={false}
                        type="number"
                    />
                </FourCol>
                <FourCol pb={"30px"} borderBottom={"1px dashed #DCDCDC"}>
                    {id ? (
                        <>
                            <Input
                                type="password"
                                displayName={"Set new password"}
                                value={() => viewModel.getValue("password")}
                                command={viewModel.updateAccountPasswordCommand}
                                validationMessage={() => viewModel.getError("password")}
                                autoFill={false}
                                isRequired={false}
                            />
                            <Input
                                type="password"
                                displayName={"Confirm new password"}
                                value={() => viewModel.getValue("confirmPassword")}
                                command={viewModel.updateConfirmPasswordCommand}
                                validationMessage={() => viewModel.getError("confirmPassword")}
                                autoFill={false}
                                isRequired={false}
                            />
                        </>
                    ) : (
                        <>
                            <Input
                                type="password"
                                displayName={"Account Password"}
                                value={() => viewModel.getValue("password")}
                                command={viewModel.updateAccountPasswordCommand}
                                validationMessage={() => viewModel.getError("password")}
                                autoFill={false}
                                isRequired={false}
                            />
                            <Input
                                type="password"
                                displayName={"Confirm account password"}
                                value={() => viewModel.getValue("confirmPassword")}
                                command={viewModel.updateConfirmPasswordCommand}
                                validationMessage={() => viewModel.getError("confirmPassword")}
                                autoFill={false}
                                isRequired={false}
                            />
                        </>
                    )}
                </FourCol>

                <Box pt={"28px"}>
                    <Box mb={"32px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap="wrap" style={{ gap: "25px" }}>
                        <Label style={{ font: "normal normal bold 18px/24px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0px", color: "#000000" }}>
                            Mentor availability:
                        </Label>
                        <SwitchBox>
                            <Label style={{ font: "normal normal bold 18px/24px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0px", color: "#000000" }}>Live</Label>
                            <label className="switch">
                                <input type="checkbox" onClick={() => viewModel.showAvalibilityCommand.execute()} />
                                <span className="slider round"></span>
                            </label>
                            <Label style={{ font: "normal normal bold 18px/24px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0px", color: "#000000" }}>
                                Editable
                            </Label>
                        </SwitchBox>
                    </Box>

                    {viewModel.showAvalibility ? (
                        <Box display={"flex"} style={{ gap: "2px" }} overflowX={"auto"}>
                            {mentorAvalibilityData.map((item) => {
                                return (
                                    <Box flex={"1 0 0"} key={uniqueId()}>
                                        <Box background={"#F3F3F3"} textAlign={"center"} padding={"12px"} mb={"2px"}>
                                            <Label
                                                style={{
                                                    font: "normal normal bold 12px/17px Open Sans",
                                                    color: "#000000",
                                                    fontFamily: "OpenSans-CondBold",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {item.day}
                                            </Label>
                                        </Box>
                                        {/* <h1>{viewModel.getValue("mentorAvailability")}</h1> */}
                                        <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "2px" }}>
                                            {item.time.map((day) => {
                                                return (
                                                    <Box display={"grid"} style={{ gap: "15px" }} key={uniqueId()}>
                                                        <Box background={"#F3F3F3"} textAlign={"center"} padding={"12px"}>
                                                            <Label
                                                                style={{
                                                                    font: "normal normal bold 12px/17px Open Sans",
                                                                    color: "#000000",
                                                                    fontFamily: "OpenSans-CondBold",
                                                                    textTransform: "uppercase",
                                                                }}
                                                            >
                                                                {day.name}
                                                            </Label>
                                                        </Box>
                                                        <TableData>
                                                            <CustomCheckBox>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={day.isAvalible}
                                                                    onClick={(e) => viewModel.updateMentorAvalibilitydCommand(e, day.value)}
                                                                />
                                                                <label></label>
                                                            </CustomCheckBox>
                                                            {/* <CheckBox command={viewModel.updateMentorAvalibilitydCommand} value={() => day.isAvalible} /> */}
                                                        </TableData>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : (
                        ""
                    )}
                </Box>
                <BottomBtn py="30px" display={"flex"} alignItems={"center"} justifyContent={"center"} my="30px" borderTop="1px solid #DCDCDC">
                    <Button command={viewModel.saveMentorCommand} displayName={viewModel.displayName} paletteColor="primary" />
                    <Button command={viewModel.navigateToMentorListCommand} displayName={"Cancel"} paletteColor="secondary" />
                </BottomBtn>
            </FormContainer>
        );
    };

    return (
        <PageContainer style={{ paddingBottom: "10rem" }}>
            <Header>
                <Label fontStyle="h1" color="black">
                    Mentor
                </Label>
            </Header>
            <NavigationContainer>
                <BackSVG style={{ cursor: "pointer" }} />
                <Box onClick={() => viewModel.navigateToMentorListCommand.execute()}>
                    <Label style={{ cursor: "pointer" }}>Back to mentors</Label>
                </Box>
            </NavigationContainer>
            {(() => {
                switch (true) {
                    case viewModel.apiClient.IsBusy:
                        return <ThemedLoader isOpen delayBeforeOpen={1000} containerStyles={{ height: "calc(100vh - 60px)", width: "calc(100vw - 80px)" }} />;

                    case viewModel.apiClient.IsSubmitted && viewModel.apiClient.HaveValidationMessage:
                        return (
                            <div className="error-scrollable-section">
                                <div>
                                    <ApplicationError additionalMessage={viewModel.apiClient.ValidationMessage} resetCommand={viewModel.resetApiClientErrorCommand} />
                                </div>
                            </div>
                        );

                    default:
                        return <div className="scrollable-section">{renderWorkspace()}</div>;
                }
            })()}
        </PageContainer>
    );
});

export default MentorDetailView;
