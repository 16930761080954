import * as React from "react";
import { SVGProps } from "react";

export const BackSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="7" viewBox="0 0 4 7">
        <path
            id="Path_5250"
            data-name="Path 5250"
            d="M6.829.187A.506.506,0,0,0,6.488.1H.427A.643.643,0,0,0,.085.274.385.385,0,0,0,0,.535.664.664,0,0,0,.171.883L3.159,3.926A.441.441,0,0,0,3.5,4.1a.643.643,0,0,0,.341-.174L6.829.8A.454.454,0,0,0,7,.448C6.915.361,6.915.274,6.829.187Zm0,0"
            transform="translate(4.1) rotate(90)"
            fill="#fcd21d"
        />
    </svg>
);
