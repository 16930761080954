import styled from "@emotion/styled";
import { RelayCommand, theme } from "Application";
import { Box, Button, Input, Label } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import React from "react";
import { MentorWeeklyRotaViewModel } from "../MentorWeeklyRotaViewModel";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import { observer } from "mobx-react-lite";
import moment from "moment";

const Container = styled.div`
    padding: 25px 20px;
    label {
        margin-top: 0;
    }
`;

const FormField = styled(Box)`
    & > div > div:first-child {
        display: none !important;
    }
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 30px;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 400px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

export interface IProps {
    onCancel: () => void;
    viewModel: MentorWeeklyRotaViewModel;
    onAdd: () => void;
}

const MentorStudentAbsenceModal: React.FC<IProps> = observer((props: IProps) => {
    const viewModel = props.viewModel;

    const mentorStudentAbsenceModalCloseCommand = new RelayCommand(() => {
        props.onCancel();
        viewModel.model.reason = "";
        viewModel.model.moreDetail = "";
        viewModel.model.sessionDate = "";
    });

    const addMentorStudentAbsence = new RelayCommand(() => {
        props.onAdd();
    });

    return (
        <>
            <StyledDialog open={true} style={{ zIndex: 1 }}>
                <Header>
                    Attendance <CloseIcon onClick={() => mentorStudentAbsenceModalCloseCommand.execute()} />
                </Header>
                <Container>
                    <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "30px" }} mb="26px">
                        <Box>
                            <Label
                                mb={"5px"}
                                style={{
                                    font: "normal normal bold 14px/19px Open Sans",
                                    fontFamily: "OpenSans-CondBold",
                                    letterSpacing: "-0.21px",
                                    color: "#000000",
                                }}
                            >
                                Date of session
                            </Label>
                            <FormField mb={"10px"}>
                                <Label>{moment(viewModel?.model?.sessionDate).format("DD/MM/yyyy")}</Label>
                            </FormField>
                        </Box>
                        <Box>
                            <Label
                                mb={"5px"}
                                style={{ font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "-0.21px", color: "#000000" }}
                            >
                                Time of session
                            </Label>
                            <FormField mb={"10px"}>
                                <Label>
                                    {moment(viewModel.model.sessionStartTime, "HH:mm:ss").format("HH:mm")} - {moment(viewModel.model.sessionEndTime, "HH:mm:ss").format("HH:mm")}
                                </Label>
                            </FormField>
                        </Box>
                    </Box>
                    <Box>
                        <ThemedEditSelect
                            displayName={"Reason for absence*"}
                            placeholder={"Type name of activity or add a new one"}
                            command={viewModel.updateReasonAbsenceCommand}
                            options={viewModel.getAbsenceReasons}
                            value={() => viewModel.getValue("reason")}
                            validationMessage={() => viewModel.getError("reason")}
                        />
                    </Box>

                    {viewModel.showOtherText && (
                        <Box mt={"30px"}>
                            <Input
                                multiline={true}
                                rows={4}
                                displayName="Please give more detail"
                                validationMessage={() => viewModel.getError("moreDetail")}
                                command={viewModel.updateStudentMoreDetailsCommand}
                                value={() => viewModel.getValue("moreDetail")}
                                placeholder="Please add notes..."
                            />
                        </Box>
                    )}
                    <Footer>
                        <ButtonGroup style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                            <Button command={addMentorStudentAbsence} displayName={"ADD"} paletteColor="primary" style={{ height: "40px", width: "100%", minHeight: "40px" }} />
                            <Button
                                command={mentorStudentAbsenceModalCloseCommand}
                                displayName={"CANCEL"}
                                paletteColor="secondary"
                                style={{ height: "40px", width: "100% ", minHeight: "40px" }}
                            />
                        </ButtonGroup>
                    </Footer>
                </Container>
            </StyledDialog>
        </>
    );
});

export default MentorStudentAbsenceModal;
