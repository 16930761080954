import React, { useState } from "react";

import { observer } from "mobx-react-lite";

import { HeaderViewModel } from "./HeaderViewModel";
import { HeaderLink } from "./HeaderLink";
import { HeaderLogoOptionBase } from "./HeaderLogoOptions";

import { Box } from "Components";
import { Sidebar } from "./SideBar";
import { ThemedDialog } from "Styles/ThemedPrimitives/Style1/ThemedDialog";
import LogoutView from "Views/Logout/LogoutView";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";
import { MenuBlock } from "./MenuBlock";
import { AppUrls } from "AppUrls";
import useWindowDimensions from "Application/Hooks/useWindowDimensions";
import { theme } from "Application";
import { CoreStoreInstance } from "@shoothill/core";

import { pxToRem } from "../../Application";
import logo from "Assets/LifeShedLogo.svg";
export interface IHeaderStyles {
    root: IHeaderStyle;
    content: IHeaderStyle;
    logoOption: IHeaderStyle;
    options: IHeaderStyle;
    option: IHeaderStyle;
    optionActive: IHeaderStyle;
}

export interface IHeaderStyle {
    CSSProperties?: React.CSSProperties;
    className?: string;
}

export interface IHeaderProps {
    /**
     * An optional class name for use with the header.
     */
    className?: string;
    /**
     * Styling of the header.
     */
    styles?: IHeaderStyles;
}

export const HeaderBase: React.FC<IHeaderProps> = observer((props) => {
    const [viewModel] = useState(() => new HeaderViewModel());
    const { styles } = props;
    const { width } = useWindowDimensions();
    const accountStore = container.resolve(AccountStore);
    const isMentor = accountStore.isInRole("MENTOR");
    //region properties

    const headerStyleHandler = (props: IHeaderStyle) => {
        return {
            styles: styles ? props.CSSProperties : undefined,
            className: styles ? props.className : undefined,
        };
    };

    const rootStyles = styles ? headerStyleHandler(styles.root).styles : undefined;
    const contentStyles = styles ? headerStyleHandler(styles.content).styles : undefined;
    const logoOptionStyles = styles ? headerStyleHandler(styles.logoOption).styles : undefined;
    const optionsStyles = styles ? headerStyleHandler(styles.options).styles : undefined;
    const optionStyles = styles ? headerStyleHandler(styles.option).styles : undefined;
    const optionActiveStyles = styles ? headerStyleHandler(styles.optionActive).styles : undefined;

    const rootClassName = styles ? headerStyleHandler(styles.root).className : undefined;
    const contentClassName = styles ? headerStyleHandler(styles.content).className : undefined;
    const logoOptionClassName = styles ? headerStyleHandler(styles.logoOption).className : undefined;
    const optionsClassName = styles ? headerStyleHandler(styles.options).className : undefined;
    const optionClassName = styles ? headerStyleHandler(styles.option).className : undefined;
    const optionActiveClassName = styles ? headerStyleHandler(styles.optionActive).className : undefined;

    //endregion properties

    // #region Code behind

    const renderLogOutWarningDialog = () => {
        return (
            <>
                <ThemedDialog title="Are you sure you wish to logout?" onCloseCommand={viewModel.logOutUserCommand} isOpen={viewModel.userIsLoggingOut}>
                    {<LogoutView onCancel={() => viewModel.setUserIsLoggingOut(false)} />}
                </ThemedDialog>
            </>
        );
    };

    // #endregion Code behind

    const menuLinksForMobile = () => {
        return (
            <>
                <Box dc={"1fr"} marginTop={"3rem"}>
                    <HeaderLogoOptionBase
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            backgroundImage: `url(${logo})`,
                            backgroundPosition: "left center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            content: "",
                            height: pxToRem(65),
                            width: pxToRem(65),
                            position: "absolute",
                            left: pxToRem(20),
                            top: pxToRem(12),
                        }}
                        className={logoOptionClassName}
                    />
                    {isMentor ? (
                        <>
                            <MenuBlock title={"weekly rota"} path={AppUrls.Client.Admin.Mentor.WeeklyRota} onClick={() => viewModel.navigateToMentorWeeklyRotaCommand.execute()} />
                            <MenuBlock title={"resources"} path={AppUrls.Client.Admin.Mentor.Resources} onClick={() => viewModel.navigateToMenotrResourceCommand.execute()} />
                            <MenuBlock title={"Log out"} onClick={() => viewModel.logUserOutCommand.execute()} />
                        </>
                    ) : (
                        <>
                            <MenuBlock title={"students"} path={AppUrls.Client.Students.Student.StudentDetail.Root} onClick={() => viewModel.navigateToStudentsCommand.execute()} />
                            <MenuBlock title={"mentors"} path={AppUrls.Client.Mentors.Root} onClick={() => viewModel.navigateToMentorsCommand.execute()} />
                            <MenuBlock title={"weekly rota"} path={AppUrls.Client.WeeklyRota.Home} onClick={() => viewModel.navigateToWeeklyRotaCommand.execute()} />
                            <MenuBlock title={"attendance"} path={AppUrls.Client.Attendance.Home} onClick={() => viewModel.navigateToAttendanceCommand.execute()} />
                            <MenuBlock title={"resources"} path={AppUrls.Client.Resources.Home} onClick={() => viewModel.navigateToResourceCommand.execute()} />
                            <MenuBlock title={"Log out"} onClick={() => viewModel.logUserOutCommand.execute()} />
                        </>
                    )}
                </Box>
            </>
        );
    };

    return (
        <div style={rootStyles} className={rootClassName}>
            {CoreStoreInstance.isDesktop ? (
                <div style={contentStyles} className={contentClassName}>
                    <HeaderLogoOptionBase style={logoOptionStyles} className={logoOptionClassName} />
                    <div style={optionsStyles} className={optionsClassName}>
                        {isMentor ? (
                            <>
                                <HeaderLink
                                    show={viewModel.showWeeklyRota}
                                    className={optionClassName}
                                    selectedClassName={optionActiveClassName}
                                    style={optionStyles}
                                    selectedStyles={optionActiveStyles}
                                    command={viewModel.navigateToMentorWeeklyRotaCommand}
                                    displayName={viewModel.mentorWeeklyRotaViewModel.getValue("displayName")}
                                    isSelected={viewModel.mentorWeeklyRotaViewModel.getValue("isActive")}
                                />
                                <HeaderLink
                                    show={viewModel.showResources}
                                    className={optionClassName}
                                    selectedClassName={optionActiveClassName}
                                    style={optionStyles}
                                    selectedStyles={optionActiveStyles}
                                    command={viewModel.navigateToMenotrResourceCommand}
                                    displayName={viewModel.resourcesViewModel.getValue("displayName")}
                                    isSelected={viewModel.resourcesViewModel.getValue("isActive")}
                                />
                            </>
                        ) : (
                            <>
                                <HeaderLink
                                    show={viewModel.showStudents}
                                    className={optionClassName}
                                    selectedClassName={optionActiveClassName}
                                    style={optionStyles}
                                    selectedStyles={optionActiveStyles}
                                    command={viewModel.navigateToStudentsCommand}
                                    displayName={viewModel.studentsViewModel.getValue("displayName")}
                                    isSelected={viewModel.studentsViewModel.getValue("isActive")}
                                />
                                <HeaderLink
                                    show={viewModel.showMentors}
                                    className={optionClassName}
                                    selectedClassName={optionActiveClassName}
                                    style={optionStyles}
                                    selectedStyles={optionActiveStyles}
                                    command={viewModel.navigateToMentorsCommand}
                                    displayName={viewModel.mentorsViewModel.getValue("displayName")}
                                    isSelected={viewModel.mentorsViewModel.getValue("isActive")}
                                />

                                <HeaderLink
                                    show={viewModel.showWeeklyRota}
                                    className={optionClassName}
                                    selectedClassName={optionActiveClassName}
                                    style={optionStyles}
                                    selectedStyles={optionActiveStyles}
                                    command={viewModel.navigateToWeeklyRotaCommand}
                                    displayName={viewModel.weeklyRotaViewModel.getValue("displayName")}
                                    isSelected={viewModel.weeklyRotaViewModel.getValue("isActive")}
                                />
                                <HeaderLink
                                    show={viewModel.showAttendance}
                                    className={optionClassName}
                                    selectedClassName={optionActiveClassName}
                                    style={optionStyles}
                                    selectedStyles={optionActiveStyles}
                                    command={viewModel.navigateToAttendanceCommand}
                                    displayName={viewModel.attendanceViewModel.getValue("displayName")}
                                    isSelected={viewModel.attendanceViewModel.getValue("isActive")}
                                />
                                <HeaderLink
                                    show={viewModel.showResources}
                                    className={optionClassName}
                                    selectedClassName={optionActiveClassName}
                                    style={optionStyles}
                                    selectedStyles={optionActiveStyles}
                                    command={viewModel.navigateToResourceCommand}
                                    displayName={viewModel.resourcesViewModel.getValue("displayName")}
                                    isSelected={viewModel.resourcesViewModel.getValue("isActive")}
                                />
                            </>
                        )}
                    </div>

                    <HeaderLink
                        className={optionClassName}
                        selectedClassName={optionActiveClassName}
                        style={optionStyles}
                        selectedStyles={optionActiveStyles}
                        command={viewModel.logUserOutCommand}
                        displayName={viewModel.logoutViewModel.getValue("displayName")}
                        isSelected={viewModel.logoutViewModel.getValue("isActive")}
                    />
                </div>
            ) : (
                <Sidebar width={width as number} color={"#fff"} left={true} props={props}>
                    {menuLinksForMobile()}
                </Sidebar>
            )}
            <>{renderLogOutWarningDialog()}</>
        </div>
    );
});
