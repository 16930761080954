import { APIClient, EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetail/WeeklyRotaDetailViewModel";
import { runInAction } from "mobx";

class Response {
    public mentorAvailability: any = [];
    public rota: any = {};
}
export class GETRotaByDateEndPoint extends EndpointWithoutRequest<Response> {
    private readonly viewModel: any;
    private readonly weeklyRotaDetailViewModel: WeeklyRotaDetailsViewModel;
    public apiClient = new APIClient();

    constructor(startdate: any, weeklyRotaDetailModel: WeeklyRotaDetailsViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.WeeklyRota.GetWeeklyRotaByDate.replace(":startdate", startdate));
        this.weeklyRotaDetailViewModel = weeklyRotaDetailModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.weeklyRotaDetailViewModel.model.rotaId = "";
            this.weeklyRotaDetailViewModel.model.mentorAvailability = [];
            if (response.rota !== null) {
                this.weeklyRotaDetailViewModel.model.rotaId = response?.rota?.id;
                this.weeklyRotaDetailViewModel.model.mentorAvailability = response.mentorAvailability;
            } else {
                this.weeklyRotaDetailViewModel.model.rotaId = null;
            }
        });
        return Promise.resolve();
    }
}
