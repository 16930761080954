/*
Summary: A button that has all of the styling removed
*/

import React from "react";
import { observer } from "mobx-react-lite";
import { Button, IButtonProps } from "Application";
import styled from "@emotion/styled";

interface IClearButtonProps extends IButtonProps {}

const CustomizedButton = styled(Button)`
    background-color: transparent;
    padding: 0px;
    cursor: pointer !important;
    &:hover,
    &:active {
        background-color: transparent;
    }
`;

export const ClearButton: React.FC<IClearButtonProps> = observer((props: IClearButtonProps) => {
    return (
        <>
            <CustomizedButton {...props} />
        </>
    );
});
