import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

// App
export class UserModel extends ModelBase<UserModel> {
    public id: Guid = null;
    public firstName: string = "";
    public lastName: string = "";
    public emailAddress: string = "";
    public password: string = "";
    public concurrencyToken: string = "";
    public lastLoginDate: Date | null = null;
    public createdDate: Date | null = null;
    public userRoles = observable<string>([]);

    constructor(id: Guid) {
        super();
        this.id = id;
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            emailAddress: observable,
            password: observable,
            concurrencyToken: observable,
            lastLoginDate: observable,
            userRoles: observable,
        });
    }
}

export class UserModelValidator extends Validator<UserModel> {
    constructor() {
        super();
        this.ruleFor("emailAddress")
            .notNull()
            .withMessage("Please enter a emailAddress")
            .notEmpty()
            .withMessage("Please enter a emailAddress")
            .emailAddress()
            .withMessage("Please enter a Valid emailAddress");
        this.ruleFor("firstName")
            .notNull()
            .withMessage("Please enter a firstName")
            .notEmpty()
            .withMessage("Please enter a firstName")
            .maxLength(50)
            .withMessage("Please enter a firstName less than 50 characters");
        this.ruleFor("lastName")
            .notNull()
            .withMessage("Please enter a LastName")
            .notEmpty()
            .withMessage("Please enter a LastName")
            .maxLength(50)
            .withMessage("Please enter a LastName less than 50 characters");
        this.ruleFor("userRoles").must({ predicate: (item, model) => item.length > 0, message: (value, model) => "Please select a Role" });
    }
}
