import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { WeeklyRotaDetailModel } from "../../WeeklyRotaDetailModel";
import { MentorAbsenceViewModel } from "../MentorAbsenceViewModel";

class Request {
    public rotaId: Guid = "";
    public mentorId: Guid = "";
    public absenseStartDate?: Date | string;
    public absenseEndDate?: Date | string;
}
export class POSTMentorAbsenceEndPoint extends Endpoint<Request, Response> {
    private readonly viewmodel: MentorAbsenceViewModel;

    constructor(viewmodel: MentorAbsenceViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.WeeklyRota.SaveMentorRotaAbsence);
        this.viewmodel = viewmodel;
    }

    public async HandleRequestAsync(model: MentorAbsenceViewModel): Promise<any> {
        let request: Request = new Request();
        request.rotaId = model.model.rotaId;
        request.mentorId = model.model.mentorId;
        request.absenseStartDate = model.model.absenseStartDate + "T" + model.model.absenceShiftAM;
        request.absenseEndDate = model.model.absenseEndDate + "T" + model.model.absenceShiftPM;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        return Promise.resolve(response);
    }
}
