import { EndpointWithoutRequest, Http } from "Application";
import { Logger } from "index";

class Response {}

export class GETConfirmEmailEndpoint extends EndpointWithoutRequest<Response> {
    constructor(token: string) {
        super();
        this.verb(Http.Get);
        this.path("/api/Account/ConfirmEmail/" + token);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return {};
    }
}
