import React from "react";
import { Route, Routes } from "react-router-dom";
import { MentorsView } from "./Mentors/MentorsView";
import MentorDetailView from "./Form/MentorDetail/MentorDetailView";

const MentorsRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path={"*"} element={<MentorsView />} />
            <Route path={"mentor"} element={<MentorDetailView />} />
            <Route path={"mentor/:id"} element={<MentorDetailView />} />
        </Routes>
    );
};

export default MentorsRoutes;
