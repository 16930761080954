import styled from "@emotion/styled";
import { NullCommand, RelayCommand } from "Application";
import { Button } from "Components";
import React from "react";

const Container = styled.div``;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 0px 10px;
    Button {
        width: 100%;
        margin-bottom: 15px;
        height: 40px;
        border-radius: 5px;
    }
`;

export interface IProps {
    onCancel: () => void;
    onDelete: () => void;
}

const DeleteMentorView: React.FC<IProps> = (props: IProps) => {
    const deleteModelCloseCommand = new RelayCommand(() => {
        props.onCancel();
    });

    const deleteMentorCommand = new RelayCommand(() => {
        props.onDelete();
    });

    return (
        <>
            <Container>
                <Footer>
                    <ButtonGroup>
                        <Button command={deleteMentorCommand} displayName={"PROCEED"} paletteColor="primary" />
                        <Button command={deleteModelCloseCommand} displayName={"CANCEL"} paletteColor="secondary" />
                    </ButtonGroup>
                </Footer>
            </Container>
        </>
    );
};

export default DeleteMentorView;
