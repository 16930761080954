import { ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";

import { APIClient, RelayCommand } from "Application";
import { GETMentorsEndpoint } from "./EndPoints/GETMentorsEndpoint";
import { MentorsModel } from "./MentorModel";
import { MentorItemViewModel } from "./MentorItemViewModel";
import { AppUrls } from "AppUrls";
import { DELETEMentorByIdEndpoint } from "./EndPoints/DELETEMentorByIdEndpoint";

export class MentorsViewModel extends ViewModelBase<MentorsModel> {
    public apiClient = new APIClient();

    public mentorViewModels = observable<MentorItemViewModel>([]);

    public deleteModalOpen = false;
    public deleteMentorId = "";

    constructor() {
        super(new MentorsModel());
        makeObservable(this, {
            mentors: computed,
            deleteModalOpen: observable,
        });
        this.loadMentors();
    }

    public get mentors() {
        return this.mentorViewModels.slice();
    }

    public navigateToMentorDetailsCommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Mentors.MentorDetail.Edit.replace(":id", id));
    });

    public navigateToAddMentorCommand = new RelayCommand(
        () => {
            this.history.push(AppUrls.Client.Mentors.MentorDetail.Root);
        },
        () => this.apiClient.IsBusy === false,
    );

    public deleteModalOpenCommand = new RelayCommand((deleteMentorId: string) => {
        this.deleteModalOpen = true;
        this.deleteMentorId = deleteMentorId;
    });

    public deleteModalCloseCommand = new RelayCommand(() => {
        this.deleteModalOpen = false;
    });

    public deleteMentorCommand = new RelayCommand(async (deleteMentorId: string) => {
        const deleteMentorByIdEndpoint = new DELETEMentorByIdEndpoint(this.model, deleteMentorId);
        let _ = await this.apiClient.sendAsync(deleteMentorByIdEndpoint);
        if (this.apiClient.IsRequestSuccessful) {
            this.deleteModalOpen = false;
            this.apiClient.sendAsync(new GETMentorsEndpoint(this));
        }
    });
    // #region Api Commands

    public resetApiClientErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Api Commands

    //#region api calls

    public loadMentors = async () => {
        await this.apiClient.sendAsync(new GETMentorsEndpoint(this));
    };

    //#endregion api calls
}
