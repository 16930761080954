import { SVGProps } from "react";

export const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30">
        <g id="Group_11629" data-name="Group 11629" transform="translate(-13780 -10478)">
            <g id="Group_5361" data-name="Group 5361" transform="translate(13072 8061)">
                <g id="Group_5240" data-name="Group 5240" transform="translate(708 2417.25)">
                    <path id="Union_42" data-name="Union 42" d="M17.985,0H.02L0,30H23.96L24,6Z" transform="translate(0 -0.25)" fill="#fcd21d" />
                </g>
                <path id="Path_5662" data-name="Path 5662" d="M0,0,6,6H0Z" transform="translate(726 2417)" fill="#fef7d6" />
            </g>
            <g id="Group_10733" data-name="Group 10733" transform="translate(13410.477 10125)">
                <g id="Group_9242" data-name="Group 9242" transform="translate(387.796 376) rotate(180)">
                    <path
                        id="sort"
                        d="M8.419,0l6.5,5.974a.183.183,0,0,1,0,.3l-6.5,5.994V8.453H.239A.22.22,0,0,1,0,8.23V4.063A.221.221,0,0,1,.239,3.84h8.18Z"
                        transform="translate(0 15) rotate(-90)"
                    />
                </g>
            </g>
        </g>
    </svg>
);
