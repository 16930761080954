import { IObservableArray, action, computed, makeObservable, observable, runInAction, set } from "mobx";
import { ViewModelBase, FieldType, KeyValuePair, isEmptyOrWhitespace } from "@shoothill/core";
import { ResourceDocumentsModel, ResourceDocumentsModelValidator } from "./ResourceDocumentsModel";
import { RelayCommand } from "Application/Commands";

import { ResourceDocumentTableItemViewModel } from "../ResourceDocumentTableItem/ResourceDocumentTableItemViewModel";
import { ResourceDocumentFormViewModel } from "../ResourceDocumentForm/ResourceDocumentFormViewModel";
import { APIClient } from "Application";
import { GetAllResourceDocumentsEndpoint } from "../Endpoints/GEtAllResourceDocumentsEndpoint";
import { POSTInsertResourceDocumentEndpoint } from "../Endpoints/POSTInsertResourceDocumentsEndpoint";
import { ResourceDocumentModel } from "../Shared/ResourceDocumentModel";
import axios, * as Axios from "axios";
import { AppUrls } from "AppUrls";
import { DELETEResourceDocumentByIdEndpoint } from "../Endpoints/DELETEResourceDocumentByIdEndpoint";
export class ResourceDocumentsViewModel extends ViewModelBase<ResourceDocumentsModel> {
    //region boilerplate
    public apiClient = new APIClient();
    //endregion boilerplate
    //region observables
    public resourceIdForDeletion: Guid = null;
    public resourceViewModels: IObservableArray<ResourceDocumentTableItemViewModel> = observable<ResourceDocumentTableItemViewModel>([]);
    public resourceDocumentFormViewModel: ResourceDocumentFormViewModel | null = null;

    //endregion observables

    constructor() {
        super(new ResourceDocumentsModel());
        makeObservable(this, {
            //observables
            resourceIdForDeletion: observable,
            resourceDocumentFormViewModel: observable,

            //actions
            setResourceDocumentFormViewModel: action,

            //computed values
            canFilterDocuments: computed,
            filteredResourceViewModels: computed,
        });
        this.setValidator(new ResourceDocumentsModelValidator());
        this.loadResourceDocuments();
    }

    //region properties

    public get resourceCategoriesOptions(): KeyValuePair[] {
        const options = this.resourceViewModels
            .map((r) => {
                return { key: r.model.resourceCategoryId, text: r.categoryName } as KeyValuePair;
            })
            .sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));

        const distinctOptions = options.filter((option, index, arr) => arr.findIndex((t) => t.key === option.key) === index);

        return [{ key: "", text: "All categories" }, ...distinctOptions];
    }

    public get isLoading() {
        return this.apiClient.IsBusy;
    }

    public get canFilterDocuments(): boolean {
        return !isEmptyOrWhitespace(this.model.filterResourceCategoryId);
    }

    //endregion properties

    //region computed values

    public get canShowDeleteResourceDocumentModal() {
        return this.resourceIdForDeletion != null;
    }

    public get canShowUploadNewResourceDocumentModal() {
        return this.resourceDocumentFormViewModel != null;
    }

    public get filteredResourceViewModels() {
        let retVal = this.resourceViewModels.slice();

        if (this.canFilterDocuments) {
            retVal = retVal.filter((vm) => vm.model.resourceCategoryId === this.model.filterResourceCategoryId).slice();
        }

        return retVal;
    }

    //endregion computed values

    //region actions

    public setResourceDocumentIdForDeletion = (id: Guid) => {
        this.resourceIdForDeletion = id;
    };

    public setResourceDocumentFormViewModel = (value: ResourceDocumentFormViewModel | null) => {
        this.resourceDocumentFormViewModel = value;
    };

    //endregion actions

    // #region Api Commands

    public resetApiClientErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Api Commands

    //region commands

    public submitResourceDocumentDeletionCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new DELETEResourceDocumentByIdEndpoint(this));
            if (this.apiClient.IsRequestSuccessful) {
                this.setResourceDocumentIdForDeletion(null);
            }
        },
        () => this.canShowDeleteResourceDocumentModal,
    );

    public submitResourceDocumentUploadCommand = new RelayCommand(
        async () => {
            await this.apiClient.sendAsync(new POSTInsertResourceDocumentEndpoint(this));
            if (this.apiClient.IsRequestSuccessful) {
                this.setResourceDocumentFormViewModel(null);
            }
        },
        () => {
            return this.resourceDocumentFormViewModel!.documentFilesViewModel.isModelValid();
        },
    );

    public selectResourceDocumentForDeletionCommand = new RelayCommand((id: Guid) => {
        this.setResourceDocumentIdForDeletion(id);
    });

    public cancelResourceDocumentDeletionCommand = new RelayCommand(() => {
        this.setResourceDocumentIdForDeletion(null);
    });

    public updateFilterResourceCategoryIdCommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.setValue("filterResourceCategoryId", value.key);
        },
        () => !this.apiClient.IsBusy,
    );

    public closeUploadNewResourceDocumentModalCommand = new RelayCommand(() => {
        this.setResourceDocumentFormViewModel(null);
    });

    public openUploadNewResourceDocumentModalCommand = new RelayCommand(
        () => {
            this.setResourceDocumentFormViewModel(new ResourceDocumentFormViewModel(this.closeUploadNewResourceDocumentModalCommand, this.submitResourceDocumentUploadCommand));
        },
        () => !this.canShowUploadNewResourceDocumentModal || this.apiClient.IsBusy,
    );

    //endregion commands

    //region api calls
    public loadResourceDocuments = async () => {
        await this.apiClient.sendAsync(new GetAllResourceDocumentsEndpoint(this));
    };

    //endregion api calls

    //region helpers
    //endregion helpers
}
