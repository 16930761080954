import React from "react";
import { observer } from "mobx-react-lite";
import { AttendanceViewModel } from "./AttendanceViewModel";
import { Box, Label } from "Components";
import { StudentSVG } from "Assets/Icons/StudentSVG";
import { MentorsSVG } from "Assets/Icons/MentorsSVG";
import { HoursSVG } from "Assets/Icons/HoursSVG";
import { SessionSVG } from "Assets/Icons/SessionSVG";
import styled from "@emotion/styled";

export interface IAttendanceProps {
    viewModel: AttendanceViewModel;
}

const IconBox = styled(Box)`
    background: #f3f3f3;
    height: 162px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 1440px) {
        height: 145px;
    }
`;

const ProgressBox = styled(Box)`
    .greenBoy {
        svg {
            width: 100%;
            height: 520px;
        }
        path {
            fill: #27e6a3;
        }
    }
    .redBoy {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        svg {
            width: 100%;
            height: 520px;
        }
        path {
            fill: #e6054e;
        }
    }
`;

const NumberBox = styled(Box)`
    height: 162px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    label {
        font: normal normal normal 20px/11px Open Sans;
        letter-spacing: -0.22px;
        color: #ffffff;
        &:first-child {
            font: normal normal bold 63px/65px Open Sans;
            letter-spacing: 0px;
            color: #ffffff;
            font-family: OpenSans-CondBold;
        }
    }
    @media only screen and (max-width: 1440px) {
        height: 145px;
        label {
            font: normal normal normal 16px/11px Open Sans;
        }
    }
`;

const AttendanceFilter = styled(Box)`
    grid-template-columns: 1fr 1fr 1fr;
    @media only screen and (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
        gap: 40px;
    }
    @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
`;

const LeftBox = styled(Box)`
    padding-right: 55px;
    @media only screen and (max-width: 1550px) {
        padding-right: 30px;
    }
    @media only screen and (max-width: 991px) {
        grid-column-end: 2;
        padding-right: 0;
    }
`;

const CenterBox = styled(Box)`
    padding: 0 55px;
    @media only screen and (max-width: 1550px) {
        padding: 0 30px;
    }
    @media only screen and (max-width: 1400px) {
        border-right: none !important;
    }
    @media only screen and (max-width: 991px) {
        border-left: none !important;
        border-top: 1px solid #dcdcdc;
        padding: 40px 0 0;
    }
`;

const RightBox = styled(Box)`
    padding-left: 55px;
    grid-template-columns: 196px 162px;
    @media only screen and (max-width: 1550px) {
        padding-left: 30px;
    }
    @media only screen and (max-width: 1440px) {
        grid-template-columns: 196px 145px;
    }
    @media only screen and (max-width: 1400px) {
        grid-column-start: 1;
        grid-column-end: 3;
        justify-content: center;
        border-top: 1px solid #dcdcdc;
        padding-top: 40px;
    }
    @media only screen and (max-width: 991px) {
        grid-column-end: 2;
        padding-left: 0;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        & > div:last-child {
            flex-direction: row;
            justify-content: center;
            & > div {
                width: 145px;
            }
        }
    }
`;

const TwoIconBox = styled(Box)`
    grid-template-columns: 162px 162px;
    @media only screen and (max-width: 1440px) {
        grid-template-columns: 145px 145px;
    }
    @media only screen and (max-width: 1400px) {
        justify-content: center;
    }
`;

const ThreeIconBox = styled(Box)`
    grid-template-columns: 162px 162px 162px;
    @media only screen and (max-width: 1440px) {
        grid-template-columns: 145px 145px 145px;
    }
    @media only screen and (max-width: 600px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & > div {
            width: 145px !important;
        }
    }
`;

export const AttendanceFilterView: React.FC<IAttendanceProps> = observer((props) => {
    return (
        <>
            <AttendanceFilter display={"grid"} borderBottom="1px solid #dcdcdc" paddingBottom={"30px"} mb={"30px"}>
                <LeftBox pr={"55px"}>
                    <TwoIconBox display={"grid"} style={{ gap: "14px" }} mb={"14px"}>
                        <IconBox>
                            <StudentSVG />
                        </IconBox>
                        <NumberBox bg={"#F39200"}>
                            <Label>{props.viewModel.model.studentCount}</Label>
                            <Label>Students</Label>
                        </NumberBox>
                    </TwoIconBox>
                    <TwoIconBox display={"grid"} style={{ gap: "14px" }} mb={"14px"}>
                        <IconBox>
                            <MentorsSVG />
                        </IconBox>
                        <NumberBox bg={"#C15DB0"}>
                            <Label>{props.viewModel.model.mentorCount}</Label>
                            <Label>Mentors</Label>
                        </NumberBox>
                    </TwoIconBox>
                    <TwoIconBox display={"grid"} style={{ gap: "14px" }}>
                        <IconBox>
                            <HoursSVG />
                        </IconBox>
                        <NumberBox bg={"#11A696"}>
                            <Label>{props.viewModel.model.hours !== 0 ? props.viewModel.model.hours : 0}</Label>
                            <Label>Hours</Label>
                        </NumberBox>
                    </TwoIconBox>
                </LeftBox>
                <CenterBox borderLeft="1px solid #dcdcdc" borderRight="1px solid #dcdcdc" px={"55px"}>
                    <Box display={"grid"} justifyContent="center" gridTemplateColumns={"338px"} style={{ gap: "14px" }} mb={"14px"}>
                        <IconBox width={"100%"}>
                            <SessionSVG />
                        </IconBox>
                    </Box>
                    <Box display={"grid"} justifyContent="center" gridTemplateColumns={"338px"} style={{ gap: "14px" }} mb={"14px"}>
                        <NumberBox bg={"#71B3FC"} width={"100%"}>
                            <Label>{props.viewModel.model.studentSessionCount}</Label>
                            <Label>Sessions</Label>
                        </NumberBox>
                    </Box>
                    <ThreeIconBox display={"grid"} justifyContent="center" style={{ gap: "14px" }}>
                        <NumberBox bg={"#71B3FC"}>
                            <Label>{props.viewModel.model.amSessions}</Label>
                            <Label>AM Sessions</Label>
                        </NumberBox>
                        <NumberBox bg={"#71B3FC"}>
                            <Label>{props.viewModel.model.pmSessions}</Label>
                            <Label>PM Sessions</Label>
                        </NumberBox>
                        <NumberBox bg={"#71B3FC"}>
                            <Label>{props.viewModel.model.pendingSessions}</Label>
                            <Label>Pending Sessions</Label>
                        </NumberBox>
                    </ThreeIconBox>
                </CenterBox>
                <RightBox pl={"55px"} display={"grid"} justifyContent={"flex-end"} style={{ gap: "14px 55px" }}>
                    <ProgressBox position={"relative"} height="520px">
                        <Box className="greenBoy">
                            <StudentSVG />
                        </Box>
                        <Box className="redBoy" height={`${props.viewModel.model.absence}%`}>
                            <StudentSVG className="redBoy" />
                        </Box>
                    </ProgressBox>
                    <Box display={"flex"} flexDirection={"column"} style={{ gap: "14px" }}>
                        <NumberBox bg={"#27E6A3"}>
                            <Label>{Math.round(props.viewModel.model.attendance)}%</Label>
                            <Label>Attendance</Label>
                        </NumberBox>
                        <NumberBox bg={"#E6054E"}>
                            <Label>{Math.round(props.viewModel.model.absence)}%</Label>
                            <Label>Absence</Label>
                        </NumberBox>
                    </Box>
                </RightBox>
            </AttendanceFilter>
        </>
    );
});
