import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box, Button, Input, Label, setPageTitle } from "Application";
import { RegisterUserViewModel } from "./RegisterUserViewModel";
import { useParams } from "react-router";
import { LoginHomeViewModel } from "../LoginHomeViewModel";
import { Route, Routes } from "react-router-dom";

interface Props {
    confirmEmail?: boolean;
    loginHomeViewModel: LoginHomeViewModel;
}
/**
 * Login page view.
 */
const RegisterView: React.FunctionComponent<Props> = observer((props: Props) => {
    // #region Code Behind
    const [viewModel] = useState(() => new RegisterUserViewModel());
    let params = useParams();
    let token = params.token || null;

    useEffect(() => {
        if (viewModel.isLoggedIn) {
            viewModel.history.push("/");
        }
        setPageTitle("Sign Up");
    }, []);

    useEffect(() => {
        if (props.confirmEmail) {
            viewModel.confirmEmailCommand.execute(token);
        }
    }, [token]);

    // #endregion Code Behind
    return (
        <>
            <Routes>
                <Route index element={<RenderForm />} />
                <Route path={"success"} element={<Label fontStyle={"h5"}>Thank you for registering your account. You should shortly receive on email.</Label>} />
                <Route path={"error"} element={<Label fontStyle={"h4"}>There was an error confirming your email.</Label>} />
            </Routes>
            <Box onClick={() => props.loginHomeViewModel.navigateToSignInCommand.execute()} mt={1}>
                <Label fontStyle={"h6"} pointer>
                    Back to sign in
                </Label>
            </Box>
        </>
    );
});
export default RegisterView;

const RenderForm = observer(() => {
    const [viewModel] = useState(() => new RegisterUserViewModel());

    return (
        <>
            <Label fontStyle={"error"} color={"error"}>
                {viewModel.apiClient.ValidationMessage}
            </Label>
            <Box grid alignContent={"center"} dc="1fr" rowGap={"15px"} minWidth={"300px"}>
                <Input
                    displayName="First name *"
                    command={viewModel.updateFirstNameCommand}
                    value={() => viewModel.getValue("firstName")}
                    validationMessage={() => viewModel.getError("firstName")}
                    cy={"firstName"}
                />
                <Input
                    displayName="Last name *"
                    command={viewModel.updateLastNameCommand}
                    value={() => viewModel.getValue("lastName")}
                    validationMessage={() => viewModel.getError("lastName")}
                    cy={"lastName"}
                />
                <Input
                    displayName="Email address *"
                    autoFill={false}
                    command={viewModel.updateEmailAddressCommand}
                    value={() => viewModel.getValue("emailAddress")}
                    validationMessage={() => viewModel.getError("emailAddress")}
                    cy={"emailAddress"}
                />
                {/*<Input
                    displayName="Password *"
                    command={viewModel.updatePasswordCommand}
                    autoFill={false}
                    type="password"
                    value={() => viewModel.getValue("password")}
                    validationMessage={() => viewModel.getError("password")}
                    cy={"password"}
                />
                <Input
                    displayName="Confirm Password *"
                    command={viewModel.updateConfirmPasswordCommand}
                    autoFill={false}
                    type="password"
                    value={() => viewModel.getValue("confirmPassword")}
                    validationMessage={() => viewModel.getError("confirmPassword")}
                    cy={"confirmPassword"}
                />*/}
                <Button displayName="Continue" command={viewModel.registerCommand} paletteColor="primary" type="submit" cy={"continue"} />
            </Box>
        </>
    );
});
