import { isNullOrEmpty } from "@shoothill/core";
import { config } from "../config";
import { Lambda, observe } from "mobx";
import { Logger } from "../index";
import moment from "moment";

export const setPageTitle = (title: string): void => {
    const currentPageTitle = document.getElementsByTagName("title")[0].innerHTML as string;
    if (!isNullOrEmpty(title)) {
        const newPageTitle = title + " | " + config.rootPageTitle;
        if (currentPageTitle !== newPageTitle) {
            document.title = newPageTitle;
        }
    }
};

/**
 * This helper method is used to link a mobx observable array to a mobx observable array of viewmodels
 * @param observableModelArray The observable array to link to that exists in your model
 * @param observableViewModelArray The observable array of viewmodels to link to that exists in your viewmodel
 * @param viewModelClass The type of viewmodel to create. IE TodoItemViewModel
 */
export const viewModelModelLink = (observableModelArray: any, observableViewModelArray: any, viewModelClass: any): Lambda => {
    const disposable: Lambda = observe(observableModelArray, (childModelChanges: any) => {
        try {
            for (const addedChildModel of childModelChanges.added) {
                observableViewModelArray.push(new viewModelClass(addedChildModel));
            }

            for (const removedChildModel of childModelChanges.removed) {
                const childViewModelToRemove = observableViewModelArray.find((vm: any) => vm.model.KEY === removedChildModel.KEY);

                if (childViewModelToRemove) {
                    observableViewModelArray.remove(childViewModelToRemove);
                }
            }
        } catch (e: any) {
            Logger.logError(`${viewModelClass.constructor.name} viewModelModelLink`, e);
        }
    });
    return () => {
        Logger.logInformation(`${viewModelClass.constructor.name} viewModelModelLink`, "Disposing of observer");
        disposable();
    };
};

export const formatDate = (value: any, isoCode = "en-GB"): string => {
    // const formatter = new Intl.DateTimeFormat(isoCode);
    // return formatter.format(value); /* 15/06/2022 */
    const date = new Date(value);
    if (isNaN(date.getTime())) {
        throw new Error("Invalid date value");
    }
    const formatter = new Intl.DateTimeFormat(isoCode);
    return formatter.format(date);
};

export const formatTime = (value: Date, isoCode = "en-GB"): string => {
    const formatter = new Intl.DateTimeFormat(isoCode, {
        hour: "2-digit",
        minute: "2-digit",
    });

    return formatter.format(value);
};

export const startOfDay = (date: Date | null | undefined) => {
    return date ? moment(date).clone().startOf("day").toDate() : date;
};

export const endOfDay = (date: Date | null | undefined) => {
    return date ? moment(date).clone().startOf("day").add(1, "day").subtract(1, "second").toDate() : date;
};

export const dateInRange = (target: Date, start: Date, end: Date) => {
    return target.setHours(0, 0, 0, 0) >= start.setHours(0, 0, 0, 0) && target.setHours(0, 0, 0, 0) <= end.setHours(0, 0, 0, 0);
};
