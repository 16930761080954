import { Box, Show } from "Components";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { File } from "./File";
import { FilesViewModel } from "./FilesViewModel";
import { FileIcon } from "Assets/Icons/FileIcon";
import { toast } from "react-hot-toast";
export interface IFilesProps {
    className?: string;
    viewModel: FilesViewModel;
    styles?: any;
}

export const Files: React.FC<IFilesProps> = observer((props) => {
    const inputReference = useRef<any>(null);

    const fileTypes = (): string => {
        return props.viewModel.fileTypes;
    };

    const isDisabled = (): boolean => {
        return !props.viewModel.addCommand.canExecute;
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const allowedFileTypes = [".pdf", ".doc", ".xls", ".jpg", ".png", ".docx"];
        const files = event.target.files;

        // Check if any files are selected
        if (!files || files.length === 0) {
            return;
        }

        for (const file of files) {
            const fileType = "." + (file.name.split(".").pop() || "").toLowerCase();

            if (!allowedFileTypes.includes(fileType)) {
                toast.error(`Invalid file type: ${file.name}`, {
                    position: "top-right",
                    style: {
                        marginTop: "45px",
                    },
                });
                return;
            }
        }

        props.viewModel.addCommand.execute(files);

        event.target.value = "";
    };

    const onDropFile = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        props.viewModel.addCommand.execute(event.dataTransfer.files);
    };

    const onDragFile = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        inputReference?.current?.click();
    };

    return (
        <div>
            <Show if={props.viewModel.fileViewModels.length == 0}>
                <Box onDragEnter={onDragFile} onDragOver={onDragFile} onDrop={onDropFile}>
                    <input accept={fileTypes()} multiple={false} ref={inputReference} style={{ display: "none" }} type="file" onChange={onChange} />
                    <button
                        disabled={isDisabled()}
                        onClick={onClick}
                        type="button"
                        style={{
                            background: "#F9F9F9",
                            border: "1px dashed #DCDCDC",
                            width: "100%",
                            padding: "15px",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                        }}
                    >
                        <FileIcon />
                        <Box style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#8D8D8D" }} mt={"5px"}>
                            Click or drag a file to attach a file <br />
                            (.PDF .DOC .XLS .JPG .PNG .DOCX)
                        </Box>
                    </button>
                </Box>
            </Show>
            <Show if={props.viewModel.fileViewModels.length > 0}>
                <Box dc={"1fr 1fr"} columnGap="30px" rowGap="15px">
                    {props.viewModel.fileViewModels.map((viewModel) => {
                        return <File className={props.className} viewModel={viewModel} key={viewModel.KEY} />;
                    })}
                </Box>
            </Show>
        </div>
    );
});
