import React from "react";
import styled from "@emotion/styled";
import { theme } from "Application";

const Icon = styled.div`
    height: 15px;
    width: 15px;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: "";
        height: 15px;
        border-left: 2px solid ${theme.palette.common.black};
        position: absolute;
        transform: translate(-50%, -50%) rotate(45deg);
        left: 50%;
        top: 50%;
    }
    &:before {
        content: "";
        height: 15px;
        border-left: 2px solid ${theme.palette.common.black};
        position: absolute;
        transform: translate(-50%, -50%) rotate(-45deg);
        left: 50%;
        top: 50%;
    }
`;

export const CloseIcon: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => {
    return (
        <>
            <Icon {...props} />
        </>
    );
};
