import * as React from "react";
import { SVGProps } from "react";

export const CalendarSVG = (props: SVGProps<SVGSVGElement>) => (
    // <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    //     <g data-name="Group 10" fill="none" stroke="#2b2c2c">
    //         <g data-name="Rectangle 10" transform="translate(0 1)">
    //             <rect width={18} height={17} rx={2} stroke="none" />
    //             <rect x={0.5} y={0.5} width={17} height={16} rx={1.5} />
    //         </g>
    //         <g data-name="Rectangle 11">
    //             <path stroke="none" d="M3 0h2v2H3z" />
    //             <path d="M3.5.5h1v1h-1z" />
    //         </g>
    //         <g data-name="Rectangle 14">
    //             <path stroke="none" d="M13 0h2v2h-2z" />
    //             <path d="M13.5.5h1v1h-1z" />
    //         </g>
    //         <g data-name="Rectangle 15" transform="translate(13 8)">
    //             <rect width={2} height={2} rx={1} stroke="none" />
    //             <rect x={0.5} y={0.5} width={1} height={1} rx={0.5} />
    //         </g>
    //         <g data-name="Rectangle 16" transform="translate(9 8)">
    //             <rect width={2} height={2} rx={1} stroke="none" />
    //             <rect x={0.5} y={0.5} width={1} height={1} rx={0.5} />
    //         </g>
    //         <g data-name="Rectangle 17" transform="translate(7 11)">
    //             <rect width={2} height={2} rx={1} stroke="none" />
    //             <rect x={0.5} y={0.5} width={1} height={1} rx={0.5} />
    //         </g>
    //         <g data-name="Rectangle 18" transform="translate(4 11)">
    //             <rect width={2} height={2} rx={1} stroke="none" />
    //             <rect x={0.5} y={0.5} width={1} height={1} rx={0.5} />
    //         </g>
    //         <g data-name="Rectangle 12">
    //             <path stroke="none" d="M3 4h12v2H3z" />
    //             <path d="M3.5 4.5h11v1h-11z" />
    //         </g>
    //         <g data-name="Rectangle 13">
    //             <path stroke="none" d="M3 4h12v2H3z" />
    //             <path d="M3.5 4.5h11v1h-11z" />
    //         </g>
    //     </g>
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="17" viewBox="0 0 15.001 17">
        <g id="calendar_icon" data-name="calendar icon" transform="translate(-168.999 -682)">
            <g id="Subtraction_2" data-name="Subtraction 2" transform="translate(-7425 -23797)" fill="#bd9e17">
                <path
                    d="M 7608.49853515625 24484.501953125 L 7594.49853515625 24484.501953125 L 7594.49853515625 24482.001953125 C 7594.49853515625 24481.724609375 7594.7236328125 24481.5 7595.00048828125 24481.5 L 7595.5009765625 24481.5 L 7595.501953125 24482.998046875 L 7595.50244140625 24483.498046875 L 7596.001953125 24483.498046875 L 7597.9990234375 24483.498046875 L 7598.4990234375 24483.498046875 L 7598.4990234375 24482.998046875 L 7598.4990234375 24481.501953125 L 7604.49755859375 24481.5 L 7604.498046875 24482.998046875 L 7604.498046875 24483.498046875 L 7604.998046875 24483.498046875 L 7607.00146484375 24483.498046875 L 7607.50146484375 24483.498046875 L 7607.50146484375 24482.998046875 L 7607.50146484375 24481.501953125 L 7608.00390625 24481.5 C 7608.2763671875 24481.5 7608.49853515625 24481.724609375 7608.49853515625 24482.001953125 L 7608.49853515625 24484.501953125 Z"
                    stroke="none"
                />
                <path
                    d="M 7607.998046875 24484.001953125 L 7607.998046875 24483.998046875 L 7603.99853515625 24483.998046875 L 7603.99755859375 24482 L 7598.9990234375 24482.001953125 L 7598.9990234375 24483.998046875 L 7595.00244140625 24483.998046875 L 7595.00146484375 24482.001953125 L 7594.99853515625 24482.001953125 L 7594.99853515625 24484.001953125 L 7607.998046875 24484.001953125 M 7608.99853515625 24485.001953125 L 7593.99853515625 24485.001953125 L 7593.99853515625 24482.001953125 C 7593.99853515625 24481.44921875 7594.44775390625 24481 7595.00048828125 24481 L 7596.0009765625 24481 L 7596.001953125 24482.998046875 L 7597.9990234375 24482.998046875 L 7597.9990234375 24481.001953125 L 7604.9970703125 24481 L 7604.998046875 24482.998046875 L 7607.00146484375 24482.998046875 L 7607.00146484375 24481.001953125 L 7608.0029296875 24481 C 7608.5517578125 24481 7608.99853515625 24481.44921875 7608.99853515625 24482.001953125 L 7608.99853515625 24485.001953125 Z"
                    stroke="none"
                    fill="#bd9e17"
                />
            </g>
            <path
                id="Rectangle_1951"
                data-name="Rectangle 1951"
                d="M1,0H1A1,1,0,0,1,2,1V2A0,0,0,0,1,2,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z"
                transform="translate(171 682)"
                fill="#bd9e17"
            />
            <path
                id="Rectangle_1953"
                data-name="Rectangle 1953"
                d="M1,0H1A1,1,0,0,1,2,1V2A0,0,0,0,1,2,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z"
                transform="translate(180 682)"
                fill="#bd9e17"
            />
            <g id="Rectangle_1954" data-name="Rectangle 1954" transform="translate(169 687)" fill="none" stroke="#bd9e17" stroke-width="1">
                <path d="M0,0H15a0,0,0,0,1,0,0V11a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V0A0,0,0,0,1,0,0Z" stroke="none" />
                <rect x="0.5" y="0.5" width="14" height="11" rx="0.5" fill="none" />
            </g>
            <rect id="Rectangle_1955" data-name="Rectangle 1955" width="2" height="2" transform="translate(171 689)" fill="#bd9e17" />
            <rect id="Rectangle_1956" data-name="Rectangle 1956" width="2" height="2" transform="translate(171 692)" fill="#bd9e17" />
            <rect id="Rectangle_1957" data-name="Rectangle 1957" width="2" height="2" transform="translate(171 695)" fill="#bd9e17" />
            <rect id="Rectangle_1958" data-name="Rectangle 1958" width="2" height="2" transform="translate(174 689)" fill="#bd9e17" />
            <rect id="Rectangle_1959" data-name="Rectangle 1959" width="2" height="2" transform="translate(174 692)" fill="#bd9e17" />
            <rect id="Rectangle_1960" data-name="Rectangle 1960" width="2" height="2" transform="translate(174 695)" fill="#bd9e17" />
            <rect id="Rectangle_1961" data-name="Rectangle 1961" width="2" height="2" transform="translate(177 689)" fill="#bd9e17" />
            <rect id="Rectangle_1962" data-name="Rectangle 1962" width="2" height="2" transform="translate(177 692)" fill="#bd9e17" />
            <rect id="Rectangle_1963" data-name="Rectangle 1963" width="2" height="2" transform="translate(177 695)" fill="#bd9e17" />
            <rect id="Rectangle_1964" data-name="Rectangle 1964" width="2" height="2" transform="translate(180 689)" fill="#bd9e17" />
            <rect id="Rectangle_1965" data-name="Rectangle 1965" width="2" height="2" transform="translate(180 692)" fill="#bd9e17" />
            <rect id="Rectangle_1966" data-name="Rectangle 1966" width="2" height="2" transform="translate(180 695)" fill="#bd9e17" />
        </g>
    </svg>
);
