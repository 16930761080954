/* eslint-disable @typescript-eslint/no-use-before-define */
import styled from "@emotion/styled";
import { Button } from "Components";
import React from "react";
import { ThemedDialog } from "Styles/ThemedPrimitives/Style1/ThemedDialog";
import { ICommand } from "Application";

const Container = styled.div``;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 0px 10px;
    Button {
        width: 100%;
        margin-bottom: 15px;
        height: 40px;
        border-radius: 5px;
    }
`;

export interface IProps {
    title: string;
    confirmCommand: ICommand;
    cancelCommand: ICommand;
    isOpen: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    containerClassName?: string;
    footerClassName?: string;
    buttonGroupClassName?: string;
    icon?: any;
    hideIcon?: boolean;
}

const ThemedPromptModal: React.FC<IProps> = (props: IProps) => {
    const { title, confirmCommand, cancelCommand, isOpen, containerClassName, footerClassName, buttonGroupClassName, icon, hideIcon } = props;

    const confirmButtonText = props.confirmButtonText ?? "PROCEED";
    const cancelButtonText = props.cancelButtonText ?? "CANCEL";

    return (
        <>
            <ThemedDialog title={title} isOpen={isOpen} onCloseCommand={cancelCommand} icon={icon} hideIcon={hideIcon}>
                <Container className={containerClassName}>
                    <Footer className={footerClassName}>
                        <ButtonGroup className={buttonGroupClassName}>
                            <Button command={confirmCommand} displayName={confirmButtonText} paletteColor="primary" />
                            <Button command={cancelCommand} displayName={cancelButtonText} paletteColor="secondary" />
                        </ButtonGroup>
                    </Footer>
                </Container>
            </ThemedDialog>
        </>
    );
};

export default ThemedPromptModal;
