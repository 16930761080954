import { SVGProps } from "react";

export const HoursSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="67.115" height="113.29" viewBox="0 0 67.115 113.29">
        <g id="Group_11155" data-name="Group 11155" transform="translate(-80.422 -748)">
            <path
                id="Subtraction_6"
                data-name="Subtraction 6"
                d="M21.862,43.723A21.862,21.862,0,0,1,6.4,6.4,21.862,21.862,0,0,1,37.32,37.319,21.717,21.717,0,0,1,21.862,43.723Zm0-38.465a1.369,1.369,0,0,0-1.368,1.366V24.739l11.9,6.494a1.367,1.367,0,1,0,1.31-2.4L23.228,23.116V6.624A1.368,1.368,0,0,0,21.862,5.258Z"
                transform="translate(103.815 817.568)"
                fill="#11a696"
            />
            <path
                id="Subtraction_5"
                data-name="Subtraction 5"
                d="M28.44,110.075H12.352a3.653,3.653,0,0,1-3.633-3.456L6.937,74.713A7.722,7.722,0,0,1,0,67.027V38.271A13.775,13.775,0,0,1,13.784,24.485h13.4A13.76,13.76,0,0,1,40.936,38.291V66.967a25.171,25.171,0,0,0-12.5,43.106ZM20.479,22.2A11.085,11.085,0,0,1,9.4,11.1a11.076,11.076,0,1,1,18.909,7.847A11,11,0,0,1,20.479,22.2Z"
                transform="translate(80.422 748)"
                fill="#11a696"
            />
        </g>
    </svg>
);
