import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { ApplicationError, Box, Button, EditDate, Label, ThemedLoader } from "Components";
import { WeeklyRotaViewModel } from "./WeeklyRotaViewModel";
import { formatDate } from "Application";
import { AddWeeklyRotaModal } from "./Modal/AddWeeklyRotaModal";
import styled from "@emotion/styled";
import { WeeklyRotaDetailView } from "./WeeklyRotaDetail/WeeklyRotaDetailView";

import { PageContainer, PageHeader, PageRoot } from "Styles/PageStyles";

const DateBox = styled(Box)`
    & > div {
        & > div:first-child {
            display: none !important;
        }
    }
`;

const PageWrapper = styled(Box)`
    padding: 30px;
    @media only screen and (max-width: 1024px) {
        padding: 30px 20px;
    }
`;

const PageHeaderLabel = styled(Label)`
    padding: 0px 30px 0px;
    @media only screen and (max-width: 1024px) {
        padding: 0 20px;
        z-index: 1;
    }
`;

export const WeeklyRotaView: React.FC = observer(() => {
    const [viewModel] = useState(() => new WeeklyRotaViewModel());

    const renderAddWeeklyModal = () => {
        return (
            <Box position={"fixed"} background={"#00000030"} display={"flex"} alignItems={"center"} justifyContent={"center"} top={"0"} bottom={"0"} left={"0"} right={"0"}>
                <AddWeeklyRotaModal viewModel={viewModel} />
            </Box>
        );
    };

    const renderWorkspace = () => {
        return (
            <>
                {viewModel.weeklyRotaDetailViewModel.model.rotaId && (
                    <Box bg={"#F3F3F3"} p={"12px 18px"} textAlign={"center"} onClick={() => viewModel?.weeklyRotaDetailViewModel.showSeesionLeftModalopenCommand.execute()}>
                        <Label style={{ cursor: "pointer", textDecoration: "underline", font: "normal normal bold 18px/22px Open Sans", letterSpacing: "0px", color: "#000000" }}>
                            {viewModel.weeklyRotaDetailViewModel.model.leftStudentSession.length} sessions left to assign for week commencing{" "}
                            {formatDate(viewModel.getValue("startDate"))}
                        </Label>

                        <>{console.log("leftStudentSession", viewModel.weeklyRotaDetailViewModel.model.leftStudentSession)}</>
                    </Box>
                )}
                <PageWrapper display={"flex"} flexWrap={"wrap"} style={{ gap: "30px" }} justifyContent={"space-between"}>
                    <Box display={"flex"} flexWrap={"wrap"} style={{ gap: "15px 30px" }} alignItems={"center"}>
                        <DateBox display={"flex"} style={{ gap: "15px" }} alignItems={"center"} flexWrap={"wrap"}>
                            <Label style={{ font: "normal normal bold 18px/22px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0px", color: "#000000" }}>
                                Week Starting:
                            </Label>
                            <EditDate
                                displayName={""}
                                value={() => viewModel.weeklyRotaDetailViewModel.getValue("startDate")}
                                command={viewModel.weeklyRotaDetailViewModel.updateRotaDateRangeCommand}
                                isWeekDisable={true}
                            />
                        </DateBox>
                        <Button command={viewModel.updatePreviousDateCommand} displayName={"Previous"} paletteColor="primary" style={{ height: "40px", minWidth: "130px" }} />
                        <Button command={viewModel.updateNextDateCommand} displayName={"Next"} paletteColor="primary" style={{ height: "40px", minWidth: "130px" }} />
                    </Box>

                    {viewModel.weeklyRotaDetailViewModel.model.rotaId ? (
                        <WeeklyRotaDetailView
                            viewModel={viewModel?.weeklyRotaDetailViewModel}
                            key={viewModel.weeklyRotaDetailViewModel.model.rotaId}
                            successfullyGetWeeklyRota={viewModel.weeklyRotaDetailViewModel.successfullyGetWeeklyRota}
                        />
                    ) : (
                        <>
                            <Button
                                command={viewModel.toggleWeeklyRotaModalOpenStateCommand}
                                displayName={"ADD WEEKLY ROTA"}
                                paletteColor="primary"
                                style={{ height: "40px", minWidth: "220px" }}
                            />
                            <Box width={"100%"} borderTop="1px dashed #DCDCDC" pt={"30px"}>
                                {viewModel.isWeeklyRotaModalOpen && renderAddWeeklyModal()}
                            </Box>
                        </>
                    )}
                </PageWrapper>
            </>
        );
    };
    return (
        <PageRoot padding="0px" style={{ paddingBottom: "6rem" }}>
            <PageHeader>
                <PageHeaderLabel fontStyle="h1" color="black">
                    Weekly Rota
                </PageHeaderLabel>
            </PageHeader>
            <PageContainer padding="0px">
                {(() => {
                    switch (true) {
                        case viewModel.apiClient.IsBusy:
                            return <ThemedLoader isOpen delayBeforeOpen={1000} containerStyles={{ height: "calc(100vh - 60px)", width: "calc(100vw - 80px)" }} />;

                        case viewModel.apiClient.IsSubmitted && viewModel.apiClient.HaveValidationMessage:
                            return (
                                <div className="error-scrollable-section">
                                    <div>
                                        <ApplicationError additionalMessage={viewModel.apiClient.ValidationMessage} resetCommand={viewModel.resetApiClientErrorCommand} />
                                    </div>
                                </div>
                            );

                        default:
                            return <div className="scrollable-section">{renderWorkspace()}</div>;
                    }
                })()}
            </PageContainer>
        </PageRoot>
    );
});
