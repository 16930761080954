import { SVGProps } from "react";

export const MinusSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_11594" data-name="Group 11594" transform="translate(-236 -367)">
            <rect id="Rectangle_3472" data-name="Rectangle 3472" width="20" height="20" transform="translate(236 367)" fill="#ff7070" />
            <g id="Group_10956" data-name="Group 10956" transform="translate(246 369.929) rotate(45)">
                <line id="Line_85" data-name="Line 85" x1="0.047" y2="8" transform="translate(4.995 1)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2" />
                <line id="Line_86" data-name="Line 86" x1="8" transform="translate(1 5)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2" />
            </g>
        </g>
    </svg>
);
