import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box, Button, Input, Label, Show, Else, ThemedLoader, setPageTitle } from "Application";
import { LoginHomeViewModel } from "../../LoginHomeViewModel";
import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";
import { useParams } from "react-router";
import styled from "@emotion/styled";

export const ThemeBtn = styled.div`
    min-height: 30px;
    padding: 8px 30px;
    text-transform: uppercase;
    font-family: OpenSans-CondBold;
    font-size: 0.875rem;
    letter-spacing: 0rem;
    line-height: 1.125rem;
    // background-color: #FCD21D;
    background-color: #fcd21d;
    text-align: center;
    border-radius: 3px;
`;
export const LoginOuter = styled.div`
    .innerloginPanel {
        min-width: 300px;
        @media only screen and (max-width: 480px) {
            min-width: auto;
        }
    }
`;
export const ForgotPasswordView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    // #region Code Behind
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    let params = useParams();
    let token = params.token || null;

    useEffect(() => {
        if (token) {
            viewModel.showResetPasswordCommand.execute(token);
        }
        setPageTitle("Forgot Password");
    }, []);
    // #endregion Code Behind

    const renderForm = () => (
        <>
            <LoginOuter>
                <Box grid alignContent={"center"} dc="1fr" rowGap={"15px"} className={"innerloginPanel"}>
                    <Label fontStyle={"error"} color={"error"}>
                        {viewModel.apiClient.ValidationMessage}
                    </Label>
                    <Show if={!viewModel.showSuccess}>
                        <Label fontStyle="field">To reset your password please provide us with the email address you use on your account. </Label>
                        <Input
                            placeholder="Email address"
                            displayName="Email Address"
                            command={viewModel.updateEmailAddressCommand}
                            value={() => viewModel.getValue("emailAddress")}
                            validationMessage={() => viewModel.getError("emailAddress")}
                        />
                        <Button
                            style={{ marginTop: "15px" }}
                            displayName="SEND RESET PASSWORD LINK"
                            command={viewModel.forgotPasswordCommand}
                            paletteColor="primary"
                            type="submit"
                        />
                        <Else>
                            <Label> Thank you a reset password link has been sent to your email.</Label>
                            <Button
                                style={{ marginTop: "15px" }}
                                displayName="Return to login"
                                command={loginHomeViewModel.navigateToSignInCommand}
                                paletteColor="primary"
                                type="submit"
                            />
                        </Else>
                    </Show>
                </Box>
            </LoginOuter>
        </>
    );

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;
    return (
        <React.Fragment>
            {renderForm()}
            {renderBusy()}
        </React.Fragment>
    );
});
