import { isEmptyOrWhitespace } from "@shoothill/core";
import React from "react";
import styled from "@emotion/styled";

import { Button, ICommand } from "Application";
import { ApplicationRoot } from "./ApplicationStyles";
import { ApplicationLoadingSpinner } from "./ApplicationLoadingSpinner";

interface IProps {
    additionalMessage?: string;
    resetCommand: ICommand;
}

export const ApplicationError: React.FC<IProps> = (props) => {
    // #region Code Behind

    const canDisplayAdditionalMessage = () => {
        return !isEmptyOrWhitespace(props.additionalMessage);
    };

    // #endregion Code Behind

    return (
        <ApplicationRoot>
            <div>
                <div className="message">Oops! Something went wrong.</div>
                {canDisplayAdditionalMessage() && <div className="additional-message">{props.additionalMessage}</div>}
                <div className="reset-container">
                    <Button command={props.resetCommand} displayName="OK" paletteColor={"primary"} />
                </div>
            </div>
        </ApplicationRoot>
    );
};
