import { ResetPasswordModel, ResetPasswordValidator } from "./ResetPasswordModel";
import { makeObservable, observable } from "mobx";
import { ViewModelBase, FieldType } from "@shoothill/core";
import { ICommand, ICommandAsync, RelayCommand, RelayCommandAsync } from "Application/Commands";
import { POSTResetPasswordEndpoint } from "./Endpoints/POSTResetPasswordEndpoint";
import { APIClient, IKeyState } from "Application";
import { AppUrls } from "AppUrls";

export class ResetPasswordViewModel extends ViewModelBase<ResetPasswordModel> {
    public tokenValid: boolean = false;
    public errorMessage: string = "";
    public showSuccessMessage: boolean = false;
    public showErrorMessage: boolean = false;
    public apiClient = new APIClient();
    private resetPasswordEndpoint = new POSTResetPasswordEndpoint();

    constructor() {
        super(new ResetPasswordModel());
        this.setValidator(new ResetPasswordValidator());
        makeObservable(this, {
            tokenValid: observable,
            errorMessage: observable,
            showSuccessMessage: observable,
            showErrorMessage: observable,
        });
    }
    public updateNewPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("newPassword", value, keyState);
        this.isFieldValid("confirmPassword");
    });
    public updateConfirmPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("confirmPassword", value, keyState);
    });
    private updateField(fieldName: keyof FieldType<ResetPasswordModel>, value: any, keyState: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
    public navigateToSignInCommand: ICommand = new RelayCommand(() => {
        this.history.replace(AppUrls.Client.Account.Login);
    });
    public resetPasswordCommand: ICommandAsync = new RelayCommandAsync(async (token: string) => {
        if (this.isModelValid()) {
            await this.apiClient.sendAsync(this.resetPasswordEndpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                this.showSuccessMessage = true;
            } else {
                this.showErrorMessage = true;
            }
        }
    });
}
