import styled from "@emotion/styled";
import { RelayCommand, theme } from "Application";
import { Box, Button, Label } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import React from "react";

const Container = styled.div`
    padding: 25px 20px;
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px 30px;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
        align: center;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 400px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

const CreateBox = styled(Box)`
    & > div .isButtonDisable {
        cursor: default;
        color: #495057;
        background-color: #e9ecef;
    }
`;

export interface IProps {
    validationMessage?: string;
    viewModel: any;
    onCancel: () => void;
}

const ErrorMessage: React.FC<IProps> = (props) => {
    const modalCloseCommand = new RelayCommand(() => {
        props.onCancel();
    });

    return (
        <>
            <StyledDialog open={true}>
                <Header>
                    Warning <CloseIcon onClick={() => modalCloseCommand.execute()} />
                </Header>
                <Container>
                    <Label>{props.validationMessage}</Label>
                    <Footer>
                        <CreateBox>
                            <ButtonGroup>
                                <Button
                                    command={modalCloseCommand}
                                    className={props.viewModel.apiClient.IsBusy ? "isButtonDisable" : ""}
                                    displayName={"CANCEL"}
                                    paletteColor="secondary"
                                />
                            </ButtonGroup>
                        </CreateBox>
                    </Footer>
                </Container>
            </StyledDialog>
        </>
    );
};

export default ErrorMessage;
