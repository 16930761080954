//dependencies
import { makeObservable } from "mobx";
import moment from "moment";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";

//components
import { APIClient, ICommand, RelayCommand } from "Application";
import { MentorAbsenceModelModelValidator, MentorAbsenceModel } from "./MentorAbsenceModel";
import { GETMentorsEndpoint } from "./EndPoints/GETMentorsEndpoint";
import { POSTMentorAbsenceEndPoint } from "./EndPoints/POSTMentorAbsenceEndPoint";
import { GETRotaByDateEndPoint } from "Views/WeeklyRota/EndPoint/GETRotaByDateEndPoint";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetailViewModel";

export class MentorAbsenceViewModel extends ViewModelBase<MentorAbsenceModel> {
    public apiClient = new APIClient();
    constructor() {
        super(new MentorAbsenceModel());
        this.setValidator(new MentorAbsenceModelModelValidator());
        makeObservable(this, {});
        this.loadMentorsAsync();
    }
    public get getAbsenceShift() {
        return [
            {
                key: "1",
                text: "Morning",
            },
            {
                key: "2",
                text: "Afternoon",
            },
        ] as KeyValuePair[];
    }

    public get getAbsenceTime() {
        return [
            {
                key: "1",
                text: "Lunch break ",
            },
            {
                key: "2",
                text: "End of day",
            },
        ] as KeyValuePair[];
    }
    /**
     *filter mentor absese time based on choosing shift
     */
    public get filteredMentorAbsenceTime() {
        if (this.getValue("absenceShift") === "2") {
            return [
                {
                    key: "2",
                    text: "End of day",
                },
            ] as KeyValuePair[];
        } else {
            return [
                {
                    key: "1",
                    text: "Lunch break ",
                },
                {
                    key: "2",
                    text: "End of day",
                },
            ] as KeyValuePair[];
        }
    }

    public updateMentorAbsenceStartDateCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("absenseStartDate", moment(value).format("YYYY-MM-DD"));
    });

    public updateMentorAbsenceEndDateCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("absenseEndDate", moment(value).format("YYYY-MM-DD"));
    });

    public updateMentorAbsenceShiftCommand = new RelayCommand((kvp: KeyValuePair) => {
        const isShift1 = kvp.key === "1";
        this.updateField("absenceShiftAM", isShift1 ? "00:00:00" : "12:00:00");
        this.updateField("absenceShift", kvp.key);
    });

    public updateMentorAbsenceTimeCommand = new RelayCommand((kvp: KeyValuePair) => {
        const isShift1 = this.getValue("absenceShift") === "1";
        if (isShift1 && kvp.key === "1") {
            this.updateField("absenceShiftPM", "11:59:00");
        } else {
            this.updateField("absenceShiftPM", "23:59:00");
        }
        this.updateField("absenceTime", kvp.key);
    });

    public updateMentorSelectTimeCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("mentorId", kvp.key);
    });

    public async loadMentorsAsync(): Promise<void> {
        let _ = await this.apiClient.sendAsync(new GETMentorsEndpoint(this));
        if (this.apiClient.IsRequestSuccessful) {
        }
    }

    public get mentorOptions(): KeyValuePair[] {
        return this.model.mentors.map((model: any) => {
            return { key: model.id, text: model.firstName + " " + model.lastName } as KeyValuePair;
        });
    }
    private async updateField(fieldName: keyof FieldType<MentorAbsenceModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public get canSubmitForm(): boolean {
        const isFormValid = this.isModelValid();
        return isFormValid;
    }
    public addMentorAbsenseRotaCommand = async (selectedRotaId: Guid, viewmodel: WeeklyRotaDetailsViewModel, props: any) => {
        viewmodel.successfullyGetWeeklyRota = false;
        if (this.canSubmitForm) {
            this.updateField("rotaId", selectedRotaId);
            let _ = await this.apiClient.sendAsync(new POSTMentorAbsenceEndPoint(this), this);
            if (this.apiClient.IsRequestSuccessful) {
                let _ = await this.apiClient.sendAsync(new GETRotaByDateEndPoint(moment(viewmodel.model.startDate).format("YYYY-MM-DD"), viewmodel));
                if (this.apiClient.IsRequestSuccessful) {
                    viewmodel.successfullyGetWeeklyRota = true;
                    props.onCancel();
                }
            }
        }
    };
}
