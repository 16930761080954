import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ResourceDocumentModel extends ModelBase<ResourceDocumentModel> {
    public resourceCategoryId: string = "";
    public fileName: string = "";
    public fileSize: number = 0;
    public fileURL: string = "";

    //related
    public createdByFullName: string = "";
    constructor() {
        super();
        makeObservable(this, {
            resourceCategoryId: observable,
        });
    }
}

export class ResourceDocumentModelValidator extends Validator<ResourceDocumentModel> {
    constructor() {
        super();
        this.ruleFor("resourceCategoryId").notEmpty().withMessage("Please select a category");
    }
}
