import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { Validator } from "Application/Validation";
import { FileModel } from "./FileModel";

export class FilesModel extends ModelBase<FilesModel> {
    public KEY: string = nanoid();
    public files = observable<FileModel>([]);
    public removedFiles = observable<FileModel>([]);
    public allowMultipleFiles = true;
    public fileTypes = "";

    constructor() {
        super();

        makeObservable(this, {
            files: observable,
            removedFiles: observable,
        });
    }
}

export class FilesModelValidator extends Validator<FilesModel> {
    constructor() {
        super();
        this.ruleFor("files").must({ predicate: (files, model) => files.length > 0, message: (value, model) => "Please Upload File" });
    }
}
