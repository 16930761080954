import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable } from "mobx";

export class MentorsModel extends ModelBase<MentorsModel> {
    constructor() {
        super();
        makeObservable(this, {});
    }
}

export class MentorsModelValidator extends Validator<MentorsModel> {
    constructor() {
        super();
    }
}
