import { ForgotPasswordModel, ForgotPasswordValidator } from "Application/Models";
import { makeObservable, observable } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { POSTForgotPasswordEndpoint } from "./Endpoints/POSTForgotPasswordEndpoint";
import { ResetPasswordViewModel } from "./ResetPasswordViewModel";
import { APIClient, ICommand, ICommandAsync, RelayCommand, RelayCommandAsync } from "Application";
import { IKeyState } from "Application";

export class ForgotPasswordViewModel extends ViewModelBase<ForgotPasswordModel> {
    public apiClient = new APIClient();
    public showSuccess: boolean = false;
    public showResetPassword: boolean = false;
    private forgotPasswordEndpoint = new POSTForgotPasswordEndpoint();
    public resetPasswordViewModel = new ResetPasswordViewModel();

    constructor() {
        super(new ForgotPasswordModel());
        this.setValidator(new ForgotPasswordValidator());
        makeObservable(this, {
            showSuccess: observable,
            showResetPassword: observable,
        });
    }

    public forgotPasswordCommand: ICommandAsync = new RelayCommandAsync(async () => {
        if (this.isModelValid()) {
            await this.apiClient.sendAsync(this.forgotPasswordEndpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                this.showSuccess = true;
            }
        }
    });

    public updateEmailAddressCommand: ICommand = new RelayCommand(async (value: string, keyState: IKeyState) => {
        this.setValue("emailAddress", value);
    });

    public showResetPasswordCommand: ICommand = new RelayCommand(async (token: string) => {
        this.resetPasswordViewModel.setValue("token", token);
        this.showResetPassword = true;
    });
}
