import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { Mentor, Student, StudentEngagementModel, StudentTargets } from "../Form/WeeklyRotaSessionReportModel";
import { WeeklyRotaSessionReportViewModel } from "../Form/WeeklyRotaSessionReportViewModel";
import { ActivityType } from "Application/Models/Domain";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";

class WeeklyRotaSessionReportResponse {
    public student: Student = new Student();
    public mentor: Mentor[] = [];
    public studentTargets: StudentTargets[] = [];
    public studentEngagements: StudentEngagementModel[] = [];
    public activityTypes: ActivityType[] = [];
}

export class GETWeeklyRotaSessionReportEndpoint extends EndpointWithoutRequest<any> {
    private readonly viewModel: WeeklyRotaSessionReportViewModel;
    public lookupStore = container.resolve(LookupStore);
    constructor(id: string, viewModel: WeeklyRotaSessionReportViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Student.StudentSessions.GetStudentSessionReport.replace(":id", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: WeeklyRotaSessionReportResponse): Promise<any> {
        runInAction(() => {
            this.viewModel.getModel.student.fromResponse(response.student);
            this.viewModel.model.mentor.replace(
                response.mentor.map((e) => {
                    const model = new Mentor();
                    model.fromResponse(e);
                    return model;
                }),
            );

            this.viewModel.model.wellbeing.replace(
                response.studentEngagements
                    .sort((a, b) => (a.ordinal > b.ordinal ? 1 : b.ordinal > a.ordinal ? -1 : 0))
                    .map((e) => {
                        const model = new StudentEngagementModel();
                        model.fromResponse(e);
                        return model;
                    }),
            );

            this.viewModel.model.studentTargets.replace(
                response.studentTargets
                    .sort((a, b) => (a.reference > b.reference ? 1 : b.reference > a.reference ? -1 : 0))
                    .map((e) => {
                        const model = new StudentTargets();
                        model.fromResponse(e);
                        return model;
                    }),
            );

            if (response.activityTypes) {
                this.lookupStore.activityTypes.replace(
                    response.activityTypes.map((model: ActivityType) => {
                        return { id: model.id, name: model.name, type: model.type, ordinal: model.ordinal, isDeleted: model.isDeleted } as ActivityType;
                    }),
                );
            }
        });
    }
}
