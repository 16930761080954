import React from "react";
import { Box } from "./General";

interface Props {
    children: any;
}

export const Centered = ({ children }: Props) => {
    return (
        <Box grid alignContent={"center"}>
            <>{children}</>
        </Box>
    );
};
