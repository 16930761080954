import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import moment from "moment";
import { startOfDay } from "date-fns";

export class WeeklyRotaModel extends ModelBase<WeeklyRotaModel> {
    public currentDate = moment();
    public startOfWeek = this.currentDate.startOf("isoWeek");
    public formattedStartOfWeek = this.startOfWeek.format("MM-DD-YYYY");
    public startDate: Date | string = startOfDay(new Date()).toISOString();
    public copyFromPreviousWeek: boolean = false;
    public selectedWeekStartDate?: Date;

    public mentorAvailability = [];

    constructor() {
        super();
        makeObservable(this, { startDate: observable });
    }
}

export class WeeklyRotaModelValidator extends Validator<WeeklyRotaModel> {
    constructor() {
        super();
    }
}
