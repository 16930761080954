import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { APIClient } from "Application";
import { ICommand, RelayCommand } from "Application/Commands";
import { StudentAbsenceModel, StudentAbsenceModelModelValidator } from "./StudentAbsenceModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";

export class StudentAbsenceViewModel extends ViewModelBase<StudentAbsenceModel> {
    public apiClient = new APIClient();
    public lookupStore = container.resolve(LookupStore);
    constructor(studentParentModel: StudentAbsenceModel | null) {
        super(studentParentModel ?? new StudentAbsenceModel());
        this.setValidator(new StudentAbsenceModelModelValidator());
        makeObservable(this, {});
    }

    public get KEY(): string {
        return this.model.KEY;
    }

    public get getReason() {
        return this.lookupStore.absenceTypeKeyValuePairs;
    }

    public updateAbsenseTypeIdCommand: ICommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("absenseTypeId", kvp.key);
    });

    public reUpdateAbsenseTypeIdCommand: ICommand = new RelayCommand((value: string | null) => {
        this.updateField("absenseTypeId", value);
    });

    public updateReasonCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("reason", value);
    });

    public updateIsAttendedCommand: ICommand = new RelayCommand((value: boolean) => {
        this.updateField("isAttended", value);
    });

    private async updateField(fieldName: keyof FieldType<StudentAbsenceModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
