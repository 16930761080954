import React from "react";
import { observer } from "mobx-react-lite";
import { Box, theme } from "Application";
import styled from "@emotion/styled";

const LoadingSpinner = styled.div`
    border-width: 12px;
    border-style: solid;
    border-color: ${theme.palette.primary.lighter};
    border-top-width: 12px;
    border-top-style: solid;
    border-top-color: ${theme.palette.primary.main};
    border-radius: 50%;
    width: 100px;
    height: 100px;

    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export const ApplicationLoadingSpinner: React.FC = observer(() => {
    return <LoadingSpinner />;
});
