import React, { useEffect } from "react";
import {
    Column,
    ColumnDef,
    ColumnFiltersState,
    ColumnOrderState,
    FilterFn,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Row,
    SortingFn,
    sortingFns,
    SortingState,
    Table,
    useReactTable,
} from "@tanstack/react-table";
import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import styled from "@emotion/styled";
import { Box } from "../../General";
import { theme } from "Styles";
import { FieldLabel } from "../CommonStyles";
import { SmallDownTriangle } from "Assets/Icons/SmallDownTriangle";
import { observer } from "mobx-react-lite";
import { ICommand } from "Application";
import { Logger } from "index";
import { SmallUpTriangle } from "Assets/Icons/SmallUpTriangle";
import { SmallArrow } from "Assets";
import SerachIcon from "Assets/Icons/SearchIcon.png";
export interface IMeta {
    className: string;
    headerClassName: string;
}

const DataTableWrapper = styled(Box)``;
const MainTable = styled(Box)`
    //border: 1px solid lightgray;
    width: 100%;
    td:last-child {
        //border-right: 0;
    }
`;
const TableGroupHead = styled(Box)`
    height: 40px;

    div:first-of-type {
        //display: none;
    }
    #actions {
        & > div > div {
            display: none;
        }
    }
`;
const TableBody = styled(Box)``;
const TableHeader = styled.div`
    background-color: ${theme.palette.tableHeader.light};
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px 10px;
`;
const TableHead = styled(Box)`
    border: 0;
    padding: 0;
`;
const SortingButtonsContainer = styled(Box)`
    cursor: pointer;
    margin-right: 10px;

    .sorting-arrow {
        &--descending {
            transform: rotate(180deg);
        }
    }
`;

const SortingArrow = styled.div`
    content: "";
    height: 10px;
    width: 10px;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 50%;
        width: 2px;
        height: 10px;
        background-color: ${theme.palette.common.black};
        transform: translateX(-50%);
    }

    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 50%;
        width: 0px;
        height: 0px;
        transform: translateX(-50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid ${theme.palette.common.black};
    }
`;

const TableRow = styled(Box)`
    gap: 1px !important;
    :nth-child(2) {
        background-color: #f9f9f9;
    }
`;

const TableFoot = styled(Box)`
    color: gray;
    th {
        font-weight: normal;
    }
`;
const TableCell = styled(Box)`
    border-right: 1px solid lightgray;
    padding: 15px 10px;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: -0.12px;
    color: #000000;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid lightgray;
    .close-btn {
        background: none;
        border: 0px;
        cursor: pointer;
    }
    &:nth-child(4) {
        justify-content: center;
    }
    &:last-child {
        border-right: none;
    }
`;

const CustomSearch = styled.div`
    max-width: 638px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    input {
        width: 100%;
        padding-left: 36px;
        height: 40px;
        border: 1px solid #d4d4d4;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.12px;
        color: #000;
        font-family: "OpenSans-Regular";
        ::placeholder {
            color: #8d8d8d;
        }
    }
    img {
        position: absolute;
        left: 10px;
        top: 12px;
        cursor: pointer;
    }
`;

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);
    // Store the itemRank info
    addMeta({
        itemRank,
    });
    // Return if the item should be filtered in/out
    return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
    let dir = 0;
    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems((rowA.columnFiltersMeta[columnId] as any)?.itemRank!, (rowB.columnFiltersMeta[columnId] as any)?.itemRank!);
    }
    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

interface DataTableProps {
    columns: ColumnDef<any, any>[];
    data: any[];
    //optional parameter to determine if the data passed is pre filtered or not
    //used to determine the behaviour of the useEffect hook responsible for updating the data
    isDataFiltered?: boolean;
    showPagination?: boolean;
    showFilters?: boolean;
    debug?: boolean;
    rowClickCommand?: ICommand;
    showSearchBox?: boolean;
    isBusy?: boolean;
    serachText?: string;
    isSearchIcon?: boolean;
}
export const DataTable: React.FC<DataTableProps> = observer((props) => {
    const [data, setData] = React.useState(() => props.data);
    const [columns] = React.useState(() => [...props.columns]);
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState({});
    const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = React.useState("");

    useEffect(() => {
        setData(props.data);
    }, [props.isDataFiltered, props.data]);

    let tableOptions = {
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnVisibility,
            columnOrder,
            sorting,
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: props.debug,
        debugHeaders: props.debug,
        debugColumns: props.debug,
    };
    if (props.showPagination) {
        tableOptions = {
            ...tableOptions,
            getPaginationRowModel: getPaginationRowModel(),
        } as any;
    }

    const table = useReactTable(tableOptions as any);

    const canShowSearchBar = props.showSearchBox ?? true;

    return (
        <DataTableWrapper>
            <div>{props.isBusy}</div>
            {/*// @ts-ignore*/}
            {/* <FieldLabel>{props.columns.length > 0 && props.columns[0].header}</FieldLabel> */}
            {canShowSearchBar && (
                <CustomSearch>
                    <DebouncedInput
                        isSearchIcon={props.isSearchIcon}
                        value={globalFilter ?? ""}
                        onChange={(value) => setGlobalFilter(String(value))}
                        placeholder={props.serachText !== undefined ? props.serachText : "Search all columns..."}
                    />
                </CustomSearch>
            )}
            <MainTable className="mainTable">
                <TableGroupHead>
                    {table
                        .getHeaderGroups()
                        .slice(1)
                        .map((headerGroup, index) => (
                            <TableRow id={"tablerow-" + index} grid dc={`repeat(${headerGroup.headers.length}, 1fr)`} columnGap={"1px"} key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHeader key={header.id} id={header.id}>
                                        {header.isPlaceholder ? null : (
                                            <>
                                                <Box
                                                    justifyContent={"center"}
                                                    flexBox
                                                    style={{ gap: 0 }}
                                                    {...{
                                                        className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {header.column.columnDef.enableSorting || header.column.columnDef.enableSorting === undefined ? (
                                                        <SortingButtonsContainer width="7px" height="7px" style={{ gap: 0 }}>
                                                            <Box grid dc={"1fr"} style={{ gap: "2px", gridTemplateRows: "5px 5px" }}>
                                                                {(header.column.getIsSorted() as any) === "asc" || (header.column.getIsSorted() as any) === false ? (
                                                                    <SortingArrow className="sorting-arrow--ascending" />
                                                                ) : (
                                                                    <div style={{ minHeight: "5px" }}></div>
                                                                )}
                                                                {(header.column.getIsSorted() as any) === "desc" || (header.column.getIsSorted() as any) === false ? (
                                                                    <SortingArrow className="sorting-arrow--descending" />
                                                                ) : (
                                                                    <div style={{ minHeight: "5px" }}></div>
                                                                )}
                                                            </Box>
                                                        </SortingButtonsContainer>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </Box>
                                                {header.column.getCanFilter() && props.showFilters ? (
                                                    <div>
                                                        <Filter column={header.column} table={table} />
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        ))}
                </TableGroupHead>

                <TableBody>
                    {table.getRowModel().rows.map((row: Row<any>) => {
                        return (
                            <TableRow grid dc={`repeat(${row.getVisibleCells().length}, 1fr)`} key={row.id} onClick={() => props.rowClickCommand?.execute(row)}>
                                {row.getVisibleCells().map((cell) => {
                                    const getClassName = (): string => {
                                        if (cell.column.columnDef.meta) {
                                            return (cell.column.columnDef.meta as IMeta)!.className;
                                        } else {
                                            return "";
                                        }
                                    };
                                    return (
                                        <TableCell key={cell.id} className={getClassName()}>
                                            <Box flexBox width={"100%"}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Box>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>

                <TableFoot>
                    {table.getFooterGroups().map((footerGroup) => (
                        <TableRow grid dc={`repeat(5 1fr)`} key={footerGroup.id}>
                            {footerGroup.headers.map((header) => (
                                <TableHead key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</TableHead>
                            ))}
                        </TableRow>
                    ))}
                </TableFoot>
            </MainTable>
            {props.showPagination && Pagination(table)}
            {props.debug && <pre>{JSON.stringify(table.getState(), null, 2)}</pre>}
        </DataTableWrapper>
    );
});

const Pagination = (table: any) => {
    return (
        <div className="flex items-center gap-2">
            <button className="border rounded p-1" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
                {"<<"}
            </button>
            <button className="border rounded p-1" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                {"<"}
            </button>
            <button className="border rounded p-1" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                {">"}
            </button>
            <button className="border rounded p-1" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
                {">>"}
            </button>
            <span className="flex items-center gap-1">
                <div>Page</div>
                <strong>
                    {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                </strong>
            </span>
            <span className="flex items-center gap-1">
                | Go to page:
                <input
                    type="number"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0;
                        table.setPageIndex(page);
                    }}
                    className="border p-1 rounded w-16"
                />
            </span>
            <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                }}
            >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </select>
        </div>
    );
};

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = React.useMemo(
        () => (typeof firstValue === "number" ? [] : Array.from(column.getFacetedUniqueValues().keys()).sort()),
        [column.getFacetedUniqueValues()],
    );

    return typeof firstValue === "number" ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                    value={(columnFilterValue as [number, number])?.[0] ?? ""}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ""}`}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
                    value={(columnFilterValue as [number, number])?.[1] ?? ""}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ""}`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : (
        <>
            <datalist id={column.id + "list"}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? "") as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
                className="w-36 border shadow rounded"
                list={column.id + "list"}
            />
            <div className="h-1" />
        </>
    );
}

function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    isSearchIcon,
    ...props
}: {
    value: string | number;
    onChange: (value: string | number) => void;
    debounce?: number;
    isSearchIcon?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
    const [value, setValue] = React.useState(initialValue);

    React.useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value);
        }, debounce);

        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <>
            <img src={SerachIcon} alt="not found " />
            <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
        </>
    );
}
