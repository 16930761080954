import { Endpoint, Http } from "Application";

class Request {
    public studentSessionId: Guid = "";
}

export class RemoveDetailsMentorWeeklyRotaEndPoint extends Endpoint<Request, Response> {
    private studentSessionId: Guid;

    constructor(studentSessionId: Guid) {
        super();
        this.studentSessionId = studentSessionId;
        this.verb(Http.Put);
        this.path("/api/student/editMentorStudentRota");
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request: Request = new Request();
        request.studentSessionId = this.studentSessionId;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        return Promise.resolve(responses);
    }
}
