import { Endpoint, Http } from "Application";
import { Logger } from "index";
import { ResetPasswordModel } from "../ResetPasswordModel";

class Request {
    public newPassword: string = "";
    public token: string = "";
}

class Response {}

export class POSTResetPasswordEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/Account/resetpassword/");
    }

    HandleRequestAsync(model: ResetPasswordModel): Promise<Request> {
        let request = new Request();
        request.token = model.token;
        request.newPassword = model.newPassword;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return {};
    }
}
