import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { ResourceDocumentResponse, ResourceDocumentsResponse } from "./Shared/Responses";
import { AppUrls } from "AppUrls";
import { ResourceDocumentsViewModel } from "../ResourceDocuments/ResourceDocumentsViewModel";
import { runInAction } from "mobx";
import { ResourceDocumentTableItemViewModel } from "../ResourceDocumentTableItem/ResourceDocumentTableItemViewModel";

export class DELETEResourceDocumentByIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: ResourceDocumentsViewModel;
    constructor(viewModel: ResourceDocumentsViewModel) {
        super();
        this.verb(Http.Delete);
        this.viewModel = viewModel;
        this.path(AppUrls.Server.Resources.PostDeleteResourceDocumentById.replace(":id", viewModel.resourceIdForDeletion!));
    }

    public async HandleResponseAsync(response: ResourceDocumentsResponse): Promise<any> {
        runInAction(() => {
            const viewModels: ResourceDocumentTableItemViewModel[] = [];

            response.resourceDocuments.forEach((resourceDocument: ResourceDocumentResponse) => {
                //create viewModel
                const viewModel = new ResourceDocumentTableItemViewModel(this.viewModel.selectResourceDocumentForDeletionCommand);
                viewModel.model.fromResponse(resourceDocument);
                //push viewModel to array
                viewModels.push(viewModel);
            });

            //set viewModels
            this.viewModel.resourceViewModels.replace(viewModels);
        });
    }
}
