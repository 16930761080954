import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Input } from "Components";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import { StudentAbsenceViewModel } from "./StudentAbsenceViewModel";
import styled from "@emotion/styled";

export interface IStudentAbsenceProps {
    viewModel: StudentAbsenceViewModel;
}

const FormField = styled(Box)`
    .placeholder {
        font: normal normal bold 14px/19px Open Sans;
        letter-spacing: 0px;
        font-family: OpenSans-CondBold;
    }
`;

export const StudentAbsenceView: React.FC<IStudentAbsenceProps> = observer((props) => {
    return (
        <Box>
            <FormField mt={"25px"} maxWidth={"310px"}>
                <ThemedEditSelect
                    displayName={"Reason why*"}
                    placeholder={"Type name of activity or add a new one"}
                    command={props.viewModel.updateAbsenseTypeIdCommand}
                    options={props.viewModel.getReason}
                    value={() => props.viewModel.getValue("absenseTypeId")}
                    validationMessage={() => props.viewModel.getError("absenseTypeId")}
                />
            </FormField>
            <FormField mt={"25px"}>
                <Input
                    multiline={true}
                    displayName="Please give more detail"
                    value={() => props.viewModel.getValue("reason")}
                    command={props.viewModel.updateReasonCommand}
                    placeholder={"Please add notes..."}
                    rows={5}
                    validationMessage={() => props.viewModel.getError("reason")}
                />
            </FormField>
        </Box>
    );
});
