import { EndpointWithoutRequest, Http } from "Application";
import { WeeklyRotaDetailsViewModel } from "../../WeeklyRotaDetailViewModel";
import { AppUrls } from "AppUrls";

export class ResetWeeklyRotaEndPoint extends EndpointWithoutRequest<Response> {
    constructor(startDate: string | Date, viewModel: WeeklyRotaDetailsViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.WeeklyRota.ResetRota.replace(":startdate", startDate.toString()));
    }
    public async HandleResponseAsync(response: any): Promise<any> {
        return Promise.resolve();
    }
}
