import React from "react";
import { AppRoutes } from "./AppRoutes";
import { GlobalHistory } from "index";
import { CustomThemeProvider } from "./Application";
import { CustomBrowserRouter } from "Components/CustomRouter";
import { GlobalStyles } from "./Styles/GlobalStyles";
import { Toaster } from "react-hot-toast";

export const App: React.FC = () => {
    return (
        <>
            <CustomThemeProvider>
                <GlobalStyles />
                <CustomBrowserRouter history={GlobalHistory}>
                    <AppRoutes />
                    <Toaster position="bottom-center" reverseOrder={false} />
                </CustomBrowserRouter>
            </CustomThemeProvider>
        </>
    );
};
