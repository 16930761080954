import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable, observe } from "mobx";

import { RelayCommand } from "Application";
import { DocumentFilesModel, DocumentFilesModelValidator } from "./DocumentFilesModel";
import { DocumentFileModel } from "./DocumentFileModel";
import { DocumentFileViewModel } from "./DocumentFileViewModel";

export class DocumentFilesViewModel extends ViewModelBase<DocumentFilesModel> {
    public fileViewModels = observable<DocumentFileViewModel>([]);

    constructor(fileModel: DocumentFilesModel = new DocumentFilesModel(), fileTypes = "", allowMultipleTYpes = true) {
        super(fileModel);

        this.setValidator(new DocumentFilesModelValidator());

        this.model.allowMultipleFiles = allowMultipleTYpes;
        this.model.fileTypes = fileTypes;

        makeObservable(this, {
            fileViewModels: observable,
        });
    }

    public dispose = (): void => {
        this.filesObserverDispose?.();
    };

    public get allowMultipleFiles() {
        return this.model.allowMultipleFiles;
    }

    public get fileTypes() {
        return this.model.fileTypes;
    }

    public get errorMessage() {
        if (!this.model.validMultipleFiles) {
            return `Only one file is allowed`;
        }

        if (!this.model.validFileTypes) {
            return `The type of one or more files is not allowed`;
        }

        if (!this.model.insideUploadLimit) {
            return `The total file size should be less than ${DocumentFilesModel.MAX_FILESIZE_MB}`;
        }
        return "";
    }

    public addCommand = new RelayCommand((files: FileList) => {
        if (files) {
            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const fileModel = new DocumentFileModel();

                fileModel.file = files.item(fileIndex);
                fileModel.fileName = files.item(fileIndex)!.name;
                fileModel.fileSizeBytes = files.item(fileIndex)!.size;
                fileModel.mimeType = files.item(fileIndex)!.type;
                fileModel.documentUrl = "";
                if (fileModel.fileSizeBytes > 0 && !isEmptyOrWhitespace(fileModel.mimeType)) {
                    this.model.files.push(fileModel);
                }
            }
        }
    });

    public removeCommand = new RelayCommand((file: DocumentFileModel) => {
        const fileToRemove = this.model.files.find((m) => m.KEY === file.KEY);
        if (fileToRemove) {
            this.model.files.remove(fileToRemove);
            if (!isEmptyOrWhitespace(fileToRemove.id)) {
                this.model.removedFiles.remove(fileToRemove);
            }
        }
    });

    private filesObserverDispose = observe(this.model.files, (fileChanges: any) => {
        for (const addedFile of fileChanges.added) {
            this.fileViewModels.push(new DocumentFileViewModel(addedFile, this.removeCommand));
        }

        for (const removedFile of fileChanges.removed) {
            const fileViewModelToRemove = this.fileViewModels.find((vm) => vm.model.KEY === removedFile.KEY);

            if (fileViewModelToRemove) {
                this.fileViewModels.remove(fileViewModelToRemove);
            }
        }
    });

    private async updateField(fieldName: keyof FieldType<DocumentFilesModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
