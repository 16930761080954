import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Box, Label, formatDate } from "Application";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { DataTable } from "Components/Primitives/DataTable";
import styled from "@emotion/styled";
import { DownloadIcon } from "Assets/Icons/DownloadIcon";
import { isNullOrUndefined } from "@shoothill/core";

interface IProps {
    viewModel: any;
    data?: any;
}

const WeeklyRotaReportTableData = styled.div`
    .mainTable {
        overflow: auto;
        & > div {
            &:first-child {
                background: #f3f3f3;
                margin-bottom: 15px;
                & > div {
                    grid-template-columns: 9fr 3fr 2fr 1fr !important;
                    & > div:not(:last-child) {
                        border-right: 1px solid #fff;
                    }
                    @media only screen and (max-width: 767px) {
                        grid-template-columns: 220px 120px 120px 65px !important;
                    }
                }
            }
            & > div {
                grid-template-columns: 9fr 3fr 2fr 1fr !important;
                @media only screen and (max-width: 767px) {
                    grid-template-columns: 220px 120px 120px 65px !important;
                }
                &:nth-child(even) {
                    background: #f9f9f9;
                }
                & > div {
                    width: 100%;
                    box-sizing: border-box;

                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        .justifyCenter {
            > div {
                justify-content: center;
            }
        }
        #lastAction {
            justify-content: flex-end;
        }
    }
`;

export const WeeklyRotaReportTableReadOnlyView: React.FC<IProps> = observer((prop: any) => {
    const defaultColumns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "fileName",
                        header: () => <Label fontStyle="h4">FILE NAME</Label>,
                        cell: (props: CellContext<any, any>) => (
                            <Box
                                flexBox
                                alignItems={"center"}
                                gap={"10px"}
                                style={{ cursor: "pointer" }}
                                onClick={() => prop?.viewModel?.downloadResourceDocumentCommand.execute(props.row.original.id!, props.row.original.fileName!)}
                            >
                                <DownloadIcon />
                                <Box>
                                    <Label>{props.row.original.fileName}</Label>
                                    <Label style={{ font: "normal normal normal 10px/16px Open Sans", letterSpacing: "-0.1px", color: "#D4D2D7" }}>
                                        {props.row.original.fileSize}kb
                                    </Label>
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        accessorKey: "documentCategoryName",
                        header: () => <Label fontStyle="h4">CATEGORY</Label>,
                        cell: (props: CellContext<any, any>) => (
                            <Box flexBox>
                                <Label>{props.row.original.documentCategoryName}</Label>
                            </Box>
                        ),
                    },
                    {
                        accessorKey: "lastAction",
                        header: () => <Label fontStyle="h4">ADDED BY</Label>,
                        cell: (props: CellContext<any, any>) => (
                            <Box flexBox flexDirection={"column"} width={"100%"} textAlign={"end"}>
                                <Label>{!isNullOrUndefined(props.row.original.createdDate) ? formatDate(props.row.original.createdDate!) : ""}</Label>
                                <Label>
                                    {props.row.original.createdByFirstName} {props.row.original.createdByLastName}
                                </Label>
                            </Box>
                        ),
                    },
                ],
            },
        ],
        [],
    );

    return (
        <WeeklyRotaReportTableData>
            <DataTable data={prop.data!} columns={defaultColumns} showFilters={false} showSearchBox={false} />
        </WeeklyRotaReportTableData>
    );
});
