import { Box, Button, CheckBox, EditDate, Input, Label, NullCommand, setPageTitle, theme } from "Application";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { BackSVG } from "Assets/Back";
import { useParams } from "react-router";
import styled from "@emotion/styled";
import { isEmptyOrWhitespace } from "@shoothill/core";
import moment from "moment";
import { WeeklyRotaSessionReportViewModel } from "./WeeklyRotaSessionReportViewModel";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import { StudentAbsenceView } from "Views/Students/Forms/StudentReports/StudentAbsence/StudentAbsenceView";
import { PageContainer } from "Views/Mentors/Form/MentorDetail/StyleComponents";
import { WeeklyRotaReportTableView } from "./WeeklyRotaReportTableView";
import WeeklyRotaUploadSessionPhotoModal from "../Modal/WeeklyRotaUploadSessionPhoto";
import { TickSVG } from "Assets/Icons/TickSVG";
import { WeeklyRotaReportTableReadOnlyView } from "./WeeklyRotaReportTableReadOnlyView";
import { ToolTip } from "Views/Students/Forms/StudentDetail/StyleComponents";
import { ExclamationIconSVG } from "Assets/Icons/ExclamationIconSVG";
import { WellbeingSVG } from "Assets/WellbeingSVG";
import ErrorMessage from "../ErrorMessage";

interface Props {
    color?: string;
}

const AttendanceField = styled(Box)`
    .form-control {
        input[type="checkbox"]:before {
            box-shadow: inset 1em 1em #e6054e;
        }
    }
`;

const TargetList = styled(Box)`
    & > div:nth-child(odd) {
        background: #fff;
    }
`;

const FormField = styled(Box)`
    .placeholder {
        font: italic normal normal 12px/16px Open Sans;
        letter-spacing: 0px;
        color: #000000;
    }
`;

const NavigationBox = styled(Box)`
    align-items: center;
    background-color: #f3f3f3;
    cursor: pointer;
    display: flex;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    cursor: pointer;
    gap: 10px;
    label {
        text-decoration: underline;
        font: normal normal normal 12px/16px Open Sans;
        letter-spacing: -0.12px;
        color: #000000;
    }
`;

const CreateBox = styled(Box)`
    & > div .isButtonDisable {
        cursor: default;
        color: #495057;
        background-color: #e9ecef;
    }
`;

const PageWrapper = styled(Box)`
    padding: 30px;
    @media only screen and (max-width: 1200px) {
        padding: 30px 20px;
    }
`;

const ListBlock = styled(Box)`
    grid-template-columns: 1.5fr 1.5fr 2fr 2fr 1.5fr;
    gap: 30px;
    @media only screen and (max-width: 1400px) {
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

const DetailsList = styled(Box)`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

const BreadCrumbContainer = styled.div`
    position: absolute;
    top: 50px;
    left: 20px;
`;

const WeeklyRotaFilter = styled(Box)`
    grid-template-columns: 1fr 1fr 1fr 2fr 3fr 1fr;
    @media only screen and (max-width: 1440px) {
        grid-template-columns: 1fr 1fr 1fr 1.7fr 2.6fr;
    }
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        & > div:last-child {
            flex-wrap: wrap;
        }
    }
`;

const TableList = styled(Box)``;

const TableBox = styled(Box)<Props>`
    display: grid;
    grid-template-columns: 280px auto;
    @media only screen and (max-width: 767px) {
        grid-template-columns: 60px auto;
    }
    &:nth-child(2n) {
        background: #f9f9f9;
    }
    & > div:first-child {
        height: 100%;
        display: flex;
        & > label {
            padding: 0 15px;
            display: grid;
            grid-template-columns: 45px auto;
            gap: 0;
        }
    }
    input[type="checkbox"] {
        height: 30px;
        width: 30px;
        &::before {
            height: 16px;
            width: 18px;
            box-shadow: inset 1em 1em #bd9e17;
        }
    }
    label {
        font: normal normal bold 12px/16px Open Sans;
        letter-spacing: -0.12px;
    }
    .check-label {
        display: flex;
        align-items: center;
        border-left: 1px solid #dcdcdc;
        height: 100%;
        padding-left: 15px;
        @media only screen and (max-width: 767px) {
            display: none !important;
        }
        label {
            color: ${(p) => p.color};
        }
    }
    & > div:last-child {
        padding: 20px 10px;
        display: flex;
        justify-content: center;
        border-left: 1px solid #dcdcdc;
        flex-direction: column;
        gap: 2px;
        label {
            font: normal normal bold 12px/16px Open Sans;
            letter-spacing: -0.12px;
            &:first-child {
                display: none;
                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }
        }
        & > label:last-child {
            font: normal normal normal 12px/16px Open Sans;
            color: #000000;
        }
    }
`;

const TargetCheckbox = styled(Box)`
    input[type="checkbox"] {
        height: 30px;
        width: 30px;
        &::before {
            height: 16px;
            width: 18px;
            box-shadow: inset 1em 1em #bd9e17;
        }
    }
`;

export const WeeklyRotaSessionReportView: React.FC = observer(() => {
    const { id, mentorId, reportId, studentId } = useParams();
    const [viewModel] = useState(() => new WeeklyRotaSessionReportViewModel(id, mentorId, reportId, studentId));

    useEffect(() => {
        setPageTitle("Weekly Rota");
    }, []);

    const sessionStatus = viewModel.getSessionStatusById(viewModel.model.sessionStatusId);

    const SelectBox = styled(Box)`
        max-width: 130px;
        .editselect__control {
            background: ${viewModel.displayStatus};
        }
        .editselect__placeholder,
        .editselect__single-value {
            color: #fff;
            font: normal normal bold 12px/18px Open Sans;
            letter-spacing: 0px;
        }
    `;

    const ToolTipBox = styled(Box)`
        & > label > div > div {
            top: auto;
            bottom: calc(100% + 10px);
            font-size: 12px;
        }
    `;

    const ListBox = styled(Box)``;
    const ListItem = styled(Box)`
        display: grid;
        grid-template-columns: 60px auto;
        background: #f9f9f9;
        & > div:last-child {
            display: grid;
            grid-template-columns: 220px auto;
            @media only screen and (max-width: 767px) {
                grid-template-columns: 1fr;
                gap: 2px;
                padding: 20px 15px;
                border-left: 1px solid #dcdcdc;
            }
        }
        label {
            font: normal normal normal 12px/16px Open Sans;
            letter-spacing: -0.12px;
            color: #000000;
            padding: 20px 15px;
            border-left: 1px solid #dcdcdc;
            @media only screen and (max-width: 767px) {
                border-left: none;
                padding: 0;
            }
        }
    `;

    const renderSessionFileContentModel = () => {
        return <Box>{<WeeklyRotaUploadSessionPhotoModal viewModel={viewModel} onCancel={() => viewModel.showSessionDocPhotoCommand.execute()} />}</Box>;
    };

    const convertTimeToDate = (value: any) => {
        const updatedValue = value ? value : moment(new Date().setHours(12, 0)).format("HH:mm");
        const initialMoment = moment(updatedValue, "HH:mm:ss");
        const initialDate = new Date();
        initialDate.setHours(initialMoment.hours());
        initialDate.setMinutes(initialMoment.minutes());
        return initialDate;
    };

    const renderWellbeingError = () => {
        if (!isEmptyOrWhitespace(viewModel.getError("wellbeingId"))) {
            return (
                <ToolTipBox>
                    <Label style={{ color: "rgb(230, 5, 78)", fontWeight: 600, display: "flex", alignItems: "center" }}>
                        Wellbeing *
                        <ToolTip>
                            <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />
                            <div> {viewModel.getError("wellbeingId")}</div>
                        </ToolTip>
                    </Label>
                </ToolTipBox>
            );
        }
    };

    const renderInvolvementError = () => {
        if (!isEmptyOrWhitespace(viewModel.getError("involvementId"))) {
            return (
                <ToolTipBox>
                    <Label style={{ color: "rgb(230, 5, 78)", fontWeight: 600, display: "flex", alignItems: "center" }}>
                        Involvement*
                        <ToolTip>
                            <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />
                            <div> {viewModel.getError("involvementId")}</div>
                        </ToolTip>
                    </Label>
                </ToolTipBox>
            );
        }
    };

    const renderForm = () => {
        return (
            <Box p={"25px 30px 10rem 30px"}>
                <WeeklyRotaFilter pb={"30px"} mb={"30px"} borderBottom="1px dashed #DCDCDC" style={{ gap: "30px" }} display={"grid"}>
                    <EditDate
                        displayName={"Date of session*"}
                        maxDate={new Date()}
                        value={() => viewModel.getValue("sessionDate")}
                        command={viewModel.updateSessionDateCommand}
                        isWeekDisable={true}
                        includeTime={false}
                    />
                    <EditDate
                        displayName={"Time of session*"}
                        value={() => convertTimeToDate(viewModel.getValue("sessionStartTime"))}
                        command={viewModel.updateSessionStartTimeCommand}
                        timePicker={true}
                        dateFormat={"HH:mm"}
                        validationMessage={() => viewModel.getError("sessionStartTime")}
                    />
                    <EditDate
                        displayName={"End Time of session*"}
                        value={() => convertTimeToDate(viewModel.getValue("sessionEndTime"))}
                        command={viewModel.updateSessionEndTimeCommand}
                        timePicker={true}
                        dateFormat={"HH:mm"}
                        validationMessage={() => viewModel.getError("sessionEndTime")}
                    />
                    {viewModel.renderForm && (
                        <ThemedEditSelect
                            displayName={"Activity*"}
                            placeholder={"Type name of activity or add a new one"}
                            command={viewModel.updateActivityTypeCommand}
                            options={viewModel.getActivity}
                            value={() => viewModel.getValue("activityTypeId")}
                            validationMessage={() => viewModel.getError("activityTypeId")}
                            isCreatable={true}
                        />
                    )}
                    <AttendanceField display={"flex"} alignContent={"flex-end"} style={{ gap: "30px" }}>
                        <SelectBox>
                            <ThemedEditSelect
                                displayName={"Attendance*"}
                                placeholder={""}
                                command={viewModel.updateSessionStatusCommand}
                                options={viewModel.renderGetAttendance}
                                value={() => viewModel.getValue("attendance")}
                                validationMessage={() => viewModel.getError("attendance")}
                            />
                        </SelectBox>
                        {viewModel.renderForm && (
                            <CheckBox
                                command={viewModel.updateReportIncidentCommand}
                                value={() => viewModel.getValue("reportIncident")}
                                displayName="Report incident"
                                validationMessage={() => viewModel.getError("reportIncident")}
                            />
                        )}
                    </AttendanceField>
                </WeeklyRotaFilter>
                <Box bg={"#F9F9F9"} p={"25px 20px"} boxShadow="0px 3px 3px #00000029">
                    <DetailsList mb={"22px"} display={"grid"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}
                        >
                            Student name:
                            <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px" }}>
                                {!isEmptyOrWhitespace(viewModel.model.student.firstName) ? viewModel.model.student.firstName : ""}
                                {!isEmptyOrWhitespace(viewModel.model.student.lastName) ? viewModel.model.student.lastName : ""}
                            </Label>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}
                        >
                            <Box>Mentor name:</Box>
                            {viewModel.getsessionMentorName}
                            {`${viewModel.model.sessionMentor.firstName} ${viewModel.model.sessionMentor.lastName}`}
                        </Box>
                    </DetailsList>
                    <Box mb={"22px"}>
                        <Label style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0.7px", color: "#000000", fontFamily: "OpenSans-CondBold" }}>
                            Overall aim from referrer:
                        </Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px", color: "#000000" }}>
                            {!isEmptyOrWhitespace(viewModel.model.student.overallAim) ? viewModel.model.student.overallAim : "-"}
                        </Label>
                    </Box>
                    <Label style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0.7px", color: "#000000", fontFamily: "OpenSans-CondBold" }}>
                        Targets:
                    </Label>
                    {viewModel.model.studentTargets.map((item) => {
                        return (
                            <TargetList mt={"15px"}>
                                <Box display={"grid"} gridTemplateColumns={"50px auto"}>
                                    <Label
                                        style={{
                                            textAlign: "center",
                                            padding: "12px 10px",
                                            font: "normal normal bold 10px/16px Open Sans",
                                            letterSpacing: "-0.1px",
                                            color: "#000000",
                                        }}
                                    >
                                        {item.reference}
                                    </Label>
                                    <Box
                                        borderLeft="1px solid #DCDCDC"
                                        padding="12px 10px"
                                        position={"relative"}
                                        pr={"0px"}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}
                                    >
                                        <Label style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>
                                            {item.description.length > 100 && !item.showText ? (
                                                <div dangerouslySetInnerHTML={{ __html: `${item.description.substring(0, 300)}..` }} />
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                            )}
                                        </Label>
                                        <TargetCheckbox>
                                            <CheckBox
                                                command={new NullCommand()}
                                                onChange={() => viewModel.checkSelectStudentTargetCommand.execute(item.id!)}
                                                displayName={""}
                                                value={() => viewModel.model.studentTargetIds.includes(item.id!)}
                                            />
                                        </TargetCheckbox>
                                    </Box>
                                </Box>
                            </TargetList>
                        );
                    })}
                </Box>

                {viewModel.renderForm && (
                    <>
                        <Box my={"26px"}>
                            {viewModel.getError("wellbeingId") ? (
                                renderWellbeingError()
                            ) : (
                                <Label style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0", color: "#000000" }}>Wellbeing*</Label>
                            )}
                            <Label style={{ font: "italic normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>
                                Wellbeing focuses on the extent of which young people feel at ease, act spontaneously, show vitality, and self-confidence. It is a crucial component
                                of emotional intelligence and good mental health.
                            </Label>
                        </Box>
                        <TableList>
                            {viewModel.model.wellbeing.map((item, index) => {
                                return (
                                    <TableBox color={item.displayColor}>
                                        <CheckBox
                                            key={index}
                                            command={new NullCommand()}
                                            onChange={() => viewModel.updateWellbeingCommand.execute(item.id)}
                                            displayName={item.severityLevel}
                                            value={() => viewModel.model.wellbeingId === item.id}
                                        />
                                        <Box>
                                            <Label style={{ color: `${item.displayColor}` }}>{item.severityLevel}</Label>
                                            <Label>{item.wellBeingDescription}</Label>
                                        </Box>
                                    </TableBox>
                                );
                            })}
                        </TableList>

                        <Box my={"26px"}>
                            {viewModel.getError("involvementId") ? (
                                renderInvolvementError()
                            ) : (
                                <Label style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0", color: "#000000" }}>Involvement*</Label>
                            )}

                            <Label style={{ font: "italic normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>
                                Involvement focuses on the extent to which pupils are operating to their full capabilities. In particular it refers to whether the young person is
                                focused, engaged and interest in various activities.
                            </Label>
                        </Box>
                        <TableList>
                            {viewModel.model.wellbeing.map((item, index) => {
                                return (
                                    <TableBox color={item.displayColor}>
                                        <CheckBox
                                            key={index}
                                            command={new NullCommand()}
                                            onChange={() => viewModel.updateInvolvementCommand.execute(item.id)}
                                            displayName={item.severityLevel}
                                            value={() => viewModel.model.involvementId === item.id}
                                        />
                                        <Box>
                                            <Label style={{ color: `${item.displayColor}` }}>{item.severityLevel}</Label>
                                            <Label>{item.involvementDescription}</Label>
                                        </Box>
                                    </TableBox>
                                );
                            })}
                        </TableList>
                    </>
                )}

                {viewModel.renderForm && (
                    <Box>
                        <Box borderBottom="1px dashed #DCDCDC" pb={"30px"} mb={"30px"}>
                            <FormField mt={"25px"}>
                                <Label style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0px", fontFamily: "OpenSans-CondBold" }}>Plan for session*</Label>
                                <Input
                                    multiline={true}
                                    displayName={
                                        "(Why have you chosen this activity/session plan to work towards the chosen targets. AQA – can an AQA be achieved during this session plan?)"
                                    }
                                    value={() => viewModel.getValue("sessionPlan")}
                                    command={viewModel.updateSessionPlanCommand}
                                    placeholder={"Please add notes..."}
                                    rows={5}
                                    validationMessage={() => viewModel.getError("sessionPlan")}
                                />
                            </FormField>
                            <FormField mt={"25px"}>
                                <Label style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0px", fontFamily: "OpenSans-CondBold" }}>Session feedback*</Label>
                                <Input
                                    multiline={true}
                                    displayName="(Did anything stand out, positive or negative? Concerning comments? Concerning behaviours? Any noted achievements?)"
                                    value={() => viewModel.getValue("subTargets")}
                                    command={viewModel.updateSubTargetsCommand}
                                    placeholder={"Please add notes..."}
                                    rows={5}
                                    validationMessage={() => viewModel.getError("subTargets")}
                                />
                            </FormField>
                            <FormField mt={"25px"}>
                                <Label style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0px", fontFamily: "OpenSans-CondBold" }}>Considerations*</Label>
                                <Input
                                    multiline={true}
                                    displayName="(Actions for next session? Risk assessment/ behaviour plan need updating? Any minor concerns risen from the session?)"
                                    value={() => viewModel.getValue("mentoring")}
                                    command={viewModel.updateMentoringCommand}
                                    placeholder={"Please add notes..."}
                                    rows={5}
                                    validationMessage={() => viewModel.getError("mentoring")}
                                />
                            </FormField>
                        </Box>
                        <Box style={{ gap: "15px" }} pb={"15px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"}>
                            <Label style={{ font: "normal normal bold 18px/24px Open Sans", letterSpacing: "0px", color: "#000000" }}>Session doc(s)/photo(s)</Label>
                            {viewModel.mentorWithoutMatchingEmail && (
                                <Button
                                    command={viewModel.showSessionDocPhotoCommand}
                                    displayName={"UPLOAD SESSION DOC/PHOTO"}
                                    paletteColor="primary"
                                    style={{ minWidth: "300px", borderRadius: "5px" }}
                                />
                            )}
                        </Box>
                        {viewModel.model.sessionDocument.length === 0 && (
                            <Box borderTop="1px solid #DCDCDC" mb={"40px"} pt={"28px"}>
                                <Label style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px" }}>
                                    There are currently no doc(s)/photo(s) added to this session to{" "}
                                    <Label style={{ textDecoration: "underline", color: "#2D91FF", display: "inline-block" }}>add a doc or photo</Label> please use the button
                                    above.
                                </Label>
                            </Box>
                        )}
                    </Box>
                )}

                {!viewModel.renderForm && <StudentAbsenceView viewModel={viewModel.studentAbsenceViewModel} />}

                {viewModel.renderForm && viewModel.model.sessionDocument.length > 0 && (
                    <WeeklyRotaReportTableView key={viewModel.model.sessionDocument.length} viewModel={viewModel} data={viewModel.model.sessionDocument} />
                )}

                <CreateBox>
                    <Box
                        mt={"45px"}
                        style={{ gap: "30px" }}
                        borderTop="1px solid #DCDCDC"
                        pt={"30px"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexWrap={"wrap"}
                    >
                        <Button
                            command={viewModel.saveStudentSessionCommand}
                            displayName={viewModel.displayName}
                            className={viewModel.apiClient.IsBusy ? "isButtonDisable" : "add-another"}
                            paletteColor="primary"
                            style={{ padding: "11px 20px", borderRadius: "5px", minWidth: "140px" }}
                        />

                        <Button
                            command={viewModel.navigateToBackCommand}
                            displayName={"CANCEL"}
                            paletteColor="secondary"
                            className={viewModel.apiClient.IsBusy ? "isButtonDisable" : "add-another"}
                            style={{ padding: "11px 20px", borderRadius: "5px", minWidth: "140px" }}
                        />
                    </Box>
                </CreateBox>
            </Box>
        );
    };

    const renderAttendReadOnlyForm = () => {
        return (
            <PageWrapper style={{ paddingBottom: "10rem" }}>
                <ListBlock pb={"30px"} mb={"30px"} borderBottom="1px dashed #DCDCDC" display={"grid"}>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Date of session:</Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", color: "#000000", fontWeight: 400 }}>
                            {moment(viewModel.model.sessionDate).format("DD/MM/yyyy")}
                        </Label>
                    </Box>

                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Time of session:</Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", color: "#000000" }}>
                            {moment(viewModel.model.sessionStartTime, "HH:mm:ss").format("HH:mm")} - {moment(viewModel.model.sessionEndTime, "HH:mm:ss").format("HH:mm")}
                        </Label>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Activity:</Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", color: "#000000" }}>
                            {viewModel.getSessionActivityType(viewModel.model.activityTypeId as string)}
                        </Label>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Attendance:</Label>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", color: "#27E6A3" }}>{viewModel.model.attendance ? "Attended" : ""}</Label>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {viewModel.model.reportIncident && <TickSVG />}
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Report incident</Label>
                    </Box>
                </ListBlock>
                <Box bg={"#F9F9F9"} p={"25px 20px"} boxShadow="0px 3px 3px #00000029">
                    <DetailsList mb={"22px"} display={"grid"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}
                        >
                            Student name:
                            <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px" }}>
                                {!isEmptyOrWhitespace(viewModel.model.student.firstName) ? viewModel.model.student.firstName : ""}
                                {!isEmptyOrWhitespace(viewModel.model.student.lastName) ? viewModel.model.student.lastName : ""}
                            </Label>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}
                        >
                            <Box>Mentor name:</Box>
                            {viewModel.getsessionMentorName}
                            {`${viewModel.model.sessionMentor.firstName} ${viewModel.model.sessionMentor.lastName}`}
                        </Box>
                    </DetailsList>
                    <Box mb={"22px"}>
                        <Label style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0.7px", color: "#000000", fontFamily: "OpenSans-CondBold" }}>
                            Overall aim from referrer:
                        </Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px", color: "#000000" }}>
                            {!isEmptyOrWhitespace(viewModel.model.student.overallAim) ? viewModel.model.student.overallAim : "-"}
                        </Label>
                    </Box>
                    <Label style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0.7px", color: "#000000", fontFamily: "OpenSans-CondBold" }}>
                        Targets:
                    </Label>
                    {viewModel.model.studentTargets.map((item) => {
                        return (
                            <TargetList mt={"15px"}>
                                <Box display={"grid"} gridTemplateColumns={"50px auto"}>
                                    <Label
                                        style={{
                                            textAlign: "center",
                                            padding: "12px 10px",
                                            font: "normal normal bold 10px/16px Open Sans",
                                            letterSpacing: "-0.1px",
                                            color: "#000000",
                                        }}
                                    >
                                        {item.reference}
                                    </Label>
                                    <Box
                                        borderLeft="1px solid #DCDCDC"
                                        padding="12px 10px"
                                        position={"relative"}
                                        pr={"0px"}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}
                                    >
                                        <Label style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>
                                            {item.description.length > 100 && !item.showText ? (
                                                <div dangerouslySetInnerHTML={{ __html: `${item.description.substring(0, 300)}..` }} />
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                            )}
                                        </Label>
                                        <TargetCheckbox>
                                            <CheckBox command={new NullCommand()} displayName={""} value={() => viewModel.model.studentTargetIds.includes(item.id!)} />
                                        </TargetCheckbox>
                                    </Box>
                                </Box>
                            </TargetList>
                        );
                    })}
                </Box>

                {viewModel.renderForm && (
                    <Box>
                        <Box my={"26px"}>
                            <Label style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0", color: "#000000" }}>Wellbeing*</Label>
                            <Label style={{ font: "italic normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>
                                Wellbeing focuses on the extent of which young people feel at ease, act spontaneously, show vitality, and self-confidence. It is a crucial component
                                of emotional intelligence and good mental health.
                            </Label>
                        </Box>
                        <ListBox>
                            {viewModel.displayWellbeing?.wellBeingDescription && (
                                <ListItem>
                                    <Box p={"15px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                        <WellbeingSVG />
                                    </Box>
                                    <Box>
                                        <Label style={{ font: "normal normal bold 12px/16px Open Sans", color: `${viewModel.displayWellbeing?.displayColor}` }}>
                                            {viewModel.displayWellbeing?.severityLevel}
                                        </Label>
                                        <Label>{viewModel.displayWellbeing?.wellBeingDescription}</Label>
                                    </Box>
                                </ListItem>
                            )}
                        </ListBox>
                        <Box my={"26px"}>
                            <Label style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0", color: "#000000" }}>Involvement*</Label>
                            <Label style={{ font: "italic normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>
                                Involvement focuses on the extent to which pupils are operating to their full capabilities. In particular it refers to whether the young person is
                                focused, engaged and interest in various activities.
                            </Label>
                        </Box>
                        <ListBox>
                            {viewModel.displayInvolvement?.involvementDescription && (
                                <ListItem>
                                    <Box p={"15px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                        <WellbeingSVG />
                                    </Box>
                                    <Box>
                                        <Label style={{ font: "normal normal bold 12px/16px Open Sans", color: `${viewModel.displayInvolvement?.displayColor}` }}>
                                            {viewModel.displayInvolvement?.severityLevel}
                                        </Label>
                                        <Label>{viewModel.displayInvolvement?.involvementDescription}</Label>
                                    </Box>
                                </ListItem>
                            )}
                        </ListBox>
                    </Box>
                )}

                {viewModel.renderForm && (
                    <Box>
                        <Box borderBottom="1px dashed #DCDCDC" pb={"30px"} mb={"30px"}>
                            <FormField mt={"25px"}>
                                <Label mb={"1px"} style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0px", fontFamily: "OpenSans-CondBold" }}>
                                    Plan for session
                                </Label>
                                <Label mb={"12px"} style={{ font: "italic normal normal 12px/16px Open Sans", letterSpacing: "0px" }}>
                                    (Why have you chosen this activity/session plan to work towards the chosen targets. AQA – can an AQA be achieved during this session plan?)
                                </Label>
                                <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px", color: "#000000" }}>{viewModel.model.sessionPlan}</Label>
                            </FormField>
                            <FormField mt={"25px"}>
                                <Label mb={"1px"} style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0px", fontFamily: "OpenSans-CondBold" }}>
                                    Session feedback:
                                </Label>
                                <Label mb={"12px"} style={{ font: "italic normal normal 12px/16px Open Sans", letterSpacing: "0px" }}>
                                    (Did anything stand out, positive or negative? Concerning comments? Concerning behaviours? Any noted achievements?)
                                </Label>
                                <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px", color: "#000000" }}>{viewModel.model.subTargets}</Label>
                            </FormField>
                            <FormField mt={"25px"}>
                                <Label mb={"1px"} style={{ font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0px", fontFamily: "OpenSans-CondBold" }}>
                                    Considerations:
                                </Label>
                                <Label mb={"12px"} style={{ font: "italic normal normal 12px/16px Open Sans", letterSpacing: "0px" }}>
                                    (Actions for next session? Risk assessment/ behaviour plan need updating? Any minor concerns risen from the session?)
                                </Label>
                                <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px", color: "#000000" }}>{viewModel.model.mentoring}</Label>
                            </FormField>
                        </Box>
                        <Box style={{ gap: "15px" }} pb={"15px"} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Label style={{ font: "normal normal bold 18px/24px Open Sans", letterSpacing: "0px", color: "#000000" }}>Session doc(s)/photo(s)</Label>
                        </Box>
                    </Box>
                )}
                {viewModel.renderForm && viewModel.model.sessionDocument.length > 0 && (
                    <WeeklyRotaReportTableReadOnlyView key={viewModel.model.sessionDocument.length} viewModel={viewModel} data={viewModel.model.sessionDocument} />
                )}
            </PageWrapper>
        );
    };

    const renderAbsenceReadOnlyForm = () => {
        return (
            <PageWrapper style={{ paddingBottom: "10rem" }}>
                <ListBlock pb={"30px"} mb={"30px"} borderBottom="1px dashed #DCDCDC" display={"grid"}>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Date of session:</Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", color: "#000000", fontWeight: 400 }}>
                            {moment(viewModel.model.sessionDate).format("DD/MM/yyyy")}
                        </Label>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Time of session:</Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", color: "#000000", fontWeight: 400 }}>
                            {moment(viewModel.model.sessionStartTime, "HH:mm:ss").format("HH:mm")} - {moment(viewModel.model.sessionEndTime, "HH:mm:ss").format("HH:mm")}
                        </Label>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Attendance: </Label>
                        {viewModel.model.attendance === "1" ? (
                            <Label>Attended</Label>
                        ) : (
                            <Label style={{ font: "normal normal bold 12px/17px OpenSans-CondBold", letterSpacing: "0.7px", color: "#E6054E" }}>Absent</Label>
                        )}
                    </Box>
                </ListBlock>
                <Box bg={"#F9F9F9"} p={"25px 20px"} boxShadow="0px 3px 3px #00000029" mb="27px">
                    <DetailsList mb={"22px"} display={"grid"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}
                        >
                            Student name:
                            <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px" }}>
                                {!isEmptyOrWhitespace(viewModel.model.student.firstName) ? viewModel.model.student.firstName : ""}
                                {!isEmptyOrWhitespace(viewModel.model.student.lastName) ? viewModel.model.student.lastName : ""}
                            </Label>
                        </Box>

                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}
                        >
                            Mentor name:
                            <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px" }}>
                                {viewModel.getsessionMentorName}
                                {`${viewModel.model.sessionMentor.firstName} ${viewModel.model.sessionMentor.lastName}`}
                            </Label>
                        </Box>
                    </DetailsList>
                    <Box mb={"22px"}>
                        <Label style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0.7px", color: "#000000", fontFamily: "OpenSans-CondBold" }}>
                            Overall aim from referrer:
                        </Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px", color: "#000000" }}>
                            {!isEmptyOrWhitespace(viewModel.model.student.overallAim) ? viewModel.model.student.overallAim : "-"}
                        </Label>
                    </Box>
                    <Label style={{ gap: "10px", font: "normal normal bold 14px/19px Open Sans", letterSpacing: "0.7px", color: "#000000", fontFamily: "OpenSans-CondBold" }}>
                        Targets:
                    </Label>
                    {viewModel.model.studentTargets.map((item) => {
                        return (
                            <TargetList mt={"15px"}>
                                <Box display={"grid"} gridTemplateColumns={"50px auto"}>
                                    <Label
                                        style={{
                                            textAlign: "center",
                                            padding: "12px 10px",
                                            font: "normal normal bold 10px/16px Open Sans",
                                            letterSpacing: "-0.1px",
                                            color: "#000000",
                                        }}
                                    >
                                        {item.reference}
                                    </Label>
                                    <Box
                                        borderLeft="1px solid #DCDCDC"
                                        padding="12px 10px"
                                        position={"relative"}
                                        pr={"0px"}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}
                                    >
                                        <Label style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>
                                            {item.description.length > 100 && !item.showText ? (
                                                <div dangerouslySetInnerHTML={{ __html: `${item.description.substring(0, 300)}..` }} />
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                            )}
                                        </Label>
                                        <TargetCheckbox>
                                            <CheckBox command={new NullCommand()} displayName={""} value={() => viewModel.model.studentTargetIds.includes(item.id!)} />
                                        </TargetCheckbox>
                                    </Box>
                                </Box>
                            </TargetList>
                        );
                    })}
                </Box>
                <Box mb="26px" display={"flex"} alignItems={"center"} style={{ gap: "12px" }}>
                    <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000" }}>Reason why: </Label>
                    <Label style={{ font: "normal normal normal 12px/17px Open Sans", color: "#000000", fontWeight: 400 }}>
                        {viewModel.getReasonType(viewModel.studentAbsenceViewModel.model.absenseTypeId as string)}
                    </Label>
                </Box>
                {viewModel.studentAbsenceViewModel.model.reason && (
                    <Box>
                        <Label style={{ font: "normal normal bold 14px/19px OpenSans-CondBold", letterSpacing: "0.7px", color: "#000000", marginBottom: "10px" }}>
                            Please give more details:
                        </Label>
                        <Label style={{ font: "normal normal normal 12px/17px Open Sans", color: "#000000", fontWeight: 400 }}>
                            {viewModel.studentAbsenceViewModel.model.reason ? viewModel.studentAbsenceViewModel.model.reason : "-"}
                        </Label>
                    </Box>
                )}
            </PageWrapper>
        );
    };

    const renderErrorModel = () => {
        return (
            <Box>
                <ErrorMessage validationMessage={viewModel.apiClient.ValidationMessage} viewModel={viewModel} onCancel={() => viewModel.showErrorsCommand.execute()} />
            </Box>
        );
    };

    return (
        <>
            <BreadCrumbContainer>
                <Label fontStyle="h1" color="black">
                    Session report
                </Label>
            </BreadCrumbContainer>
            <PageContainer>
                {/* Navigation */}
                <Box marginTop="100px" borderTop="1px solid #D4D4D4" justifyContent={"space-between"}>
                    <NavigationBox>
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "10px" }} onClick={() => viewModel.navigateToBackCommand.execute()}>
                            <BackSVG style={{ cursor: "pointer" }} />
                            <Label style={{ cursor: "pointer" }}>Back to weekly rota</Label>
                        </Box>
                        <Box marginLeft={"auto"}>
                            {sessionStatus === "Submitted" && (
                                <Button
                                    command={viewModel.showEditFormCommand}
                                    displayName={"Edit"}
                                    paletteColor="primary"
                                    style={{ padding: "8px 16px", borderRadius: "5px", minWidth: "140px" }}
                                />
                            )}
                        </Box>
                        <Box
                            style={{ font: "normal normal bold 12px/18px Open Sans", letterSpacing: "0px", textTransform: "uppercase" }}
                            background={sessionStatus === "Submitted" ? "#11A696" : "#C15DB0"}
                            textAlign={"center"}
                            color={"#fff"}
                            height={"30px"}
                            minWidth={"140px"}
                            p={"6px 25px"}
                        >
                            {sessionStatus === "Submitted" ? "Submitted" : "Pending"}
                        </Box>
                    </NavigationBox>
                    {sessionStatus === "Submitted" && !viewModel.model.studentAbsence.id && !viewModel.isEditForm
                        ? renderAttendReadOnlyForm()
                        : sessionStatus === "Submitted" && viewModel.model.studentAbsence && !viewModel.isEditForm
                        ? renderAbsenceReadOnlyForm()
                        : renderForm()}
                    {viewModel.showSessionDocPhoto && renderSessionFileContentModel()}
                    {viewModel.showError && renderErrorModel()}
                </Box>
            </PageContainer>
        </>
    );
});

export default WeeklyRotaSessionReportView;
