//dependencies
import React, { useState } from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

//components
import { Box, Button, CheckBox, EditDate, Label, formatDate, theme } from "Application";
import { MentorWeeklyRotaViewModel } from "./MentorWeeklyRotaViewModel";
import MentorStudentAbsenceModal from "./Modal/MentorStudentAbsenceModal";
import SortIcon from "../../../../Assets/Icons/sort.svg";
import { uniqueId } from "lodash-es";
import { AccountStore } from "Stores/Domain";
import { container } from "tsyringe";
import moment from "moment";
import ClipBoardIcon from "../../../../Assets/Icons/ClipBoardIcon.jpg";
import MentorAbsenceCros from "../../../../Assets/Icons/MentorAbsenceCros.svg";
interface mentorAbsenceType {
    absenseEndDate: Date;
    absenseStartDate: Date;
    mentorId: Guid;
    rotaId: Guid;
    id: Guid;
}
interface mentorType {
    mentorId: string;
    firstName: string;
    lastName: string;
    mentorAvailabilityId: Guid;
    monAM: boolean;
    monPM: boolean;
    tuesAM: boolean;
    tuesPM: boolean;
    wedsAM: boolean;
    wedsPM: boolean;
    thurAM: boolean;
    thurPM: boolean;
    friAM: boolean;
    friPM: boolean;
    monAMOverride: boolean;
    monPMOverride: boolean;
    tuesAMOverride: boolean;
    tuesPMOverride: boolean;
    wedsAMOverride: boolean;
    wedsPMOverride: boolean;
    thurAMOverride: boolean;
    thurPMOverride: boolean;
    friAMOverride: boolean;
    friPMOverride: boolean;
    mentorStudentRota: [
        {
            id: Guid;
            studentStatusId: Guid;
            firstName: string;
            lastName: string;
            mentorStudentRotaId: Guid;
            mentorId: Guid;
            pickUpAddress: string;
            dropOffAddress: string;
            didAttend: boolean | null;
            sessionDate: string;
            tuesAMStudentSessionId: Guid;
            studentSessionId: string | null;
            sessionStatusId: string;
            reportIncident: boolean;
            studentSessionAbsenceId: Guid;
            sessionStartTime: string;
            sessionEndTime: string;
        },
    ];
    mentorRotaAbsenses: [mentorAbsenceType];
}

const AMDays = ["monAM", "tuesAM", "wedsAM", "thurAM", "friAM"];
const PMDays = ["monPM", "tuesPM", "wedsPM", "thurPM", "friPM"];

const ButtonBox = styled.button`
    font: normal normal bold 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 8px 20px;
    border: none;
    cursor: pointer !important;
    &.redBtn {
        color: #fff !important;
        background: #dc3545 !important;
    }
`;

const FilterRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 20px 30px;
    @media only screen and (max-width: 1240px) {
        flex-wrap: wrap;
    }
`;

const FilterLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media only screen and (max-width: 1240px) {
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 22px;
    }
    label {
    }
    .placeholder {
        display: none;
    }
    .inputbox {
        border-color: #e6e9ed;
        min-width: 250px;
        height: 40px;
        position: relative;
        .calender-icon {
            position: absolute;
            top: 11px;
            right: 11px;
            z-index: 1;
        }
    }
    button {
        padding: 10px 15px;
        font-size: 14px;
        color: #000;
        min-width: 140px;
    }
`;

const FilterRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    label {
        margin-right: 0px;
    }
    .placeholder {
        display: none;
    }
    .editselect {
        font-size: 12px;
        color: #000;
        .editselect__control {
            border-color: #e6e9ed;
            min-width: 250px;
            height: 40px;
            &.editselect__control--is-focused {
                border-color: #c5c7c9 !important;
            }
            .editselect__placeholder {
                font-size: 12px;
                color: #000;
            }
            :hover {
                border-color: #c5c7c9 !important;
            }
            .editselect__indicators {
                .editselect__indicator-separator {
                    display: none;
                }
                .editselect__indicator {
                    position: relative;
                    svg {
                        display: none;
                    }
                    :after {
                        content: "";
                        position: absolute;
                        top: 7px;
                        right: 11px;
                        border-top: 4px solid #000;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                    }
                }
            }
        }
    }
`;

const CustomTable = styled.div`
    overflow: auto;
    table {
        padding: 0px 20px;
        width: 100%;
        font-family: OpenSans-CondBold;
        th {
            background-color: #f3f3f3;
            font-size: 12px;
            padding: 12px;
        }
        td {
            padding: 15px;
        }
    }
`;

const StatusCol = styled.div`
    margin-bottom: 20px;
    label {
        letter-spacing: -0.12px;
        text-decoration: underline;
        margin-bottom: 5px;
        &.no-underline {
            text-decoration: none;
        }
        &.status {
            font-family: OpenSans-CondBold;
            &.green {
                color: #11a696;
            }
            &.pink {
                color: #c15db0;
            }
            &.yellow {
                color: #f39200;
            }
        }
        &.badge {
            letter-spacing: -0.1px;
            &.red-badge {
                color: #dc3545;
            }
        }
    }
`;

const StatusRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 22px;

    @media only screen and (max-width: 1240px) {
        display: block;
    }
    label {
        letter-spacing: -0.12px;
        text-decoration: underline;
        margin-bottom: 5px;
        &.no-underline {
            text-decoration: none;
        }
        &.status {
            font-family: OpenSans-CondBold;
            &.green {
                color: #11a696;
            }
            &.pink {
                color: #c15db0;
            }
            &.yellow {
                color: #f39200;
            }
        }
        &.badge {
            letter-spacing: -0.1px;
            &.red-badge {
                color: #dc3545;
            }
        }
    }
    .status-track {
        text-align: right;
    }
`;

const SingalButtonRow = styled.div`
    margin: 0px;
    button {
        background: #fcd21d 0% 0% no-repeat padding-box;
        border-radius: 5px;
        text-align: center;
        font: normal normal bold 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
        padding: 10px 16px;
        width: 100%;
        border: none;
        box-sizing: border-box;
        margin: 0 !important;
        cursor: pointer !important;
        &.disabled {
            cursor: auto !important;
        }
    }
`;

const ContentContainer = styled.div`
    background-color: ${theme.palette.common.white};
    width: 100%;
    height: calc(100% - 100px);
    margin-top: 100px;
    border-top: 1px solid ${theme.palette.quarternary.main};
`;

const BreadCrumbContainer = styled.div`
    position: absolute;
    top: 50px;
    left: 20px;
`;

const ActionBtn = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    height: 20px;
    width: 20px;
    background: #fcd21d;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.sortBtn {
        background: transparent;
        border-top: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
        border-radius: 0;
    }
    &.crossBtn {
        background: transparent;
        border-top: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
        border-radius: 0;
        background: red;
    }
`;

const AddressToggle = styled.div`
    position: relative;

    & > div {
        position: absolute;
        right: 0;
        top: 130%;
        height: 0;
        max-width: 165px;
        width: 165px;
        overflow: hidden;
        z-index: 11;
        background: #fff;
        border-radius: 5px;
        @media only screen and (max-width: 1240px) {
            right: unset;
            left: 0;
        }
        &:before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: #fff;
            transform: rotate(45deg);
            right: 15px;
            top: -4px;
            @media only screen and (max-width: 1240px) {
                left: 15px;
                right: unset;
            }
        }
        label {
            padding: 11px;
            display: flex;
            align-items: flex-start;
            gap: 15px;
            margin: 0;
            text-decoration: none;
            span {
                font-size: 12px;
                line-height: 16px;
                color: #171716;
            }
        }
    }
    &:hover {
        & > div {
            height: auto;
            overflow: visible;
        }
    }
`;

export const MentorWeeklyRotaView: React.FC = observer(() => {
    const [viewModel] = useState(() => new MentorWeeklyRotaViewModel());
    const accountStore = container.resolve(AccountStore);
    //this is for testing only
    const mentorWithMatchingEmail = viewModel.model.mentorsWeeklyRota.filter((mentor: any) => mentor.email === accountStore.DisplayName);
    const mentorWithoutMatchingEmail = viewModel.model.mentorsWeeklyRota.filter((mentor: any) => mentor.email !== accountStore.DisplayName);

    const renderStudentAbsenceModal = () => {
        return (
            <>
                <MentorStudentAbsenceModal
                    key={0}
                    viewModel={viewModel}
                    onCancel={() => viewModel.showStudentAbsenceModalCommand.execute()}
                    onAdd={() => viewModel.addStudentAbsenceModalCommand.execute()}
                ></MentorStudentAbsenceModal>
            </>
        );
    };

    const renderCopyView = ({ type, text }: { type: string; text: string }) => {
        const addressArray = text.split("/");
        const cleanedAddressArray = addressArray.map((part) => part.trim()).filter((part) => part !== "");
        const cleanedAddressString = cleanedAddressArray.join(",");
        const addressLines = cleanedAddressString.split(",");

        return (
            <>
                <AddressToggle>
                    <Label>{type}</Label>
                    <label className="arrow"></label>
                    <Box onClick={() => viewModel.copyAddressToClipboardCommand.execute(cleanedAddressString)}>
                        <Label>
                            <img style={{ cursor: "copy" }} src={ClipBoardIcon} alt="Not found"></img>
                            <div>
                                {addressLines.map((line, index) => (
                                    <div key={index}>{line}</div>
                                ))}
                            </div>
                        </Label>
                    </Box>
                </AddressToggle>
            </>
        );
    };

    const renderCustomTable = () => {
        return (
            <>
                <CustomTable style={{ paddingBottom: "10rem" }}>
                    <table color="black">
                        <thead>
                            <tr>
                                <th colSpan={2}></th>
                                <th>MONDAY</th>
                                <th>TUESDAY</th>
                                <th>WEDNESDAY</th>
                                <th>THURSDAY</th>
                                <th>FRIDAY</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mentorWithMatchingEmail.map((mentor: mentorType) => {
                                return (
                                    <>
                                        <tr>
                                            <td rowSpan={2} style={{ backgroundColor: "#F3F3F3", width: "50px", position: "relative", minWidth: "50px" }}>
                                                <Box
                                                    style={{
                                                        transform: "rotate(-90deg)",
                                                        position: "absolute",
                                                        left: "-48px",
                                                        top: "50%",
                                                        width: "150px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {mentor.firstName + " " + mentor.lastName}
                                                </Box>
                                            </td>
                                            <td style={{ backgroundColor: "#F3F3F3", width: "40 px", boxSizing: "border-box", textAlign: "center", padding: "12px" }}>AM</td>
                                            {AMDays.map((day) => {
                                                if (mentor[day]) {
                                                    const student = mentor.mentorStudentRota.find((student) => student[`${day}StudentSessionId`]);
                                                    if (student) {
                                                        const sessionStatus = viewModel.getSessionStatusById(student!.sessionStatusId);

                                                        return (
                                                            <td
                                                                style={{
                                                                    backgroundColor:
                                                                        sessionStatus === "Submitted" ? "#C3E9E5" : sessionStatus === "Pending" ? "#F0D6EB" : "#FCE4BF",
                                                                    width: "249px",
                                                                    minWidth: "220px",
                                                                    position: "relative",
                                                                }}
                                                                key={uniqueId()}
                                                            >
                                                                <Box
                                                                    display={"flex"}
                                                                    flexDirection={"column"}
                                                                    justifyContent={"space-between"}
                                                                    style={{ height: "100%", minHeight: "250px", justifyContent: "space-between" }}
                                                                >
                                                                    <Box>
                                                                        <StatusCol>
                                                                            <StatusRow style={{ paddingRight: "10px" }}>
                                                                                <Box>
                                                                                    <Label
                                                                                        className="status no-underline"
                                                                                        fontStyle="h5"
                                                                                        style={{
                                                                                            color: student.didAttend ? "#27E6A3" : "#E6054E",
                                                                                            font: "normal normal bold 12px/16px Open Sans",
                                                                                        }}
                                                                                    >
                                                                                        {student.didAttend === null ? "" : student.didAttend === true ? "Attended" : "Absent"}
                                                                                    </Label>
                                                                                    <Label
                                                                                        className="badge red-badge no-underline"
                                                                                        fontStyle="h6"
                                                                                        style={{
                                                                                            color: "#E6054E",

                                                                                            font: "italic normal normal 10px/16px Open Sans",
                                                                                        }}
                                                                                    >
                                                                                        {student.reportIncident ? "(INCIDENT REPORTED)" : ""}
                                                                                    </Label>
                                                                                </Box>
                                                                                <Label
                                                                                    className="status green no-underline"
                                                                                    fontStyle="h5"
                                                                                    style={{
                                                                                        font: "normal normal bold 12px/16px Open Sans",
                                                                                        color:
                                                                                            sessionStatus === "Submitted"
                                                                                                ? "#11A696"
                                                                                                : sessionStatus === "Pending"
                                                                                                ? "#C15DB0"
                                                                                                : "#F39200",
                                                                                    }}
                                                                                >
                                                                                    {sessionStatus.toUpperCase()}
                                                                                </Label>
                                                                                {student.didAttend != null && (
                                                                                    <Box
                                                                                        bg={"red"}
                                                                                        position={"absolute"}
                                                                                        right={0}
                                                                                        top={0}
                                                                                        height={"20px"}
                                                                                        width={"20px"}
                                                                                        display={"flex"}
                                                                                        alignItems={"center"}
                                                                                        justifyContent={"center"}
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() => viewModel.removeDetails(student.studentSessionId)}
                                                                                    >
                                                                                        <img height={10} src={MentorAbsenceCros} alt="not found" />
                                                                                    </Box>
                                                                                )}
                                                                            </StatusRow>
                                                                            <Box
                                                                                onClick={() => viewModel.navigateToStudentDetailsCommand.execute(student[`${day}StudentSessionId`])}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <Label
                                                                                    style={{ cursor: "pointer" }}
                                                                                    fontStyle="h5"
                                                                                >{`${student.firstName} ${student.lastName}`}</Label>
                                                                                <Label style={{ textDecoration: "none" }}>{`${formatDate(student.sessionDate)}`}</Label>
                                                                            </Box>
                                                                        </StatusCol>

                                                                        <StatusRow style={{ flexDirection: "column" }}>
                                                                            <StatusRow style={{ justifyContent: "space-between", width: "100%", marginBottom: "5px" }}>
                                                                                <Box>
                                                                                    {student.pickUpAddress &&
                                                                                        renderCopyView({ type: "Pickup location:", text: student.pickUpAddress })}
                                                                                </Box>
                                                                                <Box>
                                                                                    {student.dropOffAddress &&
                                                                                        renderCopyView({
                                                                                            type: "Drop off location:",
                                                                                            text: student.dropOffAddress,
                                                                                        })}
                                                                                </Box>
                                                                            </StatusRow>
                                                                            <Box>
                                                                                <Label style={{ textDecoration: "none" }}>{`(${moment(student.sessionStartTime, "HH:mm:ss").format(
                                                                                    "HH:mm a",
                                                                                )} - ${moment(student.sessionEndTime, "HH:mm:ss").format("HH:mm a")})`}</Label>
                                                                            </Box>
                                                                        </StatusRow>
                                                                    </Box>
                                                                    {student.didAttend ? (
                                                                        <>
                                                                            {viewModel.getSessionStatusById(student.sessionStatusId!) === "Submitted" ? (
                                                                                <>
                                                                                    <SingalButtonRow>
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(
                                                                                                    student!.studentSessionId!,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            VIEW SESSION REPORT
                                                                                        </button>
                                                                                    </SingalButtonRow>
                                                                                </>
                                                                            ) : (
                                                                                <SingalButtonRow>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            viewModel.navigateToWeeklyRotaReportsCommand.execute(
                                                                                                student[`${day}StudentSessionId`],
                                                                                                student!.mentorId,
                                                                                                student.studentSessionId!,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        ADD SESSION REPORT
                                                                                    </button>
                                                                                </SingalButtonRow>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {student.studentSessionAbsenceId &&
                                                                            viewModel.getSessionStatusById(student.sessionStatusId!) === "Submitted" ? (
                                                                                <SingalButtonRow>
                                                                                    <ButtonBox
                                                                                        className="redBtn"
                                                                                        onClick={() => {
                                                                                            viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(student!.studentSessionId!);
                                                                                        }}
                                                                                    >
                                                                                        VIEW REASON WHY
                                                                                    </ButtonBox>
                                                                                </SingalButtonRow>
                                                                            ) : (
                                                                                <Box display={"grid"} gap={"15px"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "15px" }}>
                                                                                    <ButtonBox
                                                                                        style={{ background: "#27E6A3" }}
                                                                                        onClick={() => viewModel.updateStudentAttendedCommand.execute(student)}
                                                                                    >
                                                                                        ATTENDED
                                                                                    </ButtonBox>
                                                                                    <ButtonBox
                                                                                        onClick={() =>
                                                                                            viewModel.showStudentAbsenceModalCommand.execute(
                                                                                                student,
                                                                                                student[`${day}StudentSessionId`],
                                                                                            )
                                                                                        }
                                                                                        style={{ background: "#E6054E" }}
                                                                                    >
                                                                                        ABSENT
                                                                                    </ButtonBox>
                                                                                </Box>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            </td>
                                                        );
                                                    }
                                                    return <td style={{ backgroundColor: "#f9f9f9", width: "249px", height: "200px", minWidth: "220px" }} key={uniqueId()} />;
                                                }
                                                return (
                                                    <td
                                                        style={{ backgroundColor: "#FFFBE8", width: "249px", height: "200px", minWidth: "220px", position: "relative" }}
                                                        key={uniqueId()}
                                                    >
                                                        <ActionBtn className="sortBtn">
                                                            <img height={10} src={SortIcon} alt="not found" />
                                                        </ActionBtn>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "#F3F3F3", width: "40px", boxSizing: "border-box", textAlign: "center", padding: "12px" }}>PM</td>
                                            {PMDays.map((day) => {
                                                if (mentor[day]) {
                                                    const student = mentor.mentorStudentRota.find((student) => student[`${day}StudentSessionId`]);
                                                    if (student) {
                                                        const sessionStatus = viewModel.getSessionStatusById(student!.sessionStatusId);
                                                        return (
                                                            <td
                                                                style={{
                                                                    backgroundColor:
                                                                        sessionStatus === "Submitted" ? "#C3E9E5" : sessionStatus === "Pending" ? "#F0D6EB" : "#FCE4BF",
                                                                    width: "249px",
                                                                    minWidth: "220px",
                                                                    position: "relative",
                                                                }}
                                                                key={uniqueId()}
                                                            >
                                                                <Box
                                                                    display={"flex"}
                                                                    flexDirection={"column"}
                                                                    justifyContent={"space-between"}
                                                                    style={{ height: "100%", minHeight: "250px", justifyContent: "space-between" }}
                                                                >
                                                                    <Box>
                                                                        <StatusCol>
                                                                            <StatusRow style={{ paddingRight: "10px" }}>
                                                                                <Box>
                                                                                    <Label
                                                                                        className="status no-underline"
                                                                                        fontStyle="h5"
                                                                                        style={{
                                                                                            color: student.didAttend ? "#27E6A3" : "#E6054E",
                                                                                            font: "normal normal bold 12px/16px Open Sans",
                                                                                        }}
                                                                                    >
                                                                                        {student.didAttend === null ? "" : student.didAttend === true ? "Attended" : "Absent"}
                                                                                    </Label>
                                                                                    <Label
                                                                                        className="badge red-badge no-underline"
                                                                                        fontStyle="h6"
                                                                                        style={{
                                                                                            fontStyle: "italic",
                                                                                            color: "#E6054E",
                                                                                            font: "italic normal normal 10px/16px Open Sans",
                                                                                        }}
                                                                                    >
                                                                                        {student.reportIncident ? "(INCIDENT REPORTED)" : ""}
                                                                                    </Label>
                                                                                </Box>
                                                                                <Label
                                                                                    className="status green no-underline"
                                                                                    fontStyle="h5"
                                                                                    style={{
                                                                                        font: "normal normal bold 12px/16px Open Sans",
                                                                                        color:
                                                                                            sessionStatus === "Submitted"
                                                                                                ? "#11A696"
                                                                                                : sessionStatus === "Pending"
                                                                                                ? "#C15DB0"
                                                                                                : "#F39200",
                                                                                    }}
                                                                                >
                                                                                    {sessionStatus.toUpperCase()}
                                                                                </Label>
                                                                                {student.didAttend != null && (
                                                                                    <Box
                                                                                        bg={"red"}
                                                                                        position={"absolute"}
                                                                                        right={0}
                                                                                        top={0}
                                                                                        height={"20px"}
                                                                                        width={"20px"}
                                                                                        display={"flex"}
                                                                                        alignItems={"center"}
                                                                                        style={{ cursor: "pointer" }}
                                                                                        justifyContent={"center"}
                                                                                        onClick={() => viewModel.removeDetails(student.studentSessionId)}
                                                                                    >
                                                                                        <img height={10} src={MentorAbsenceCros} alt="not found" />
                                                                                    </Box>
                                                                                )}
                                                                            </StatusRow>
                                                                            <Box
                                                                                onClick={() => viewModel.navigateToStudentDetailsCommand.execute(student[`${day}StudentSessionId`])}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <Label
                                                                                    style={{ cursor: "pointer" }}
                                                                                    fontStyle="h5"
                                                                                >{`${student.firstName} ${student.lastName}`}</Label>
                                                                                <Label style={{ textDecoration: "none" }}>{`${formatDate(student.sessionDate)}`}</Label>
                                                                            </Box>
                                                                        </StatusCol>
                                                                        <StatusRow style={{ display: "flex", flexDirection: "column" }}>
                                                                            <StatusRow style={{ justifyContent: "space-between", width: "100%", marginBottom: "5px" }}>
                                                                                <Box>
                                                                                    {student.pickUpAddress &&
                                                                                        renderCopyView({ type: "Pickup location:", text: student.pickUpAddress })}
                                                                                </Box>
                                                                                <Box>
                                                                                    {student.dropOffAddress &&
                                                                                        renderCopyView({
                                                                                            type: "Drop off location:",
                                                                                            text: student.dropOffAddress,
                                                                                        })}
                                                                                </Box>
                                                                            </StatusRow>
                                                                            <Box>
                                                                                <Label style={{ textDecoration: "none" }}>{`(${moment(student.sessionStartTime, "HH:mm:ss").format(
                                                                                    "HH:mm a",
                                                                                )} - ${moment(student.sessionEndTime, "HH:mm:ss").format("HH:mm a")})`}</Label>
                                                                            </Box>
                                                                        </StatusRow>
                                                                    </Box>
                                                                    {student.didAttend ? (
                                                                        <>
                                                                            {viewModel.getSessionStatusById(student!.sessionStatusId) === "Submitted" ? (
                                                                                <>
                                                                                    {student.studentSessionAbsenceId ? (
                                                                                        <SingalButtonRow>
                                                                                            <button
                                                                                                className="redBtn"
                                                                                                onClick={() => {
                                                                                                    viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(
                                                                                                        student!.studentSessionId!,
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                VIEW REASON WHY
                                                                                            </button>
                                                                                        </SingalButtonRow>
                                                                                    ) : (
                                                                                        <SingalButtonRow>
                                                                                            <button
                                                                                                onClick={() => {
                                                                                                    viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(
                                                                                                        student.studentSessionId!,
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                VIEW SESSION REPORT
                                                                                            </button>
                                                                                        </SingalButtonRow>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <SingalButtonRow>
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            viewModel.navigateToWeeklyRotaReportsCommand.execute(
                                                                                                student[`${day}StudentSessionId`],
                                                                                                student!.mentorId,
                                                                                                student.studentSessionId!,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        ADD SESSION REPORT
                                                                                    </button>
                                                                                </SingalButtonRow>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {student.studentSessionAbsenceId &&
                                                                            viewModel.getSessionStatusById(student.sessionStatusId!) === "Submitted" ? (
                                                                                <SingalButtonRow>
                                                                                    <ButtonBox
                                                                                        className="redBtn"
                                                                                        onClick={() => {
                                                                                            viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(student!.studentSessionId!);
                                                                                        }}
                                                                                    >
                                                                                        VIEW REASON WHY
                                                                                    </ButtonBox>
                                                                                </SingalButtonRow>
                                                                            ) : (
                                                                                <Box display={"grid"} gap={"15px"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "15px" }}>
                                                                                    <ButtonBox
                                                                                        style={{ background: "#27E6A3" }}
                                                                                        onClick={() => viewModel.updateStudentAttendedCommand.execute(student)}
                                                                                    >
                                                                                        ATTENDED
                                                                                    </ButtonBox>
                                                                                    <ButtonBox
                                                                                        onClick={() =>
                                                                                            viewModel.showStudentAbsenceModalCommand.execute(
                                                                                                student,
                                                                                                student[`${day}StudentSessionId`],
                                                                                            )
                                                                                        }
                                                                                        style={{ background: "#E6054E" }}
                                                                                    >
                                                                                        ABSENT
                                                                                    </ButtonBox>
                                                                                </Box>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            </td>
                                                        );
                                                    } else {
                                                        return <td style={{ backgroundColor: "#f9f9f9", width: "249px", height: "200px", minWidth: "220px" }} key={uniqueId()} />;
                                                    }
                                                }
                                                return (
                                                    <td
                                                        style={{ backgroundColor: "#FFFBE8", width: "249px", minWidth: "220px", height: "200px", position: "relative" }}
                                                        key={uniqueId()}
                                                    >
                                                        <ActionBtn className="sortBtn">
                                                            <img height={10} src={SortIcon} alt="not found" />
                                                        </ActionBtn>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </>
                                );
                            })}

                            {viewModel.getValue("showSelectMentor") &&
                                mentorWithoutMatchingEmail.map((mentor: mentorType) => {
                                    return (
                                        <>
                                            <tr>
                                                <td rowSpan={2} style={{ backgroundColor: "#F3F3F3", width: "40px", position: "relative" }}>
                                                    <Box
                                                        style={{
                                                            transform: "rotate(-90deg)",
                                                            position: "absolute",
                                                            left: "-48px",
                                                            top: "50%",
                                                            width: "150px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {mentor.firstName + " " + mentor.lastName}
                                                    </Box>
                                                </td>
                                                <td style={{ backgroundColor: "#F3F3F3", width: "40 px", boxSizing: "border-box", textAlign: "center", padding: "12px" }}>AM</td>
                                                {AMDays.map((day) => {
                                                    if (mentor[day]) {
                                                        const student = mentor.mentorStudentRota.find((student) => student[`${day}StudentSessionId`]);
                                                        if (student) {
                                                            const sessionStatus = viewModel.getSessionStatusById(student!.sessionStatusId);
                                                            return (
                                                                <td
                                                                    style={{
                                                                        backgroundColor:
                                                                            sessionStatus === "Submitted" ? "#C3E9E5" : sessionStatus === "Pending" ? "#F0D6EB" : "#FCE4BF",
                                                                        width: "249px",
                                                                        minWidth: "220px",
                                                                    }}
                                                                    key={uniqueId()}
                                                                >
                                                                    <Box
                                                                        display={"flex"}
                                                                        flexDirection={"column"}
                                                                        justifyContent={"space-between"}
                                                                        style={{ height: "100%", minHeight: "250px", justifyContent: "space-between" }}
                                                                    >
                                                                        <Box>
                                                                            <StatusRow>
                                                                                <Box>
                                                                                    <Label
                                                                                        className="status no-underline"
                                                                                        fontStyle="h5"
                                                                                        style={{
                                                                                            color: student.didAttend ? "#27E6A3" : "#E6054E",
                                                                                            font: "normal normal bold 12px/16px Open Sans",
                                                                                        }}
                                                                                    >
                                                                                        {student.didAttend === null ? "" : student.didAttend === true ? "Attended" : "Absent"}
                                                                                    </Label>
                                                                                    <Label
                                                                                        className="badge red-badge no-underline"
                                                                                        fontStyle="h6"
                                                                                        style={{
                                                                                            fontStyle: "italic",
                                                                                            color: "#E6054E",
                                                                                            font: "italic normal normal 10px/16px Open Sans",
                                                                                        }}
                                                                                    >
                                                                                        {student.reportIncident ? "(INCIDENT REPORTED)" : ""}
                                                                                    </Label>
                                                                                </Box>

                                                                                <Box>
                                                                                    <Label
                                                                                        className="status green no-underline"
                                                                                        fontStyle="h5"
                                                                                        style={{
                                                                                            font: "normal normal bold 12px/16px Open Sans",
                                                                                            color:
                                                                                                sessionStatus === "Submitted"
                                                                                                    ? "#11A696"
                                                                                                    : sessionStatus === "Pending"
                                                                                                    ? "#C15DB0"
                                                                                                    : "#F39200",
                                                                                        }}
                                                                                    >
                                                                                        {sessionStatus.toUpperCase()}
                                                                                    </Label>
                                                                                </Box>
                                                                            </StatusRow>
                                                                            <Box
                                                                                onClick={() => viewModel.navigateToStudentDetailsCommand.execute(student[`${day}StudentSessionId`])}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <Label
                                                                                    style={{ cursor: "pointer", marginBottom: "20px", textDecoration: "underline" }}
                                                                                    fontStyle="h5"
                                                                                >{`${student.firstName} ${student.lastName}`}</Label>
                                                                                <Label style={{ textDecoration: "none" }}>{`${formatDate(student.sessionDate)}`}</Label>
                                                                            </Box>
                                                                            <StatusRow style={{ display: "flex", flexDirection: "column" }}>
                                                                                <StatusRow style={{ justifyContent: "space-between", width: "100%", marginBottom: "5px" }}>
                                                                                    <Box>
                                                                                        {student.pickUpAddress &&
                                                                                            renderCopyView({ type: "Pickup location:", text: student.pickUpAddress })}
                                                                                    </Box>
                                                                                    <Box>
                                                                                        {student.dropOffAddress &&
                                                                                            renderCopyView({
                                                                                                type: "Drop off location:",
                                                                                                text: student.dropOffAddress,
                                                                                            })}
                                                                                    </Box>
                                                                                </StatusRow>
                                                                                <Box>
                                                                                    <Label style={{ textDecoration: "none" }}>{`(${moment(
                                                                                        student.sessionStartTime,
                                                                                        "HH:mm:ss",
                                                                                    ).format("HH:mm a")} - ${moment(student.sessionEndTime, "HH:mm:ss").format(
                                                                                        "HH:mm a",
                                                                                    )})`}</Label>
                                                                                </Box>
                                                                            </StatusRow>
                                                                        </Box>
                                                                        {sessionStatus === "Pending" ? (
                                                                            <>
                                                                                {student.didAttend === null && sessionStatus === "Pending" && (
                                                                                    <Box display={"grid"} gap={"15px"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "15px" }}>
                                                                                        <ButtonBox style={{ backgroundColor: "#e9ecef" }} className="disabled">
                                                                                            ATTENDED
                                                                                        </ButtonBox>
                                                                                        <ButtonBox style={{ backgroundColor: "#e9ecef" }} className="disabled">
                                                                                            ABSENT
                                                                                        </ButtonBox>
                                                                                    </Box>
                                                                                )}
                                                                                {student.didAttend === true && sessionStatus === "Pending" && (
                                                                                    <SingalButtonRow>
                                                                                        <button style={{ backgroundColor: "#e9ecef" }} className="disabled">
                                                                                            ADD SESSION REPORT
                                                                                        </button>
                                                                                    </SingalButtonRow>
                                                                                )}
                                                                            </>
                                                                        ) : !student.studentSessionAbsenceId ? (
                                                                            <SingalButtonRow>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(student!.studentSessionId);
                                                                                    }}
                                                                                >
                                                                                    VIEW SESSION REPORT
                                                                                </button>
                                                                            </SingalButtonRow>
                                                                        ) : (
                                                                            <SingalButtonRow>
                                                                                <ButtonBox
                                                                                    className="redBtn"
                                                                                    onClick={() => {
                                                                                        viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(student!.studentSessionId!);
                                                                                    }}
                                                                                >
                                                                                    VIEW REASON WHY
                                                                                </ButtonBox>
                                                                            </SingalButtonRow>
                                                                        )}
                                                                    </Box>
                                                                </td>
                                                            );
                                                        }
                                                        return <td style={{ backgroundColor: "#f9f9f9", width: "249px", height: "200px", minWidth: "220px" }} key={uniqueId()} />;
                                                    }
                                                    return (
                                                        <td
                                                            style={{ backgroundColor: "#FFFBE8", width: "249px", height: "200px", minWidth: "220px", position: "relative" }}
                                                            key={uniqueId()}
                                                        >
                                                            <ActionBtn className="sortBtn">
                                                                <img height={10} src={SortIcon} alt="not found" />
                                                            </ActionBtn>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                            <tr>
                                                <td style={{ backgroundColor: "#F3F3F3", width: "40px", boxSizing: "border-box", textAlign: "center", padding: "12px" }}>PM</td>
                                                {PMDays.map((day) => {
                                                    if (mentor[day]) {
                                                        const student = mentor.mentorStudentRota.find((student) => student[`${day}StudentSessionId`]);
                                                        if (student) {
                                                            const sessionStatus = viewModel.getSessionStatusById(student!.sessionStatusId);
                                                            return (
                                                                <td
                                                                    style={{
                                                                        backgroundColor:
                                                                            sessionStatus === "Submitted" ? "#C3E9E5" : sessionStatus === "Pending" ? "#F0D6EB" : "#FCE4BF",
                                                                        width: "249px",
                                                                        minWidth: "220px",
                                                                    }}
                                                                    key={uniqueId()}
                                                                >
                                                                    <Box
                                                                        display={"flex"}
                                                                        flexDirection={"column"}
                                                                        justifyContent={"space-between"}
                                                                        style={{ height: "100%", minHeight: "250px", justifyContent: "space-between" }}
                                                                    >
                                                                        <Box>
                                                                            <StatusRow>
                                                                                <Box>
                                                                                    <Label
                                                                                        className="status no-underline"
                                                                                        fontStyle="h5"
                                                                                        style={{
                                                                                            color: student.didAttend ? "#27E6A3" : "#E6054E",
                                                                                            font: "normal normal bold 12px/16px Open Sans",
                                                                                        }}
                                                                                    >
                                                                                        {student.didAttend === null ? "" : student.didAttend === true ? "Attended" : "Absent"}
                                                                                    </Label>
                                                                                    <Label
                                                                                        className="badge red-badge no-underline"
                                                                                        fontStyle="h6"
                                                                                        style={{
                                                                                            fontStyle: "italic",
                                                                                            color: "#E6054E",
                                                                                            font: "italic normal normal 10px/16px Open Sans",
                                                                                        }}
                                                                                    >
                                                                                        {student.reportIncident ? "(INCIDENT REPORTED)" : ""}
                                                                                    </Label>
                                                                                </Box>

                                                                                <Box>
                                                                                    <Label
                                                                                        className="status green no-underline"
                                                                                        fontStyle="h5"
                                                                                        style={{
                                                                                            font: "normal normal bold 12px/16px Open Sans",
                                                                                            color:
                                                                                                sessionStatus === "Submitted"
                                                                                                    ? "#11A696"
                                                                                                    : sessionStatus === "Pending"
                                                                                                    ? "#C15DB0"
                                                                                                    : "#F39200",
                                                                                        }}
                                                                                    >
                                                                                        {sessionStatus.toUpperCase()}
                                                                                    </Label>
                                                                                </Box>
                                                                            </StatusRow>
                                                                            <Box
                                                                                onClick={() => viewModel.navigateToStudentDetailsCommand.execute(student[`${day}StudentSessionId`])}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <Label
                                                                                    style={{ cursor: "pointer", textDecoration: "underline", marginBottom: "20px" }}
                                                                                    fontStyle="h5"
                                                                                >{`${student.firstName} ${student.lastName}`}</Label>
                                                                                <Label style={{ textDecoration: "none" }}>{`${formatDate(student.sessionDate)}`}</Label>
                                                                            </Box>
                                                                            <StatusRow style={{ display: "flex", flexDirection: "column" }}>
                                                                                <StatusRow style={{ justifyContent: "space-between", width: "100%", marginBottom: "5px" }}>
                                                                                    <Box>
                                                                                        {student.pickUpAddress &&
                                                                                            renderCopyView({ type: "Pickup location:", text: student.pickUpAddress })}
                                                                                    </Box>
                                                                                    <Box>
                                                                                        {student.dropOffAddress &&
                                                                                            renderCopyView({
                                                                                                type: "Drop off location:",
                                                                                                text: student.dropOffAddress,
                                                                                            })}
                                                                                    </Box>
                                                                                </StatusRow>
                                                                                <Box>
                                                                                    <Label style={{ textDecoration: "none" }}>{`(${moment(
                                                                                        student.sessionStartTime,
                                                                                        "HH:mm:ss",
                                                                                    ).format("HH:mm a")} - ${moment(student.sessionEndTime, "HH:mm:ss").format(
                                                                                        "HH:mm a",
                                                                                    )})`}</Label>
                                                                                </Box>
                                                                            </StatusRow>
                                                                        </Box>
                                                                        {sessionStatus === "Pending" ? (
                                                                            <>
                                                                                {student.didAttend === null && sessionStatus === "Pending" && (
                                                                                    <Box display={"grid"} gap={"15px"} gridTemplateColumns={"1fr 1fr"} style={{ gap: "15px" }}>
                                                                                        <ButtonBox style={{ backgroundColor: "#e9ecef" }} className="disabled">
                                                                                            ATTENDED
                                                                                        </ButtonBox>
                                                                                        <ButtonBox style={{ backgroundColor: "#e9ecef" }} className="disabled">
                                                                                            ABSENT
                                                                                        </ButtonBox>
                                                                                    </Box>
                                                                                )}
                                                                                {student.didAttend === true && sessionStatus === "Pending" && (
                                                                                    <SingalButtonRow>
                                                                                        <button style={{ backgroundColor: "#e9ecef" }} className="disabled">
                                                                                            ADD SESSION REPORT
                                                                                        </button>
                                                                                    </SingalButtonRow>
                                                                                )}
                                                                            </>
                                                                        ) : !student.studentSessionAbsenceId ? (
                                                                            <SingalButtonRow>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(student!.studentSessionId);
                                                                                    }}
                                                                                >
                                                                                    VIEW SESSION REPORT
                                                                                </button>
                                                                            </SingalButtonRow>
                                                                        ) : (
                                                                            <SingalButtonRow>
                                                                                <ButtonBox
                                                                                    className="redBtn"
                                                                                    onClick={() => {
                                                                                        viewModel.navigateToDetailsWeeklyRotaReportsCommand.execute(student!.studentSessionId!);
                                                                                    }}
                                                                                >
                                                                                    VIEW REASON WHY
                                                                                </ButtonBox>
                                                                            </SingalButtonRow>
                                                                        )}
                                                                    </Box>
                                                                </td>
                                                            );
                                                        } else {
                                                            return (
                                                                <td style={{ backgroundColor: "#f9f9f9", width: "249px", minWidth: "220px", height: "200px" }} key={uniqueId()} />
                                                            );
                                                        }
                                                    }
                                                    return (
                                                        <td
                                                            style={{ backgroundColor: "#FFFBE8", width: "249px", height: "200px", minWidth: "220px", position: "relative" }}
                                                            key={uniqueId()}
                                                        >
                                                            <ActionBtn className="sortBtn">
                                                                <img height={10} src={SortIcon} alt="not found" />
                                                            </ActionBtn>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </>
                                    );
                                })}
                        </tbody>
                    </table>
                </CustomTable>
            </>
        );
    };

    return (
        <>
            <BreadCrumbContainer>
                <Label fontStyle="h1" color="black">
                    Weekly rota
                </Label>
            </BreadCrumbContainer>
            <ContentContainer>
                <FilterRow>
                    <FilterLeft>
                        <Label fontStyle="h2">Week:</Label>
                        <EditDate displayName={""} value={() => viewModel.getValue("startDate")} command={viewModel.updateMentorWeeklyDateCommand} isWeekDisable={true} />

                        <Button command={viewModel.updatePreviousDateCommand} displayName={"Previous"} paletteColor="lightYellow" />
                        <Button command={viewModel.updateNextDateCommand} displayName={"Next"} paletteColor="lightYellow" />
                    </FilterLeft>
                    <FilterRight>
                        <CheckBox command={viewModel.updateShowAllMentorsCommand} value={() => viewModel.getValue("showSelectMentor")} paletteColor="lightYellow" />
                        <Label fontStyle="h2">Show all other mentors</Label>
                    </FilterRight>
                </FilterRow>
                {renderCustomTable()}
                {viewModel.studentAbsence && renderStudentAbsenceModal()}
            </ContentContainer>
        </>
    );
});
