import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Label, theme } from "Application";
import styled from "@emotion/styled";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { TransparentButton } from "Styles/ThemedPrimitives/Style1/TransparentButton";

const StyledDialog = styled.dialog`
    max-width: 400px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;

    background: ${theme.palette.primary.main};
    height: 50px;
    padding: 0px 20px;
    position: relative;

    button {
        position: absolute;
        top: 10px;
        right: 20px;
    }
`;

export interface IProps {
    //summary: boolean value used to toggle show/hide the html dialog
    isOpen: boolean;
    //summary: function called on html dialog close
    onCloseCommand: ICommand;
    //summary: option boolean value that determines if the dialog can close if clicking outside of the dialog
    //note:    default value = true
    closeOnClickOutside?: boolean;
    //summary: optional title prop used to display the content of the modal title
    title?: string;
    //summary: optional title prop used to display the content of the modal title
    children: React.ReactNode;
}

export const ThemedFormModal: React.FC<IProps> = observer((props: IProps) => {
    const { isOpen, children, title } = props;
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (isOpen) {
            ref.current?.showModal();
            document.body.classList.add("modal-open"); // prevent bg scroll
        } else {
            ref.current?.close();
            document.body.classList.remove("modal-open");
        }
    }, [isOpen]);

    //region properties

    const closeOnClickOutside = props.closeOnClickOutside ?? true;
    const showTitle = !isEmptyOrWhitespace(title) && !isNullOrUndefined(title);
    //endregion properties

    const isClickInsideRectangle = (e: any, element: HTMLElement) => {
        const r = element.getBoundingClientRect();

        return e.clientX > r.left && e.clientX < r.right && e.clientY > r.top && e.clientY < r.bottom;
    };

    const handleOnClose = (e: any) => {
        if (closeOnClickOutside) {
            ref.current && !isClickInsideRectangle(e, ref.current) && onClose();
        }
    };

    const onClose = () => {
        if (props.onCloseCommand.canExecute()) {
            props.onCloseCommand.execute();
        }
    };

    return (
        <>
            <StyledDialog ref={ref} onCancel={onClose} onClick={(e) => handleOnClose(e)}>
                <Header>
                    {showTitle && <Label fontStyle="h2">Upload new resource</Label>}
                    <TransparentButton displayName={<CloseIcon />} command={props.onCloseCommand} />
                </Header>
                <>{children}</>
            </StyledDialog>
        </>
    );
});
