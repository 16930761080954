import { SVGProps } from "react";

export const SessionSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg id="Group_11140" data-name="Group 11140" xmlns="http://www.w3.org/2000/svg" width="90.677" height="113.293" viewBox="0 0 90.677 113.293">
        <path
            id="Subtraction_4"
            data-name="Subtraction 4"
            d="M79.067,104.809H63.821a3.416,3.416,0,0,1-3.262-2.439l2.054-31.2C67.566,70.74,71.3,67.231,71.3,63V32.476a13.146,13.146,0,0,0-3.756-9.161h10.2a12.769,12.769,0,0,1,9.12,3.814,13.135,13.135,0,0,1,3.811,9.332v27.36a7.383,7.383,0,0,1-1.886,4.937,7.218,7.218,0,0,1-4.634,2.379l-1.674,30.38A3.455,3.455,0,0,1,79.067,104.809Zm-52.214,0H11.608a3.455,3.455,0,0,1-3.415-3.291L6.52,71.137a7.218,7.218,0,0,1-4.636-2.371A7.394,7.394,0,0,1,0,63.821V36.437a13.129,13.129,0,0,1,3.793-9.281,12.776,12.776,0,0,1,9.163-3.841H23.82a13.186,13.186,0,0,0-3.769,9.137V63c0,4.226,3.734,7.736,8.683,8.165l1.746,26.487-.212,3.862A3.455,3.455,0,0,1,26.854,104.809Zm44.6-83.674a10.253,10.253,0,0,1-7.361-3.1A10.681,10.681,0,0,1,64.1,3.1a10.292,10.292,0,0,1,14.717,0,10.681,10.681,0,0,1,0,14.943A10.253,10.253,0,0,1,71.457,21.136Zm-52.214,0a10.257,10.257,0,0,1-7.357-3.1,10.672,10.672,0,0,1,0-14.943A10.287,10.287,0,0,1,26.6,3.1a10.672,10.672,0,0,1,0,14.943A10.246,10.246,0,0,1,19.244,21.136Z"
            transform="translate(0 8.201)"
            fill="#71b3fc"
        />
        <g id="Group_10452" data-name="Group 10452" transform="translate(24.171 0)">
            <g id="Group_10267" data-name="Group 10267" transform="translate(0 0)">
                <path
                    id="Path_8835"
                    data-name="Path 8835"
                    d="M273.806,124.537h-13.78a14.179,14.179,0,0,0-14.187,14.187v29.6a7.947,7.947,0,0,0,7.139,7.91l1.835,32.839a3.769,3.769,0,0,0,3.739,3.559h16.7a3.753,3.753,0,0,0,3.739-3.559l1.835-32.839a7.962,7.962,0,0,0,7.139-7.91V138.746a14.165,14.165,0,0,0-14.164-14.21Z"
                    transform="translate(-245.839 -99.337)"
                    fill="#71b3fc"
                />
                <path
                    id="Path_8836"
                    data-name="Path 8836"
                    d="M316.467,11.422A11.4,11.4,0,1,1,305.068,0a11.41,11.41,0,0,1,11.4,11.422"
                    transform="translate(-283.99 0)"
                    fill="#71b3fc"
                />
            </g>
        </g>
    </svg>
);
