import { isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { makeObservable, observable, observe } from "mobx";

import { RelayCommand } from "Application";
import { FileModel } from "./FileModel";
import { FilesModel, FilesModelValidator } from "./FilesModel";
import { FileViewModel } from "./FileViewModel";

export class FilesViewModel extends ViewModelBase<FilesModel> {
    public fileViewModels = observable<FileViewModel>([]);

    constructor(fileModel: FilesModel = new FilesModel(), fileTypes = "", allowMultipleTYpes = true) {
        super(fileModel);

        this.setValidator(new FilesModelValidator());

        this.model.allowMultipleFiles = allowMultipleTYpes;
        this.model.fileTypes = fileTypes;

        makeObservable(this, {
            fileViewModels: observable,
        });
    }

    public dispose = (): void => {
        this.filesObserverDispose?.();
    };

    public get allowMultipleFiles() {
        return this.model.allowMultipleFiles;
    }

    public get fileTypes() {
        return this.model.fileTypes;
    }

    public addCommand = new RelayCommand((files: FileList) => {
        if (files) {
            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const fileModel = new FileModel();
                fileModel.file = files.item(fileIndex);
                fileModel.fileName = files.item(fileIndex)!.name;
                fileModel.fileSizeBytes = files.item(fileIndex)!.size;
                fileModel.mimeType = files.item(fileIndex)!.type;
                fileModel.documentUrl = "";
                if (fileModel.fileSizeBytes > 0 && !isEmptyOrWhitespace(fileModel.mimeType)) {
                    this.model.files.push(fileModel);
                }
            }
        }
    });

    public removeCommand = new RelayCommand((file: FileModel) => {
        const fileToRemove = this.model.files.find((m) => m.KEY === file.KEY);
        if (fileToRemove) {
            this.model.files.remove(fileToRemove);
            if (!isEmptyOrWhitespace(fileToRemove.id)) {
                this.model.removedFiles.remove(fileToRemove);
            }
        }
    });

    private filesObserverDispose = observe(this.model.files, (fileChanges: any) => {
        for (const addedFile of fileChanges.added) {
            this.fileViewModels.push(new FileViewModel(addedFile, this.removeCommand));
        }

        for (const removedFile of fileChanges.removed) {
            const fileViewModelToRemove = this.fileViewModels.find((vm) => vm.model.KEY === removedFile.KEY);

            if (fileViewModelToRemove) {
                this.fileViewModels.remove(fileViewModelToRemove);
            }
        }
    });
}
