//app
import { formatDate } from "Application";

export class MentorItemViewModel {
    // #region Model Properties

    public id?: string;
    public firstName?: string;
    public lastName?: string;
    public email?: string;
    public phone?: string;
    public lastActionFirstName?: string;
    public lastActionLastName?: string;
    public lastActionDate?: Date;
    public lastActionByUserId?: string;
    public updatedDate?: Date;

    // #region Formatted Properties

    public get formattedFullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    public get formattedActionName() {
        return `${this.lastActionFirstName} ${this.lastActionLastName}`;
    }

    public get actionDate() {
        return !!this.updatedDate ? this.updatedDate : this.lastActionDate;
    }

    public get lastActionDates() {
        return this.lastActionDate;
    }

    public get phones() {
        return this.phone;
    }

    public get formattedLastActionDate() {
        return formatDate(this.lastActionDate);
    }
}
