import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { LoginViewModel } from "./LoginViewModel";
import { Box, Button, Input, Label, setPageTitle, ThemedLoader } from "Application";
import { LoginHomeViewModel } from "../LoginHomeViewModel";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { AppUrls } from "AppUrls";

/**
 * Login page view.
 */
export const ForgotPass = styled.div`
    a {
        color: #2d91ff;
        font-size: 12px;
    }
`;

export const LoginOuter = styled.div`
    .innerloginPanel {
        min-width: 300px;
        @media only screen and (max-width: 480px) {
            margin-bottom: 10rem;
            min-width: auto;
        }
    }
`;

const LoginView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    // #region Code Behind
    const [viewModel] = useState(() => new LoginViewModel());
    useEffect(() => {
        setPageTitle("Sign In");
    }, []);
    // #endregion Code Behind

    const renderForm = () => (
        <>
            <LoginOuter>
                <Box grid alignContent={"center"} dc="1fr" rowGap={"15px"} className={"innerloginPanel"}>
                    <Label fontStyle={"error"} color={"error"}>
                        {viewModel.apiClient.ValidationMessage}
                    </Label>
                    <Input
                        command={viewModel.updateEmailCommand}
                        displayName="Login"
                        autoFocus={true}
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => viewModel.getError("emailAddress")}
                        cy={"emailAddress"}
                        placeholder={"Email address"}
                    />
                    <Input
                        command={viewModel.updatePasswordCommand}
                        displayName="Password"
                        type="password"
                        value={() => viewModel.getValue("password")}
                        validationMessage={() => viewModel.getError("password")}
                        cy={"password"}
                        placeholder={"Your password"}
                    />
                    <Box flexBox justifyContent={"space-between"}>
                        <ForgotPass>
                            <Link to={AppUrls.Client.Account.ForgotPassword}> Oops I've forgotten my password </Link>
                        </ForgotPass>
                    </Box>
                    <Button cy={"login"} displayName="LOG IN" paletteColor="lightYellow" style={{ color: "black" }} command={viewModel.loginCommand} type="submit" />
                </Box>
            </LoginOuter>
        </>
    );

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    return (
        <React.Fragment>
            {renderForm()}
            {renderBusy()}
        </React.Fragment>
    );
});
export default LoginView;
