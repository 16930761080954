//dependencies
import { Endpoint, Http } from "Application";
import { AppUrls } from "AppUrls";

//components
import { WeeklyRotaDetailsViewModel } from "../../WeeklyRotaDetailViewModel";

class Request {
    public id: Guid = "";
    public startDate: any;
    public isAbsence?: boolean;
}

export class DeleteMentorAbsenceEndPoint extends Endpoint<Request, Response> {
    private deleteMentorRotaAbsenceId: Guid;

    constructor(deleteMentorRotaAbsenceId: Guid) {
        super();
        this.deleteMentorRotaAbsenceId = deleteMentorRotaAbsenceId;
        this.verb(Http.Delete);
        this.path(AppUrls.Server.WeeklyRota.DeleteMentorRotaAbsence);
    }

    public async HandleRequestAsync(): Promise<any> {
        let request: Request = new Request();
        request.id = this.deleteMentorRotaAbsenceId;
        request.isAbsence = true;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        return Promise.resolve(responses);
    }
}
