import { Endpoint, Http } from "Application";
import { AppUrls } from "AppUrls";
import { WeeklyRotaDetailsViewModel } from "../../WeeklyRotaDetailViewModel";
import moment from "moment";

class Request {
    public studentSessionId: Guid = "";
}

export class RemoveDetailsMentorStudentEndPoint extends Endpoint<Request, Response> {
    private studentSessionId: Guid;

    constructor(studentSessionId: Guid, viewModel: WeeklyRotaDetailsViewModel) {
        super();
        this.studentSessionId = studentSessionId;
        this.verb(Http.Put);
        this.path("/api/student/editMentorStudentRota");
    }

    public async HandleRequestAsync(model: WeeklyRotaDetailsViewModel): Promise<any> {
        let request: Request = new Request();
        request.studentSessionId = this.studentSessionId;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        return Promise.resolve(responses);
    }
}
