//dependencies
import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

//component
import { MentorWeeklyRotaViewModel } from "../MentorWeeklyRotaViewModel";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import moment from "moment";

class Request {
    public id: Guid = "";
    public sessionDate: Date | String = "";
    public sessionStartTime: string | null = null;
    public sessionEndTime: string = "";
    public studentId: Guid = "";
    public sessionStatusId: Guid = "";
    public mentorId: Guid = "";
    public activityTypeId: Guid = "";
    public isAdmin: boolean = false;
    public studentSessionAbsenceRequest: studentSessionAbsenceRequest = new studentSessionAbsenceRequest();
}

class studentSessionAbsenceRequest {
    public id: string | null = null;
    public studentSessionId: string | null = null;
    public absenseTypeId: string | null = null;
    public reason: string | null = null;
    public isDeleted: boolean = false;
}

export class POSTStudentAbsenceEndpoint extends Endpoint<Request, Response> {
    public lookupStore = container.resolve(LookupStore);
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Student.StudentSessions.PostSaveStudentSession);
    }

    public async HandleRequestAsync(weeklyRota: MentorWeeklyRotaViewModel): Promise<any> {
        let request: Request = new Request();

        const convertTimeToDate = (value: any) => {
            const initialMoment = moment(value, "HH:mm:ss");
            const initialDate = new Date();
            initialDate.setUTCHours(initialMoment.hours());
            initialDate.setUTCMinutes(initialMoment.minutes());
            initialDate.setUTCSeconds(initialMoment.seconds());
            const gmtFormattedDate = initialDate.toISOString();
            return gmtFormattedDate;
        };

        request.id = weeklyRota.model.selectedStudentAbsense.studentSessionId;
        request.studentId = weeklyRota.model.studentId;
        request.sessionStatusId = this.getSessionStatusId("Submitted");
        request.mentorId = weeklyRota.model.selectedStudentAbsense.mentorId;
        request.activityTypeId = weeklyRota.model.selectedStudentAbsense.activityTypeId;
        request.sessionDate = weeklyRota.model.sessionDate;

        request.sessionStartTime = convertTimeToDate(weeklyRota.model.sessionStartTime);
        request.sessionEndTime = convertTimeToDate(weeklyRota.model.sessionEndTime);

        request.studentSessionAbsenceRequest.id = weeklyRota.model.selectedStudentAbsense.studentSessionAbsenceId;
        request.studentSessionAbsenceRequest.studentSessionId = weeklyRota.model.selectedStudentAbsense.studentSessionId;
        request.studentSessionAbsenceRequest.absenseTypeId = weeklyRota.model.reason;
        request.studentSessionAbsenceRequest.reason = weeklyRota.model.moreDetail;
        request.isAdmin = false;

        return Promise.resolve(request);
    }

    public getSessionStatusId = (value: string) => {
        const sessionStatus = this.lookupStore.sessionStatusKeyValuePairs.find((m) => m.text === value);
        return sessionStatus ? sessionStatus.key : "";
    };

    public async HandleResponseAsync(response: any): Promise<any> {
        runInAction(() => {});
    }
}
