import { useEffect, useState } from "react";

import { theme } from "Styles/AppTheme";
import { useTimeout } from "Application/Hooks";
import { observer } from "mobx-react-lite";
import { keyframes } from "@emotion/react";
import * as React from "react";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
    .loading-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .loading-icon {
        border: 5px solid #f3f3f3;
        border-top: 5px solid ${theme.palette.primary.main};
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }

    .loading-text {
        font-size: 1.5em;
        margin-left: 1em;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

/**
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
interface Props {
    isOpen: boolean;
    delayBeforeOpen?: number;
    containerStyles?: React.CSSProperties;
}
export const ThemedLoader: React.FC<Props> = observer((props: Props) => {
    const [show, setShow] = useState(false);
    const { isOpen, delayBeforeOpen } = props;

    const myEffect = keyframes({
        "0%": {
            backgroundColor: "transparent",
            opacity: "0",
        },
        "100%": {
            backgroundColor: `${theme.palette.common.default}B2`,
            opacity: "1",
        },
    });

    const { start, clear } = useTimeout(() => {
        setShow(true);
    }, delayBeforeOpen);

    useEffect(() => {
        if (isOpen) {
            start();
        }
        return () => {
            clear();
        };
    }, []);

    return (
        <Wrapper>
            {show && (
                <div className="loading-page" style={{ ...props.containerStyles }}>
                    <div className="loading-icon"></div>
                    <h2 className="loading-text">Loading...</h2>
                </div>
            )}
        </Wrapper>
    );
});
