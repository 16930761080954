import { AbsenceType, ActivityType, DocumentCategory, Region, SessionStatus, StudentStatus } from "Application/Models/Domain";
import { InitViewModel } from "./InitViewModel";
import { InitialState } from "Application/Models";

//region response types
class _GenericTypeResponse {
    public id: string = "";
    public name: string = "";
    public type: string = "";
    public ordinal: number = 0;
    public isDeleted: boolean = false;
}

export class AbsenceTypeResponse extends _GenericTypeResponse {}

export class ActivityTypeResponse extends _GenericTypeResponse {}

export class DocumentCategoryResponse extends _GenericTypeResponse {}

export class RegionResponse {
    public id: string = "";
    public code: string = "";
    public country: string = "";
}

export class ResourceCategoryResponse extends _GenericTypeResponse {}

export class SessionsStatusResponse extends _GenericTypeResponse {}

export class StudentStatusResponse extends _GenericTypeResponse {}

//endregion response types

export class LookUpResponse extends Response {
    //region response definitions
    absenceTypes: AbsenceTypeResponse[] = [];
    activityTypes: ActivityTypeResponse[] = [];
    documentCategories: DocumentCategoryResponse[] = [];
    regions: RegionResponse[] = [];
    resourceCategories: ResourceCategoryResponse[] = [];
    sessionStatuses: SessionsStatusResponse[] = [];
    studentStatuses: StudentStatusResponse[] = [];
    //endregion response definitions

    //region data mapping
    public static handleAbsenceTypesResponse(response: LookUpResponse, initialState: InitialState) {
        const types: AbsenceType[] = [];

        if (response.absenceTypes.length > 0) {
            response.absenceTypes.forEach((response: AbsenceTypeResponse) => {
                const type = {} as AbsenceType;

                type.id = response.id;
                type.name = response.name;
                type.type = response.type;
                type.ordinal = response.ordinal;
                type.isDeleted = response.isDeleted;

                types.push(type);
            });

            initialState.absenceTypes = types;
        }
    }

    public static handleActivityTypesResponse(response: LookUpResponse, initialState: InitialState) {
        const types: ActivityType[] = [];

        if (response.activityTypes.length > 0) {
            response.activityTypes.forEach((response: AbsenceTypeResponse) => {
                const type = {} as ActivityType;

                type.id = response.id;
                type.name = response.name;
                type.type = response.type;
                type.ordinal = response.ordinal;
                type.isDeleted = response.isDeleted;

                types.push(type);
            });

            initialState.activityTypes = types;
        }
    }

    public static handleDocumentCategoriesResponse(response: LookUpResponse, initialState: InitialState) {
        const types: DocumentCategory[] = [];

        if (response.documentCategories.length > 0) {
            response.documentCategories.forEach((response: AbsenceTypeResponse) => {
                const type = {} as DocumentCategory;

                type.id = response.id;
                type.name = response.name;
                type.type = response.type;
                type.ordinal = response.ordinal;
                type.isDeleted = response.isDeleted;

                types.push(type);
            });

            initialState.documentCategories = types;
        }
    }

    public static handleRegionsResponse(response: LookUpResponse, initialState: InitialState) {
        const regions: Region[] = [];

        if (response.regions.length > 0) {
            response.regions.forEach((response: RegionResponse) => {
                const region = {} as Region;

                region.id = response.id;
                region.code = response.code;
                region.country = response.country;

                regions.push(region);
            });

            initialState.regions = regions;
        }
    }

    public static handleResourceCategoriesResponse(response: LookUpResponse, initialState: InitialState) {
        const types: DocumentCategory[] = [];

        if (response.resourceCategories.length > 0) {
            response.resourceCategories.forEach((response: ResourceCategoryResponse) => {
                const type = {} as DocumentCategory;

                type.id = response.id;
                type.name = response.name;
                type.type = response.type;
                type.ordinal = response.ordinal;
                type.isDeleted = response.isDeleted;

                types.push(type);
            });

            initialState.resourceCategories = types;
        }
    }

    public static handleSessionStatusesResponse(response: LookUpResponse, initialState: InitialState) {
        const types: SessionStatus[] = [];

        if (response.sessionStatuses.length > 0) {
            response.sessionStatuses.forEach((response: SessionsStatusResponse) => {
                const type = {} as SessionStatus;

                type.id = response.id;
                type.name = response.name;
                type.type = response.type;
                type.ordinal = response.ordinal;
                type.isDeleted = response.isDeleted;

                types.push(type);
            });

            initialState.sessionStatuses = types;
        }
    }

    public static handleStudentStatusesResponse(response: LookUpResponse, initialState: InitialState) {
        const types: StudentStatus[] = [];

        if (response.studentStatuses.length > 0) {
            response.studentStatuses.forEach((response: StudentStatusResponse) => {
                const type = {} as StudentStatus;
                if (response.type !== "LEFT") {
                    type.id = response.id;
                    type.name = response.name;
                    type.type = response.type;
                    type.ordinal = response.ordinal;
                    type.isDeleted = response.isDeleted;

                    types.push(type);
                }
            });

            initialState.studentStatuses = types;
        }
    }

    //endregion data mapping
}
