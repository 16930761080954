import { Box, Label, Show } from "Components";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { FileIcon } from "Assets/Icons/FileIcon";
import { DocumentFilesViewModel } from "./DocumentFilesViewModel";
import { DocumentFile } from "./DocumentFile";
import { isNullOrEmpty } from "@shoothill/core";
import { ExclamationIconSVG } from "Assets/ExclamationSVG";
import styled from "@emotion/styled";
import { theme } from "Application";

export interface IFilesProps {
    className?: string;
    viewModel: DocumentFilesViewModel;
    styles?: any;
}

export const ToolTip = styled(Box)`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    & > div {
        background: #000;
        padding: 5px 12px;
        min-width: 150px;
        border-radius: 5px;
        color: #fff;
        text-align: center;
        position: absolute;
        opacity: 0;
        top: -35px;
        font-size: 14px;
        &:after {
            content: "";
            height: 8px;
            width: 8px;
            bottom: -4px;
            margin: 0 auto;
            position: absolute;
            left: 0;
            right: 0;
            display: block;
            background: #000;
            transform: rotate(-45deg);
        }
    }
    &:hover {
        & > div {
            opacity: 100%;
        }
    }
`;

export const DocumentFiles: React.FC<IFilesProps> = observer((props) => {
    const { viewModel } = props;

    const inputReference = useRef<any>(null);

    const fileTypes = (): string => {
        return viewModel.fileTypes;
    };

    const isDisabled = (): boolean => {
        return !viewModel.addCommand.canExecute;
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const allowedFileTypes = [".pdf", ".doc", ".xls", ".jpg", ".png", ".docx"];
        const files = event.target.files;

        // Check if any files are selected
        if (!files || files.length === 0) {
            return;
        }

        for (const file of files) {
            const fileType = "." + (file.name.split(".").pop() || "").toLowerCase();

            if (!allowedFileTypes.includes(fileType)) {
                alert(`Invalid file type: ${file.name}`);
                return;
            }
        }

        viewModel.addCommand.execute(files);

        event.target.value = "";
    };

    const onDropFile = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
        viewModel.addCommand.execute(event.dataTransfer.files);
    };

    const onDragFile = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault();
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        inputReference?.current?.click();
    };

    const renderError = () => {
        if (viewModel.getError("files") !== undefined && !isNullOrEmpty(viewModel.getError("files"))) {
            return (
                <>
                    <Label style={{ color: "rgb(230, 5, 78)", fontWeight: 600, display: "flex", alignItems: "center" }}>
                        File Upload *
                        <ToolTip>
                            <ExclamationIconSVG backgroundColor={theme.palette.common.error} color={theme.palette.common.white} />
                            <div> {viewModel.getError("files") as string}</div>
                        </ToolTip>
                    </Label>
                </>
            );
        }
    };

    return (
        <div>
            <Show if={viewModel.fileViewModels.length == 0}>
                <Box>{renderError()}</Box>
                <Box onDragEnter={onDragFile} onDragOver={onDragFile} onDrop={onDropFile}>
                    <input accept={fileTypes()} multiple={false} ref={inputReference} style={{ display: "none" }} type="file" onChange={onChange} />
                    <button
                        disabled={isDisabled()}
                        onClick={onClick}
                        type="button"
                        style={{
                            background: "#F9F9F9",
                            border: "1px dashed #DCDCDC",
                            width: "100%",
                            padding: "15px",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                        }}
                    >
                        <FileIcon />
                        <Box style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#8D8D8D" }} mt={"5px"}>
                            Click or drag a file to attach a file <br />
                            (.PDF .DOC .XLS .JPG .PNG .DOCX)
                        </Box>
                    </button>
                </Box>
            </Show>
            <Show if={viewModel.fileViewModels.length > 0}>
                <Box dc={"1fr 1fr"} columnGap="30px" rowGap="15px">
                    {viewModel.fileViewModels.map((viewModel) => {
                        return <DocumentFile className={props.className} viewModel={viewModel} key={viewModel.KEY} />;
                    })}
                </Box>
            </Show>
        </div>
    );
});
