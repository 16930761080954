import { ViewModelBase } from "@shoothill/core";
import { computed, makeObservable } from "mobx";

import { ICommand, RelayCommand } from "Application";
import { FileModel } from "./FileModel";

export class FileViewModel extends ViewModelBase<FileModel> {
    private parentRemoveFileModelCommand: ICommand;

    constructor(fileModel: FileModel = new FileModel(), removeFileModelCommand: ICommand) {
        super(fileModel);

        this.parentRemoveFileModelCommand = removeFileModelCommand;

        makeObservable(this, {
            displayName: computed,
            documentUrl: computed,
        });
    }

    public get KEY() {
        return this.model.KEY;
    }

    public get displayName() {
        return this.model.fileName;
    }

    public get documentUrl() {
        return this.model.documentUrl;
    }

    public get fileSizeBytes() {
        const fileSizeKiloBytes = new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 3 }).format(this.model.fileSizeBytes / 1024);
        return `${fileSizeKiloBytes} KB`;
    }

    public removeCommand = new RelayCommand(() => {
        this.parentRemoveFileModelCommand.execute(this.model);
    });
}
