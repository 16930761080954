import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

export class FileModel extends ModelBase<FileModel> {
    public KEY: string = nanoid();
    public studentId: string = "";
    public documentCategoryId: string = "";
    public id: string | null = null;
    public file: File | null = null;
    public fileName: string = "";
    public fileSizeBytes: number = 0;
    public mimeType: string = "";
    public documentUrl: string = "";

    constructor() {
        super();
        makeObservable(this, {
            file: observable,
            fileName: observable,
            fileSizeBytes: observable,
            mimeType: observable,
            documentUrl: observable,
        });
    }
}
