import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Input, Label, theme } from "Application";
import { ResetPasswordViewModel } from "./ResetPasswordViewModel";
import { useParams } from "react-router";
import { Centered } from "Components/Centered";
import artboard from "Assets/Artboard.png";
import styled from "@emotion/styled";

export const LoginOuter = styled.div`
    .innerloginPanel {
        min-width: 300px;
        @media only screen and (max-width: 480px) {
            min-width: auto;
        }
        .s-text {
            color: #000;
            font-family: OpenSans-Regular;
            font-size: 0.75rem;
            letter-spacing: 0rem;
            text-transform: none;
            cursor: default;
            -webkit-transition: 0.25s ease all;
            transition: 0.25s ease all;
            display: block;
            line-height: normal;
            font-weight: 400;
        }
        .back-to-sign-in {
            label {
                min-height: 30px;
                padding: 8px 30px;
                text-transform: uppercase;
                font-family: OpenSans-CondBold;
                font-size: 14px;
                letter-spacing: 0rem;
                line-height: 1.125rem;
                background-color: #fcd21d;
                text-align: center;
                border-radius: 3px;
            }
        }
    }
`;
export const Wrapper = styled.div`
    @media only screen and (max-width: 1024px) {
        .logoImg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: #fcd21d;
            width: 120px;
            height: 120px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                padding: 10px;
            }
        }
    }
`;
export const ResetPasswordView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ResetPasswordViewModel());
    let params = useParams();
    let token = params.token || null;

    useEffect(() => {
        viewModel.setValue("token", token);
    }, []);

    return (
        <>
            <Wrapper>
                <Box className={"logoImg"} marginBottom={"30px"} textAlign={"center"}>
                    <img src={artboard} alt="" />
                </Box>
            </Wrapper>
            <Centered>
                <LoginOuter>
                    <Box showIf={!viewModel.showSuccessMessage} grid alignContent={"center"} dc="1fr" rowGap={"15px"} className={"innerloginPanel"}>
                        <Label fontStyle="field"> Before you can get access to your Lifeshed account again please create a new, secure memorable password to protect it. </Label>
                        {/* <Label className="error" fontStyle={"error"} color={"error"}>
                            {viewModel.apiClient.ValidationMessage}
                        </Label> */}

                        <Input
                            placeholder="New password for your account"
                            displayName={"New Password"}
                            command={viewModel.updateNewPasswordCommand}
                            value={() => viewModel.getValue("newPassword")}
                            validationMessage={() => viewModel.getError("newPassword")}
                            type={"password"}
                        />
                        <Input
                            placeholder="New password for your account"
                            displayName={"Confirm Password"}
                            command={viewModel.updateConfirmPasswordCommand}
                            value={() => viewModel.getValue("confirmPassword")}
                            validationMessage={() => viewModel.getError("confirmPassword")}
                            type={"password"}
                        />
                        <Button displayName="Reset Password" command={viewModel.resetPasswordCommand} paletteColor="primary" type="submit" />
                    </Box>
                    <Box showIf={viewModel.showSuccessMessage} className={"innerloginPanel"}>
                        <Label fontStyle={"h3"} className={"s-text"}>
                            You have successfully reset your password
                        </Label>
                        <Label fontStyle={"h3"} className={"s-text"}>
                            You can now go back to the login page
                        </Label>
                        <Box mt={1} className={"back-to-sign-in"} onClick={() => viewModel.navigateToSignInCommand.execute()}>
                            <Label pointer fontStyle={"h6"}>
                                Back to sign in
                            </Label>
                        </Box>
                    </Box>
                </LoginOuter>
            </Centered>
        </>
    );
});
