import styled from "@emotion/styled";
import { Box } from "Components";
import { theme } from "Styles/AppTheme";

export const PageContainer = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
`;

export const NavigationContainer = styled(Box)`
    align-items: center;
    background-color: #f3f3f3;
    display: flex;
    min-height: 40px;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 30px;
    gap: 10px;
    label {
        text-decoration: underline;
        font: normal normal normal 12px/16px Open Sans;
        letter-spacing: -0.12px;
        color: #000000;
    }
`;

export const FormContainer = styled(Box)`
    background-color: ${theme.palette.common.white};
    flex: 1;
    padding: 25px 30px;
    @media only screen and (max-width: 767px) {
        padding: 25px 15px;
    }
    &.student-main {
        .student-main-label {
            margin: 25px 0px 21px;
        }
        label {
            letter-spacing: 0px;
        }
        .inputbox {
            border-color: rgb(171 183 194 / 30%);
            border-radius: 0px;
        }
        .add-another {
            color: #000;
            padding: 11px 31px;
            border-radius: 5px;
        }
        .form-control {
            input {
                border-color: #dcdcdc;
                height: 39px;
                width: 39px;
                border-radius: 0px;
                :before {
                    transform: rotate(15deg);
                    font-size: 35px;
                    box-shadow: inset 1em 1em #bd9e17;
                }
            }
            .check-label {
                padding-left: 12px;
                label {
                    text-transform: inherit;
                }
            }
        }
        .editselect {
            font-size: 12px;
            color: #000;
            .editselect__control {
                border-color: #e6e9ed;
                min-width: 250px;
                height: 40px;
                &.editselect__control--is-focused {
                    border-color: #c5c7c9 !important;
                }
                .editselect__placeholder {
                    font-size: 12px;
                    color: #8d8d8d;
                }
                :hover {
                    border-color: #c5c7c9 !important;
                }
                .editselect__indicators {
                    .editselect__indicator-separator {
                        display: none;
                    }
                    .editselect__indicator {
                        position: relative;
                        svg {
                            display: none;
                        }
                        :after {
                            content: "";
                            position: absolute;
                            top: 7px;
                            right: 11px;
                            border-top: 4px solid #000;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                        }
                    }
                }
            }
        }
    }
`;

export const FormContainerRow = styled(Box)`
    align-items: center;
    padding: 0px 30px;
`;

export const BottomBtn = styled(Box)`
    gap: 30px;
    @media only screen and (max-width: 767px) {
        gap: 15px;
    }
    button {
        border-radius: 5px;
        height: 40px;
        min-width: 140px;
        text-align: center;
        font: normal normal bold 14px/19px Open Sans;
        letter-spacing: 0px;
        text-transform: uppercase;
    }
`;

export const RadioGroup = styled(Box)`
    gap: 20px;
    &:not(:last-child) {
        margin-bottom: 30px;
    }
    & > label {
        font-family: OpenSans-CondBold;
        font-size: 0.875rem;
        letter-spacing: 0.04375rem;
        line-height: 1.125rem;
        margin-top: 5px;
        margin-bottom: 5px;
        display: block;
        min-height: 20px;
    }
    & > div {
        display: grid;
        gap: 10px;
        grid-template-columns: 130px 130px 130px auto;
        .radio {
            display: flex;
            margin: 0;
            label {
                font: normal normal normal 12px/16px Open Sans !important;
                letter-spacing: -0.12px !important;
                color: #000000 !important;
                align-items: center !important;
                display: flex !important;
                &:before {
                    outline: 1px solid #dcdcdc !important;
                    box-shadow: none !important;
                    margin-right: 10px !important;
                    top: 0 !important;
                    border: none !important;
                    outline-offset: 4px;
                    height: 20px !important;
                    width: 20px !important;
                }
            }
        }
    }
`;
