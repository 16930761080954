import React from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { InfoBar } from "Components/InfoBar";
import { Outlet } from "react-router";
import { Box } from "Components";
import student from "Assets/std.png";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";

export const Root = styled.div`
    display: flex;
    min-height: 100vh;
    gap: 3px;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        min-height: initial;
    }
`;

const Content = styled.div`
    box-shadow: 3px 0px 6px #00000029;
    overflow: hidden;
    padding: 50px;
    max-width: 410px;
    width: 100%;
    position: relative;
    z-index: 99;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 1024px) {
        order: 2;
        position: initial;
        max-width: 100%;
        box-shadow: none;
        padding: 30px 20px;
    }
    form {
        @media only screen and (max-width: 1024px) {
            display: block;
        }
    }
`;

const ImageBox = styled.div`
    max-height: 100vh;
    width: 100%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        @media only screen and (max-width: 1024px) {
            height: 100%;
        }
    }
    @media only screen and (max-width: 1024px) {
        order: 1;
        height: 487px;
    }
`;

//EN: use empty generic type to get default react props
export const SplitLayout: React.FC<{}> = observer((props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    {/* <Header /> */}
                    {/*EN: Make sure you apply overflowContainer classname to all your body content*/}
                    <Box className={"overflowContainer--split-layout"}>
                        <InfoBar />
                        <Root>
                            <Content>
                                <Outlet />
                            </Content>
                            <ImageBox>
                                <img src={student} alt="student" />
                            </ImageBox>
                        </Root>
                    </Box>
                </>
            )}
        </>
    );
});
