import { SVGProps } from "react";

export const FileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg id="Group_7165" data-name="Group 7165" xmlns="http://www.w3.org/2000/svg" width="18.682" height="24.242" viewBox="0 0 18.682 24.242">
        <g id="Group_7166" data-name="Group 7166" transform="translate(18.682 2.326) rotate(90)">
            <g id="Path_5673" data-name="Path 5673" fill="none">
                <path
                    d="M5.449,0H20.063a1.854,1.854,0,0,1,1.854,1.854V14.828a1.854,1.854,0,0,1-1.854,1.854v-.953a.816.816,0,0,0,.795-.9V1.854a.844.844,0,0,0-.795-.881H5.449L.488,5.043,0,4.438Z"
                    stroke="none"
                />
                <path
                    d="M 5.44921875 0 L 20.06249809265137 0 C 21.08616828918457 0 21.91601753234863 0.8298501968383789 21.91601753234863 1.853509902954102 L 21.91601753234863 14.82812023162842 C 21.91601753234863 15.85179042816162 21.08616828918457 16.681640625 20.06249809265137 16.681640625 L 20.06249809265137 15.7285099029541 C 20.06249809265137 15.7285099029541 20.06249809265137 15.7285099029541 20.06249809265137 15.7285099029541 C 20.06269264221191 15.7285099029541 20.85741806030273 15.72848033905029 20.85741806030273 14.82812023162842 C 20.85741806030273 13.92765998840332 20.85741806030273 2.692090034484863 20.85741806030273 1.853509902954102 C 20.85741806030273 1.01494026184082 20.06249809265137 0.9726600646972656 20.06249809265137 0.9726600646972656 L 5.44921875 0.9726600646972656 L 0.4882774353027344 5.042970657348633 L -1.9073486328125e-06 4.4375 L 5.44921875 0 Z"
                    stroke="none"
                    fill="#bd9e17"
                />
            </g>
        </g>
        <g id="Group_7165-2" data-name="Group 7165" transform="translate(16.682) rotate(90)">
            <g id="Path_5673-2" data-name="Path 5673" fill="none">
                <path d="M5.529,0h14.86a1.854,1.854,0,0,1,1.854,1.854V14.828a1.854,1.854,0,0,1-1.854,1.854H1.854A1.854,1.854,0,0,1,0,14.828V4.172Z" stroke="none" />
                <path
                    d="M 5.863729476928711 1 L 1.000003814697266 4.670372009277344 L 1.000003814697266 14.82812023162842 C 1.000003814697266 15.29874992370605 1.382883071899414 15.681640625 1.853513717651367 15.681640625 L 20.38867378234863 15.681640625 C 20.85930442810059 15.681640625 21.24218368530273 15.29874992370605 21.24218368530273 14.82812023162842 L 21.24218368530273 1.853509902954102 C 21.24218368530273 1.382880210876465 20.85930442810059 1 20.38867378234863 1 L 5.863729476928711 1 M 5.528743743896484 0 L 20.38867378234863 0 C 21.41234397888184 0 22.24218368530273 0.8298501968383789 22.24218368530273 1.853509902954102 L 22.24218368530273 14.82812023162842 C 22.24218368530273 15.85179042816162 21.41234397888184 16.681640625 20.38867378234863 16.681640625 L 1.853513717651367 16.681640625 C 0.8298435211181641 16.681640625 3.814697265625e-06 15.85179042816162 3.814697265625e-06 14.82812023162842 L 3.814697265625e-06 4.172220230102539 L 5.528743743896484 0 Z"
                    stroke="none"
                    fill="#bd9e17"
                />
            </g>
            <g id="Group_7137" data-name="Group 7137">
                <g id="Path_5673-3" data-name="Path 5673" fill="none">
                    <path d="M5.529,4.091V0h14.86a1.854,1.854,0,0,1,1.854,1.854V14.828a1.854,1.854,0,0,1-1.854,1.854H1.854A1.854,1.854,0,0,1,0,14.828V4.091Z" stroke="none" />
                    <path
                        d="M 6.528743743896484 1 L 6.528743743896484 5.090770721435547 L 1.000003814697266 5.090770721435547 L 1.000003814697266 14.82812023162842 C 1.000003814697266 15.29874992370605 1.382883071899414 15.681640625 1.853513717651367 15.681640625 L 20.38867378234863 15.681640625 C 20.85930442810059 15.681640625 21.24218368530273 15.29874992370605 21.24218368530273 14.82812023162842 L 21.24218368530273 1.853509902954102 C 21.24218368530273 1.382880210876465 20.85930442810059 1 20.38867378234863 1 L 6.528743743896484 1 M 5.528743743896484 0 L 20.38867378234863 0 C 21.41234397888184 0 22.24218368530273 0.8298501968383789 22.24218368530273 1.853509902954102 L 22.24218368530273 14.82812023162842 C 22.24218368530273 15.85179042816162 21.41234397888184 16.681640625 20.38867378234863 16.681640625 L 1.853513717651367 16.681640625 C 0.8298435211181641 16.681640625 3.814697265625e-06 15.85179042816162 3.814697265625e-06 14.82812023162842 L 3.814697265625e-06 4.090770721435547 L 5.528743743896484 4.090770721435547 L 5.528743743896484 0 Z"
                        stroke="none"
                        fill="#bd9e17"
                    />
                </g>
            </g>
        </g>
    </svg>
);
