import { ModelBase } from "@shoothill/core";
import { ReferrerOrganisation } from "Application/Models/Domain/ReferrerOrganisation";
import { Validator } from "Application/Validation";
import { Mentor, Student } from "Views/Students/Forms/StudentReports/Form/SessionReportModel";
import { makeObservable, observable } from "mobx";

export class AttendanceModel extends ModelBase<AttendanceModel> {
    public referrerOrganisationId: string | null = null;
    public mentorId: string | null = null;
    public studentId: string | null = null;
    public attendanceWeekStartDate: Date = new Date();
    public attendanceWeekEndDate: Date = new Date();
    public referrerOrganisation = observable<ReferrerOrganisation>([]);
    public students = observable<Student>([]);
    public mentors = observable<Mentor>([]);

    public studentSessionCount: number = 0;
    public studentCount: number = 0;
    public mentorCount: number = 0;
    public amSessions: number = 0;
    public pmSessions: number = 0;
    public attendance: number = 0;
    public absence: number = 0;
    public hours: number = 0;
    public pendingSessions: number = 0;

    public isShow: boolean = false;
    public isExport: boolean = false;
    constructor() {
        super();
        makeObservable(this, {
            referrerOrganisationId: observable,
            mentorId: observable,
            attendanceWeekStartDate: observable,
            attendanceWeekEndDate: observable,
            studentId: observable,

            studentSessionCount: observable,
            studentCount: observable,
            mentorCount: observable,
            amSessions: observable,
            pmSessions: observable,
            attendance: observable,
            absence: observable,
            hours: observable,
            pendingSessions: observable,

            isShow: observable,
            isExport: observable,
        });
    }
}

export class AttendanceModelValidator extends Validator<AttendanceModel> {
    constructor() {
        super();
    }
}
