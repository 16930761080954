import { action, computed, makeObservable, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommandAsync, IKeyState, RelayCommandAsync } from "Application";
import { ConfirmEmailModel, ConfirmEmailModelValidator } from "./ConfirmEmailModel";
import { AppUrls } from "AppUrls";
import { PostCreateNewPasswordEndpoint } from "../Endpoints/POSTCreateNewPasswordEndpoint";
import { PostResendEmailConfirmationEndpoint } from "../Endpoints/POSTResendEmailConfirmationEndpoint";

export class ConfirmEmailViewModel extends ViewModelBase<ConfirmEmailModel> {
    public apiClient = new APIClient();
    public passwordCreationSuccessful: boolean = false;
    public redirectMillisecondsValue: number = 0;
    public showRedirectMessage: boolean = false;

    public emailConfirmationRequired: boolean = false;

    constructor() {
        super(new ConfirmEmailModel());
        this.setValidator(new ConfirmEmailModelValidator());
        makeObservable(this, {
            passwordCreationSuccessful: observable,
            showRedirectMessage: observable,
            redirectMillisecondsValue: observable,
            emailConfirmationRequired: observable,
            redirectMilliseconds: computed,
            setPasswordCreationSuccessful: action,
            setRedirectCountDown: action,
            setShowRedirectMessage: action,
        });
    }

    public setPasswordCreationSuccessful = (value: boolean) => {
        this.passwordCreationSuccessful = value;
    };

    public setRedirectCountDown = (value: number) => {
        this.redirectMillisecondsValue = value;
    };

    public setShowRedirectMessage = (value: boolean) => {
        this.showRedirectMessage = value;
    };

    public setEmailConfirmationRequired = (value: boolean) => {
        this.emailConfirmationRequired = value;
    };

    public resetRedirectCountDown = () => this.setRedirectCountDown(5000);

    public get redirectMilliseconds() {
        return this.redirectMillisecondsValue;
    }

    //region commands

    public updatePasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("password", value, keyState);
    });

    public updateConfirmPasswordCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("confirmPassword", value, keyState);
    });

    public updateConfirmEmailCommand: ICommandAsync = new RelayCommandAsync(async (value: string, keyState: IKeyState) => {
        await this.updateField("confirmEmail", value, keyState);
    });

    public createPasswordCommand: ICommandAsync = new RelayCommandAsync(async () => {
        if (this.isModelValid()) {
            const registerEndpoint = new PostCreateNewPasswordEndpoint(this);
            await this.apiClient.sendAsync(registerEndpoint, this.model);

            if (this.apiClient.IsRequestSuccessful) {
                this.setPasswordCreationSuccessful(true);
                this.redirectToLoginPage(3000);
            } else {
                this.setPasswordCreationSuccessful(false);
            }
        }
    });

    public resendEmailConfirmationCommand: ICommandAsync = new RelayCommandAsync(async () => {
        if (this.isFieldValid("confirmEmail")) {
            const endpoint = new PostResendEmailConfirmationEndpoint(this);
            await this.apiClient.sendAsync(endpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                this.setEmailConfirmationRequired(true);
            } else {
                this.setEmailConfirmationRequired(false);
            }
        }
    });

    public redirectToLoginPage(ms: number) {
        this.setRedirectCountDown(ms);
        this.setShowRedirectMessage(true);

        const timerCount = setInterval(() => this.setRedirectCountDown(this.redirectMillisecondsValue - 1000), 1000);

        setTimeout(() => {
            this.resetRedirectCountDown();
            this.setShowRedirectMessage(false);
            clearInterval(timerCount);
            // redirect to login screen
            this.history.replace(AppUrls.Client.Account.Login);
        }, ms);
    }

    //end region commands

    //region helpers
    private async updateField(fieldName: keyof FieldType<ConfirmEmailModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
    }

    public get redirectToLoginMessage() {
        return `Your password was created. You're being redirected to the login page in ${this.redirectMilliseconds / 1000} second${this.redirectMilliseconds == 1000 ? "" : "s"}.`;
    }

    public get showCreatePasswordForm() {
        return !this.showRedirectMessage;
    }

    public get showCheckEmailMessage() {
        let retVal = false;

        if (this.emailConfirmationRequired) {
            retVal = true;
        }

        return retVal;
    }

    //end region helpers
}
