import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

export class AddStudentItemViewModel extends ModelBase<AddStudentItemViewModel> {
    public id: Guid = "";
    public startDate?: Date | string;
    public rotaId: Guid = null;
    public mentorId: string = "";
    public studentSessionId: Guid = "";
    public pickUpAddressId: string = "";
    public dropOffAddressId: string = "";

    constructor() {
        super();
        makeObservable(this, {
            startDate: observable,
            rotaId: observable,
            mentorId: observable,
            studentSessionId: observable,
            pickUpAddressId: observable,
            dropOffAddressId: observable,
        });
    }
}
