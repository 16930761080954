import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ApplicationError, Box, Button, EditDate, Label, ThemedLoader } from "Components";
import styled from "@emotion/styled";
import { AttendanceViewModel } from "./AttendanceViewModel";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import { setPageTitle } from "Application";
import { AttendanceFilterView } from "./AttendanceFilterView";
import { PageContainer, PageHeader, PageRoot } from "Styles/PageStyles";

const MainContain = styled.div`
    position: relative;
    border-bottom: 1px dashed #dcdcdc;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .add-mentor-btn {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px 60px;
        max-width: 309px;
        width: 100%;
        border-radius: 5px;
        @media only screen and (max-width: 1100px) {
            position: static;
            margin-bottom: 15px;
        }
    }
    .last-action {
        p {
            line-height: normal;
        }
    }
`;

const CreateBox = styled(Box)`
    .isButtonDisable {
        cursor: default;
        color: #495057;
        background-color: #e9ecef;
    }
`;

const AttendanceModelOuter = styled.div`
    width: 100%;
`;

const AttendanceModel = styled.div`
    width: 100%;
    overflow: auto;
`;

const AttendanceFormRow = styled.div`
    .form-row {
        @media only screen and (max-width: 1200px) {
            grid-template-columns: 2fr 2fr 2fr;
        }
        @media only screen and (max-width: 767px) {
            grid-template-columns: auto;
        }
    }
`;

export const AttendanceView: React.FC = observer(() => {
    const [viewModel] = useState(() => new AttendanceViewModel());

    useEffect(() => {
        setPageTitle("Attendance");
    }, []);

    const renderFilterAttendanceForm = () => {
        return (
            <MainContain style={{ marginBottom: "6rem" }}>
                <AttendanceFormRow>
                    <Box className={"form-row"} display={"grid"} gridTemplateColumns={"2fr 2fr 2fr 2fr"} mb={"15px"} style={{ gap: "30px" }}>
                        <ThemedEditSelect
                            displayName="Referrer organisation"
                            options={viewModel.referrerOrganization}
                            command={viewModel.updateFilterReferrerOrganisationCommand}
                            value={() => viewModel.getValue("referrerOrganisationId")}
                        />
                        <ThemedEditSelect
                            displayName="Choose a mentor"
                            options={viewModel.mentorList}
                            command={viewModel.updateFiltermentorIdCommand}
                            value={() => viewModel.getValue("mentorId")}
                        />
                        <ThemedEditSelect
                            displayName="Choose a Student"
                            options={viewModel.studentList}
                            command={viewModel.updateFilterStudentIdCommand}
                            value={() => viewModel.getValue("studentId")}
                        />
                    </Box>
                </AttendanceFormRow>
                <AttendanceFormRow>
                    <Box className={"form-row"} display={"grid"} gridTemplateColumns={"2fr 2fr 2fr 2fr"} style={{ gap: "30px" }} alignItems={"flex-end"}>
                        <EditDate
                            displayName={"Attendance Start Date"}
                            includeTime={false}
                            value={() => viewModel.getValue("attendanceWeekStartDate")}
                            command={viewModel.updateAttendanceWeekStartDateCommand}
                        />
                        <EditDate
                            displayName={"Attendance End Date"}
                            includeTime={false}
                            value={() => viewModel.getValue("attendanceWeekEndDate")}
                            command={viewModel.updateAttendanceWeekEndDateCommand}
                        />
                        <Box>
                            <Button
                                command={viewModel.runReportCommand}
                                displayName={"RUN REPORT"}
                                paletteColor="primary"
                                className="add-another"
                                style={{ padding: "11px 20px", borderRadius: "5px", minWidth: "140px" }}
                            />
                        </Box>
                    </Box>
                </AttendanceFormRow>
            </MainContain>
        );
    };

    return (
        <PageRoot style={{ paddingBottom: "10rem" }}>
            <PageHeader>
                <Label fontStyle="h1" color="black">
                    Attendance
                </Label>
            </PageHeader>
            <PageContainer padding="15px 0px">
                {(() => {
                    switch (true) {
                        case viewModel.apiClient.IsBusy:
                            return <ThemedLoader isOpen delayBeforeOpen={1000} containerStyles={{ height: "calc(100vh - 60px)", width: "calc(100vw - 80px)" }} />;

                        case viewModel.apiClient.IsSubmitted && viewModel.apiClient.HaveValidationMessage:
                            return (
                                <Box className="error-scrollable-section">
                                    <Box>
                                        <ApplicationError additionalMessage={viewModel.apiClient.ValidationMessage} resetCommand={viewModel.resetApiClientErrorCommand} />
                                    </Box>
                                </Box>
                            );

                        default:
                            return (
                                <>
                                    <AttendanceModelOuter>
                                        {renderFilterAttendanceForm()}
                                        {viewModel.canDisplayReport && (
                                            <>
                                                <AttendanceModel>
                                                    <AttendanceFilterView viewModel={viewModel} />
                                                </AttendanceModel>
                                                <Box py={"30px"} display={"flex"} alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
                                                    <CreateBox>
                                                        <Button
                                                            command={viewModel.runExportReportCommand}
                                                            className={viewModel.showApplicationBusyValue ? "isButtonDisable" : ""}
                                                            displayName={"EXPORT REPORT"}
                                                            paletteColor="primary"
                                                            style={{ padding: "11px 20px", borderRadius: "5px", minWidth: "140px", marginBottom: "6rem" }}
                                                        />
                                                    </CreateBox>
                                                </Box>
                                            </>
                                        )}
                                    </AttendanceModelOuter>
                                </>
                            );
                    }
                })()}
            </PageContainer>
        </PageRoot>
    );
});
