import { action } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { WeeklyRotaSessionReportViewModel } from "../Form/WeeklyRotaSessionReportViewModel";
import { SessionDocument, WeeklyRotaSessionReportModel } from "../Form/WeeklyRotaSessionReportModel";

class Request {
    public id: string | null = null;
    public studentId: string | null = null;
    public documentCategoryId: string | null = null;
}

class Response {
    public id: string | null = null;
    public studentSessionId: string | null = null;
    public documentCategoryId: string = "";
    public fileName: string = "";
    public fileSize: number = 0;
    public fileUrl: string = "";
    public createdDate: Date | null = null;
    public createdByUserId: string = "";
}

export class POSTSaveWeeklyRotaSessionDocumentsEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: WeeklyRotaSessionReportViewModel;

    constructor(viewModel: WeeklyRotaSessionReportViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Student.StudentSessions.PostSaveStudentSessionDocument);
        this.viewModel = viewModel;
        this.AllowFileUploads();
    }

    public async HandleRequestAsync(model: WeeklyRotaSessionReportModel): Promise<any> {
        let request: Request = new Request();
        request.studentId = model.studentId;
        request.documentCategoryId = model.documentCategoryId;
        const formData = new FormData();
        formData.append("data", JSON.stringify(request));
        for (const file of this.viewModel.filesViewModel.model?.files!) {
            formData.append("documentFile", file.file!);
        }
        return Promise.resolve(formData);
    }

    @action
    public async HandleResponseAsync(response: any): Promise<any> {
        const studentDocumentItem: SessionDocument[] = [];
        const model = new SessionDocument();
        model.fromResponse(response);
        studentDocumentItem.push(model);
        this.viewModel.model.sessionDocument.push(model);
    }
}
