import { SVGProps } from "react";

export const StudentSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg id="Group_11125" data-name="Group 11125" xmlns="http://www.w3.org/2000/svg" width="42.131" height="113.293" viewBox="0 0 42.131 113.293">
        <g id="Group_10267" data-name="Group 10267" transform="translate(0 0)">
            <path
                id="Path_8835"
                data-name="Path 8835"
                d="M273.806,124.537h-13.78a14.179,14.179,0,0,0-14.187,14.187v29.6a7.947,7.947,0,0,0,7.139,7.91l1.835,32.839a3.769,3.769,0,0,0,3.739,3.559h16.7a3.753,3.753,0,0,0,3.739-3.559l1.835-32.839a7.962,7.962,0,0,0,7.139-7.91V138.746a14.165,14.165,0,0,0-14.164-14.21Z"
                transform="translate(-245.839 -99.336)"
                fill="#f39200"
            />
            <path
                id="Path_8836"
                data-name="Path 8836"
                d="M316.467,11.422A11.4,11.4,0,1,1,305.068,0a11.41,11.41,0,0,1,11.4,11.422"
                transform="translate(-283.99 0)"
                fill="#f39200"
            />
        </g>
    </svg>
);
