import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { MentorDetailModel } from "../MentorDetailModel";
import { MentorDetailViewModel } from "../MentorDetailViewModel";

interface mentorAvailability {
    monAM: boolean;
    monPM: boolean;
    tuesAM: boolean;
    tuesPM: boolean;
    wedsAM: boolean;
    wedsPM: boolean;
    thurAM: boolean;
    thurPM: boolean;
    friAM: boolean;
    friPM: boolean;
}
class Response {
    public mentor: any = {};
    public mentorAvailability: mentorAvailability = {
        monAM: false,
        monPM: false,
        tuesAM: false,
        tuesPM: false,
        wedsAM: false,
        wedsPM: false,
        thurAM: false,
        thurPM: false,
        friAM: false,
        friPM: false,
    };
}

export class GETMentorsByIdEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: MentorDetailViewModel;
    constructor(id: string, viewModel: MentorDetailViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Mentor.GetMentorById.replace(":id", id));
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        this.viewModel.model.id = response.mentor.id;
        this.viewModel.model.firstName = response.mentor.firstName + " " + response.mentor.lastName;
        this.viewModel.model.email = response.mentor.email;
        this.viewModel.model.phone = response.mentor.phone;
        this.viewModel.model.mentorAvailability.monAM = response.mentorAvailability.monAM;
        this.viewModel.model.mentorAvailability.monPM = response.mentorAvailability.monPM;
        this.viewModel.model.mentorAvailability.tuesAM = response.mentorAvailability.tuesAM;
        this.viewModel.model.mentorAvailability.tuesPM = response.mentorAvailability.tuesPM;
        this.viewModel.model.mentorAvailability.wedsAM = response.mentorAvailability.wedsAM;
        this.viewModel.model.mentorAvailability.wedsPM = response.mentorAvailability.wedsPM;
        this.viewModel.model.mentorAvailability.thurAM = response.mentorAvailability.thurAM;
        this.viewModel.model.mentorAvailability.thurPM = response.mentorAvailability.thurPM;
        this.viewModel.model.mentorAvailability.friAM = response.mentorAvailability.friAM;
        this.viewModel.model.mentorAvailability.friPM = response.mentorAvailability.friPM;
        return Promise.resolve();
    }
}
