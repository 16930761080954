import { singleton } from "tsyringe";
import { action, makeObservable, observable } from "mobx";

@singleton()
export class DomainStore {
    public isSideBarClosed: boolean = true;

    constructor() {
        makeObservable(this, {
            isSideBarClosed: observable,
            setSideBarClosed: action,
        });
        (window as any).DomainStore = this;
    }

    public setSideBarClosed = (isOpen: boolean) => {
        this.isSideBarClosed = isOpen;
    };
}
