import * as React from "react";
import { SVGProps } from "react";

export const WellbeingSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.626" height="15" viewBox="0 0 20.626 15">
        <path
            id="tick"
            d="M6.982,14.746.264,8.368a.824.824,0,0,1,0-1.208l1.895-1.8a.938.938,0,0,1,1.279,0L7.623,9.318,17.188.247A.93.93,0,0,1,17.828,0a.891.891,0,0,1,.635.252l1.9,1.807a.827.827,0,0,1,0,1.212L8.266,14.746a.938.938,0,0,1-1.283,0Z"
            fill="#bd9e17"
        />
    </svg>
);
