import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ConfirmEmailModel extends ModelBase<ConfirmEmailModel> {
    public userId: string = "";
    public password: string = "";
    public confirmPassword: string = "";
    public token: string = "";

    public confirmEmail: string = "";
    public resendEmailConfirmation: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            userId: observable,
            password: observable,
            confirmPassword: observable,
            confirmEmail: observable,
        });
    }
}

export class ConfirmEmailModelValidator extends Validator<ConfirmEmailModel> {
    constructor() {
        super();
        this.ruleFor("password")
            .must({
                predicate: (password, model) => password.length >= 6 && passwordPattern.test(password),
                message: (value, model) => "Passwords must be least 8 characters long and must contain at least one number and one special character and one uppercase character.",
            })
            .maxLength(100)
            .withMessage("Please enter a password");
        this.ruleFor("confirmPassword")
            .must({ predicate: (confirmPassword, model) => confirmPassword === model.password, message: (value, model) => "Passwords must match" })
            .withMessage("Passwords must match")
            .when((model) => model.password.length > 0);
    }
}

// Regex to check if a string
// contains uppercase, lowercase
// special character & numeric value
const passwordPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$");
const passwordPatternIncludingUpperCase = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$");
const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
