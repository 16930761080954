//dependencies
import { makeObservable, observable } from "mobx";
import moment from "moment";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";

//components

import { APIClient, ICommand, RelayCommand } from "Application";
import { MentorWeeklyRotaModel, MentorWeeklyRotaModelValidator } from "./MentorWeeklyRotaModel";
import { GETAllMentorEndpoint } from "./Endpoints/GETAllMentorEndpoint";
import { GETMentorsSessionReportsEndpoint } from "./Endpoints/GETMentorsSessionReportsEndpoint";
import { POSTStudentAbsenceEndpoint } from "./Endpoints/POSTStudentAbsenceEndpoint";
import { PUTMentorStudentAttended } from "./Endpoints/PUTMentorStudentAttended";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";
import { toast } from "react-hot-toast";
import { RemoveDetailsMentorWeeklyRotaEndPoint } from "./Endpoints/RemoveDetailsMentorWeeklyRotaEndPoint";

export class MentorWeeklyRotaViewModel extends ViewModelBase<MentorWeeklyRotaModel> {
    public apiClient = new APIClient();
    public studentAbsence: boolean = false;
    public showOtherText: boolean = false;
    public lookupStore = container.resolve(LookupStore);
    constructor() {
        super(new MentorWeeklyRotaModel());
        this.setValidator(new MentorWeeklyRotaModelValidator());
        makeObservable(this, { studentAbsence: observable, showOtherText: observable });
        this.apiClient.sendAsync(new GETAllMentorEndpoint(this));
        this.model.startDate = moment(new Date()).startOf("isoWeek").toDate();
    }

    public showStudentAbsenceModalCommand: ICommand = new RelayCommand((student: any, studentId: string) => {
        this.model.selectedStudentAbsense = student;
        if (student) {
            this.model.sessionDate = student!.sessionDate!;
            this.model.sessionStartTime = student!.sessionStartTime!;
            this.model.sessionEndTime = student!.sessionEndTime!;
        }
        this.model.studentId = studentId!;
        this.studentAbsence = !this.studentAbsence;
        this.showOtherText = false;
        this.model.reason = "";
        this.model.moreDetail = "";
    });

    public updateStudentAttendedCommand: ICommand = new RelayCommand(async (student: any) => {
        this.model.selectedStudentAbsense = student;
        let _ = await this.apiClient.sendAsync(new PUTMentorStudentAttended(), this);
        if (this.apiClient.IsRequestSuccessful) {
            this.loadMentorWeeklyRotaByDate();
        }
    });

    public navigateToWeeklyRotaReportsCommand: ICommand = new RelayCommand(async (studentId: string, mentorId: string, id: string) => {
        this.history.push(AppUrls.Client.WeeklyRota.AddWeeklyRotaReport.replace(":studentId", studentId).replace(":mentorId", mentorId).replace(":id", id!));
    });

    public navigateToDetailsWeeklyRotaReportsCommand: ICommand = new RelayCommand(async (id: string) => {
        this.history.push(AppUrls.Client.WeeklyRota.DetailsWeeklyRotaReport.replace(":reportId", id!));
    });

    public navigateToStudentDetailsCommand: ICommand = new RelayCommand(async (id: string) => {
        this.history.push(AppUrls.Client.Students.Student.StudentDetail.Edit.replace(":id", id!));
    });

    public get getSessionStatus() {
        return this.lookupStore.sessionStatusKeyValuePairs;
    }

    public getSessionStatusById = (id: string) => {
        if (this.getSessionStatus.length > 0) {
            const sessionStatus = this.getSessionStatus.find((m) => m.key === id);
            return sessionStatus ? sessionStatus.text : "";
        }
        return "";
    };

    public copyAddressToClipboardCommand = new RelayCommand((text: string) => {
        navigator.clipboard.writeText(text);
        toast.success("Copied!", {
            style: {
                borderRadius: "1px",
            },
        });
    });

    public get getAbsenceReasons() {
        return this.lookupStore.absenceTypeKeyValuePairs;
    }

    public updateAbsenceDateCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("sessionDate", value);
    });

    public updateAbsenceStartTimeCommand: ICommand = new RelayCommand((value: string) => {
        const sessionStartTime = moment(new Date(value));
        this.updateField("sessionStartTime", sessionStartTime);
    });

    public updateAbsenceEndTimeCommand: ICommand = new RelayCommand((value: string) => {
        const sessionEndTime = moment(new Date(value));
        this.updateField("sessionEndTime", sessionEndTime);
    });

    public updateReasonAbsenceCommand: ICommand = new RelayCommand((kp: KeyValuePair) => {
        if (kp.text === "Other") {
            this.showOtherText = true;
        } else {
            this.showOtherText = false;
        }
        this.updateField("reason", kp.key);
    });

    public updateStudentMoreDetailsCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("moreDetail", value);
    });

    private async updateField(fieldName: keyof FieldType<MentorWeeklyRotaModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateMentorWeeklyDateCommand = new RelayCommand((value: Date) => {
        this.updateField("startDate", value.toISOString());
        this.loadMentorWeeklyRotaByDate();
    });

    public updateNextDateCommand = new RelayCommand(() => {
        this.updateField("startDate", moment(this.model.startDate).add(1, "week").startOf("isoWeek").toISOString());
        this.loadMentorWeeklyRotaByDate();
    });

    public updatePreviousDateCommand = new RelayCommand(() => {
        this.updateField("startDate", moment(this.model.startDate).add(-1, "week").startOf("isoWeek").toISOString());
        this.loadMentorWeeklyRotaByDate();
    });

    public updateShowAllMentorsCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("showSelectMentor", value);
    });

    public addStudentAbsenceModalCommand = new RelayCommand(async () => {
        if (this.isModelValid()) {
            let _ = await this.apiClient.sendAsync(new POSTStudentAbsenceEndpoint(), this);
            if (this.apiClient.IsRequestSuccessful) {
                this.studentAbsence = false;
                this.loadMentorWeeklyRotaByDate();
                this.apiClient.sendAsync(new GETAllMentorEndpoint(this));
                this.apiClient.sendAsync(new GETMentorsSessionReportsEndpoint(this, this.model.startDate));
            }
        }
    });

    public get mentorOptions(): KeyValuePair[] {
        return this.model.mentors.map((model: any) => {
            return { key: model.id, text: model.firstName + " " + model.lastName } as KeyValuePair;
        });
    }

    public async loadMentorWeeklyRotaByDate(): Promise<void> {
        let _ = this.apiClient.sendAsync(new GETMentorsSessionReportsEndpoint(this, this.model.startDate));
    }

    public async removeDetails(studentSessionId: Guid): Promise<void> {
        let _ = await this.apiClient.sendAsync(new RemoveDetailsMentorWeeklyRotaEndPoint(studentSessionId!), this);
        if (this.apiClient.IsRequestSuccessful) {
            this.loadMentorWeeklyRotaByDate();
        }
    }
}
