//dependencies
import { runInAction } from "mobx";
import { AppUrls } from "AppUrls";

//components
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { MentorWeeklyRotaViewModel } from "../MentorWeeklyRotaViewModel";

export class GETMentorsSessionReportsEndpoint extends EndpointWithoutRequest<any> {
    private readonly viewModel: MentorWeeklyRotaViewModel;

    constructor(viewModel: MentorWeeklyRotaViewModel, startDate: any) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.MentorRole.GetMentorsSessionReports.replace(":startdate", startDate));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        runInAction(() => {
            this.viewModel.model.mentorsWeeklyRota = response.mentorAvailability;
        });
    }
}
