import { runInAction } from "mobx";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { WeeklyRotaDetailsViewModel } from "../../WeeklyRotaDetailViewModel";
export class MentorStudentRotaResponse {
    public studentId: Guid = "";
    public studentName: string = "";
    public mentorId: Guid = "";
    public mentorName: string = "";
    public sessionStatusId: string = "";
    public studentSessionId: string = "";
    public sessionStatusName: string = "";
    public sessionDate: string = "";
    public sessionStartTime: string = "";
    public sessionEndTime: string = "";
    public didAttend: boolean = false;
    public reportIncident: boolean = false;

    public pickupAddressTitle: string = "";
    public pickupAddressLine1: string = "";
    public pickupAddressLine2: string = "";
    public pickupCity: string = "";
    public pickupPostcode: string = "";

    public dropAddAddressTitle: string = "";
    public dropAddAddressLine1: string = "";
    public dropAddAddressLine2: string = "";
    public dropAddCity: string = "";
    public dropAddPostcode: string = "";

    public id: string = "";
}

export class GETMentorStudentRotaByIdEndpoint extends EndpointWithoutRequest<any> {
    private readonly viewModel: WeeklyRotaDetailsViewModel;
    constructor(id: string, viewModel: WeeklyRotaDetailsViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.WeeklyRota.GetMentorStudentRotaById.replace(":mentorStudentRotaId", id));
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: MentorStudentRotaResponse): Promise<any> {
        runInAction(() => {
            if (response) {
                this.viewModel.model.studentRota.fromResponse(response);
            }
        });
    }
}
