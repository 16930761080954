import { FieldType, KeyValuePair, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { computed, makeObservable, observable } from "mobx";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";
import moment from "moment";
import { SessionDocument, WeeklyRotaSessionReportModel, WeeklyRotaSessionReportModelValidator } from "./WeeklyRotaSessionReportModel";
import { FilesViewModel } from "Views/Students/Forms/StudentReports/Files/FilesViewModel";
import { StudentAbsenceViewModel } from "Views/Students/Forms/StudentReports/StudentAbsence/StudentAbsenceViewModel";
import { GETWeeklyRotaSessionReportEndpoint } from "../Endpoints/GETWeeklyRotaSessionReportEndpoint";
import { POSTSaveWeeklyRotaSessionDocumentsEndpoint } from "../Endpoints/POSTSaveWeeklyRotaSessionDocumentsEndpoint";
import { POSTSaveWeeklyRotaSessionEndpoint } from "../Endpoints/POSTSaveWeeklyRotaSessionEndpoint";
import { GETWeeklyRotaSessionReportByIdEndpoint } from "../Endpoints/GETWeeklyRotaSessionReportByIdEndpoint";
import { AccountStore } from "Stores/Domain/AccountStores";
import { POSTSaveActivityOptionsEndpoint } from "../Endpoints/POSTSaveActivityOptionsEndpoint";
import axios, * as Axios from "axios";
export class WeeklyRotaSessionReportViewModel extends ViewModelBase<WeeklyRotaSessionReportModel> {
    public apiClient = new APIClient();
    public lookupStore = container.resolve(LookupStore);
    public filesViewModel = new FilesViewModel();
    public showSessionDocPhoto: boolean = false;
    public isEditForm: boolean = false;
    public studentAbsenceViewModel: StudentAbsenceViewModel;
    public accountStore = container.resolve(AccountStore);
    public studentId: string = "";
    public showError: boolean = false;
    constructor(id: string | undefined, mentorId: string | undefined, reportId: string | undefined, studentId: string | undefined) {
        super(new WeeklyRotaSessionReportModel());
        this.model.id = id!;
        this.model.mentorId = mentorId!;
        this.model.filesViewModel = this.filesViewModel;
        this.model.studentId = studentId!;
        this.setValidator(new WeeklyRotaSessionReportModelValidator());
        this.studentAbsenceViewModel = new StudentAbsenceViewModel(this.model.studentAbsence);
        makeObservable(this, {
            showSessionDocPhoto: observable,
            filesViewModel: observable,
            isEditForm: observable,
            studentId: observable,
            showError: observable,
            displayWellbeing: computed,
            displayInvolvement: computed,
            getActivity: computed,
        });
        if (studentId) {
            let _ = this.apiClient.sendAsync(new GETWeeklyRotaSessionReportEndpoint(studentId!, this));
        }
        if (reportId) {
            let _ = this.apiClient.sendAsync(new GETWeeklyRotaSessionReportByIdEndpoint(reportId!, this));
        }
    }

    public showEditFormCommand = new RelayCommand(() => {
        if (this.isEditForm) {
            this.isEditForm = false;
        } else {
            this.isEditForm = true;
        }
    });

    public navigateToBackCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Admin.Mentor.WeeklyRota);
    });

    public updateSessionDateCommand = new RelayCommand((value: Date) => {
        this.updateField("sessionDate", value);
    });

    public updateSessionStartTimeCommand = new RelayCommand((value: string) => {
        const sessionStartTime = moment(new Date(value));
        this.updateField("sessionStartTime", sessionStartTime);
    });

    public updateSessionEndTimeCommand = new RelayCommand((value: string) => {
        const sessionEndTime = moment(new Date(value));
        this.updateField("sessionEndTime", sessionEndTime);
    });

    public get mentorWithoutMatchingEmail(): boolean {
        const mentorWithoutMatchingEmail = this.model.sessionMentor.email !== this.accountStore.DisplayName;
        return mentorWithoutMatchingEmail;
    }

    public updateSessionStatusCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("attendance", kvp.key);
        if (kvp.key === "0") {
            this.updateField("activityTypeId", null);
            this.updateField("sessionPlan", "");
            this.updateField("subTargets", "");
            this.updateField("mentoring", "");
            this.updateField("wellbeingId", null);
            this.updateField("involvementId", null);
            this.studentAbsenceViewModel.updateIsAttendedCommand.execute(false);
        } else {
            this.studentAbsenceViewModel.reUpdateAbsenseTypeIdCommand.execute(null);
            this.studentAbsenceViewModel.updateReasonCommand.execute("");
            this.studentAbsenceViewModel.updateIsAttendedCommand.execute(true);
        }
    });

    public updateActivityTypeCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("activityTypeId", kvp.key);
        if (kvp) {
            this.updateField("activityOptionName", kvp);
        } else {
            this.updateField("activityOptionName", null);
        }

        if (!kvp.key) {
            let _ = this.apiClient.sendAsync(new POSTSaveActivityOptionsEndpoint(this), this.model);
        }
    });

    public updateDocumentCategoryCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("documentCategoryId", kvp.key);
    });

    public updateReportIncidentCommand = new RelayCommand((value: boolean) => {
        this.updateField("reportIncident", value);
    });

    public updateSessionPlanCommand = new RelayCommand((value: string) => {
        this.updateField("sessionPlan", value);
    });

    public updateSubTargetsCommand = new RelayCommand((value: string) => {
        this.updateField("subTargets", value);
    });

    public updateMentoringCommand = new RelayCommand((value: string) => {
        this.updateField("mentoring", value);
    });

    public updateMentorCommand = new RelayCommand((value: KeyValuePair) => {
        this.updateField("mentorId", value.key);
    });

    public updateWellbeingCommand = new RelayCommand((value: string) => {
        this.setValue("wellbeingId", value);
    });

    public updateInvolvementCommand = new RelayCommand((value: string) => {
        this.setValue("involvementId", value);
    });

    public checkSelectStudentTargetCommand = new RelayCommand((id: string) => {
        if (id) {
            const index = this.model.studentTargetIds.indexOf(id);
            if (index === -1) {
                // Add the id if it's not already selected
                this.model.studentTargetIds.push(id);
            } else {
                // Remove the id if it's already selected
                this.model.studentTargetIds.splice(index, 1);
            }
        }
    });

    public get displayWellbeing() {
        const displayItem = this.model.wellbeing.find((item) => item.id === this.model.wellbeingId);
        return displayItem;
    }

    public get displayInvolvement() {
        const displayItem = this.model.wellbeing.find((item) => item.id === this.model.involvementId);
        return displayItem;
    }

    public get getActivity() {
        return this.lookupStore.activityTypeKeyValuePairs;
    }

    public get getDocumentCategory() {
        return this.lookupStore.documentCategoryKeyValuePairs;
    }

    public get getSessionStatus() {
        return this.lookupStore.sessionStatusKeyValuePairs;
    }

    public get showExport() {
        return isEmptyOrWhitespace(this.model.id) ? true : false;
    }

    public get displayName() {
        return this.model.sessionStatusId ? "UPDATE" : "CREATE";
    }

    public get showUploadButton(): boolean {
        this.model.filesViewModel = this.filesViewModel;
        return false;
    }

    public getSessionStatusById = (id: string) => {
        if (this.getSessionStatus.length > 0) {
            const sessionStatus = this.getSessionStatus.find((m) => m.key === id);
            return sessionStatus ? sessionStatus.text : "";
        }
        return "";
    };

    public getSessionActivityType = (id: string) => {
        if (this.getActivity.length > 0) {
            const getActivity = this.getActivity.find((m) => m.key === id);
            return getActivity ? getActivity.text : "";
        }
        return "";
    };

    public get getReason() {
        return this.lookupStore.absenceTypeKeyValuePairs;
    }

    public getReasonType = (id: string) => {
        if (this.getReason.length > 0) {
            const getReason = this.getReason.find((m) => m.key === id);
            return getReason ? getReason.text : "";
        }
        return "";
    };

    public get mentorKeyValuePairs(): KeyValuePair[] {
        return this.model.mentor.map((model: any) => {
            return { key: model.id, text: model.firstName + " " + model.lastName } as KeyValuePair;
        });
    }

    public get getAttendance() {
        return [
            {
                key: "0",
                text: "Absent",
            },
            {
                key: "1",
                text: "Attended",
            },
        ] as KeyValuePair[];
    }

    public get renderGetAttendance(): KeyValuePair[] {
        const studentAttendance = this.getAttendance.filter((m) => m.key === this.model.attendance);
        const attended = this.getAttendance.filter((m) => m.key === "1");
        return studentAttendance.length > 0 ? studentAttendance : attended;
    }

    public get renderForm() {
        const studentAttendance = this.getAttendance.find((m) => m.key === this.model.attendance);
        return studentAttendance?.text !== "Absent";
    }

    public get displayStatus() {
        const studentAttendance = this.getAttendance.find((m) => m.key === this.model.attendance);
        return studentAttendance?.text === "Attended" ? "#27E6A3" : studentAttendance?.text === "Absent" ? "#E6054E" : "";
    }

    public isDisabled = (): boolean => {
        return !this.saveStudentSessionCommand.canExecute();
    };

    public getDocumentCategoryName = (id: string) => {
        const studentAttendance = this.getDocumentCategory.find((m) => m.key === id);
        return studentAttendance?.text;
    };

    public get getsessionMentorName() {
        const mentorName = this.mentorKeyValuePairs.find((m) => m.key === this.model.mentorId);
        return mentorName?.text;
    }

    public showHideTextCommand = new RelayCommand((id: string) => {
        const studentTarget = this.model.studentTargets.find((item) => item.id === id);
        if (studentTarget) studentTarget.showText = !studentTarget.showText;
    });

    public showSessionDocPhotoCommand = new RelayCommand(() => {
        if (this.showSessionDocPhoto) {
            this.showSessionDocPhoto = false;
            this.filesViewModel.model.files.clear();
        } else {
            this.showSessionDocPhoto = true;
        }
    });

    public cancelCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Students.Student.StudentReports.Edit.replace(":id", this.model.id!));
    });

    public showErrorsCommand = new RelayCommand(() => {
        if (this.showError) {
            this.showError = false;
        } else {
            this.showError = true;
        }
    });

    public saveStudentSessionCommand = new RelayCommand(async () => {
        if (this.canSubmitForm) {
            let _ = await this.apiClient.sendAsync(new POSTSaveWeeklyRotaSessionEndpoint(this), this.model);
            if (this.apiClient.IsRequestSuccessful) {
                this.history.push(AppUrls.Client.Admin.Mentor.WeeklyRota);
                this.isEditForm = false;
            } else {
                this.showError = true;
            }
        }
    });

    public uploadStudentReportCommand: ICommand = new RelayCommand(async () => {
        if (this.canSubmitSessionDoc && this.filesViewModel.model.files.length > 0) {
            let _ = await this.apiClient.sendAsync(new POSTSaveWeeklyRotaSessionDocumentsEndpoint(this), this.model);
            if (this.apiClient.IsRequestSuccessful) {
                this.showSessionDocPhoto = false;
                this.filesViewModel = new FilesViewModel();
            }
        }
    });

    public removeStudentDocumentsCommand: ICommand = new RelayCommand((studentDocument: SessionDocument) => {
        const studentDocumentToRemove = this.model.sessionDocument.find((m) => m.KEY === studentDocument.KEY);
        if (studentDocumentToRemove) {
            this.model.sessionDocument.remove(studentDocumentToRemove);
        }
    });

    public downloadResourceDocumentCommand: ICommand = new RelayCommand(async (id: string, fileName?: string) => {
        try {
            const config: Axios.AxiosRequestConfig = {};
            config.responseType = "blob";
            config.headers = { Authorization: `Bearer ${sessionStorage.getItem(".auth")}` };
            await axios
                .get(AppUrls.Server.Student.StudentSessions.GetStudentDocumentForDownloadById.replace(":id", id), config)
                .then((response: any) => {
                    const filename = fileName!;
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            return { error };
        }
    });

    public get canSubmitSessionDoc(): boolean {
        const isFormValid = this.isFieldValid("documentCategoryId");
        return isFormValid ? true : false;
    }

    public get canSubmitForm(): boolean {
        const isFormValid = this.isModelValid();
        const isStudentAbsenceValid = this.studentAbsenceViewModel.isModelValid();
        return isFormValid && isStudentAbsenceValid;
    }

    private async updateField(fieldName: keyof FieldType<WeeklyRotaSessionReportModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
