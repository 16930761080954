import { SVGProps } from "react";
export const MentorsSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="98.324" height="113.293" viewBox="0 0 98.324 113.293">
        <g id="Group_11139" data-name="Group 11139" transform="translate(0)">
            <g id="Group_11137" data-name="Group 11137" transform="translate(0 0)">
                <g id="Group_11134" data-name="Group 11134" transform="translate(0 0)">
                    <g id="Group_10267" data-name="Group 10267" transform="translate(0 0)">
                        <path
                            id="Path_8835"
                            data-name="Path 8835"
                            d="M273.806,124.537h-13.78a14.179,14.179,0,0,0-14.187,14.187v29.6a7.947,7.947,0,0,0,7.139,7.91l1.835,32.839a3.769,3.769,0,0,0,3.739,3.558h16.7a3.753,3.753,0,0,0,3.739-3.558l1.835-32.839a7.962,7.962,0,0,0,7.139-7.91V138.746a14.164,14.164,0,0,0-14.164-14.21Z"
                            transform="translate(-245.839 -99.336)"
                            fill="#c15db0"
                        />
                        <path
                            id="Path_8836"
                            data-name="Path 8836"
                            d="M316.467,11.422A11.4,11.4,0,1,1,305.068,0a11.41,11.41,0,0,1,11.4,11.422"
                            transform="translate(-283.99 0)"
                            fill="#c15db0"
                        />
                    </g>
                </g>
                <g id="Group_11136" data-name="Group 11136" transform="translate(56.193 0)">
                    <g id="Group_10267-2" data-name="Group 10267" transform="translate(0 0)">
                        <path
                            id="Path_8835-2"
                            data-name="Path 8835"
                            d="M273.806,124.537h-13.78a14.179,14.179,0,0,0-14.187,14.187v29.6a7.947,7.947,0,0,0,7.139,7.91l1.835,32.839a3.769,3.769,0,0,0,3.739,3.558h16.7a3.753,3.753,0,0,0,3.739-3.558l1.835-32.839a7.962,7.962,0,0,0,7.139-7.91V138.746a14.164,14.164,0,0,0-14.164-14.21Z"
                            transform="translate(-245.839 -99.336)"
                            fill="#c15db0"
                        />
                        <path
                            id="Path_8836-2"
                            data-name="Path 8836"
                            d="M316.467,11.422A11.4,11.4,0,1,1,305.068,0a11.41,11.41,0,0,1,11.4,11.422"
                            transform="translate(-283.99 0)"
                            fill="#c15db0"
                        />
                    </g>
                </g>
            </g>
            <g id="Group_11138" data-name="Group 11138" transform="translate(30.066 40.43)">
                <g id="Group_11131" data-name="Group 11131">
                    <rect id="Rectangle_3498" data-name="Rectangle 3498" width="37.764" height="37.764" transform="translate(37.764 37.764) rotate(180)" fill="#fff" />
                    <path
                        id="sort"
                        d="M7.3,13.614.09,6.987a.2.2,0,0,1,0-.337L7.3,0V5.75h7.561A.244.244,0,0,1,15.126,6v1.6a.245.245,0,0,1-.265.247H7.3Z"
                        transform="translate(26.471 26.471) rotate(180)"
                        fill="#c15db0"
                    />
                </g>
            </g>
        </g>
    </svg>
);
