import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { WeeklyRotaModel } from "../WeeklyRotaModel";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetail/WeeklyRotaDetailViewModel";

class Request {
    public startDate?: Date | string;
    public copyFromPreviousWeek?: boolean;
    public selectedWeekStartDate?: Date | string;
}
class Response {
    public mentorAvailability: any = [];
    public rota: any = {};
}

export class POSTRotaEndPoint extends Endpoint<Request, Response> {
    private readonly viewModel: WeeklyRotaModel;
    private readonly weeklyRotaDetailViewModel: WeeklyRotaDetailsViewModel;

    constructor(viewModel: any, weeklyRotaDetailModel: WeeklyRotaDetailsViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.WeeklyRota.SaveRota);
        this.viewModel = viewModel;
        this.weeklyRotaDetailViewModel = weeklyRotaDetailModel;
    }

    public async HandleRequestAsync(model: WeeklyRotaModel): Promise<any> {
        let request: Request = new Request();
        request.startDate = this.weeklyRotaDetailViewModel.model.startDate;
        request.copyFromPreviousWeek = model.copyFromPreviousWeek;
        request.selectedWeekStartDate = model.startDate;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        runInAction(() => {
            this.weeklyRotaDetailViewModel.model.rotaId = "";
            this.weeklyRotaDetailViewModel.model.mentorAvailability = [];
            if (response.rota !== null) {
                this.weeklyRotaDetailViewModel.model.rotaId = response?.rota?.id;
                this.weeklyRotaDetailViewModel.model.mentorAvailability = response.mentorAvailability;
            } else {
                this.weeklyRotaDetailViewModel.model.rotaId = null;
            }
            return Promise.resolve();
        });
    }
}
