//libraries
import { makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { container } from "tsyringe";
import moment from "moment";
import axios, * as Axios from "axios";
//app
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { LookupStore } from "Stores/Domain/LookupStore";

import { ResourceDocumentModel, ResourceDocumentModelValidator } from "../Shared/ResourceDocumentModel";
import { AppUrls } from "AppUrls";
import { FormatFileSize } from "Utils";

export class ResourceDocumentTableItemViewModel extends ViewModelBase<ResourceDocumentModel> {
    public apiClient = new APIClient();
    public lookupStore = container.resolve(LookupStore);
    //region parent commands

    private parentDeleteResourceDocumentCommand: ICommand;
    //endregion parent commands

    constructor(parentDeleteResourceDocumentCommand: ICommand) {
        super(new ResourceDocumentModel());
        this.parentDeleteResourceDocumentCommand = parentDeleteResourceDocumentCommand;
        makeObservable(this, {});
    }

    //region properties

    public get fileName() {
        return this.getValue<string>("fileName");
    }

    private get fileSize() {
        return this.getValue<number>("fileSize");
    }

    public get formattedFileSize() {
        return FormatFileSize(this.fileSize, true);
    }

    private get category() {
        if (this.model.resourceCategoryId == null) {
            return null;
        }
        return this.lookupStore.getResourceCategoryById(this.model.resourceCategoryId);
    }

    public get categoryName() {
        if (this.category == null) {
            return "";
        }
        return this.category.name;
    }

    public get createdByFullName() {
        return this.getValue<string>("createdByFullName");
    }

    public get createdDate() {
        return this.getValue<Date>("createdDate");
    }

    public get formattedCreatedDate() {
        return moment(this.createdDate).format("DD/MM/YYYY");
    }

    //end region properties

    //region commands

    public deleteResourceDocumentCommand: ICommand = new RelayCommand(
        () => {
            this.parentDeleteResourceDocumentCommand.execute(this.model.id);
        },
        () => this.parentDeleteResourceDocumentCommand.canExecute(),
    );

    public downloadResourceDocumentCommand: ICommand = new RelayCommand(async () => {
        try {
            const config: Axios.AxiosRequestConfig = {};
            config.responseType = "blob";
            config.headers = { Authorization: `Bearer ${sessionStorage.getItem(".auth")}` };
            await axios
                .get(AppUrls.Server.Resources.GetResourceDocumentForDownloadById.replace(":id", this.model.id!), config)
                .then((response: any) => {
                    const filename = this.fileName;
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            return { error };
        }
    });

    //endregion commands

    //region filtering

    public filterPredicate = (documentCategoryId: string | null): boolean => {
        let result = true;
        if (result) {
            switch (true) {
                case isEmptyOrWhitespace(documentCategoryId):
                case this.model.resourceCategoryId === documentCategoryId:
                    break;
                default:
                    result = false;
                    break;
            }
        }
        return result;
    };

    //endregion filtering
}
