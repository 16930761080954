import { Endpoint, Http } from "Application";
import { AppUrls } from "AppUrls";
import { WeeklyRotaDetailsViewModel } from "../../WeeklyRotaDetailViewModel";
import moment from "moment";

class Request {
    public id: Guid = "";
    public studentSessionId: Guid = "";
    public startDate: any;
    public isAbsence?: boolean;
}

export class DeleteMentorStudentEndPoint extends Endpoint<Request, Response> {
    private deleteMentorStudentId: Guid;
    private studentSessionId: Guid;

    constructor(deleteMentorStudentId: Guid, studentSessionId: Guid, viewModel: WeeklyRotaDetailsViewModel) {
        super();
        this.deleteMentorStudentId = deleteMentorStudentId;
        this.studentSessionId = studentSessionId;
        this.verb(Http.Delete);
        this.path(AppUrls.Server.WeeklyRota.DeleteMentorRotaAbsence);
    }

    public async HandleRequestAsync(model: WeeklyRotaDetailsViewModel): Promise<any> {
        let request: Request = new Request();
        request.id = this.deleteMentorStudentId;
        request.studentSessionId = this.studentSessionId;
        request.startDate = moment(model.model.startDate).format("YYYY-MM-DD");
        request.isAbsence = false;

        return Promise.resolve(request);
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        return Promise.resolve(responses);
    }
}
