import styled from "@emotion/styled";
import { RelayCommand, theme } from "Application";
import { Box, Button, Label } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import React from "react";
import { observer } from "mobx-react-lite";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetailViewModel";
import moment from "moment";

const Container = styled.div`
    padding: 25px 20px;
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px 30px;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 410px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }

    @media only screen and (max-width: 600px) {
        width: 90%;
        z-index: 999999;
        overflow: auto;
        max-height: 96vh;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

const ListItemBox = styled(Box)`
    grid-template-columns: 1fr 1fr;
    gap: 22px 12px;
    display: grid;
    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

const ListItem = styled(Box)`
    label:first-child {
        margin-bottom: 4px;
    }
    &.fullGrid {
        grid-column-start: 1;
        grid-column-end: 3;
        @media only screen and (max-width: 768px) {
            grid-column-end: 2;
        }
    }
`;

const ViewStudentDetailsButton = styled.button`
    height: 30px;
    border-radius: 5px;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 17px;
    font-family: OpenSans-CondBold;
    background-color: #fcd21d;
    border: none;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 6px 30px;
    cursor: pointer;
`;

export interface IProps {
    onCancel: () => void;
    viewModel: WeeklyRotaDetailsViewModel;
}

const StudentDetailsView: React.FC<IProps> = observer((props: IProps) => {
    const { onCancel, viewModel } = props;

    const closeStudentMentorRotaModalCommand = new RelayCommand(() => {
        onCancel();
    });

    return (
        <>
            {viewModel.model.studentRota && (
                <StyledDialog open={true}>
                    <Header>
                        {viewModel.model.studentRota.studentName}
                        <CloseIcon
                            onClick={() => {
                                onCancel();
                            }}
                        />
                    </Header>
                    <Container>
                        <Box>
                            <ListItemBox>
                                <ListItem>
                                    <Label fontStyle="h3">Session date:</Label>
                                    <Label>{moment(viewModel.model.studentRota.sessionDate).format("DD/MM/yyyy")}</Label>
                                </ListItem>
                                <ListItem>
                                    <Label fontStyle="h3">Session time:</Label>
                                    <Label>
                                        {viewModel.model.studentRota.sessionStartTime ? moment(viewModel.model.studentRota.sessionStartTime, "HH:mm:ss").format("HH:mm") : " "}-
                                        {viewModel.model.studentRota.sessionEndTime ? moment(viewModel.model.studentRota.sessionEndTime, "HH:mm:ss").format("HH:mm") : " "}
                                    </Label>
                                </ListItem>
                                <ListItem>
                                    <Label fontStyle="h3">Attendance:</Label>
                                    <Label
                                        style={{
                                            color: viewModel.model.studentRota.didAttend ? "#27E6A3" : "#E6054E",
                                            font: "normal normal bold 12px/16px Open Sans",
                                            letterSpacing: "-0.1px",
                                            minHeight: "16px",
                                            fontFamily: "OpenSans-CondBold",
                                        }}
                                    >
                                        {viewModel.model.studentRota.didAttend === null
                                            ? ""
                                            : viewModel.model.studentRota.didAttend === true
                                            ? "Attended"
                                            : viewModel.model.studentRota.didAttend === false
                                            ? "Absent"
                                            : ""}
                                    </Label>
                                    <Label
                                        style={{
                                            color: "#E6054E",
                                            font: "italic normal normal 10px/16px Open Sans",
                                            letterSpacing: "-0.08px",
                                            textTransform: "uppercase",
                                            minHeight: "16px",
                                        }}
                                    >
                                        {viewModel.model.studentRota.reportIncident ? "(INCIDENT REPORTED)" : ""}
                                    </Label>
                                </ListItem>
                                <ListItem>
                                    <Label fontStyle="h3">Session report status:</Label>
                                    <Label
                                        style={{
                                            font: "normal normal bold 12px/16px Open Sans",
                                            textTransform: "uppercase",
                                            marginBottom: "4px",
                                            color:
                                                viewModel.model.studentRota.sessionStatusName === "Submitted"
                                                    ? "#11A696"
                                                    : viewModel.model.studentRota.sessionStatusName === "Pending"
                                                    ? "#C15DB0"
                                                    : viewModel.model.studentRota.sessionStatusName === "Overdue"
                                                    ? "#F39200"
                                                    : "",

                                            fontFamily: "OpenSans-CondBold",
                                        }}
                                    >
                                        {viewModel.model.studentRota.sessionStatusName}
                                    </Label>
                                </ListItem>
                                <ListItem className="fullGrid">
                                    <Label fontStyle="h3">Session with mentor:</Label>
                                    <Label style={{ textDecoration: "underline", font: "normal normal normal 12px/17px Open Sans", letterSpacing: "0px", color: "#000000" }}>
                                        {viewModel.model.studentRota.mentorName}
                                    </Label>
                                </ListItem>
                                {viewModel.model.studentRota.sessionStatusName === "Overdue" && (
                                    <ListItem className="fullGrid">
                                        <Label fontStyle="h3">Transport NOT required</Label>
                                    </ListItem>
                                )}
                                {viewModel.model.studentRota.sessionStatusName !== "Overdue" && (
                                    <>
                                        <ListItem>
                                            <Label fontStyle="h3">Pickup location:</Label>
                                            <Label>{viewModel.model.studentRota.pickupAddressTitle ? viewModel.model.studentRota.pickupAddressTitle : ""}</Label>
                                            <Label>{viewModel.model.studentRota.pickupAddressLine1 ? viewModel.model.studentRota.pickupAddressLine1 : ""},</Label>
                                            <Label>{viewModel.model.studentRota.pickupAddressLine2 ? viewModel.model.studentRota.pickupAddressLine2 : ""},</Label>
                                            <Label>{viewModel.model.studentRota.pickupCity ? viewModel.model.studentRota.pickupCity : ""},</Label>
                                            <Label>{viewModel.model.studentRota.pickupPostcode ? viewModel.model.studentRota.pickupPostcode : ""}</Label>
                                        </ListItem>
                                        <ListItem>
                                            <Label fontStyle="h3">Drop off location:</Label>
                                            <Label>{viewModel.model.studentRota.dropAddAddressTitle ? viewModel.model.studentRota.dropAddAddressTitle : ""}</Label>
                                            <Label>{viewModel.model.studentRota.dropAddAddressLine1 ? viewModel.model.studentRota.dropAddAddressLine1 : ""},</Label>
                                            <Label>{viewModel.model.studentRota.dropAddAddressLine2 ? viewModel.model.studentRota.dropAddAddressLine2 : ""},</Label>
                                            <Label>{viewModel.model.studentRota.dropAddCity ? viewModel.model.studentRota.dropAddCity : ""},</Label>
                                            <Label>{viewModel.model.studentRota.dropAddPostcode ? viewModel.model.studentRota.dropAddPostcode : ""}</Label>
                                        </ListItem>
                                    </>
                                )}
                            </ListItemBox>
                        </Box>
                        <Footer>
                            <ButtonGroup>
                                <ViewStudentDetailsButton onClick={() => viewModel.navigateToDetailCommand.execute(viewModel.model.studentRota.studentId)}>
                                    VIEW STUDENT DETAILS
                                </ViewStudentDetailsButton>
                                {viewModel.model.studentRota.sessionStatusName === "Submitted" && (
                                    <ViewStudentDetailsButton
                                        style={{
                                            backgroundColor: viewModel.model.studentRota.didAttend === false ? "#dc3545" : "#fcd21d",
                                            color: viewModel.model.studentRota.didAttend === false ? "#fff" : "#000000",
                                        }}
                                        onClick={() => viewModel.navigateToSessionDetailCommand.execute(viewModel.model.studentRota.studentSessionId)}
                                    >
                                        {viewModel.model.studentRota.didAttend === false ? "VIEW REASON WHY" : "VIEW SESSION REPORT"}
                                    </ViewStudentDetailsButton>
                                )}
                                <Button command={closeStudentMentorRotaModalCommand} displayName={"RETURN TO ROTA"} paletteColor="secondary" />
                            </ButtonGroup>
                        </Footer>
                    </Container>
                </StyledDialog>
            )}
        </>
    );
});

export default StudentDetailsView;
