import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

import { RelayCommand } from "Application/Commands";
import { HeaderLinkModel } from "./HeaderLinkModel";

export class HeaderLinkViewModel extends ViewModelBase<HeaderLinkModel> {
    constructor(displayName: string, routePath: string) {
        super(new HeaderLinkModel(displayName));

        makeObservable(this, {});

        this.setValue("routePath", routePath);

        this.history.listen((update: any) => {
            this.setActiveLink(update.location);
        });

        this.setActiveLink(this.history.location);
    }

    // #region Commands

    public navigateToLink = (url: string) => {
        this.setActiveOption();
        this.history.push(url);
    };

    // #endregion Commands

    // #region Supporting

    public setActiveOption = () => {
        this.setValue("isActive", true);
    };

    public clearActiveOption = () => {
        this.setValue("isActive", false);
    };

    private setActiveLink = (location: any) => {
        if (this.getValue("routePath") === location.pathname || this.getValue("routePath") === location.pathname.split("/")[1]) {
            this.setValue("isActive", true);
        } else {
            this.setValue("isActive", false);
        }
    };

    // #endregion Supporting
}
