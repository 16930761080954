import React from "react";
import { SessionExpiredView } from "./Views/SessionExpired/SessionExpiredView";
import { Error } from "Views/Error/Error";
import { AppUrls } from "./AppUrls";
import { Route, Route as ReactRoute, Routes } from "react-router-dom";
import { DebugRouter } from "./Components/DebugRouter";
import LoginHomeView from "./Views/Login/LoginHomeView";
import { DefaultLayout } from "./Views/Layouts";
import { SplitLayout } from "./Views/Layouts/SplitLayout";
import { StyleSheetView } from "Application/Examples/StyleSheetView";
import { PrivateRoute, ThemedLoader } from "./Components";
import ConfirmEmailView from "./Views/Login/SignUp/ConfirmEmail/ConfirmEmailView";
import { ResetPasswordView } from "./Views/Login/SignIn/ForgotPassword/ResetPasswordView";
import { WeeklyRotaView } from "Views/WeeklyRota/WeeklyRotaView";
import { AttendanceView } from "Views/Attendance/AttendanceView";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";
import MentorsRoutes from "Views/Mentors/MentorsRoutes";
import MentorRoutes from "Views/Admin/Mentors/MentorRoutes";
import { ResourceDocumentsView } from "Views/Resources/ResourceDocuments/ResourceDocumentsView";

//Routes
const AdminRoutes = React.lazy(() => import("./Views/Admin/AdminRoutes"));

// const MentorRoutes = React.lazy(() => import("./Views/Admin/Mentors/MentorRoutes"));
const PageBuilderView = React.lazy(() => import("Views/PageBuilder/PageBuilderView"));
const StudentRoutes = React.lazy(() => import("./Views/Students/StudentsRoutes"));

export const AppRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);

    return (
        <>
            <DebugRouter>
                <Routes>
                    {/* These routes are only available if the user has logged in. */}
                    <Route element={<PrivateRoute isAllowed={() => accountStore.IsLoggedIn} redirectPath={AppUrls.Client.Account.Login} />}>
                        <Route path={"/"} element={<DefaultLayout />}>
                            {/* Resources */}
                            <Route path={"/resources"} element={<ResourceDocumentsView />} />

                            <Route path={AppUrls.Client.Account.SessionExpired} element={<SessionExpiredView />} />
                            <Route path={AppUrls.Client.Error} element={<Error supportEmail={"support@shoothill.com"} />} />

                            <Route path={"/stylesheet"} element={<StyleSheetView />} />
                            <Route
                                path={AppUrls.Client.PageBuilder}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <PageBuilderView />
                                    </React.Suspense>
                                }
                            />

                            {/*Student Route*/}
                            <Route
                                path={"/students/*"}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <StudentRoutes />
                                    </React.Suspense>
                                }
                            />

                            {/*Admin Mentor Route*/}
                            <Route
                                path={"/mentors/*"}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <MentorsRoutes />
                                    </React.Suspense>
                                }
                            />

                            {/* Mentor Role Route*/}
                            <Route
                                path={"/mentor/*"}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <MentorRoutes />
                                    </React.Suspense>
                                }
                            />

                            {/* SW to be implemented */}
                            <Route
                                path={AppUrls.Client.WeeklyRota.Home}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <WeeklyRotaView />
                                    </React.Suspense>
                                }
                            />
                            {/* <Route
                                path={AppUrls.Client.WeeklyRota.WeeklyRotaDetail.Edit}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <WeeklyRotaDetailView />
                                    </React.Suspense>
                                }
                            /> */}
                            <Route
                                path={AppUrls.Client.Attendance.Home}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <AttendanceView />
                                    </React.Suspense>
                                }
                            />

                            <Route
                                path={"/admin/*"}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <AdminRoutes />
                                    </React.Suspense>
                                }
                            />
                        </Route>
                    </Route>
                    <Route element={<SplitLayout />}>
                        <Route path="/account/confirmemail/:token/*" element={<ConfirmEmailView confirmEmail={true} />} />
                        <Route path="/account/resetpassword/:token/*" element={<ResetPasswordView />} />
                        <Route path={"/account/*"} element={<LoginHomeView />} />
                    </Route>

                    {/*404 Route*/}
                    <ReactRoute
                        path={"*"}
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                </Routes>
            </DebugRouter>
        </>
    );
};
