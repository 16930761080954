import { computed, makeObservable, observable, runInAction } from "mobx";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { ResourceDocumentModel, ResourceDocumentModelValidator } from "../Shared/ResourceDocumentModel";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";
import { DocumentFilesViewModel } from "Views/Shared/FileUploader/DocumentFilesViewModel";

export class ResourceDocumentFormViewModel extends ViewModelBase<ResourceDocumentModel> {
    public apiClient = new APIClient();
    private lookupStore = container.resolve(LookupStore);
    //region observables
    public documentFilesViewModel: DocumentFilesViewModel = new DocumentFilesViewModel();
    //endregion observables

    //region parent commands
    private parentCloseResourceDocumentModalCommand: ICommand;
    private parentSubmitResourceDocumentUploadCommand: ICommand;
    //endregion parent commands

    constructor(parentCloseResourceDocumentModalCommand: ICommand, parentSubmitResourceDocumentUploadCommand: ICommand) {
        super(new ResourceDocumentModel());
        this.setValidator(new ResourceDocumentModelValidator());
        makeObservable(this, {
            //observables
            documentFilesViewModel: observable,
            //computed values
            canShowUploadNewResourceDocumentModal: computed,
        });
        this.parentCloseResourceDocumentModalCommand = parentCloseResourceDocumentModalCommand;
        this.parentSubmitResourceDocumentUploadCommand = parentSubmitResourceDocumentUploadCommand;
    }

    //region properties

    public get resourceCategoriesOptions(): KeyValuePair[] {
        return this.lookupStore.resourceCategoryKeyValuePairs;
    }

    public get canSubmitForm(): boolean {
        const isFormValid = this.isModelValid();
        const isFilesValid = this.documentFilesViewModel.isModelValid();
        return isFormValid && isFilesValid;
    }

    //endregion properties

    //region computed values

    public get canShowUploadNewResourceDocumentModal(): boolean {
        return this.documentFilesViewModel != null;
    }

    //endregion computed values

    //region actions

    //endregion actions

    //region commands
    public updateResourceDocumentCategoryCommand: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.updateField("resourceCategoryId", value.key);
    });

    public closeResourceDocumentModalCommand: ICommand = new RelayCommand(
        () => {
            this.parentCloseResourceDocumentModalCommand.execute();
        },
        () => this.parentCloseResourceDocumentModalCommand.canExecute(),
    );

    public submitResourceDocumentCommand: ICommand = new RelayCommand(
        () => {
            this.parentSubmitResourceDocumentUploadCommand.execute();
        },
        () => this.canSubmitForm,
    );

    public deleteResourceDocumentCommand: ICommand = new RelayCommand(() => {});

    //endregion commands

    //region helpers

    private updateField(fieldName: keyof FieldType<ResourceDocumentModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    //endregion helpers
}
