import * as React from "react";
import { SVGProps } from "react";
interface Props extends SVGProps<SVGSVGElement> {
    color?: string;
}
export const TickSVG = (props: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.502" height="19.997" viewBox="0 0 27.502 19.997">
        <path
            id="tick"
            d="M9.312,19.66l-8.961-8.5a1.1,1.1,0,0,1,0-1.617l2.527-2.4a1.252,1.252,0,0,1,1.7,0l5.582,5.279L22.915.333a1.253,1.253,0,0,1,1.708,0L27.15,2.75a1.1,1.1,0,0,1,0,1.615l-16.13,15.3a1.253,1.253,0,0,1-1.705,0Z"
            transform="translate(0.002 0.002)"
            fill="#e6054e"
        />
    </svg>
);
