//libraries
import { container, singleton } from "tsyringe";
import { KeyValuePair } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
//app
import { AccountStore } from "./AccountStores";
import { AbsenceType, ActivityType, DocumentCategory, SessionStatus, StudentStatus, Region, Role, ResourceCategory } from "Application/Models/Domain";
import { Logger } from "index";

type LookupStoreDomainModel = AbsenceType | ActivityType | DocumentCategory | SessionStatus | StudentStatus | Region | Role | ResourceCategory;

@singleton()
export class LookupStore {
    public AccountStore = container.resolve(AccountStore);
    //domain models
    public absenceTypes = observable<AbsenceType>([]);
    public activityTypes = observable<ActivityType>([]);
    public documentCategories = observable<DocumentCategory>([]);
    public regions = observable<Region>([]);
    public resourceCategories = observable<ResourceCategory>([]);
    public sessionStatuses = observable<SessionStatus>([]);
    public studentStatuses = observable<StudentStatus>([]);

    constructor() {
        Logger.logDebug("Domain store created");
        makeObservable(this, {
            absenceTypes: observable,
            activityTypes: observable,
            documentCategories: observable,
            regions: observable,
            resourceCategories: observable,
            sessionStatuses: observable,
            studentStatuses: observable,
        });

        this.initialize();
    }

    public sync(domainModelArray: LookupStoreDomainModel[], lookupStoreObsArray: IObservableArray<LookupStoreDomainModel>) {
        if (domainModelArray.length > 0) {
            domainModelArray.forEach((model) => lookupStoreObsArray.push(model));
        }
    }

    public initialize = () => {
        this.absenceTypes.clear();
        this.activityTypes.clear();
        this.documentCategories.clear();
        this.regions.clear();
        this.resourceCategories.clear();
        this.sessionStatuses.clear();
        this.studentStatuses.clear();

        const initialState = this.AccountStore.initialState;

        if (initialState.absenceTypes) {
            this.sync(initialState.absenceTypes, this.absenceTypes);
        }

        if (initialState.activityTypes) {
            this.sync(initialState.activityTypes, this.activityTypes);
        }

        if (initialState.documentCategories) {
            this.sync(initialState.documentCategories, this.documentCategories);
        }

        if (initialState.regions) {
            this.sync(initialState.regions, this.regions);
        }

        if (initialState.resourceCategories) {
            this.sync(initialState.resourceCategories, this.resourceCategories);
        }

        if (initialState.sessionStatuses) {
            this.sync(initialState.sessionStatuses, this.sessionStatuses);
        }

        if (initialState.studentStatuses) {
            this.sync(initialState.studentStatuses, this.studentStatuses);
        }
    };

    //region key value pairs

    public get absenceTypeKeyValuePairs(): KeyValuePair[] {
        return this.absenceTypes.map((model: AbsenceType) => {
            return { key: model.id, text: model.name } as KeyValuePair;
        });
    }
    public get activityTypeKeyValuePairs(): KeyValuePair[] {
        return this.activityTypes.map((model: ActivityType) => {
            return { key: model.id, text: model.name } as KeyValuePair;
        });
    }

    public get documentCategoryKeyValuePairs(): KeyValuePair[] {
        return this.documentCategories.map((model: DocumentCategory) => {
            return { key: model.id, text: model.name } as KeyValuePair;
        });
    }

    public get regionKeyValuePairs(): KeyValuePair[] {
        return this.regions.map((model: Region) => {
            return { key: model.id, text: model.country } as KeyValuePair;
        });
    }

    public get resourceCategoryKeyValuePairs(): KeyValuePair[] {
        return this.resourceCategories.map((model: ResourceCategory) => {
            return { key: model.id, text: model.name } as KeyValuePair;
        });
    }

    public get sessionStatusKeyValuePairs(): KeyValuePair[] {
        return this.sessionStatuses.map((model: SessionStatus) => {
            return { key: model.id, text: model.name } as KeyValuePair;
        });
    }

    public get studentStatusKeyValuePairs(): KeyValuePair[] {
        return this.studentStatuses.map((model: StudentStatus) => {
            return { key: model.id, text: model.name } as KeyValuePair;
        });
    }

    //endregion key value pairs

    //region properties
    public getDomainModelById(lookupStoreObsArray: IObservableArray<LookupStoreDomainModel>, id: string) {
        return lookupStoreObsArray.find((model) => model.id === id);
    }

    public getActivityTypeById(id: string) {
        return this.getDomainModelById(this.activityTypes, id) as ActivityType | undefined;
    }

    public getAbsenceTypeById(id: string) {
        return this.getDomainModelById(this.absenceTypes, id) as AbsenceType | undefined;
    }

    public getDocumentCategoryById(id: string) {
        return this.getDomainModelById(this.documentCategories, id) as DocumentCategory | undefined;
    }

    public getRegionById(id: string) {
        return this.getDomainModelById(this.regions, id) as Region | undefined;
    }

    public getResourceCategoryById(id: string) {
        return this.getDomainModelById(this.resourceCategories, id) as ResourceCategory | undefined;
    }

    public getSessionStatusById(id: string) {
        return this.getDomainModelById(this.sessionStatuses, id) as SessionStatus | undefined;
    }

    public getStudentStatusById(id: string) {
        return this.getDomainModelById(this.studentStatuses, id) as StudentStatus | undefined;
    }

    //endregion

    public dummyData = () => {
        return [
            {
                key: "John Doe",
                text: "John Doe",
            },
            {
                key: "Jane Doe",
                text: "Jane Doe",
            },
            {
                key: "Jack Doe",
                text: "Jack Doe",
            },
        ] as KeyValuePair[];
    };
    public YesNoOptions = () => {
        return [
            {
                key: "yes",
                text: "Yes",
            },
            {
                key: "no",
                text: "No",
            },
        ] as KeyValuePair[];
    };
}
