import styled from "@emotion/styled";
import { RelayCommand, theme } from "Application";
import { Box, Button, Label } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import React from "react";
import { isNullOrEmpty } from "@shoothill/core";
import { ExclamationIconSVG } from "Assets/Icons/ExclamationIconSVG";
import { ToolTip } from "Views/Students/Forms/StudentDetail/StyleComponents";
import { Files } from "Views/Students/Forms/StudentReports/Files/Files";

const Container = styled.div`
    padding: 25px 20px;
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 30px;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 400px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

const CreateBox = styled(Box)`
    & > div .isButtonDisable {
        cursor: default;
        color: #495057;
        background-color: #e9ecef;
    }
`;

export interface IProps {
    viewModel: any;
    onCancel: () => void;
}

const WeeklyRotaUploadSessionPhotoModal: React.FC<IProps> = (props) => {
    const modalCloseCommand = new RelayCommand(() => {
        props.onCancel();
    });

    const renderError = () => {
        if (props.viewModel.getError("sessionDocument") !== undefined && !isNullOrEmpty(props.viewModel.getError("sessionDocument"))) {
            return (
                <>
                    <Label style={{ color: "rgb(230, 5, 78)", fontWeight: 600, display: "flex", alignItems: "center" }}>
                        File Upload *
                        <ToolTip>
                            <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />
                            <div> {props.viewModel.getError("sessionDocument") as string}</div>
                        </ToolTip>
                    </Label>
                </>
            );
        }
    };

    return (
        <>
            <StyledDialog open={true}>
                <Header>
                    Upload session doc/photo <CloseIcon onClick={() => modalCloseCommand.execute()} />
                </Header>
                <Container>
                    <Box mb="30px">
                        <ThemedEditSelect
                            displayName={"Category"}
                            placeholder={"Please select"}
                            command={props.viewModel.updateDocumentCategoryCommand}
                            options={props.viewModel.getDocumentCategory}
                            value={() => props.viewModel.getValue("documentCategoryId")}
                            validationMessage={() => props.viewModel.getError("documentCategoryId")}
                        />
                    </Box>
                    {/* <Box>{renderError()}</Box> */}
                    <Box>
                        <Files viewModel={props.viewModel.filesViewModel} />
                    </Box>
                    <Footer>
                        <CreateBox>
                            <ButtonGroup>
                                <Button
                                    command={props.viewModel.uploadStudentReportCommand}
                                    className={props.viewModel.apiClient.IsBusy ? "isButtonDisable" : ""}
                                    displayName={"UPLOAD"}
                                    paletteColor="primary"
                                />
                                <Button
                                    command={modalCloseCommand}
                                    className={props.viewModel.apiClient.IsBusy ? "isButtonDisable" : ""}
                                    displayName={"CANCEL"}
                                    paletteColor="secondary"
                                />
                            </ButtonGroup>
                        </CreateBox>
                    </Footer>
                </Container>
            </StyledDialog>
        </>
    );
};

export default WeeklyRotaUploadSessionPhotoModal;
