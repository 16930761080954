//dependencies
import { runInAction } from "mobx";
import { AppUrls } from "AppUrls";

//components
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { MentorWeeklyRotaViewModel } from "../MentorWeeklyRotaViewModel";

export class GETAllMentorEndpoint extends EndpointWithoutRequest<any> {
    private readonly viewModel: MentorWeeklyRotaViewModel;

    constructor(viewModel: MentorWeeklyRotaViewModel) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.MentorRole.GetAllMentors);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        runInAction(() => {
            this.viewModel.model.mentors = response;
        });
    }
}
