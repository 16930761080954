import { runInAction } from "mobx";

import { EndpointWithoutRequest, Http } from "Application";
import { MentorItemViewModel } from "../MentorItemViewModel";
import { MentorsViewModel } from "../MentorViewModel";
import { AppUrls } from "AppUrls";

class Response {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public phone: string = "";
    public lastActionDate: string = "";
    public lastActionByUserId: string = "";
    public updatedDate: string = "";
    public lastActionFirstName: string = "";
    public lastActionLastName: string = "";
}

export class GETMentorsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: MentorsViewModel;

    constructor(viewModel: MentorsViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Mentor.GetAllMentors);
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        runInAction(() => {
            const mentors: MentorItemViewModel[] = [];

            // Build the list of mentors.
            responses.forEach((user: any, index) => {
                const viewModel = new MentorItemViewModel();
                viewModel.id = user.id;
                viewModel.firstName = user.firstName;
                viewModel.lastName = user.lastName;
                viewModel.email = user.email;
                viewModel.phone = user.phone;
                viewModel.lastActionDate = user.lastActionDate;
                viewModel.lastActionByUserId = user.lastActionByUserId;
                viewModel.updatedDate = user.updatedDate;
                viewModel.lastActionFirstName = user.lastActionFirstName;
                viewModel.lastActionLastName = user.lastActionLastName;
                mentors.push(viewModel);
            });

            this.viewModel.mentorViewModels.replace(mentors);
        });
    }
}
