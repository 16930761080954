import React from "react";
import { PrivateRoute } from "Components/PrivateRoute";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";
import { Routes } from "react-router-dom";
import { Route } from "react-router";

import { MentorWeeklyRotaView } from "Views/Mentors/Mentor/WeeklyRota/MentorWeeklyRotaView";
import WeeklyRotaSessionReportView from "Views/Mentors/Mentor/WeeklyRota/Form/WeeklyRotaSessionReportView";

const MentorRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);
    // const isMentor = true;
    const isMentor = accountStore.isInRole("MENTOR");
    return (
        <Routes>
            <Route
                element={
                    <PrivateRoute
                        isAllowed={() => {
                            return accountStore.IsLoggedIn && isMentor;
                        }}
                    />
                }
            >
                <Route path={"/weeklyrota"} element={<MentorWeeklyRotaView />} />
                <Route path={"/weeklyrota/student/:studentId/mentor/:mentorId/report/:id"} element={<WeeklyRotaSessionReportView />} />
                <Route path={"/weeklyrota/reports/report/:reportId"} element={<WeeklyRotaSessionReportView />} />
            </Route>
        </Routes>
    );
};
export default MentorRoutes;
