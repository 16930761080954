import React from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { Footer } from "../Footer/FooterView";
import { InfoBar } from "Components/InfoBar";
import { Outlet } from "react-router";
import { Box, Label } from "Components";
import { Header } from "../Header/Header";
import styled from "@emotion/styled";
import largeBackground from "../../Assets/LayoutBackground@2x.png";

const Fixed = styled(Box)`
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100000;
    width: 100%;
`;

//EN: use empty generic type to get default react props
export const DefaultLayout: React.FC<{}> = (props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    <Fixed>
                        <Header />
                    </Fixed>

                    {/*EN: Make sure you apply overflowContainer classname to all your body content*/}
                    <Box className={"overflowContainer--default"}>
                        <Box className="backgroundContainer" />
                        {/* <InfoBar /> */}
                        {/*{props && <Box>{props.children}</Box>}*/}
                        <Outlet />
                        {/* <Footer /> */}
                    </Box>
                </>
            )}
        </>
    );
};
