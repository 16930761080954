import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { Validator } from "Application/Validation";

export class StudentAbsenceModel extends ModelBase<StudentAbsenceModel> {
    public KEY: string = nanoid();
    public id: string | null = null;
    public studentSessionId: string = "";
    public absenseTypeId: string | null = null;
    public reason: string = "";
    public isAttended: boolean = false;

    constructor() {
        super();
        makeObservable(this, {
            studentSessionId: observable,
            absenseTypeId: observable,
            reason: observable,
            isAttended: observable,
        });
    }
}

export class StudentAbsenceModelModelValidator extends Validator<StudentAbsenceModel> {
    constructor() {
        super();
        this.ruleFor("absenseTypeId")
            .notNull()
            .withMessage("Please select a Reason why")
            .when((model) => {
                return !model.isAttended;
            });
        this.ruleFor("reason")
            .notEmpty()
            .withMessage("Please enter a Reason")
            .when((model) => {
                return !model.isAttended;
            });
    }
}
