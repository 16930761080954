import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { ICommand } from "../../Application/Commands";

export interface IHeaderLogoOptionProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * An optional class name for use with the button.
     */
    style?: React.CSSProperties;
    /**
     * An optional boolean value that handles rendering the component.
     */
    show?: boolean;
    /**
     * A option command to execute.
     */
    command?: ICommand;
}

export const HeaderLogoOptionBase: React.FC<IHeaderLogoOptionProps> = observer((props) => {
    // #region Code Behind

    const getClassName = (): string => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getStyles = (): React.CSSProperties | undefined => {
        return props.style;
    };

    const show = (): boolean => {
        const { show } = props;
        return show ?? true;
    };

    const onClick = (): void => {
        const { command } = props;
        if (command && command.canExecute()) {
            command.execute();
        }
    };

    // #endregion Code Behind

    return show() ? <div style={getStyles()} className={getClassName()} onClick={onClick} /> : <React.Fragment />;
});
