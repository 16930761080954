import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ReferrerOrganisation } from "Application/Models/Domain/ReferrerOrganisation";
import { AttendanceViewModel } from "../AttendanceViewModel";
import { Mentor, Student } from "Views/Students/Forms/StudentReports/Form/SessionReportModel";

class StudentSessionReportByIdResponse {
    public referrerOrganisation: ReferrerOrganisation[] = [];
    public student: Student[] = [];
    public mentor: Mentor[] = [];
}

class Request {
    public organizationId: string | null = null;
}

export class GETAttendanceReportAndRelatedEndpoint extends Endpoint<Request, StudentSessionReportByIdResponse> {
    private readonly viewModel: AttendanceViewModel;
    constructor(viewModel: AttendanceViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Attendance.GetAttendanceReportAndRelated);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();
        request.organizationId = this.viewModel && this.viewModel.model.referrerOrganisationId !== "" ? this.viewModel.model.referrerOrganisationId : null;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: StudentSessionReportByIdResponse): Promise<any> {
        runInAction(() => {
            if (Array.isArray(response.referrerOrganisation) && response.referrerOrganisation.length > 0) {
                this.viewModel.model.referrerOrganisation.replace(
                    response.referrerOrganisation.map((cc: ReferrerOrganisation) => {
                        return { key: cc.id, text: cc.name } as any;
                    }),
                );
            }

            if (Array.isArray(response.student) && response.student.length > 0) {
                this.viewModel.model.students.replace(
                    response.student.map((cc: Student) => {
                        return { key: cc.id, text: cc.firstName + " " + cc.lastName } as any;
                    }),
                );
            }

            if (Array.isArray(response.mentor) && response.mentor.length > 0) {
                this.viewModel.model.mentors.replace(
                    response.mentor.map((cc: Mentor) => {
                        return { key: cc.id, text: cc.firstName + " " + cc.lastName } as any;
                    }),
                );
            }

            this.viewModel.model.mentorId = "";
            this.viewModel.model.studentId = "";
        });
    }
}
