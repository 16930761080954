import { EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
import { AddStudentViewModel } from "../AddStudentViewModel";

class Response {
    public id: string = "";
    public firstName: string = "";
    public lastName: string = "";
}

export class GETStudentsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: AddStudentViewModel;

    constructor(viewModel: AddStudentViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Student.GetAllStudents);
    }

    public async HandleResponseAsync(responses: Response[]): Promise<any> {
        if (responses.length > 0) {
            this.viewModel.model.students.replace(responses);
        }
    }
}
