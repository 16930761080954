import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

import { MentorDetailViewModel } from "../MentorDetailViewModel";
import { MentorDetailModel } from "../MentorDetailModel";

class MentorAvailabilityResponse {
    public monAM = false;
    public monPM = false;
    public tuesAM = false;
    public tuesPM = false;
    public wedsAM = false;
    public wedsPM = false;
    public thurAM = false;
    public thurPM = false;
    public friAM = false;
    public friPM = false;
}

class Request {
    public id: Guid = "";
    public name: string = "";
    public email: string = "";
    public phone: string = "";
    public password: string = "";
    public isAdmin: boolean = false;
    public mentorAvailabilityRequest: MentorAvailabilityResponse | undefined;
}

export class POSTMentorEndPoint extends Endpoint<Request, Response> {
    private readonly viewModel: MentorDetailViewModel;

    constructor(viewModel: MentorDetailViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Mentor.SaveMentor);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: MentorDetailModel): Promise<any> {
        let request: Request = new Request();
        request.id = model.id;
        request.name = model.firstName;
        request.email = model.email;
        request.phone = model.phone;
        request.password = model.password;
        request.isAdmin = false;
        request.mentorAvailabilityRequest = model.mentorAvailability;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        runInAction(() => {
            this.viewModel.history.push(AppUrls.Client.Mentors.Root);
        });
    }
}
