import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { DownloadIcon } from "Assets/Icons/DownloadIcon";
import { DocumentFileViewModel } from "./DocumentFileViewModel";
import { Box, Label } from "Application";
import { CancelSVG } from "Assets/Icons/Cancel";

interface IProps {
    className?: string;
    viewModel: DocumentFileViewModel;
}

export const DocumentFile: React.FC<IProps> = observer((props) => {
    const canDisplayLink = (): boolean => {
        return !isEmptyOrWhitespace(props.viewModel.model.documentUrl);
    };

    const isDisabled = (): boolean => {
        return !props.viewModel.removeCommand.canExecute;
    };

    return (
        <Box background="#FFFFFF" border="1px solid #E6E9ED" padding="8px 10px" display={"flex"} alignItems={"center"} justifyContent={"space-between"} style={{ gap: "10px" }}>
            <Box display={"flex"} alignItems={"center"} style={{ gap: "10px" }}>
                <DownloadIcon />
                <div>
                    {canDisplayLink() ? (
                        <a href={props.viewModel.model.documentUrl} target="_blank">
                            {props.viewModel.displayName}
                        </a>
                    ) : (
                        <Label style={{ font: "normal normal normal 12px/16px Open Sans", letterSpacing: "-0.12px", color: "#000000" }}>{props.viewModel.displayName}</Label>
                    )}
                    <Label style={{ font: "normal normal normal 10px/16px Open Sans", letterSpacing: "-0.1px", color: "#D4D2D7" }}>{props.viewModel.fileSizeBytes}</Label>
                    {/* <Label style={{ font: "normal normal normal 10px/16px Open Sans", letterSpacing: "-0.1px", color: "#D4D2D7" }}>15/05/2023 (Jason Keele)</Label> */}
                </div>
            </Box>
            {!isDisabled() && (
                <Box style={{ cursor: "pointer" }} onClick={() => props.viewModel.removeCommand.execute()}>
                    <CancelSVG />
                </Box>
            )}
        </Box>
    );
});
