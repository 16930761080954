//dependencies
import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";

//components

import { Validator } from "Application/Validation";
import { WeeklyRotaSessionReportModel } from "./Form/WeeklyRotaSessionReportModel";
import { startOfDay } from "date-fns";

export class MentorWeeklyRotaModel extends ModelBase<MentorWeeklyRotaModel> {
    public startDate: Date | string = startOfDay(new Date()).toISOString();
    public sessionDate: string = "";
    public sessionStartTime: string = "";
    public sessionEndTime: string = "";

    public reason: string = "";
    public moreDetail: string = "";
    public mentorsWeeklyRota: any = [];
    public mentors: any = [];
    public selectedStudentAbsense: any = {};
    public selectedDay: string = "";
    public showSelectMentor: boolean = true;
    public studentSessionId: string = "";
    public studentSession: WeeklyRotaSessionReportModel = new WeeklyRotaSessionReportModel();

    public studentId: string = "";
    constructor() {
        super();
        makeObservable(this, {
            startDate: observable,
            sessionDate: observable,
            sessionStartTime: observable,
            sessionEndTime: observable,
            reason: observable,
            mentors: observable,
            moreDetail: observable,
            mentorsWeeklyRota: observable,
            selectedStudentAbsense: observable,
            showSelectMentor: observable,
            studentSession: observable,
            studentSessionId: observable,
            studentId: observable,
        });
    }
}

export class MentorWeeklyRotaModelValidator extends Validator<MentorWeeklyRotaModel> {
    constructor() {
        super();

        this.ruleFor("reason").notNull().withMessage("Please select a reason for absence").notEmpty().withMessage("Please select a reason for absence");
    }
}
