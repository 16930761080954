import { InitialState } from "../Models";
import { makeObservable, observable } from "mobx";
import { deleteJWT, isJWTValid, ViewModelBase } from "@shoothill/core";
import { APIClient } from "../Helpers/APIClient";
import { InitStateEndpoint } from "./InitialStateEndpoint";

export class InitViewModel extends ViewModelBase<any> {
    public apiClient = new APIClient();

    public DataReady: boolean = false;
    public InitData: InitialState = {} as InitialState;

    constructor() {
        super({});
        makeObservable(this, {
            DataReady: observable,
        });
    }

    public getInitialState = async (): Promise<void> => {
        if (!(await isJWTValid())) {
            await deleteJWT();
        }
        const endPoint = new InitStateEndpoint(this);
        await this.apiClient.sendAsync(endPoint);
        //const _ = await endPoint.sendAsync();
    };
}
