import React from "react";
import styled from "@emotion/styled";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { InfoBar } from "Components/InfoBar";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router";
import { Box } from "Components";
import { Header } from "../Header/Header";

const Fixed = styled(Box)`
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100000;
    width: 100%;
`;

//EN: use empty generic type to get default react props
export const DefaultLayoutNoFooter: React.FC<{}> = observer((props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <Box className={"overflowContainer--no-footer"}>
                    <Fixed>
                        <Header />
                    </Fixed>
                    <Box overflow={"auto"} height={"100vh"}>
                        <InfoBar />
                        <Outlet />
                    </Box>
                </Box>
            )}
        </>
    );
});
