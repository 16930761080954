import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { ApplicationError, Box, Button, Label, ThemedLoader, setPageTitle } from "Application";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import styled from "@emotion/styled";
import CloseIcon from "Assets/Icons/CloseIcon.png";
import { MentorsViewModel } from "./MentorViewModel";
import { MentorItemViewModel } from "./MentorItemViewModel";
import { MentorTable } from "../Components/MentorTable";
import { ThemedDialog } from "Styles/ThemedPrimitives/Style1/ThemedDialog";
import DeleteMentorView from "../Components/Modals/DeleteMentorView";
import { PageContainer, PageFilterLabelGroup, PageHeader, PageRoot, PageSubHeader } from "Styles/PageStyles";

const TableData = styled.div`
    margin-bottom: 6rem;
    .mainTable {
        & > div {
            &:first-child {
                background: #f3f3f3;
                & > div {
                    grid-template-columns: 9fr 9fr 9fr 4fr 3fr 1fr !important;
                }
            }
            & > div {
                grid-template-columns: 9fr 9fr 9fr 4fr 3fr 1fr !important;
                &:nth-child(even) {
                    background: #f9f9f9;
                }
                & > div {
                    width: 100%;
                    box-sizing: border-box;
                    &:last-child {
                        border-right: none;
                        > div {
                            justify-content: center;
                        }
                    }
                    &:nth-child(4) {
                        > div {
                            justify-content: center;
                        }
                    }
                }
            }
        }
        .justifyCenter {
            > div {
                justify-content: center;
            }
        }
        #lastAction {
            justify-content: flex-end;
        }
    }

    .yellowBtn {
        height: 30px;
        border-radius: 5px;
        letter-spacing: 0px;
        color: #000000;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 17px;
        font-family: OpenSans-CondBold;
        background-color: #fcd21d;
        border: none;
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding: 6px 30px !important;
        cursor: pointer;
        margin: 0 auto;
        @media only screen and (max-width: 600px) {
            width: 100% !important;
            min-width: 100% !important;
        }
    }
`;

const ViewMentorDetailsButton = styled.button`
    height: 30px;
    border-radius: 5px;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 17px;
    font-family: OpenSans-CondBold;
    background-color: #fcd21d;
    border: none;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 6px 30px;
    cursor: pointer;
    @media only screen and (max-width: 1800px) {
        padding: 6px 20px;
    }
    @media only screen and (max-width: 1600px) {
        padding: 6px 10px;
    }
`;

const AddMentor = styled.div`
    .add-mentor {
        min-width: 310px;
        height: 40px;
        @media only screen and (max-width: 1100px) {
            min-width: 250px;
            position: relative;
            z-index: 9;
            // border-radius: 0px;
        }
        @media only screen and (max-width: 767px) {
            padding: 8px 12px;
        }
    }
`;

const PageHeaderBtn = styled(Box)`
    position: absolute;
    top: 30px;
    right: 0px;
    @media only screen and (max-width: 991px) {
        top: 85px;
    }
`;

export const MentorsView: React.FC = observer(() => {
    const [viewModel] = useState(() => new MentorsViewModel());

    useEffect(() => {
        setPageTitle("Mentors");
    }, []);

    const renderDeleteMentorModel = () => {
        return (
            <>
                <ThemedDialog title="Are you sure you want to delete this mentor?" isOpen={viewModel.deleteModalOpen} onCloseCommand={viewModel.deleteModalCloseCommand}>
                    {
                        <DeleteMentorView
                            onCancel={() => viewModel.deleteModalCloseCommand.execute()}
                            onDelete={() => viewModel.deleteMentorCommand.execute(viewModel.deleteMentorId)}
                        />
                    }
                </ThemedDialog>
            </>
        );
    };

    const tableColumns = useMemo<ColumnDef<MentorItemViewModel>[]>(
        () => [
            {
                header: " ",
                columns: [
                    {
                        accessorKey: "firstName",
                        header: () => <Label fontStyle="h4">Mentor Name</Label>,
                        cell: (props: CellContext<MentorItemViewModel, any>) => <div>{props.row.original.formattedFullName}</div>,
                    },
                    {
                        accessorKey: "email",
                        header: () => <Label fontStyle="h4">Mentor email address</Label>,
                        cell: (info) => info.getValue(),
                    },
                    {
                        accessorKey: "phone",
                        header: () => <Label fontStyle="h4">Mentor Phone Number</Label>,
                        cell: (props: CellContext<MentorItemViewModel, any>) => <div>{props.row.original.phones}</div>,
                        enableSorting: false,
                    },
                    {
                        id: "mentordetails",
                        enableSorting: false,
                        cell: (props: CellContext<MentorItemViewModel, any>) => (
                            <Box>
                                <ViewMentorDetailsButton onClick={() => viewModel.navigateToMentorDetailsCommand.execute(props.row.original.id)}>
                                    View Mentor Details
                                </ViewMentorDetailsButton>
                            </Box>
                        ),
                    },

                    {
                        accessorKey: "lastActionDates",
                        header: () => <Label fontStyle="h4">Last Action</Label>,
                        cell: (props: CellContext<MentorItemViewModel, any>) => (
                            <Box flexBox flexDirection={"column"} width={"100%"} textAlign={"end"}>
                                <Label>{props.row.original.formattedLastActionDate}</Label>
                                <Label>{props.row.original.formattedActionName}</Label>
                            </Box>
                        ),
                    },

                    {
                        id: "close",
                        enableSorting: false,
                        cell: (props: CellContext<MentorItemViewModel, any>) => (
                            <button className="close-btn" onClick={() => viewModel.deleteModalOpenCommand.execute(props.row.original.id)}>
                                <img src={CloseIcon} alt="close" />
                            </button>
                        ),
                    },
                ],
            },
        ],
        [],
    );

    return (
        <PageRoot style={{ paddingBottom: "10rem" }}>
            <PageHeader>
                <Label fontStyle="h1" color="black">
                    Mentors
                </Label>
            </PageHeader>
            <PageContainer style={{ position: "relative" }}>
                {/* region filter and upload new resource */}
                <PageHeaderBtn>
                    <PageFilterLabelGroup></PageFilterLabelGroup>
                    <AddMentor>
                        <Button paletteColor="primary" command={viewModel.navigateToAddMentorCommand} displayName={"Add Mentor"} className="add-mentor" />
                    </AddMentor>
                </PageHeaderBtn>
                {/* endregion filter and upload new resource */}
                {(() => {
                    switch (true) {
                        case viewModel.apiClient.IsBusy:
                            return <ThemedLoader isOpen delayBeforeOpen={1000} containerStyles={{ height: "calc(100vh - 160px)", width: "calc(100vw - 80px)" }} />;
                        case viewModel.apiClient.IsSubmitted && viewModel.apiClient.HaveValidationMessage:
                            return (
                                <div className="error-scrollable-section">
                                    <div>
                                        <ApplicationError additionalMessage={viewModel.apiClient.ValidationMessage} resetCommand={viewModel.resetApiClientErrorCommand} />
                                    </div>
                                </div>
                            );

                        default:
                            return (
                                <div className="scrollable-section">
                                    <TableData>
                                        <MentorTable data={viewModel.mentors} columns={tableColumns} showSearchBox={true} serachText="Keyword search" isSearchIcon={true} />
                                    </TableData>
                                </div>
                            );
                    }
                })()}
            </PageContainer>
            {/* region modals */}
            {viewModel.deleteModalOpen && renderDeleteMentorModel()}
            {/* endregion modals */}
        </PageRoot>
    );
});
