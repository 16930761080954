import styled from "@emotion/styled";
import React from "react";
import { getHistory } from "@shoothill/core";
import { Box, Label, RelayCommand } from "Application";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";

const StyledBlock = styled(Box)`
    display: flex;
    transition: all 0.3s ease;
    gap: 0;
    position: relative;
    cursor: pointer;
`;

interface BlockProps {
    title: string;
    path?: string;
    applyTransform?: boolean;
    style?: any;
    onClick?: () => void;
}

export const MenuBlock: React.FC<BlockProps> = (props) => {
    const domainStore = container.resolve(DomainStore);

    const gotoPageCommand = new RelayCommand(() => {
        if (props.path) {
            getHistory().push(props.path);
            domainStore.setSideBarClosed(false);
        }
        if (props.onClick) {
            props.onClick();
            domainStore.setSideBarClosed(true);
        }
    });

    return (
        <StyledBlock id={"block"} onClick={gotoPageCommand.execute} style={props.style!}>
            <Box flexBox style={{ width: "100%", padding: "1.5rem 0 0 2rem", borderBottom: "1px solid #000000", paddingBottom: "1rem" }} alignItems={"center"} gap={"20px"}>
                <Box flexBox flexDirection={"column"} justifyContent={"center"} style={{ gap: 0, cursor: "pointer", display: "flex", alignItems: "center" }} mr={"18px"}>
                    <Label
                        fontStyle={"h4"}
                        style={{
                            font: "normal normal 300 18px/22px Lato",
                            letterSpacing: "0px",
                            color: "#325083",
                            textTransform: "uppercase",
                            margin: "0",
                            fontFamily: "OpenSans-CondBold",
                        }}
                        margin={0}
                    >
                        {props.title}
                    </Label>
                </Box>
            </Box>
        </StyledBlock>
    );
};

MenuBlock.defaultProps = {
    applyTransform: true,
};
