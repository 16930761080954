//dependencies
import { makeObservable, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import moment from "moment";

//components
import { AddStudentItemViewModel } from "./AddStudent/AddStudentItemViewModel";
import { GETRotaByDateEndPoint } from "../EndPoint/GETRotaByDateEndPoint";
import { WeeklyRotaDetailModelValidator, WeeklyRotaDetailModel } from "./WeeklyRotaDetailModel";
import { DeleteMentorStudentEndPoint } from "./AddStudent/EndPoints/DeleteMentorStudentEndPoint";
import { DeleteMentorAbsenceEndPoint } from "./MentorAbsence/EndPoints/DeleteMentorAbsenceEndPoint";
import { GETMentorStudentRotaByIdEndpoint } from "./AddStudent/EndPoints/GETMentorStudentRotaByIdEndpoint";
import { AddStudentViewModel } from "./AddStudent/AddStudentViewModel";
import { GETLeftStudentSessionEndPoint } from "../EndPoint/GETLeftStudentSessionEndPoint";
import { AppUrls } from "AppUrls";
import { RemoveDetailsMentorStudentEndPoint } from "./AddStudent/EndPoints/RemoveDetailsMentorStudentEndPoint";
import { ResetWeeklyRotaEndPoint } from "./AddStudent/EndPoints/ResetWeeklyRotaEndPoint";

export class WeeklyRotaDetailsViewModel extends ViewModelBase<WeeklyRotaDetailModel> {
    public apiClient = new APIClient();
    public weeklyRotaModelopen: boolean = false;
    public mentorAbsenceModalopen: boolean = false;
    public sessionLeftModalOpen: boolean = false;
    public rotaEditable: boolean = false;
    public mentorStudentAdded: boolean = false;
    public successfullyGetWeeklyRota: boolean = false;
    public studentItemViewModel = new AddStudentItemViewModel();
    public addStudentViewModel: AddStudentViewModel;

    constructor() {
        super(new WeeklyRotaDetailModel());
        this.setValidator(new WeeklyRotaDetailModelValidator());
        this.addStudentViewModel = new AddStudentViewModel(this.model.addStudentModel);
        makeObservable(this, {
            weeklyRotaModelopen: observable,
            mentorAbsenceModalopen: observable,
            sessionLeftModalOpen: observable,
            rotaEditable: observable,
            mentorStudentAdded: observable,
            successfullyGetWeeklyRota: observable,
            addStudentViewModel: observable,
            studentItemViewModel: observable,
        });
        this.loadLeftStudentSession();
        this.model.startDate = moment(new Date()).startOf("isoWeek").toDate();
    }

    public toggleWeeklyRotaModalOpenStateCommand = new RelayCommand(() => {
        if (this.weeklyRotaModelopen) this.weeklyRotaModelopen = false;
        else this.weeklyRotaModelopen = true;
    });

    public showMentorAbsenceModalopenCommand = new RelayCommand(() => {
        if (this.mentorAbsenceModalopen) this.mentorAbsenceModalopen = false;
        else this.mentorAbsenceModalopen = true;
    });

    public showSeesionLeftModalopenCommand = new RelayCommand(() => {
        if (this.sessionLeftModalOpen) this.sessionLeftModalOpen = false;
        else this.sessionLeftModalOpen = true;
    });

    public updateRotaStartDateCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("startDate", moment(value).format("YYYY-MM-DD"));
    });

    public updateRotaDateRangeCommand = new RelayCommand((value: Date) => {
        this.updateField("startDate", value.toISOString());
        this.loadRotaByDate();
        this.loadLeftStudentSession();
    });

    public updateNextDateCommand: ICommand = new RelayCommand(() => {
        this.updateField("startDate", moment(this.model.startDate).add(1, "week").startOf("isoWeek").add(1, "day").format("MM-DD-YYYY"));
        this.loadRotaByDate();
        this.loadLeftStudentSession();
    });

    public updatePreviousDateCommand: ICommand = new RelayCommand(() => {
        this.updateField("startDate", moment(this.model.startDate).add(-1, "week").startOf("isoWeek").add(1, "day").format("MM-DD-YYYY"));
        this.loadRotaByDate();
        this.loadLeftStudentSession();
    });

    public showEditableSchdeule = new RelayCommand(() => {
        this.rotaEditable = !this.rotaEditable;
    });

    public async loadRotaByDate(): Promise<void> {
        let _ = await this.apiClient.sendAsync(new GETRotaByDateEndPoint(moment(this.model.startDate).format("YYYY-MM-DD"), this));
        if (this.apiClient.IsRequestSuccessful) {
            this.successfullyGetWeeklyRota = true;
        }
    }

    public async loadLeftStudentSession(): Promise<void> {
        let _ = this.apiClient.sendAsync(new GETLeftStudentSessionEndPoint(this, moment(this.model.startDate).format("YYYY-MM-DD")));
        if (this.apiClient.IsRequestSuccessful) {
        }
    }

    public async deleteStudentMentor(deleteMentorStudentId: Guid, studentSessionId: Guid): Promise<void> {
        let _ = await this.apiClient.sendAsync(new DeleteMentorStudentEndPoint(deleteMentorStudentId, studentSessionId, this), this);
        if (this.apiClient.IsRequestSuccessful) {
            let _ = await this.apiClient.sendAsync(new GETRotaByDateEndPoint(moment(this.model.startDate).format("YYYY-MM-DD"), this));
            if (this.apiClient.IsRequestSuccessful) {
                this.loadLeftStudentSession();
                this.successfullyGetWeeklyRota = true;
            }
        }
    }

    public async removeDetails(studentSessionId: Guid): Promise<void> {
        let _ = await this.apiClient.sendAsync(new RemoveDetailsMentorStudentEndPoint(studentSessionId!, this), this);
        if (this.apiClient.IsRequestSuccessful) {
            let _ = await this.apiClient.sendAsync(new GETRotaByDateEndPoint(moment(this.model.startDate).format("YYYY-MM-DD"), this));
            if (this.apiClient.IsRequestSuccessful) {
                this.loadLeftStudentSession();
                this.successfullyGetWeeklyRota = true;
            }
        }
    }

    public async getMentorStudentRotaId(mentorStudentRotaId: string): Promise<void> {
        if (mentorStudentRotaId) {
            let _ = await this.apiClient.sendAsync(new GETMentorStudentRotaByIdEndpoint(mentorStudentRotaId, this));
        }
        if (this.apiClient.IsRequestSuccessful) {
            this.loadLeftStudentSession();
            this.successfullyGetWeeklyRota = true;
        }
    }

    public async deleteMentorRotaAbsence(deleteMentorRotaId: Guid): Promise<void> {
        let _ = await this.apiClient.sendAsync(new DeleteMentorAbsenceEndPoint(deleteMentorRotaId), this);
        if (this.apiClient.IsRequestSuccessful) {
            let _ = await this.apiClient.sendAsync(new GETRotaByDateEndPoint(moment(this.model.startDate).format("YYYY-MM-DD"), this));
            if (this.apiClient.IsRequestSuccessful) {
                this.successfullyGetWeeklyRota = true;
            }
        }
    }

    public resetRotaCommand = new RelayCommand(async () => {
        let _ = await this.apiClient.sendAsync(new ResetWeeklyRotaEndPoint(moment(this.model.startDate).format("YYYY-MM-DD"), this));
        if (this.apiClient.IsRequestSuccessful) {
            let _ = await this.apiClient.sendAsync(new GETRotaByDateEndPoint(moment(this.model.startDate).format("YYYY-MM-DD"), this));
            if (this.apiClient.IsRequestSuccessful) {
                this.successfullyGetWeeklyRota = true;
            }
        }
    });

    public navigateToDetailCommand: ICommand = new RelayCommand((id: string) => {
        this.history.push(AppUrls.Client.Students.Student.StudentDetail.Edit.replace(":id", id));
    });

    public navigateToSessionDetailCommand = new RelayCommand((reportId: string) => {
        this.history.push(AppUrls.Client.Students.Student.StudentReports.Add.replace(":reportId", reportId));
    });

    private async updateField(fieldName: keyof FieldType<WeeklyRotaDetailModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
}
