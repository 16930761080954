import styled from "@emotion/styled";
import { theme } from "Application";
import { Box, Button, EditDate, Label, RadioButton } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import { RadioGroup } from "Views/Mentors/Form/MentorDetail/StyleComponents";
import React from "react";
import { WeeklyRotaViewModel } from "../WeeklyRotaViewModel";
import { ClearButton } from "Components/General/ClearButton";
import { observer } from "mobx-react-lite";

const Container = styled.div`
    padding: 25px 20px;
`;

const DateBox = styled(Box)`
    & > div {
        & > div:first-child {
            display: none !important;
        }
    }
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 30px;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 400px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

export interface IProps {
    viewModel: WeeklyRotaViewModel;
}

export const AddWeeklyRotaModal: React.FC<IProps> = observer((props: IProps) => {
    const { viewModel } = props;

    return (
        <>
            <StyledDialog open={true}>
                <Header>
                    Add weekly rota
                    <ClearButton command={viewModel.toggleWeeklyRotaModalOpenStateCommand} displayName={<CloseIcon />} />
                </Header>
                <Container>
                    <Box>
                        <Label
                            style={{
                                font: "normal normal bold 14px/19px Open Sans",
                                margin: "0 0 12px",
                                letterSpacing: "-0.07px",
                                fontFamily: "OpenSans-CondBold",
                                color: "#000000",
                            }}
                        >
                            Do you want to copy a previous rota?
                        </Label>
                        <RadioGroup display={"grid"} alignItems={"center"} gridTemplateColumns={"1fr 1fr 1fr"}>
                            <RadioButton
                                displayName="Does he/she/they have an EHCP?"
                                command={viewModel.copyWeeklyRotaCommand}
                                options={viewModel.yesNoOptions}
                                value={() => viewModel.copyWeeklyDataRadioButtonValue}
                            />
                        </RadioGroup>

                        {viewModel.copyWeeklyData && (
                            <>
                                <Label
                                    style={{
                                        font: "normal normal bold 14px/19px Open Sans",
                                        margin: "0 0 5px",
                                        letterSpacing: "-0.07px",
                                        fontFamily: "OpenSans-CondBold",
                                        color: "#000000",
                                    }}
                                >
                                    Date range of previous rota you want to copy
                                </Label>
                                <DateBox>
                                    <EditDate value={() => viewModel.getValue("startDate")} command={viewModel.updateRotaDateRangeCommand} isWeekDisable={true} />
                                </DateBox>
                            </>
                        )}
                    </Box>
                    <Footer>
                        <ButtonGroup>
                            <Button command={viewModel.addWeeklyRotaCommandAsync} displayName={"ADD"} paletteColor="primary" />
                            <Button command={viewModel.toggleWeeklyRotaModalOpenStateCommand} displayName={"CANCEL"} paletteColor="secondary" />
                        </ButtonGroup>
                    </Footer>
                </Container>
            </StyledDialog>
        </>
    );
});
