//dependencies
import { runInAction } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

//component
import { MentorWeeklyRotaViewModel } from "../MentorWeeklyRotaViewModel";

class Request {
    public studentSessionId: Guid = "";
    public didAttend: boolean = false;
}

export class PUTMentorStudentAttended extends Endpoint<Request, Response> {
    constructor() {
        super();
        this.verb(Http.Put);
        this.path(AppUrls.Server.MentorRole.EditStudentAttendend);
    }

    public async HandleRequestAsync(weeklyRota: MentorWeeklyRotaViewModel): Promise<any> {
        let request: Request = new Request();
        request.studentSessionId = weeklyRota.model.selectedStudentAbsense.studentSessionId;
        request.didAttend = true;
        return Promise.resolve(request);
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        runInAction(() => {});
    }
}
