import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class MentorAbsenceModel extends ModelBase<MentorAbsenceModel> {
    public mentors = observable<any>([]);
    public absenseStartDate?: Date | string;
    public absenseEndDate?: Date | undefined;
    public rotaId: Guid = null;
    public mentorId: string = "";
    public absenceShift: string = "";
    public absenceTime: string = "";
    public absenceShiftAM: string = "";
    public absenceShiftPM: string = "";
    public isAbsence: boolean = false;

    constructor() {
        super();
        makeObservable(this, { absenseStartDate: observable, absenseEndDate: observable, absenceShift: observable, absenceTime: observable });
    }
}

export class MentorAbsenceModelModelValidator extends Validator<MentorAbsenceModel> {
    constructor() {
        super();
        this.ruleFor("mentorId").notNull().withMessage("Please select a mentor ").notEmpty().withMessage("Please select a mentor");
        this.ruleFor("absenceShift").notNull().withMessage("Please select a absence type ").notEmpty().withMessage("Please select a absence type");
        this.ruleFor("absenceTime").notNull().withMessage("Please select a absence time ").notEmpty().withMessage("Please select a absence time");
    }
}
