import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ResourceDocumentResponse, ResourceDocumentsResponse } from "./Shared/Responses";
import { runInAction } from "mobx";
import { ResourceDocumentsViewModel } from "../ResourceDocuments/ResourceDocumentsViewModel";
import { ResourceDocumentTableItemViewModel } from "../ResourceDocumentTableItem/ResourceDocumentTableItemViewModel";

class Request {
    public id: string = "";
    public resourceCategoryId: string = "";
}

export class POSTInsertResourceDocumentEndpoint extends Endpoint<Request, Response> {
    private viewModel: ResourceDocumentsViewModel;
    constructor(viewModel: ResourceDocumentsViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Resources.PostInsertResourceDocument);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        const request = new Request();
        request.resourceCategoryId = this.viewModel.resourceDocumentFormViewModel!.model.resourceCategoryId!;
        const formData = new FormData();
        formData.append("data", JSON.stringify(request));
        for (const file of this.viewModel.resourceDocumentFormViewModel!.documentFilesViewModel.model.files) {
            formData.append("documentFile", file.file!);
        }

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: ResourceDocumentsResponse): Promise<any> {
        runInAction(() => {
            const viewModels: ResourceDocumentTableItemViewModel[] = [];

            response.resourceDocuments.forEach((resourceDocument: ResourceDocumentResponse) => {
                //create viewModel
                const viewModel = new ResourceDocumentTableItemViewModel(this.viewModel.selectResourceDocumentForDeletionCommand);
                viewModel.model.fromResponse(resourceDocument);
                //push viewModel to array
                viewModels.push(viewModel);
            });

            //set viewModels
            this.viewModel.resourceViewModels.replace(viewModels);
        });
    }
}
