//dependencies
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";

//components
import { RelayCommand, theme } from "Application";
import { Box, Button, EditDate, EditSelect, Label } from "Components";
import { CloseIcon } from "Styles/ThemedPrimitives/Style1/CloseIcon";
import { MentorAbsenceViewModel } from "./MentorAbsenceViewModel";
import { WeeklyRotaDetailsViewModel } from "../WeeklyRotaDetailViewModel";
import moment from "moment";

const Container = styled.div`
    padding: 25px 20px;
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 30px;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
    }
`;

const StyledDialog = styled.dialog`
    max-width: 410px;
    width: calc(100% - 50px);
    padding: 0;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcd21d;
    padding: 12px 20px;
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    font-family: OpenSans-CondBold;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

const FormField = styled(Box)`
    & > div > div:first-child label:empty {
        display: none !important;
    }
    .editselect {
        & > div {
            display: flex;
        }
    }
`;

const TwoCol = styled(Box)`
    grid-template-columns: 1fr 1fr;
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export interface IProps {
    onCancel: () => void;
    viewModel: WeeklyRotaDetailsViewModel;
}

const MentorAbsenceView: React.FC<IProps> = observer((props: IProps) => {
    const [viewModel] = useState(() => new MentorAbsenceViewModel());

    const mentorAbsencerRotaCommand = new RelayCommand(() => {
        let selectedRotaId = props!.viewModel!.model.rotaId;
        viewModel.addMentorAbsenseRotaCommand(selectedRotaId, props.viewModel, props);
    });

    const mentorAbsencerModelCloseCommand = new RelayCommand(() => {
        props.onCancel();
    });

    return (
        <>
            <StyledDialog open={true}>
                <Header>
                    Add mentor absence <CloseIcon onClick={() => props.onCancel()} />
                </Header>
                <Container>
                    <Box>
                        <FormField>
                            <EditSelect
                                displayName="Select Mentor"
                                placeholder="Type name of mentor or select from dropdown"
                                command={viewModel.updateMentorSelectTimeCommand}
                                options={viewModel.mentorOptions}
                                validationMessage={() => viewModel.getError("mentorId")}
                                value={() => viewModel.getValue("mentorId")}
                            />
                        </FormField>
                        <TwoCol display={"grid"} style={{ gap: "30px" }} mt="26px">
                            <Box>
                                <Label
                                    mb={"5px"}
                                    style={{
                                        font: "normal normal bold 14px/19px Open Sans",
                                        fontFamily: "OpenSans-CondBold",
                                        letterSpacing: "-0.21px",
                                        color: "#000000",
                                    }}
                                >
                                    Starting
                                </Label>
                                <FormField mb={"20px"}>
                                    <EditDate
                                        displayName={""}
                                        value={() => viewModel.getValue("absenseStartDate")}
                                        command={viewModel.updateMentorAbsenceStartDateCommand}
                                        includeTime={false}
                                        isWeekDisable={true}
                                        currentDate={moment(props.viewModel.model.startDate).format("DD-MM-YYYY")}
                                    />
                                </FormField>
                                <FormField>
                                    <EditSelect
                                        displayName={"Please select"}
                                        command={viewModel.updateMentorAbsenceShiftCommand}
                                        options={viewModel.getAbsenceShift}
                                        value={() => viewModel.getValue("absenceShift")}
                                        validationMessage={() => viewModel.getError("absenceShift")}
                                    />
                                </FormField>
                            </Box>
                            <Box>
                                <Label
                                    mb={"5px"}
                                    style={{ font: "normal normal bold 14px/19px Open Sans", fontFamily: "OpenSans-CondBold", letterSpacing: "-0.21px", color: "#000000" }}
                                >
                                    Ending
                                </Label>
                                <FormField mb={"20px"}>
                                    <EditDate
                                        displayName={""}
                                        value={() => viewModel.getValue("absenseEndDate")}
                                        command={viewModel.updateMentorAbsenceEndDateCommand}
                                        includeTime={false}
                                        currentDate={moment(props.viewModel.model.startDate).format("DD-MM-YYYY")}
                                    />
                                </FormField>
                                <FormField>
                                    <EditSelect
                                        displayName={"Please select"}
                                        command={viewModel.updateMentorAbsenceTimeCommand}
                                        options={viewModel.filteredMentorAbsenceTime}
                                        value={() => viewModel.getValue("absenceTime")}
                                        validationMessage={() => viewModel.getError("absenceTime")}
                                    />
                                </FormField>
                            </Box>
                        </TwoCol>
                    </Box>
                    <Footer>
                        <ButtonGroup>
                            <Button command={mentorAbsencerRotaCommand} displayName={"ADD"} paletteColor="primary" />
                            <Button command={mentorAbsencerModelCloseCommand} displayName={"CANCEL"} paletteColor="secondary" />
                        </ButtonGroup>
                    </Footer>
                </Container>
            </StyledDialog>
        </>
    );
});

export default MentorAbsenceView;
