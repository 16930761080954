import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { AttendanceModel, AttendanceModelValidator } from "./AttendanceModel";
import { APIClient, RelayCommand, endOfDay } from "Application";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";
import { makeObservable, observable } from "mobx";
import { GETAttendanceReportAndRelatedEndpoint } from "./EndPoints/AttendanceReportAndRelatedEndpoint";
import axios, * as Axios from "axios";
import { AppUrls } from "AppUrls";
import toast from "react-hot-toast";

export class AttendanceViewModel extends ViewModelBase<AttendanceModel> {
    public lookupStore = container.resolve(LookupStore);
    public apiClient = new APIClient();
    public showApplicationBusyValue: boolean = false;

    constructor() {
        super(new AttendanceModel());
        this.setValidator(new AttendanceModelValidator());
        makeObservable(this, { showApplicationBusyValue: observable });

        // Default values
        this.setValue("referrerOrganisationId", "");
        this.setValue("mentorId", "");
        this.setValue("studentId", "");

        this.initialize();
    }

    public get canDisplayReport(): boolean {
        return this.model.isShow;
    }

    public get referrerOrganization(): KeyValuePair[] {
        const referrerOrganizationList = this.model.referrerOrganisation.map((model: any) => {
            return { key: model.key, text: model.text } as KeyValuePair;
        });

        return [{ key: "", text: "N/A" }, ...referrerOrganizationList];
    }

    public get mentorList(): KeyValuePair[] {
        const mentorList = this.model.mentors.map((model: any) => {
            return { key: model.key, text: model.text } as KeyValuePair;
        });

        return [{ key: "", text: "All" }, ...mentorList];
    }

    public get studentList(): KeyValuePair[] {
        const studentList = this.model.students.map((model: any) => {
            return { key: model.key, text: model.text } as KeyValuePair;
        });

        return [{ key: "", text: "All" }, ...studentList];
    }

    public updateFilterReferrerOrganisationCommand = new RelayCommand((value: KeyValuePair) => {
        this.model.referrerOrganisationId = value.key;
        this.apiClient.sendAsync(new GETAttendanceReportAndRelatedEndpoint(this));
        this.model.isShow = false;
    });

    public updateFiltermentorIdCommand = new RelayCommand((value: KeyValuePair) => {
        this.model.mentorId = value.key;
        this.model.isShow = false;
    });

    public updateFilterStudentIdCommand = new RelayCommand((value: KeyValuePair) => {
        this.model.studentId = value.key;
        this.model.isShow = false;
    });

    public updateAttendanceWeekStartDateCommand = new RelayCommand((value: Date) => {
        this.model.attendanceWeekStartDate = value;
        this.model.isShow = false;
    });

    public updateAttendanceWeekEndDateCommand = new RelayCommand((value: Date) => {
        this.model.attendanceWeekEndDate = value;
        this.model.isShow = false;
    });

    public runReportCommand = new RelayCommand(() => {
        if (this.validateDates()) {
            this.runReport();
            this.model.isShow = true;
        } else {
            toast.error("End date should be less than start date.", {
                style: {
                    borderRadius: "1px",
                    fontSize: "small",
                    marginTop: "3rem",
                },
                position: "top-right",
            });
        }
    });

    private validateDates(): boolean {
        const startDate = this.model.attendanceWeekStartDate;
        const endDate = this.model.attendanceWeekEndDate;

        // Check if the end date is not less than the start date
        if (startDate > endDate) {
            return false;
        }
        return true;
    }

    public runExportReportCommand = new RelayCommand(() => {
        this.openPdfDownloadInNewTab();
        this.showApplicationBusyValue = true;
    });

    public openPdfDownloadInNewTab = async () => {
        const newTab = window.open("", "_blank");
        if (!newTab) {
            return;
        }
        try {
            await this.DownloadFile();
            newTab.close();
            this.showApplicationBusyValue = false;
        } catch (error) {
            newTab.close();
        }
    };

    public DownloadFile = async () => {
        const queryParams = {
            studentId: this.model.studentId !== "" ? this.model.studentId : null,
            mentorId: this.model.mentorId !== "" ? this.model.mentorId : null,
            organizationId: this.model.referrerOrganisationId !== "" ? this.model.referrerOrganisationId : null,
            startDate: endOfDay(this.model.attendanceWeekStartDate),
            endDate: endOfDay(this.model.attendanceWeekEndDate),
            isExport: true,
        };

        try {
            let config: Axios.AxiosRequestConfig = {};
            config.responseType = "blob";
            config.headers = { Authorization: `Bearer ${sessionStorage.getItem(".auth")}` };
            config.params = queryParams;

            const response = await axios.get(AppUrls.Server.Attendance.GetAttendanceReport, config);

            const link = document.createElement("a");

            link.href = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));

            link.setAttribute("download", "");

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        } catch (error) {
            console.log(error);
        }
    };

    public runReport = async (): Promise<any> => {
        const queryParams = {
            studentId: this.model.studentId !== "" ? this.model.studentId : null,
            mentorId: this.model.mentorId !== "" ? this.model.mentorId : null,
            organizationId: this.model.referrerOrganisationId !== "" ? this.model.referrerOrganisationId : null,
            startDate: endOfDay(this.model.attendanceWeekStartDate),
            endDate: endOfDay(this.model.attendanceWeekEndDate),
            isExport: false,
        };
        try {
            let config: Axios.AxiosRequestConfig = {};
            config.responseType = "json";
            config.headers = { Authorization: `Bearer ${sessionStorage.getItem(".auth")}` };
            config.params = queryParams;

            const response = await axios.get(AppUrls.Server.Attendance.GetAttendanceReport, config);

            this.model.fromResponse(response.data.payload);
        } catch (error) {
            console.log(error);
        }
    };

    //region actions

    //endregion actions

    // #region Api Commands

    public resetApiClientErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Api Commands

    //region api calls
    public initialize = async () => {
        await this.apiClient.sendAsync(new GETAttendanceReportAndRelatedEndpoint(this));
    };

    //endregion api calls
}
