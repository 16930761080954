import { makeObservable, observable } from "mobx";
import { EditableCopyRoute, EditableCopy, APIClient } from "Application";
import { singleton } from "tsyringe";
import { Logger } from "index";
import { GetEditableCopyEndpoint } from "./GetEditableCopyEndpoint";

@singleton()
export class EditableCopyStore {
    public editableCopies = observable<EditableCopy>([]);
    public editableCopyRoutes = observable<EditableCopyRoute>([]);
    private apiClient = new APIClient();
    private endpoint = new GetEditableCopyEndpoint(this);

    constructor() {
        Logger.logDebug("EditableCopyStore store created");
        makeObservable(this, {
            editableCopies: observable,
            editableCopyRoutes: observable,
        });
        let _ = this.init();
    }

    public init = async () => {
        await this.apiClient.sendAsync(this.endpoint);

        /*this.editableCopies.clear();
        this.editableCopyRoutes.clear();
        if (this.AccountStore.initialState.editableCopies.length > 0) {
            this.AccountStore.initialState.editableCopies.forEach((editableCopy) => {
                this.editableCopies.push(editableCopy);
            });
        }
        if (this.AccountStore.initialState.editableCopyRoutes.length > 0) {
            this.AccountStore.initialState.editableCopyRoutes.forEach((editableCopyRoute) => {
                this.editableCopyRoutes.push(editableCopyRoute);
            });
        }*/
    };
}
