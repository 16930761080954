import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { computed, makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";

import { Validator } from "Application/Validation";
import { DocumentFileModel } from "./DocumentFileModel";

export class DocumentFilesModel extends ModelBase<DocumentFilesModel> {
    public KEY: string = nanoid();
    public files = observable<DocumentFileModel>([]);
    public removedFiles = observable<DocumentFileModel>([]);
    public allowMultipleFiles = false;
    public fileTypes = "";

    constructor() {
        super();

        makeObservable(this, {
            files: observable,
            removedFiles: observable,
            insideUploadLimit: computed,
            validMultipleFiles: computed,
            validFileTypes: computed,
        });
    }

    static readonly MAX_FILESIZE_BYTES = 30000000;
    static readonly MAX_FILESIZE_MB = "28MB";

    public get insideUploadLimit(): boolean {
        return (
            this.files
                .filter((f) => isEmptyOrWhitespace(f.id))
                .map((f) => f.fileSizeBytes)
                .reduce((runningFileSize, fileSizeBytes) => runningFileSize + fileSizeBytes, 0) < DocumentFilesModel.MAX_FILESIZE_BYTES
        );
    }

    public get validMultipleFiles() {
        if (!this.allowMultipleFiles && this.files.length > 1) {
            return false;
        }

        return true;
    }

    public get validFileTypes() {
        if (!isEmptyOrWhitespace(this.fileTypes)) {
            for (const file of this.files) {
                if (!this.isValidFileType(file)) {
                    return false;
                }
            }
        }

        return true;
    }

    private isValidFileType = (fileModel: DocumentFileModel): boolean => {
        if (isEmptyOrWhitespace(this.fileTypes)) {
            return true;
        }

        const fileType = fileModel.file ? fileModel.file.type : fileModel.mimeType;
        const fileName = fileModel.file ? fileModel.file.name : fileModel.fileName;

        const baseFileType = fileType.replace(/\/.*$/, "");
        const acceptedFileMimeTypes = this.fileTypes.split(",");

        for (let acceptedFileMimeType of acceptedFileMimeTypes) {
            acceptedFileMimeType = acceptedFileMimeType.trim();

            if (acceptedFileMimeType.charAt(0) === ".") {
                if (fileName.toLowerCase().indexOf(acceptedFileMimeType.toLowerCase(), fileName.length - acceptedFileMimeType.length) !== -1) {
                    return true;
                }
            } else if (/\/\*$/.test(acceptedFileMimeType)) {
                if (baseFileType === acceptedFileMimeType.replace(/\/.*$/, "")) {
                    return true;
                }
            } else {
                if (fileType === acceptedFileMimeType) {
                    return true;
                }
            }
        }

        return false;
    };
}

export class DocumentFilesModelValidator extends Validator<DocumentFilesModel> {
    constructor() {
        super();
        this.ruleFor("files").must({ predicate: (files, model) => files.length > 0, message: (value, model) => "Please Upload File" });
    }
}
