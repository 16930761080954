import styled from "@emotion/styled";

export const ApplicationRoot = styled.div`
    align-items: center;
    animation: fadeIn 1s;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    height: calc(100vh - 160px);
    width: calc(100vw - 80px);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    overflow: hidden;

    .message {
        color: #707070;
        font-family: Poppins, sans-serif;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        margin-top: 1rem;
    }

    .additional-message {
    }

    .reset-container {
        display: flex;
        justify-content: center;
        margin-top: 1rem;

        button {
            font-size: 0.75rem;
            padding: 0.25rem 2rem;
        }
    }
`;
