import { ModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { nanoid } from "nanoid";
import { Validator } from "Application/Validation";
export class MentorDetailModel extends ModelBase<MentorDetailModel> {
    public KEY: string = nanoid();
    public id: Guid = null;
    public firstName: string = "";
    public lastName: string = "";
    public email: string = "";
    public phone: string = "";
    public password: string = "";
    public confirmPassword: string = "";
    public mentorAvailability = {
        monAM: false,
        monPM: false,
        tuesAM: false,
        tuesPM: false,
        wedsAM: false,
        wedsPM: false,
        thurAM: false,
        thurPM: false,
        friAM: false,
        friPM: false,
    };

    constructor(id: Guid) {
        super();
        this.id = id;
        makeObservable(this, {
            email: observable,
            firstName: observable,
            lastName: observable,
            phone: observable,
            password: observable,
            confirmPassword: observable,
            mentorAvailability: observable,
        });
    }
}

export class MentorDetailModelValidator extends Validator<MentorDetailModel> {
    constructor() {
        super();
        this.ruleFor("firstName")
            .notNull()
            .withMessage("Please enter a name")
            .notEmpty()
            .withMessage("Please enter a name")
            .matches(/^[a-zA-Z\s]+$/)
            .withMessage("Please enter a valid name")
            .maxLength(50)
            .withMessage("name cannot exceed 50 characters");

        this.ruleFor("phone")
            .notNull()
            .withMessage("Please enter a phone number")
            .notEmpty()
            .withMessage("Please enter a phone number")
            .matches(/^$|^\d{10,16}$/)
            .withMessage("Please enter a Valid phone number");

        this.ruleFor("email").emailAddress().maxLength(100);

        this.ruleFor("password")
            .must({
                predicate: (password, model) => password.length >= 8 && passwordPattern.test(password),
                message: (value, model) => "Passwords must be least 8 characters long and must contain at least one number and one special character and one uppercase character.",
            })
            .when((model) => !isEmptyOrWhitespace(model.password));

        this.ruleFor("confirmPassword").must({
            predicate: (confirmPassword, model) => confirmPassword === model.password,
            message: (value, model) => "Passwords must match",
        });
    }
}

const passwordPattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$");
