//libraries
import { ViewModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";

//app
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";
import { ICommand, RelayCommand } from "Application";
import { LogoutModel } from "./LogoutModel";

export class LogoutViewModel extends ViewModelBase<LogoutModel> {
    private accountStore = container.resolve(AccountStore);

    private onCancel: () => void;

    constructor(onCancel: () => void) {
        super(new LogoutModel());
        makeObservable(this, {});
        this.onCancel = onCancel;
    }

    // #region Commands
    public logoutCommand: ICommand = new RelayCommand(() => {
        this.accountStore.Logout();
        this.onCancel();
    });

    public cancelLogoutCommand: ICommand = new RelayCommand(() => {
        this.onCancel();
    });

    // #endregion Commands

    // #region Supporting

    // #endregion Supporting
}
