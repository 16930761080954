import { action } from "mobx";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { WeeklyRotaSessionReportModel } from "../Form/WeeklyRotaSessionReportModel";
import { WeeklyRotaSessionReportViewModel } from "../Form/WeeklyRotaSessionReportViewModel";

class Request {
    public name: string = "";
}
class Response {
    public name: string | null = null;
    public type: string | null = null;
    public ordinal: number = 0;
    public isDeleted: boolean = false;
    public id: string | null = null;
}

export class POSTSaveActivityOptionsEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: WeeklyRotaSessionReportViewModel;

    constructor(viewModel: WeeklyRotaSessionReportViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Student.StudentSessions.PostSaveSessionActivity);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(model: WeeklyRotaSessionReportModel): Promise<any> {
        let request: Request = new Request();
        request.name = model.activityOptionName;
        return Promise.resolve(request);
    }

    @action
    public async HandleResponseAsync(response: any): Promise<any> {
        this.viewModel.model.activityTypeId = response.id;
        const newObj = { key: response.id, text: response.name };
        this.viewModel.getActivity.push(newObj);
    }
}
