// App
import { BaseStore } from "@shoothill/core";
import { Stores } from "Stores";
import { RoleModel } from "Application/Models";
import { singleton } from "tsyringe";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { APIClient } from "Application/Helpers";
import { GetRolesEndpoint } from "./GetRolesEndpoint";

interface Filters {
    date: any;
    orderAsc: boolean;
    searchFilter: string;
}

@singleton()
export class RoleStore extends BaseStore {
    public roles = observable<RoleModel>([]);
    private apiClient = new APIClient();

    private dataLoaded: boolean = false;

    private filters: Filters = {
        date: Date,
        orderAsc: true,
        searchFilter: "",
    };
    private lastUpdated: any = new Date();

    public constructor() {
        super();
        makeObservable<RoleStore, "dataLoaded" | "filters" | "lastUpdated">(this, {
            roles: observable,
            dataLoaded: observable,
            filters: observable,
            lastUpdated: observable,
            getRoleCount: computed,
            setRoles: action,
            clearRoles: action,
            addNewRole: action,
            getRoles: computed,
            isDataLoaded: computed,
            getFilters: computed,
            getFilteredRoles: computed,
        });

        const doAsync = async () => {
            if (this.roles.length === 0) {
                let _ = await this.apiClient.sendAsync(new GetRolesEndpoint(this));
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.dataLoaded = true;
                    });
                }
            }
        };
        const _ = doAsync();
    }

    public Init(stores: Stores) {}

    get getRoleCount(): number {
        return this.roles.length;
    }

    public setRoles(roles: RoleModel[]) {
        this.roles.clear();
        this.roles.push(...roles);
        this.lastUpdated = new Date();
    }

    public clearRoles() {
        this.roles.clear();
    }

    public get getRoles() {
        return this.roles.filter((r: RoleModel) => !r.isDeleted);
    }

    public get getFilters() {
        return this.filters;
    }

    public get isDataLoaded(): boolean {
        return this.dataLoaded;
    }

    public addNewRole = (role: RoleModel) => {
        this.roles.push(role);
    };

    public get getFilteredRoles() {
        let roles = this.roles.slice();
        if (this.filters.searchFilter) {
            roles = this.roles.filter((or) => or.name.toLowerCase().includes(this.filters.searchFilter));
        }
        return roles;
    }
}
