import { ModelBase } from "@shoothill/core";
import { Validator } from "Application/Validation";
import { makeObservable, observable } from "mobx";

export class ResourceDocumentsModel extends ModelBase<ResourceDocumentsModel> {
    //region filtering
    public filterResourceCategoryId: Guid = null;

    //endregion filtering
    constructor() {
        super();
        makeObservable(this, {
            filterResourceCategoryId: observable,
        });
    }
}

export class ResourceDocumentsModelValidator extends Validator<ResourceDocumentsModel> {
    constructor() {
        super();
    }
}
