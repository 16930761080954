//libraries
import React from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";

//app
import { Box, Button } from "Application";
import { ResourceDocumentFormViewModel } from "./ResourceDocumentFormViewModel";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import { DocumentFiles } from "Views/Shared/FileUploader/DocumentFiles";

const Container = styled.div`
    padding: 25px 20px;

    .editselect__menu-list {
        max-height: 130px;
    }
`;

const Footer = styled.div``;

const ButtonGroup = styled.div`
    padding: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 30px;
    Button {
        width: 100%;
        margin-bottom: 0;
        height: 40px;
        border-radius: 5px;
    }
    .isButtonDisable {
        cursor: default;
        color: #495057;
        background-color: #e9ecef;
    }
`;

interface IProps {
    viewModel: ResourceDocumentFormViewModel;
    isLoading: boolean;
}

export const ResourceDocumentFormView: React.FC<IProps> = observer((props: IProps) => {
    const { viewModel, isLoading } = props;

    return (
        <>
            <Container>
                <Box mb="30px">
                    <ThemedEditSelect
                        displayName={"Category"}
                        placeholder={"Select a category"}
                        command={viewModel.updateResourceDocumentCategoryCommand}
                        options={viewModel.resourceCategoriesOptions}
                        value={() => viewModel.getValue("resourceCategoryId")}
                        validationMessage={() => viewModel.getError("resourceCategoryId")}
                    />
                </Box>
                <Box>
                    <DocumentFiles viewModel={viewModel.documentFilesViewModel} />
                </Box>
                <Footer>
                    <ButtonGroup>
                        <Button
                            className={isLoading ? "isButtonDisable" : ""}
                            command={viewModel.submitResourceDocumentCommand}
                            displayName={isLoading ? "Loading" : "Upload"}
                            paletteColor="primary"
                        />
                        <Button command={viewModel.closeResourceDocumentModalCommand} displayName={"CANCEL"} paletteColor="secondary" />
                    </ButtonGroup>
                </Footer>
            </Container>
        </>
    );
});
